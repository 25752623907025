import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";
import * as notifications from "../../admin/contants";

export const getServicesStart = () => {
  return {
    type: actionTypes.GET_SERVICES_START,
  };
};

export const getServicesSuccess = (services) => {
  return {
    type: actionTypes.GET_SERVICES_SUCCESS,
    services: services,
  };
};

export const getServicesFail = (error) => {
  return {
    type: actionTypes.GET_SERVICES_FAIL,
    error: error,
  };
};

export const getServices = (lang) => {
  return (dispatch) => {
    dispatch(getServicesStart());
    axios
      .get(`/site/services/active/${lang}`)
      .then((response) => {
        dispatch(getServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServicesFail(err.message));
      });
  };
};

export const getServiceWidgetsStart = () => {
  return {
    type: actionTypes.GET_SERVICE_WIDGETS_START,
  };
};

export const getServiceWidgetsSuccess = (serviceWidgets) => {
  return {
    type: actionTypes.GET_SERVICE_WIDGETS_SUCCESS,
    serviceWidgets: serviceWidgets,
  };
};

export const getServiceWidgetsFail = (error) => {
  return {
    type: actionTypes.GET_SERVICE_WIDGETS_FAIL,
    error: error,
  };
};

export const getServiceWidgets = (lang) => {
  return (dispatch) => {
    dispatch(getServiceWidgetsStart());
    axios
      .get(`/site/services-widgets/active/${lang}`)
      .then((response) => {
        dispatch(getServiceWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getServiceWidgetsFail(err.message));
      });
  };
};
