import { NavLink } from 'react-router-dom';

export const HeaderLink = (props) => {
  const checkActive = (match, location) => {
    if (!match) return false;
    const { pathname } = location;
    const { url } = match;
    return pathname === url;
  };
  return (
    <NavLink
      to={props.page}
      isActive={checkActive}
      activeClassName={
        props.small
          ? 'text-header-main-color'
          : 'border-b-2 border-main-color text-header-main-color'
      }
      className={
        props.small
          ? 'py-2.5 px-14 border-t-2 border-main-color text-header-link-color font-semibold'
          : 'hover:border-b-2 border-main-color text-header-link-color font-semibold'
      }
    >
      {props.headerLinkName}
    </NavLink>
  );
};
