import { Button } from "../components/Buttons";
import "./contactus.css";
import { IoLocationOutline } from "react-icons/io5";
import { FiSmartphone } from "react-icons/fi";
import { BiPhone } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import dotenv from "dotenv";
dotenv.config();

export const ContactUsCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contactUs = useSelector((state) => state.contact.contactUs);
  const contactUsLoading = useSelector(
    (state) => state.contact.contactUsLoading
  );
  const message = useSelector((state) => state.contact.message);
  const messageLoading = useSelector((state) => state.contact.messageLoading);
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getContactUs(language));
  }, [dispatch, language]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [body, setBody] = useState("");
  const sendMessage = useCallback(() => {
    dispatch(actionTypes.sendMessage({ name: name, email: email, body: body }));
  }, [name, email, body, dispatch]);
  return (
    <div id="contact" className="contact-us-body pt-20 pb-10 mt-28">
      <div className="contact-us-card w-90% lg:w-70% m-auto min-h-mail-card h-screen md:h-60vh flex flex-col-reverse md:flex-row">
        {contactUsLoading ? (
          <div className="w-full h-full flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="contact-us-text flex flex-col p-4 md:px-10 md:py-20 md:w-1/2 md:space-y-2 justify-between">
              <div className="contact-us-title pr-8 pb-5 text-3xl text-left">
                <span>{contactUs?.title}</span>
              </div>
              <div className="space-y-3">
                <ContactItem item={contactUs?.address} type="location" />
                <ContactItem item={contactUs?.mobileNumber} type="phone" />
                <ContactItem item={contactUs?.phoneNumber} type="whatsapp" />
              </div>
              <div className="flex flex-col md:space-y-2 py-4">
                <div className="flex justify-between space-x-1 w-full">
                  <input
                    type="text"
                    className="sender-contact w-2/5 p-3 text-xs h-10 outline-none"
                    placeholder="Name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                  <input
                    type="email"
                    className="sender-contact w-1/2 p-3 text-xs h-10 outline-none"
                    placeholder="Email Address"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="pt-2 w-full">
                  <textarea
                    className="sender-message w-full p-3 h-30 text-left text-xs rounded outline-none"
                    placeholder="Write your message here"
                    value={body}
                    onChange={(event) => setBody(event.target.value)}
                  />
                </div>
              </div>
              {messageLoading ? (
                <div>
                  <Spinner small color="border-black" />
                </div>
              ) : (
                <div className="" onClick={sendMessage}>
                  <Button name={t("Send")} />
                </div>
              )}
            </div>
            <div className="w-full md:w-1/2 h-full items-center relative">
              <img
                alt="Contact us"
                src={`${process.env.REACT_APP_BACKEND_URL}/site/${contactUs?.image}`}
                className="w-full h-full typing-image absolute"
              />
              <div className="absolute z-0 typing-image-backdrop w-full h-full flex bg-red-500 opacity-30"></div>
              {message ? (
                <div className="font-bold absolute z-50 inset-0 flex justify-center items-center">
                  {t("message")}
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const ContactItem = (props) => {
  if (props.type === "location") {
    return (
      <div className="flex space-x-5 items-center contact-item">
        <IoLocationOutline className="h-6 w-5" />
        <span className="text-base">Kanombe, Kigali Rwanda</span>
      </div>
    );
  } else if (props.type === "phone") {
    return (
      <div className="flex space-x-5 items-center contact-item">
        <BiPhone className="h-6 w-5" />
        <span>(+250) 787 113 065</span>
      </div>
    );
  } else if (props.type === "whatsapp") {
    return (
      <div className="flex space-x-5 items-center contact-item">
        <FiSmartphone className="h-6 w-5" />
        <span>(+250) 787 113 065</span>
      </div>
    );
  }
};

export const InformationCard = () => {
  const dispatch = useDispatch();
  const quickLinks = useSelector((state) => state.contact.quickLinks);
  const quickLinksLoading = useSelector(
    (state) => state.contact.quickLinksLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getQuickLinks(language));
  }, [dispatch, language]);
  return (
    <div className="information-card flex flex-col py-10 px-10 lg:px-28 xl:px-40 space-y-10 lg:space-y-0 lg:space-x-10 lg:flex-row">
      {quickLinksLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex space-x-4 lg:space-x-10">
            {quickLinks.map((quickLink, index) => {
              if (index < 2) {
                return <InformationCardItem quickLink={quickLink} />;
              } else {
                return null;
              }
            })}
          </div>
          <div className="flex space-x-4 lg:space-x-10">
            {quickLinks.map((quickLink, index) => {
              if (index >= 2) {
                return <InformationCardItem quickLink={quickLink} />;
              } else {
                return null;
              }
            })}
          </div>
        </>
      )}
    </div>
  );
};

export const FAQs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const faqs = useSelector((state) => state.contact.faqs);
  const faqsLoading = useSelector((state) => state.contact.faqsLoading);
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getFaqs(language));
  }, [dispatch, language]);
  return (
    <div
      id="faq"
      className="faqs pl-8 pt-16 pr-10 md:pr-40 md:pl-40 lg:pr-56 lg:pl-56"
    >
      <div className="faqs-title text-left text-2xl lg:text-4xl pb-16">
        <span>{t("Fequently Asked Questions")}</span>
      </div>
      {faqsLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col">
          {faqs.map((faq) => (
            <FAQItem faq={faq} />
          ))}
        </div>
      )}
    </div>
  );
};

export const FAQItem = (props) => {
  return (
    <div className="faq-item text-base flex flex-col pb-5">
      <div className="faq-body-title flex justify-between items-center md:items-end">
        <div>
          <span>
            {props.faq.question != undefined
              ? parse(eval(props.faq.question))
              : parse("<p></p>")}
          </span>
        </div>
        <div>
          <Button name="Go to Page" type="square" />
        </div>
      </div>
      <div className="border-b-2 border-gray-400 space-y-3 faq-line"></div>
      <div className="faq-body-text">
        <p>
          {props.faq.answer != undefined
            ? parse(eval(props.faq.answer))
            : parse("<p></p>")}
        </p>
      </div>
    </div>
  );
};

export const InformationCardItem = (props) => {
  return (
    <div className="flex flex-col information-card-item">
      <div className="info-card-title text-base">
        <span>{props.quickLink.title}</span>
      </div>
      <div className="text-sm">
        {
          props.quickLink.description != undefined
            ? parse(eval(props.quickLink.description))
            : parse("<p></p>"
        )}
      </div>
    </div>
  );
};
