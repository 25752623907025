import Footer from "../footerComponents/Footer";
import Header from "../headerCompoents/Header";
import ScrollToTop from "../components/ScrollToTop";
import { useLocation } from "react-router";
import parse from "html-react-parser";

export const Generic = (props) => {
  const location = useLocation();
  return (
    <div>
      <Header />
      <div className="partners-header-bg w-full py-32 mt-28">
        <span className="partners-header text-center text-2xl lg:text-5xl">
          {location?.state?.title}
        </span>
      </div>
      <div className="w-full flex min-h-mail-card py-10 px-10 lg:px-32">
        {parse(
          location?.state?.content !== undefined
            ? location?.state?.content
            : "<p></p>"
        )}
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};
