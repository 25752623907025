import { Button, SelectButton, TableLink } from "../components/Buttons";
import {
  InputText,
  InputTextArea,
  LinkComponent,
  InsertingLanguagesTable,
  MoreDropDown,
  SelectRadioButton,
  Uploading,
  InputSocialMediaLink,
  ShowOrHideLinks,
} from "./AdminComponentsList";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import AsyncSelect from "react-select/async";
import {ReactNotifications} from "react-notifications-component";
import contants, {
  successNotification,
  confirmNotification,
  successUpdateNotification,
} from "./contants";
import { useParams } from "react-router";
import classnames from "classnames";
import * as adminActionTypes from "../store/actions/adminIndex";
import richTextConstants from "./richtextconstants";

import dotenv from "dotenv";
dotenv.config();
const  ReactNotification=ReactNotifications


export const ExecutiveIntro = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const token = useSelector((state) => state.auth.token);
  const creatorName = useSelector((state) => state.auth.name);
  const object = useSelector((state) => state.adminAbout.executifLeadership);
  const params = useParams();

  const linksLoading = useSelector((state) => state.links.linksLoading);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminExcutifLeadership(params.objectId));
  }, [dispatch]);

  const [title, settitle] = useState(object != null ? object?.title : "");
  const [subtitle, setsubtitle] = useState(
    object != null ? object?.subtitle : ""
  );
  const [description, setdescription] = useState(
    object != null ? eval(object?.description) : ""
  );

  // ON SUBMIT FUNCTION

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || subtitle === "" || description === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            subtitle,
            title,
            description: richtext.editorValue,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminExcutifLeadership(obj));
          successUpdateNotification("Executive Leadership", contants.UPDATE);
          settitle("");
          setsubtitle("");
          setdescription("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              title,
              subtitle,
              description: richtext.editorValue,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Executve Intro", languageName);
          settitle("");
          setsubtitle("");
          setdescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setsubtitle(subtitle);
          setdescription(description);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(actionTypes.createExecutiveIntro(objects, token));
          setcounter(0);
          setObjects([]);
          settitle("");
          setsubtitle("");
          setdescription("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        <ReactNotification />
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Executive Intro (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Executive Intro (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Title"
                  value={title}
                  isClicked={clicked}
                  name="title"
                  onChange={(e) => settitle(e.target.value)}
                />
                <InputText
                  title="Subtitle"
                  value={subtitle}
                  isClicked={clicked}
                  name="subtitle"
                  onChange={(e) => setsubtitle(e.target.value)}
                />
                <div className="">
                  <InputTextArea
                    title="Description"
                    data={description}
                    isClicked={clicked}
                    name="description"
                    onChange={(event) => {
                      setdescription(event);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/about/executive-intro/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const Leader = () => {
  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/about/leaders" menuName="About Us" />
        </div>
        <div className="pt-4 pr-16 w-full">
          <div className="flex justify-between pl-12">
            <div className="flex flex-col pt-5 space-y-5">
              <div className="font-bold flex flex-col">
                <span>Leader</span>
              </div>
              <MoreDropDown title="Choose Leader" />
              DISPLAY IMAGE
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/executive-intro/objects/list"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Career = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const links = useSelector((state) => state.links.links);
  const platfomLinks = useSelector((state) => state.links.platfomLinks);
  const genericLinks = useSelector((state) => state.links.genericLinks);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminAbout.career);
  const token = useSelector((state) => state.auth.token);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );

  const linksLoading = useSelector((state) => state.links.linksLoading);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(actionTypes.getAdminPlatfomLinks());
    dispatch(actionTypes.getAdminGenericLinks());
    dispatch(adminActionTypes.getOneAdminCareer(params.objectId));
  }, [dispatch]);

  const [title, settitle] = useState(object != null ? object?.title : "");
  const [image, setimage] = useState(null);
  const [calltoaction, setcalltoaction] = useState(
    object != null ? object?.calltoaction : ""
  );
  const [calltoactionname, setcalltoactionname] = useState(
    object != null ? object?.calltoactionname : ""
  );
  const [description, setdescription] = useState(
    object != null ? eval(object?.description) : ""
  );

  const fileSelectoHandler = (event) => {
    setimage(event.target.files[0]);
  };

  //SHOWING SITE LINKS
  const [sitelinks, setsitelinks] = useState(false);
  const showOrHideSiteLinks = () => {
    setsitelinks(!sitelinks);
  };

  // SELECT BUTTO IMPLEMENTATION
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  // ON SUBMIT FUNCTION

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      description === "" ||
      calltoaction === "" ||
      title === "" ||
      calltoactionname === ""
    ) {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            description: richtext.editorValue,
            calltoactionname,
            calltoaction,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminCareer(obj));
          successUpdateNotification("Career", contants.UPDATE);
          settitle("");
          setdescription("");
          setcalltoaction("");
          setcalltoactionname("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const id = selectedValue.uuid;
          setObjects([
            ...objects,
            {
              title,
              description: richtext.editorValue,
              calltoactionname,
              calltoaction,
              link: id,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Career", languageName);
          settitle("");
          setdescription("");
          setcalltoaction("");
          setcalltoactionname("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setdescription(description);
          setcalltoaction(calltoaction);
          setcalltoactionname(calltoactionname);
          setdisabled(true);
        }
        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("image", image);
          fd.append("objects", JSON.stringify(objects));
          dispatch(actionTypes.createCareer(fd, token));
          setcounter(0);
          setObjects([]);
          settitle("");
          setdescription("");
          setcalltoaction("");
          setcalltoactionname("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/about/careers" menuName="About Us" />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5 w-full">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Career (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Career (Language: {object?.language}) Editing
                  </span>
                </div>
                <div className="w-70%">
                  <InputText
                    title="Title"
                    value={title}
                    isClicked={clicked}
                    name="title"
                    onChange={(e) => settitle(e.target.value)}
                  />
                  <InputText
                    title="Call to Action"
                    value={calltoaction}
                    isClicked={clicked}
                    name="calltoaction"
                    onChange={(e) => setcalltoaction(e.target.value)}
                  />
                  <InputText
                    title="Call To Action Name"
                    value={calltoactionname}
                    isClicked={clicked}
                    accept={" .mp4, .mpeg"}
                    name="calltoactionname"
                    onChange={(e) => setcalltoactionname(e.target.value)}
                  />
                  <Uploading
                    updating={
                      object != null ? contants.isTrue : contants.isFalse
                    }
                    title="Image"
                    text1="upload image"
                    text2="view the image"
                    name="image"
                    required
                    onChange={fileSelectoHandler}
                  />
                  <InputTextArea
                    title="Description"
                    data={description}
                    isClicked={clicked}
                    name="description"
                    onChange={(event) => {
                      setdescription(event);
                    }}
                  />
                  <div className="pt-5 flex flex-col">
                    <ShowOrHideLinks
                      title={"Select From Platfom Links"}
                      name={"showLinks"}
                      option1="True"
                      option2="False"
                      value={sitelinks}
                      onChange={showOrHideSiteLinks}
                    />
                    <div
                      className={
                        sitelinks === true ? "pt-3 w-full block" : "hidden"
                      }
                    >
                      <label className="input-title">Platfom Links</label>
                      <div className="flex">
                        <AsyncSelect
                          placeholder="Select Platfom Link"
                          className={classnames("mb-2 w-90%", {
                            "mb-2 w-90% border-2 border-red-500":
                              selectedValue === "" && clicked === true,
                          })}
                          cacheOptions
                          defaultOptions={platfomLinks}
                          getOptionLabel={(e) =>
                            e.name + "  (" + e.language + ")"
                          }
                          getOptionValue={(e) => e.uuid}
                          value={selectedValue}
                          onChange={handleChange}
                          onInputChange={handleInputChange}
                          isSearchable
                        />
                      </div>
                      <label className="block text-red-500">
                        {selectedValue === "" && clicked === true
                          ? "Select Link"
                          : ""}
                      </label>
                    </div>
                  </div>
                  <div
                    className={
                      sitelinks !== true ? "pt-3 w-full block" : "hidden"
                    }
                  >
                    <label className="input-title"> Link</label>
                    <div className="flex">
                      <AsyncSelect
                        placeholder="Select Link"
                        className={classnames("mb-2 w-90%", {
                          "mb-2 w-90% border-2 border-red-500":
                            selectedValue === "" && clicked === true,
                        })}
                        cacheOptions
                        defaultOptions={genericLinks}
                        getOptionLabel={(e) =>
                          e.name + "  (" + e.language + ")"
                        }
                        getOptionValue={(e) => e.uuid}
                        value={selectedValue}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        isSearchable
                      />
                    </div>
                    <label className="block text-red-500">
                      {selectedValue === "" && clicked === true
                        ? "Select Link"
                        : ""}
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/about/careers/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const StaffIntro = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminAbout.staff);
  const params = useParams();
  const token = useSelector((state) => state.auth.token);
  const creatorName = useSelector((state) => state.auth.name);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );

  const linksLoading = useSelector((state) => state.links.linksLoading);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminStaff(params.objectId));
  }, [dispatch]);

  const [title, settitle] = useState(object != null ? object?.title : "");
  const [link, setlink] = useState(object != null ? object?.link : "");
  const [description, setdescription] = useState(
    object != null ? eval(object?.description) : ""
  );

  // ON SUBMIT FUNCTION

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || description === "" || link === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            description: richtext.editorValue,
            link,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminStaff(obj));
          successUpdateNotification("Staff Intro", contants.UPDATE);
          settitle("");
          setlink("");
          setdescription("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              title,
              link,
              description: richtext.editorValue,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Staffs Intro", languageName);
          settitle("");
          setlink("");
          setdescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setlink(link);
          setdescription(description);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(actionTypes.createStaffIntro(objects, token));
          setcounter(0);
          setObjects([]);
          settitle("");
          setlink("");
          setdescription("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/about/staff-intros" menuName="About Us" />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Staff Introduction (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Staff Introduction (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Title"
                  value={title}
                  isClicked={clicked}
                  name="title"
                  onChange={(e) => settitle(e.target.value)}
                />
                <InputTextArea
                  title="Description"
                  data={description}
                  isClicked={clicked}
                  name="description"
                  onChange={(event) => {
                    setdescription(event);
                  }}
                />
                <InputText
                  title="Link"
                  value={link}
                  isClicked={clicked}
                  name="link"
                  onChange={(e) => setlink(e.target.value)}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/about/staff-intros/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const StaffMember = () => {
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const object = useSelector((state) => state.adminAbout.staffMember);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const [profile, setprofile] = useState("");
  const [name, setname] = useState(object != null ? object?.name : "");
  const [position, setposition] = useState(
    object != null ? object?.position : ""
  );

  const [isExecutive, setisExecutive] = useState(
    object != null ? object?.isExecutive : ""
  );
  const [quote, setquote] = useState(object != null ? eval(object?.quote) : "");
  const [email, setemail] = useState(object != null ? object?.email : "");

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminStaffMembers(params.objectId));
  }, [dispatch]);

  const selectProfile = (event) => {
    setprofile(event.target.files[0]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      email === "" ||
      quote === "" ||
      isExecutive === "" ||
      position === "" ||
      name === ""
    ) {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            name,
            isExecutive,
            position,
            quote: richtext.editorValue,
            email,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminStaffMembers(obj));
          successUpdateNotification("Staff Member", contants.UPDATE);
          setname("");
          setposition("");
          setisExecutive("");
          setquote("");
          setclicked(false);
        } else {
        }
      } else {
        const object = {
          name,
          position,
          isExecutive,
          quote: richtext.editorValue,
          email,
          creator: creatorName,
        };
        const fd = new FormData();
        fd.append("profile", profile);
        fd.append("name", name);
        fd.append("position", position);
        fd.append("isExecutive", isExecutive);
        fd.append("quote", quote);
        fd.append("email", email);
        fd.append("creator", creatorName);
        dispatch(actionTypes.createStaffMember(fd, token));
        setname("");
        setposition("");
        setisExecutive("");
        setquote("");
        setclicked(false);
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
          <div className="flex justify-between pl-12">
            <div className="flex flex-col pt-5 space-y-5">
              <div className="font-bold flex flex-col">
                <span
                  className={
                    object != null ? "hidden" : "block admin-page-title text-xl"
                  }
                >
                  New Staff Member
                </span>
                <span
                  className={
                    object != null ? "block admin-page-title text-xl" : "hidden"
                  }
                >
                  Staff Member Editing
                </span>
              </div>
              <InputText
                title="Name"
                value={name}
                isClicked={clicked}
                name="name"
                onChange={(e) => setname(e.target.value)}
              />
              <InputText
                title="Email"
                value={email}
                isClicked={clicked}
                name="email"
                onChange={(e) => setemail(e.target.value)}
              />
              <InputText
                title="Position"
                value={position}
                isClicked={clicked}
                name="position"
                onChange={(e) => setposition(e.target.value)}
              />
              <Uploading
                updating={object != null ? contants.isTrue : contants.isFalse}
                title="Profile"
                text1="Upload Profile"
                text2="View Profile"
                name="profile"
                required
                onChange={selectProfile}
              />
              <InputTextArea
                title="Quote"
                data={quote}
                isClicked={clicked}
                name="quote"
                onChange={(event) => {
                  setquote(event);
                }}
              />
              <SelectRadioButton
                title="IsExecutive"
                option1="True"
                option2="False"
                name="isExecutive"
                value={isExecutive}
                isClicked={clicked}
                onChange={(e) => setisExecutive(e.target.value)}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <Button
                name={object === null ? contants.Publish : contants.Update}
                type="square"
              />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/about/staff-members/objects/list"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export const SocialMediaLinkPage = () => {
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const object = useSelector((state) => state.adminAbout.staffMember);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminStaffMembers(params.objectId));
  }, [dispatch]);

  const [facebook, setfacebook] = useState(object != null ? object.email : "");
  const [twitter, settwitter] = useState(object != null ? object.twitter : "");
  const [instagram, setinstagram] = useState(
    object != null ? object.instagram : ""
  );
  const [linkedin, setlinkedin] = useState(
    object != null ? object.linkedin : ""
  );

  const onSubmit = (e) => {
    e.preventDefault();
    if (object === null) {
      alert("Please Select One Staff Member");
    } else {
      const id = object.uuid;
      if (facebook !== "") {
        const object = {
          link: facebook,
          name: contants.facebook,
          creator: creatorName,
          id,
        };
        dispatch(adminActionTypes.createAdminSocialMediaLink(object, token));
        setfacebook("");
      }
      if (twitter !== "") {
        const object = {
          link: twitter,
          name: contants.twitter,
          creator: creatorName,
          id,
        };
        dispatch(adminActionTypes.createAdminSocialMediaLink(object, token));
        settwitter("");
      }
      if (linkedin !== "") {
        const object = {
          link: linkedin,
          name: contants.linkedin,
          creator: creatorName,
          id,
        };
        dispatch(adminActionTypes.createAdminSocialMediaLink(object, token));
        setlinkedin("");
      }
      if (instagram !== "") {
        const object = {
          link: instagram,
          name: contants.instagram,
          creator: creatorName,
          id,
        };
        dispatch(adminActionTypes.createAdminSocialMediaLink(object, token));
        setinstagram("");
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
          <div className="flex justify-between pl-12">
            <div className="flex flex-col pt-5 space-y-5 w-3/5">
              <div className="font-bold flex flex-col">
                <span className="block admin-page-title text-xl">
                  Social Media Links For {object?.name}, {object?.position}
                </span>
              </div>
              <InputText
                title={"Facebook"}
                name={contants.facebook}
                value={facebook}
                onChange={(e) => setfacebook(e.target.value)}
              />
              <InputSocialMediaLink
                title={"Twitter"}
                name={contants.twitter}
                value={twitter}
                onChange={(e) => settwitter(e.target.value)}
              />
              <InputSocialMediaLink
                title={"Instagram"}
                name={contants.instagram}
                value={instagram}
                onChange={(e) => setinstagram(e.target.value)}
              />
              <InputSocialMediaLink
                title={"Linked In"}
                name={contants.linkedin}
                value={linkedin}
                onChange={(e) => setlinkedin(e.target.value)}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <Button name={contants.Publish} type="square" />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/about/staff-members/objects/list"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export const AwardsSummary = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminAbout.awardsIntro);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminAwardsIntro(params.objectId));
  }, [dispatch]);

  const [title, settitle] = useState(object != null ? object?.title : "");
  const [summary, setsummary] = useState(
    object != null ? eval(object?.summary) : ""
  );

  // ON SUBMIT FUNCTION

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || summary === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            summary: richtext.editorValue,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminAwardsIntro(obj));
          successUpdateNotification("Awards Summary", contants.UPDATE);
          settitle("");
          setsummary("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              title,
              summary: richtext.editorValue,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Awards Intro", languageName);
          settitle("");
          setsummary("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setsummary(summary);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(actionTypes.createAwardsIntro(objects, token));
          setcounter(0);
          setObjects([]);
          settitle("");
          setsummary("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Awards Summary (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Awards Summary (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Title"
                  value={title}
                  isClicked={clicked}
                  name="title"
                  onChange={(e) => settitle(e.target.value)}
                />
                <InputTextArea
                  title="Summary"
                  data={summary}
                  isClicked={clicked}
                  name="summary"
                  onChange={(event) => {
                    setsummary(event);
                  }}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/about/awards-summary/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const Awards = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const links = useSelector((state) => state.links.links);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminAbout.award);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );

  const linksLoading = useSelector((state) => state.links.linksLoading);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminAwards(params.objectId));
  }, [dispatch]);

  const [title, settitle] = useState(object != null ? object?.title : "");
  const [image, setimage] = useState(null);
  const [subtitle, setsubtitle] = useState(
    object != null ? object?.subtitle : ""
  );
  const [ismain, setismain] = useState(object != null ? object?.ismain : "");

  const fileSelectoHandler = (event) => {
    setimage(event.target.files[0]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || subtitle === "" || ismain === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            subtitle,
            ismain,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminAwards(obj));
          successUpdateNotification("Award", contants.UPDATE);
          settitle("");
          setsubtitle("");
          setismain("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              title,
              subtitle,
              ismain,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Awards", languageName);
          settitle("");
          setsubtitle("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setsubtitle(subtitle);
          setismain(ismain);
          setdisabled(true);
        }
        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("image", image);
          fd.append("objects", JSON.stringify(objects));
          dispatch(actionTypes.createAwards(fd, token));
          setcounter(0);
          setObjects([]);
          settitle("");
          setsubtitle("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Award (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Award (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Title"
                  value={title}
                  isClicked={clicked}
                  name="title"
                  onChange={(e) => settitle(e.target.value)}
                />
                <InputText
                  title="Subtitle"
                  value={subtitle}
                  isClicked={clicked}
                  name="subtitle"
                  onChange={(e) => setsubtitle(e.target.value)}
                />
                <Uploading
                  updating={object != null ? contants.isTrue : contants.isFalse}
                  title="Image"
                  text2="View the Image"
                  text1="upload Image"
                  name="image"
                  required
                  onChange={fileSelectoHandler}
                />
                <SelectRadioButton
                  title="IsMain"
                  option1="true"
                  option2="false"
                  name="ismain"
                  value={ismain}
                  isClicked={clicked}
                  onChange={(e) => setismain(e.target.value)}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/about/awards/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const EthicsCompliance = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const links = useSelector((state) => state.links.links);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminAbout.ethicsAndCompliance);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );

  const linksLoading = useSelector((state) => state.links.linksLoading);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminEthicsAndCompliance(params.objectId));
  }, [dispatch]);

  const [title, settitle] = useState(object != null ? object?.title : "");
  const [image, setimage] = useState(null);
  const [description, setdescription] = useState(
    object != null ? eval(object?.description) : ""
  );
  const [type, settype] = useState(object != null ? object?.type : "");

  const fileSelectoHandler = (event) => {
    setimage(event.target.files[0]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || description === "" || type === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            description: richtext.editorValue,
            type,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminEthicsAndCompliance(obj));
          successUpdateNotification("Ethics/Compliance", contants.UPDATE);
          settitle("");
          setdescription("");
          settype("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              title,
              description: richtext.editorValue,
              type,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Ethics/Compliance", languageName);
          settitle("");
          setdescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setdescription(description);
          settype(type);
          setdisabled(true);
        }
        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("image", image);
          fd.append("objects", JSON.stringify(objects));
          dispatch(actionTypes.createEthicsCompliance(fd, token));
          setcounter(0);
          setObjects([]);
          settitle("");
          setdescription("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Ethics/Compliance (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Ethics/Compliance (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Title"
                  value={title}
                  isClicked={clicked}
                  name="title"
                  onChange={(e) => settitle(e.target.value)}
                />
                <InputTextArea
                  title="Description"
                  data={description}
                  isClicked={clicked}
                  name="description"
                  onChange={(event) => {
                    setdescription(event);
                  }}
                />
                <Uploading
                  updating={object != null ? contants.isTrue : contants.isFalse}
                  title="Image"
                  text2="View the Image"
                  text1="upload Image"
                  name="image"
                  required
                  onChange={fileSelectoHandler}
                />
                <SelectRadioButton
                  title="Type"
                  option1="ethics"
                  option2="compliance"
                  name="type"
                  value={type}
                  isClicked={clicked}
                  onChange={(e) => settype(e.target.value)}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/about/ethics-and-compliance/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const GetInTouch = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const platfomLinks = useSelector((state) => state.links.platfomLinks);
  const genericLinks = useSelector((state) => state.links.genericLinks);
  const links = useSelector((state) => state.links.links);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminAbout.getInTouch);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );

  const linksLoading = useSelector((state) => state.links.linksLoading);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(actionTypes.getAdminPlatfomLinks());
    dispatch(actionTypes.getAdminGenericLinks());
    dispatch(adminActionTypes.getOneAdminGetInTouch(params.objectId));
  }, [dispatch]);

  const [title, settitle] = useState(object != null ? object?.title : "");
  const [image, setimage] = useState(null);
  const [calltoactionname, setcalltoactionname] = useState(
    object != null ? object?.calltoactionname : ""
  );
  const [description, setdescription] = useState(
    object != null ? eval(object?.description) : ""
  );

  const fileSelectoHandler = (event) => {
    setimage(event.target.files[0]);
  };

  //SHOWING SITE LINKS
  const [sitelinks, setsitelinks] = useState(false);
  const showOrHideSiteLinks = () => {
    setsitelinks(!sitelinks);
  };

  // SELECT BUTTO IMPLEMENTATION
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  // ON SUBMIT FUNCTION

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || calltoactionname === "" || description === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            description: richtext.editorValue,
            calltoactionname,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminGetInTouch(obj));
          successUpdateNotification("Get In Touch", contants.UPDATE);
          settitle("");
          setdescription("");
          setcalltoactionname("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const id = selectedValue.uuid;
          setObjects([
            ...objects,
            {
              title,
              description: richtext.editorValue,
              calltoactionname,
              calltoactionlink: id,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Career", languageName);
          settitle("");
          setdescription("");
          setcalltoactionname("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setdescription(description);
          setcalltoactionname(calltoactionname);
          setdisabled(true);
        }
        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("image", image);
          fd.append("objects", JSON.stringify(objects));
          dispatch(actionTypes.createGetInTouch(fd, token));
          setcounter(0);
          setObjects([]);
          settitle("");
          setdescription("");
          setcalltoactionname("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Get In Touch (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Get I Touch (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Title"
                  value={title}
                  isClicked={clicked}
                  name="title"
                  onChange={(e) => settitle(e.target.value)}
                />
                <InputTextArea
                  title="Description"
                  data={description}
                  isClicked={clicked}
                  name="description"
                  onChange={(event) => {
                    setdescription(event);
                  }}
                />
                <Uploading
                  updating={object != null ? contants.isTrue : contants.isFalse}
                  title="Image"
                  text2="View the Image"
                  text1="upload Image"
                  name="image"
                  required
                  onChange={fileSelectoHandler}
                />
                <InputText
                  title="Call To Action Name"
                  value={calltoactionname}
                  isClicked={clicked}
                  name="calltoactionname"
                  onChange={(e) => setcalltoactionname(e.target.value)}
                />
                <div className="pt-5 flex flex-col">
                  <ShowOrHideLinks
                    title={"Select From Site Links"}
                    name={"showLinks"}
                    option1="True"
                    option2="False"
                    value={sitelinks}
                    onChange={showOrHideSiteLinks}
                  />
                  <div
                    className={
                      sitelinks === true ? "pt-3 w-full block" : "hidden"
                    }
                  >
                    <label className="input-title">Platfom Links</label>
                    <div className="flex">
                      <AsyncSelect
                        placeholder="Select Platfom Link"
                        className={classnames("mb-2 w-90%", {
                          "mb-2 w-90% border-2 border-red-500":
                            selectedValue === "" && clicked === true,
                        })}
                        cacheOptions
                        defaultOptions={platfomLinks}
                        getOptionLabel={(e) =>
                          e.name + "  (" + e.language + ")"
                        }
                        getOptionValue={(e) => e.uuid}
                        value={selectedValue}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        isSearchable
                      />
                    </div>
                    <label className="block text-red-500">
                      {selectedValue === "" && clicked === true
                        ? "Select Link"
                        : ""}
                    </label>
                  </div>
                </div>
                <div
                  className={
                    sitelinks !== true ? "pt-3 w-full block" : "hidden"
                  }
                >
                  <label className="input-title">Call To Action Link</label>
                  <div className="flex">
                    <AsyncSelect
                      placeholder="Select Link"
                      className={classnames("mb-2 w-90%", {
                        "mb-2 w-90% border-2 border-red-500":
                          selectedValue === "" && clicked === true,
                      })}
                      cacheOptions
                      defaultOptions={genericLinks}
                      getOptionLabel={(e) => e.name + "  (" + e.language + ")"}
                      getOptionValue={(e) => e.uuid}
                      value={selectedValue}
                      onChange={handleChange}
                      onInputChange={handleInputChange}
                      isSearchable
                    />
                  </div>
                  <label className="block text-red-500">
                    {selectedValue === "" && clicked === true
                      ? "Select Link"
                      : ""}
                  </label>
                </div>
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/about/get-in-touch/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
