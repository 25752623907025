import React from "react";
import { ServiceImage } from "../../servicesComponent/servicePageComponents";
import { GoGlobe } from "react-icons/go";
import parse from "html-react-parser";

const WidgetB = (props) => {
  return (
    <div
      id={props.widget?.hashlink}
      className="web-service md:min-h-screen-second md:flex flex-col justify-between"
    >
      <div className="flex flex-col md:h-3/4 pb-0 md:pb-10 md:flex-row">
        <div
          data-aos="slide-up"
          data-aos-duration="1500"
          className="h-full hidden md:flex md:flex-col md:w-1/2 px-10 pt-16 justify-between"
        >
          <div>
            <div className="service-title site-text-blue text-xl">
              {props.widget?.title}
            </div>
            <div className="web-service-text text-sm">
              {
                props.widget?.body != undefined ? parse(eval(props.widget?.body)) : parse("<p></p>"
              )}
            </div>
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href={props.widget?.externallink}
            className="flex justify-between"
          >
            <div className="site-text-blue text-sm font-bold">
              CLICK TO SEE WHAT OUR CLIENT THINK
            </div>
            <div className="flex items-center  space-x-5 site-text-blue font-bold">
              <GoGlobe />
              <div>webpage</div>
            </div>
          </a>
        </div>
        <div className="h-full md:w-1/2">
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/site/${props.widget?.image}`}
            alt="webDev"
            className="h-full w-full object-cover object-center"
          />
        </div>
      </div>
      <div
        data-aos="slide-up"
        data-aos-duration="1500"
        className="flex overflow-x-scroll scrollbar-hidden lg:justify-center py-6"
      >
        {props.widget?.subwidgets.map((subWidget, index) => (
          <a
            target="_blank"
            rel="noreferrer"
            href={subWidget?.externallink}
            key={index}
            className="m-4 w-1/2 md:w-52 xl:w-80 flex-shrink-0"
          >
            <ServiceImage
              rounded
              serviceImage={`${process.env.REACT_APP_BACKEND_URL}/site/${subWidget?.image}`}
              name={"IT"}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default WidgetB;
