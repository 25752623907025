import FooterSummary from './FooterSummary';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as actionTypes from '../store/actions/';
import Spinner from '../components/Spinner';
import { Link } from 'react-router-dom';

import dotenv from "dotenv";
dotenv.config();

const Footer = function () {
  const dispatch = useDispatch();
  const homeFooterAddress = useSelector(
    (state) => state.home.homeFooterAddress
  );
  const homeFooterAddressLoading = useSelector(
    (state) => state.home.homeFooterAddressLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getHomeFooterSections(language));
    dispatch(actionTypes.getHomeFooterAddress(language));
  }, [dispatch, language]);
  return (
    <div>
      <FooterSummary />
      <footer className="bootom-footer site-text-blue py-3 lg:px-20 md:px-10">
        {homeFooterAddressLoading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <div className="flex flex-wrap flex-col-reverse justify-center md:justify-between md:flex-row">
            <div className="py-3 md:pl-5 lg:pl-10">
              <a href="/" className="no-underline">
                {homeFooterAddress?.copyright}
              </a>
            </div>
            <div className="flex flex-col space-y-2 py-3 md:flex-row md:space-y-0 md:space-x-5">
              <Link
                to={{ pathname: 'generic', state: { title: 'Site Map' } }}
                className="no-underline"
              >
                {homeFooterAddress?.sitemapname}
              </Link>
              <Link
                to={{ pathname: 'generic', state: { title: 'Privacy Policy' } }}
                className="no-underline"
              >
                {homeFooterAddress?.privacypolicyname}
              </Link>
              <Link
                to={{
                  pathname: 'generic',
                  state: { title: 'Terms Of Service' },
                }}
                className="no-underline"
              >
                {homeFooterAddress?.termsofservicename}
              </Link>
            </div>
          </div>
        )}
      </footer>
    </div>
  );
};
export default Footer;
