import { useState } from "react";
import "./spotlightcss.css";
import { PlayButton } from "../components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useMemo } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";

import dotenv from "dotenv";
dotenv.config();

const HomeSpotlight = () => {
  const [video, setVideo] = useState();
  const targetRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const handleClick = () => {
    try {
      if (isPlaying) {
        targetRef.current.pause();
        setIsPlaying(false);
      } else {
        targetRef.current.play();
        setIsPlaying(true);
      }
    } catch (error) {}
  };
  const dispatch = useDispatch();
  const homeProduct = useSelector((state) => state.home.homeProduct);
  const homeProductLoading = useSelector(
    (state) => state.home.homeProductLoading
  );
  const language = useSelector((state) => state.home.language);

  const callBackFunction = (entries) => {
    const [entry] = entries;
    if (!entry.isIntersecting) {
      targetRef?.current?.pause();
      setIsPlaying(false);
    }
  };

  const options = useMemo(() => {
    return {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
  }, []);

  const refreshing = () => {
    const observer = new IntersectionObserver(callBackFunction, options);
    const currentTarget = targetRef.current;
    if (currentTarget) observer.observe(currentTarget);
    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  };

  document.addEventListener("scroll", () => {
    refreshing();
  });

  useEffect(() => {
    dispatch(actionTypes.getHomeProduct(language));
  }, [dispatch, language]);
  const productSpotlight = {
    title: "Product Spotlight",
    info: "Nostrud do ipsum quis sit ad eiusmod mollit nisi eu ad deserunt eadolor.",
    video: "",
  };
  return (
    <div className="site-default-height items-center w-full relative">
      {homeProductLoading ? (
        <div className="w-full h-full flex justify-center align-center">
          <Spinner />
        </div>
      ) : (
        <>
          <video
            className="w-full h-full object-cover object-center home-carousel-video absolute left-0"
            src={`${process.env.REACT_APP_BACKEND_URL}/site/${homeProduct?.video}`}
            ref={targetRef}
          ></video>
          <div className="absolute z-0 left-0 w-full h-full site-bg opacity-20"></div>
          <div
            data-aos="flip-left"
            data-aos-duration="1500"
            className="absolute z-10 top-0 left-0 w-full h-full flex justify-center items-center"
          >
            <div className="flex flex-col items-center">
              <div className="flex">
                <span className="spotlight-title text-xs md:text-3xl py-4">
                  {homeProduct?.title ?? productSpotlight.title}
                </span>
              </div>
              <div className="flex text-base spotlight-text py-4 md:px-48 md:py-10">
                {homeProduct?.info != undefined
                  ? parse(eval(homeProduct?.info))
                  : parse("<p></p>")}
              </div>
              <div className="flex">
                <PlayButton clicked={handleClick} isPlaying={isPlaying} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HomeSpotlight;
