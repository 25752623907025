import { Button, SelectButton, TableLink } from "../components/Buttons";
import {
  InputText,
  InputTextArea,
  LinkComponent,
  MoreDropDown,
  PreviewButton,
  WidgetPreviewButton,
  SelectRadioButton,
  InsertingLanguagesTable,
  Uploading,
} from "./AdminComponentsList";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import * as adminActionTypes from "../store/actions/adminIndex";
import Spinner from "../components/Spinner";
import AsyncSelect from "react-select/async";
import parse from "html-react-parser";
import contants, {
  successNotification,
  confirmNotification,
  successUpdateNotification,
  successCreationNotification,
} from "./contants";
import {ReactNotifications} from "react-notifications-component";
import { useParams } from "react-router";
import moment from "moment";
import classnames from "classnames";
import richTextConstants from "./richtextconstants";

import dotenv from "dotenv";
dotenv.config();
const   ReactNotification=ReactNotifications


export const ServicesPage = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const links = useSelector((state) => state.links.links);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const params = useParams();
  const object = useSelector((state) => state.adminService.service);
  const [name, setname] = useState(object != null ? object?.name : "");
  const [link, setlink] = useState(object != null ? object?.link : "");
  const [icon, seticon] = useState(null);
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(actionTypes.getLinks());
    dispatch(adminActionTypes.getOneAdminServices(params.objectId));
  }, [dispatch]);

  const fileSelectoHandler = (event) => {
    seticon(event.target.files[0]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (link === "" || name === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            link,
            name,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminServices(obj));
          successUpdateNotification("Service", contants.UPDATE);
          setlink("");
          setname("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              name,
              link,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Service", languageName);
          setlink("");
          setname("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setlink(link);
          setname(name);
          setdisabled(true);
        }
        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("icon", icon);
          fd.append("objects", JSON.stringify(objects));
          dispatch(adminActionTypes.createAdminServices(fd, token));
          setcounter(0);
          setObjects([]);
          setname("");
          setlink("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
          <div className="flex justify-between pl-12">
            <div className="flex flex-col pt-5 w-80%">
              <div className="font-bold flex flex-col">
                <span
                  className={
                    object != null ? "hidden" : "block admin-page-title text-xl"
                  }
                >
                  New Service (Language:{" "}
                  {languages[counter] ? languages[counter].name : ""})
                </span>
                <span
                  className={
                    object != null ? "block admin-page-title text-xl" : "hidden"
                  }
                >
                  Service (Language: {object?.language}) Editing
                </span>
              </div>

              <div className=" w-3/5">
                <InputText
                  title="Name"
                  value={name}
                  isClicked={clicked}
                  name="name"
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className=" w-3/5">
                <InputText
                  title="Link"
                  value={link}
                  isClicked={clicked}
                  name="link"
                  onChange={(e) => setlink(e.target.value)}
                />
              </div>
              <Uploading
                updating={object != null ? contants.isTrue : contants.isFalse}
                title="Icon"
                text1="upload icon"
                text2="view the icon"
                name="icon"
                required={true}
                onChange={fileSelectoHandler}
              />
            </div>
            <div className="flex flex-col space-y-20">
              <div className="flex flex-col space-y-2">
                <Button
                  disabled={disabled}
                  name={object === null ? contants.Save : contants.Update}
                  type="square"
                />
                <Button
                  name={contants.Publish}
                  disabled={!disabled}
                  type="square"
                />
                <TableLink
                  title="List"
                  type="viewLink"
                  link="/admin/services/objects/list"
                />
              </div>
              <div>
                <InsertingLanguagesTable
                  languages={languages}
                  counter={counter}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export const ProductsPage = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const links = useSelector((state) => state.links.links);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const params = useParams();
  const object = useSelector((state) => state.adminProduct.product);
  const [name, setname] = useState(object != null ? object?.name : "");
  const [link, setlink] = useState(object != null ? object?.link : "");
  const [icon, seticon] = useState(null);
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(actionTypes.getLinks());
    dispatch(adminActionTypes.getOneAdminProducts(params.objectId));
  }, [dispatch]);

  const fileSelectoHandler = (event) => {
    seticon(event.target.files[0]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (name === "" || link === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            link,
            name,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminProducts(obj));
          successUpdateNotification("Product", contants.UPDATE);
          setlink("");
          setname("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              name,
              link,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Product", languageName);
          setlink("");
          setname("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setlink(link);
          setname(name);
          setdisabled(true);
        }
        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("icon", icon);
          fd.append("objects", JSON.stringify(objects));
          dispatch(adminActionTypes.createAdminProducts(fd, token));
          setcounter(0);
          setObjects([]);
          setname("");
          setlink("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
          <div className="flex justify-between pl-12">
            <div className="flex flex-col pt-5 w-80%">
              <div className="font-bold flex flex-col">
                <span
                  className={
                    object != null ? "hidden" : "block admin-page-title text-xl"
                  }
                >
                  New Product (Language:{" "}
                  {languages[counter] ? languages[counter].name : ""})
                </span>
                <span
                  className={
                    object != null ? "block admin-page-title text-xl" : "hidden"
                  }
                >
                  Product (Language: {object?.language}) Editing
                </span>
              </div>

              <div className=" w-3/5">
                <InputText
                  title="Name"
                  value={name}
                  isClicked={clicked}
                  name="name"
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className=" w-3/5">
                <InputText
                  title="Link"
                  value={link}
                  isClicked={clicked}
                  name="link"
                  onChange={(e) => setlink(e.target.value)}
                />
              </div>
              <Uploading
                updating={object != null ? contants.isTrue : contants.isFalse}
                title="Icon"
                text1="upload icon"
                text2="view the icon"
                name="icon"
                required
                onChange={fileSelectoHandler}
              />
            </div>
            <div className="flex flex-col space-y-20">
              <div className="flex flex-col space-y-2">
                <Button
                  disabled={disabled}
                  name={object === null ? contants.Save : contants.Update}
                  type="square"
                />
                <Button
                  name={contants.Publish}
                  disabled={!disabled}
                  type="square"
                />
                <TableLink
                  title="List"
                  type="viewLink"
                  link="/admin/products/objects/list"
                />
              </div>
              <div>
                <InsertingLanguagesTable
                  languages={languages}
                  counter={counter}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export const ServiceWidgetPage = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const services = useSelector((state) => state.adminService.adminServices);
  const testimonials = useSelector((state) => state.adminService.testimonials);
  const widgets = useSelector(
    (state) => state.adminService.adminServiceWidgets
  );
  const servicesLoading = useSelector(
    (state) => state.adminService.adminServicessLoading
  );
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const params = useParams();
  const object = useSelector((state) => state.adminService.serviceWidget);
  const [title, settitle] = useState(object != null ? object?.title : "");
  const [subtitle, setsubtitle] = useState(
    object != null ? object?.subtitle : ""
  );
  const [smallsubtitle, setsmallsubtitle] = useState(
    object != null ? object?.smallsubtitle : ""
  );
  const [body, setbody] = useState(object != null ? eval(object?.body) : "");
  const [externallink, setexternallink] = useState(
    object != null ? object?.externallink : ""
  );
  const [widgettype, setwidgettype] = useState("");
  const [hashlink, sethashlink] = useState(
    object != null ? object?.hashlink : ""
  );
  const [description, setdescription] = useState(
    object != null ? eval(object?.description) : ""
  );
  const [isSubwidget, setisSubwidget] = useState(
    object != null ? object?.isSubwidget : ""
  );
  const [subwidgets, setsubwidgets] = useState("");
  const [clienttestimonials, setclienttestimonials] = useState("");
  const [service, setservice] = useState("");
  const [image, setimage] = useState("");
  const [pcanimation, setpcanimation] = useState("");
  const [video, setvideo] = useState("");
  const [mobileanimation, setmobileanimation] = useState("");

  useEffect(() => {
    dispatch(adminActionTypes.getAllAdminServices());
    dispatch(adminActionTypes.getAllServicesSubwidgets());
    dispatch(adminActionTypes.getActiveClientTestimonials());
    // dispatch(adminActionTypes.getOneServicesWidgets(params.objectId));
  }, [dispatch]);

  //UPLOADING FILES
  const selectImage = (event) => {
    setimage(event.target.files[0]);
  };
  const selectVideo = (event) => {
    setvideo(event.target.files[0]);
  };
  const selectPcAnimation = (event) => {
    setpcanimation(event.target.files[0]);
  };
  const selectMobileAnimation = (event) => {
    setmobileanimation(event.target.files[0]);
  };

  // SELECT SERVICE BUTTON IMPLEMENTATION
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  // SELECT WIDGET TYPE BUTTON IMPLEMENTATION
  const [widgetType, setWidgetType] = useState("");
  const [selectedWidgetType, setSelectedWidgetType] = useState("");
  const handleWidgetTypeInputChange = (value) => {
    setWidgetType(value);
  };
  const handleWidgetTypeChange = (value) => {
    setSelectedWidgetType(value);
  };

  // SELECT CLIENT TESTIMONIALS BUTTON IMPLEMENTATION
  const [testimonies, settestimonies] = useState("");
  const [selectedTestimonies, setSelectedTestimonies] = useState([]);
  const handleTestimoniesInputChange = (value) => {
    settestimonies(value);
  };
  const handleTestimoniesChange = (value) => {
    setSelectedTestimonies(value);
  };

  // SELECT SUB WIDGETS BUTTON IMPLEMENTATION
  const [subWidgets, setSubWidgets] = useState("");
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const handleWidgetsInputChange = (value) => {
    setSubWidgets(value);
  };
  const handleWidgetsChange = (value) => {
    setSelectedWidgets(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (selectedWidgetType === "" || isSubwidget === "" || title === "") {
      setclicked(true);
    } else {
      const serviceId = selectedValue.uuid;
      const widgettype = selectedWidgetType?.value;
      const widgetsArray = [];
      selectedWidgets?.forEach((widget) => {
        widgetsArray.push(widget.uuid);
      });
      const testimonies = [];
      selectedTestimonies?.forEach((testimony) => {
        testimonies.push(testimony.uuid);
      });
      const testimoniesList = testimonies.join(",");
      const widgetsList = widgetsArray.join(",");
      const richtextDescription =
        richTextConstants.separateTextAndFiles("description");
      const richtextBody = richTextConstants.separateTextAndFiles("body");
      richtextDescription.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      richtextBody.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            subtitle,
            smallsubtitle,
            body: richtextBody.editorValue,
            externallink,
            hashlink,
            isSubwidget,
            description: richtextDescription.editorValue,
            subwidgets: widgetsList,
            widgettype,
            clienttestimonials: testimoniesList,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateServiceWidget(obj));
          successUpdateNotification("Service Widget", contants.UPDATE);
          settitle("");
          setsubtitle("");
          setsmallsubtitle("");
          setbody("");
          setexternallink("");
          sethashlink("");
          setisSubwidget("");
          setdescription("");
          setwidgettype("");
          setclicked(false);
        } else {
        }
      } else {
        const obj = {
          title,
          subtitle,
          smallsubtitle,
          body: richtextBody.editorValue,
          externallink,
          hashlink,
          isSubwidget,
          description: richtextDescription.editorValue,
          serviceId,
          subwidgets: widgetsList,
          widgettype,
          clienttestimonials: testimoniesList,
          language: contants.widgetLanguage(selectedValue),
          creator: creatorName,
        };
        const fd = new FormData();
        fd.append("image", image);
        fd.append("video", video);
        fd.append("pcanimation", pcanimation);
        fd.append("mobileanimation", mobileanimation);
        fd.append("objects", JSON.stringify(obj));
        dispatch(adminActionTypes.createServiceWidget(fd, serviceId, token));
        settitle("");
        setsubtitle("");
        setsmallsubtitle("");
        setbody("");
        setexternallink("");
        sethashlink("");
        setisSubwidget("");
        setdescription("");
        setwidgettype("");
        setclicked(false);
      }
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
          <div className="flex justify-between pl-12">
            <div className="flex flex-col pt-3 w-80%">
              <div className="font-bold flex flex-col pb-3">
                <span
                  className={
                    object != null ? "hidden" : "block admin-page-title text-xl"
                  }
                >
                  New Service Widget
                </span>
                <span
                  className={
                    object != null ? "block admin-page-title text-xl" : "hidden"
                  }
                >
                  Service Widget Editing
                </span>
              </div>
              <div className="flex space-x-6">
                <div className="w-1/2">
                  <div>
                    <label className="input-title">Service</label>
                    <AsyncSelect
                      placeholder="Select Service"
                      className="mb-4"
                      cacheOptions
                      defaultOptions={services}
                      getOptionLabel={(e) => e.name + "  (" + e.language + ")"}
                      getOptionValue={(e) => e.uuid}
                      value={selectedValue}
                      onChange={handleChange}
                      onInputChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label className="input-title">Sub Widgets</label>
                    <AsyncSelect
                      placeholder="Select Widgets"
                      className="mb-4"
                      cacheOptions
                      isMulti
                      defaultOptions={widgets}
                      getOptionLabel={(e) => e.title + "  (" + e.language + ")"}
                      getOptionValue={(e) => e.uuid}
                      value={selectedWidgets}
                      onChange={handleWidgetsChange}
                      onInputChange={handleWidgetsInputChange}
                    />
                  </div>
                  <div>
                    <label className="input-title">Testimonials</label>
                    <AsyncSelect
                      placeholder="Select Testimonials"
                      className="mb-4"
                      cacheOptions
                      isMulti
                      defaultOptions={testimonials}
                      getOptionLabel={(e) => e.name + "  (" + e.language + ")"}
                      getOptionValue={(e) => e.uuid}
                      value={selectedTestimonies}
                      onChange={handleTestimoniesChange}
                      onInputChange={handleTestimoniesInputChange}
                    />
                  </div>
                  <InputText
                    title="Title"
                    value={title}
                    isClicked={clicked}
                    name="title"
                    onChange={(e) => settitle(e.target.value)}
                  />
                  <InputText
                    title="Subtitle"
                    value={subtitle}
                    name="subtitle"
                    onChange={(e) => setsubtitle(e.target.value)}
                  />
                  <InputText
                    title="Small Subtitle"
                    value={smallsubtitle}
                    name="smallsubtitle"
                    onChange={(e) => setsmallsubtitle(e.target.value)}
                  />
                  <InputTextArea
                    id="body"
                    title="Body"
                    data={body}
                    name="body"
                    onChange={(event) => {
                      setbody(event);
                    }}
                  />
                </div>
                <div className="w-1/2">
                  <div>
                    <label className="input-title">Widget Type</label>
                    <AsyncSelect
                      placeholder="Select Type"
                      className={classnames("mb-2", {
                        "mb-4 border-2 border-red-500":
                          selectedWidgetType === "" && clicked === true,
                      })}
                      cacheOptions
                      defaultOptions={contants.widgetTypes}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      value={selectedWidgetType}
                      onChange={handleWidgetTypeChange}
                      onInputChange={handleWidgetTypeInputChange}
                    />
                    <label className="block text-red-500">
                      {selectedWidgetType === "" && clicked === true
                        ? "Select Widget Type"
                        : ""}
                    </label>
                  </div>
                  <InputText
                    title="External Link"
                    value={externallink}
                    name="externallink"
                    onChange={(e) => setexternallink(e.target.value)}
                  />
                  <InputText
                    title="Hash Link"
                    value={hashlink}
                    name="hashlink"
                    onChange={(e) => sethashlink(e.target.value)}
                  />
                  <div className="pt-3">
                    <SelectRadioButton
                      title="IsSubwidget"
                      option1="true"
                      option2="false"
                      name="isSubwidget"
                      isClicked={clicked}
                      value={isSubwidget}
                      onChange={(e) => setisSubwidget(e.target.value)}
                    />
                  </div>
                  <div className="flex">
                    <div className="w-1/2">
                      <Uploading
                        updating={
                          object != null ? contants.isTrue : contants.isFalse
                        }
                        title="Image"
                        text1="upload image"
                        text2="view the image"
                        name="image"
                        onChange={selectImage}
                      />
                      <Uploading
                        updating={
                          object != null ? contants.isTrue : contants.isFalse
                        }
                        title="Video"
                        text1="upload video"
                        text2="view the video"
                        name="video"
                        accept={" .mp4, .mpeg"}
                        onChange={selectVideo}
                      />
                    </div>
                    <div className="w-1/2">
                      <Uploading
                        updating={
                          object != null ? contants.isTrue : contants.isFalse
                        }
                        title="PC Animation"
                        text1="upload image"
                        text2="view the image"
                        name="pcanimation"
                        onChange={selectPcAnimation}
                      />
                      <Uploading
                        updating={
                          object != null ? contants.isTrue : contants.isFalse
                        }
                        title="Mobile Animation"
                        text1="upload image"
                        text2="view the image"
                        name="mobilecanimation"
                        onChange={selectMobileAnimation}
                      />
                    </div>
                  </div>

                  <InputTextArea
                    id="description"
                    title="Description"
                    data={description}
                    name="description"
                    onChange={(event) => {
                      setdescription(event);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <Button name={contants.Publish} type="square" />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/service-widget/objects/list"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export const ClientTestimonialPage = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const links = useSelector((state) => state.links.links);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.adminService.testimonial);
  const [name, setname] = useState(object != null ? object?.name : "");
  const [testimony, settestimony] = useState(
    object != null ? eval(object?.testimony) : ""
  );
  const [icon, seticon] = useState(null);

  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(actionTypes.getLinks());
    dispatch(adminActionTypes.getOneClientTestimonials(params.objectId));
  }, [dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (name === "" || testimony === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            testimony: richtext.editorValue,
            name,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateTestimonials(obj));
          successUpdateNotification("Client Testimonial", contants.UPDATE);
          settestimony("");
          setname("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              name,
              testimony: richtext.editorValue,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Client Testimonial", languageName);
          settestimony("");
          setname("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settestimony(testimony);
          setname(name);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(adminActionTypes.createClientTestimonials(objects, token));
          setcounter(0);
          setObjects([]);
          setname("");
          settestimony("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
          <div className="flex justify-between pl-12">
            <div className="flex flex-col pt-5 w-80%">
              <div className="font-bold flex flex-col">
                <span
                  className={
                    object != null ? "hidden" : "block admin-page-title text-xl"
                  }
                >
                  New Client Testimonial (Language:{" "}
                  {languages[counter] ? languages[counter].name : ""})
                </span>
                <span
                  className={
                    object != null ? "block admin-page-title text-xl" : "hidden"
                  }
                >
                  Client Testimonial (Language: {object?.language}) Editing
                </span>
              </div>

              <div className=" w-3/5">
                <InputText
                  title="Name"
                  value={name}
                  isClicked={clicked}
                  name="name"
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className=" w-3/5">
                <InputTextArea
                  title="Testimony"
                  data={testimony}
                  isClicked={clicked}
                  name="testimony"
                  onChange={(event) => {
                    settestimony(event);
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col space-y-20">
              <div className="flex flex-col space-y-2">
                <Button
                  disabled={disabled}
                  name={object === null ? contants.Save : contants.Update}
                  type="square"
                />
                <Button
                  name={contants.Publish}
                  disabled={!disabled}
                  type="square"
                />
                <TableLink
                  title="List"
                  type="viewLink"
                  link="/admin/client-testimonials/objects/list"
                />
              </div>
              <div>
                <InsertingLanguagesTable
                  languages={languages}
                  counter={counter}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export const ProductWidgetPage = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const products = useSelector((state) => state.adminProduct.adminProducts);
  const testimonials = useSelector((state) => state.adminService.testimonials);
  const widgets = useSelector(
    (state) => state.adminProduct.adminProductWidgets
  );

  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const productsLoading = useSelector(
    (state) => state.adminProduct.adminProductsLoading
  );
  const params = useParams();
  const object = useSelector((state) => state.adminProduct.productWidget);
  const [title, settitle] = useState(object != null ? object?.title : "");
  const [subtitle, setsubtitle] = useState(
    object != null ? object?.subtitle : ""
  );
  const [smallsubtitle, setsmallsubtitle] = useState(
    object != null ? object?.smallsubtitle : ""
  );
  const [body, setbody] = useState(object != null ? eval(object?.body) : "");
  const [externallink, setexternallink] = useState(
    object != null ? object?.externallink : ""
  );
  const [widgettype, setwidgettype] = useState("");
  const [hashlink, sethashlink] = useState(
    object != null ? object?.hashlink : ""
  );
  const [description, setdescription] = useState(
    object != null ? eval(object?.description) : ""
  );
  const [isSubwidget, setisSubwidget] = useState(
    object != null ? object?.isSubwidget : ""
  );

  const [packagetitle, setpackagetitle] = useState(
    object != null ? object?.packagetitle : ""
  );
  const [packageprojects, setpackageprojects] = useState(
    object != null ? object?.packageprojects : ""
  );
  const [packageprice, setpackageprice] = useState(
    object != null ? object?.packageprice : ""
  );
  const [packageusers, setpackageusers] = useState(
    object != null ? object?.packageusers : ""
  );
  const [packagemember, setpackagemember] = useState(
    object != null ? object?.packagemember : ""
  );
  const [packagestorage, setpackagestorage] = useState(
    object != null ? object?.packagestorage : ""
  );
  const [appstore, setappstore] = useState(
    object != null ? object?.appstore : ""
  );
  const [playstore, setplaystore] = useState(
    object != null ? object?.playstore : ""
  );

  const [subwidgets, setsubwidgets] = useState("");
  const [clienttestimonials, setclienttestimonials] = useState("");
  const [service, setservice] = useState("");
  const [image, setimage] = useState("");
  const [pcanimation, setpcanimation] = useState("");
  const [video, setvideo] = useState("");
  const [mobileanimation, setmobileanimation] = useState("");

  useEffect(() => {
    dispatch(adminActionTypes.getAllAdminProducts());
    dispatch(adminActionTypes.getAllProductsSubwidgets());
    dispatch(adminActionTypes.getActiveClientTestimonials());
    // dispatch(adminActionTypes.getOneProductsWidgets(params.objectId));
  }, [dispatch]);

  //UPLOADING FILES
  const selectImage = (event) => {
    setimage(event.target.files[0]);
  };
  const selectVideo = (event) => {
    setvideo(event.target.files[0]);
  };

  // SELECT PRODUCT BUTTON IMPLEMENTATION
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  // SELECT WIDGET TYPE BUTTON IMPLEMENTATION
  const [widgetType, setWidgetType] = useState("");
  const [selectedWidgetType, setSelectedWidgetType] = useState("");
  const handleWidgetTypeInputChange = (value) => {
    setWidgetType(value);
  };
  const handleWidgetTypeChange = (value) => {
    setSelectedWidgetType(value);
  };

  // SELECT CLIENT TESTIMONIALS BUTTON IMPLEMENTATION
  const [testimonies, settestimonies] = useState("");
  const [selectedTestimonies, setSelectedTestimonies] = useState([]);
  const handleTestimoniesInputChange = (value) => {
    settestimonies(value);
  };
  const handleTestimoniesChange = (value) => {
    setSelectedTestimonies(value);
  };

  // SELECT SUB WIDGETS BUTTON IMPLEMENTATION
  const [subWidgets, setSubWidgets] = useState("");
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const handleWidgetsInputChange = (value) => {
    setSubWidgets(value);
  };
  const handleWidgetsChange = (value) => {
    setSelectedWidgets(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (selectedWidgetType === "" || title === "" || isSubwidget === "") {
      setclicked(true);
    } else {
      const productId = selectedValue.uuid;
      const widgettype = selectedWidgetType?.value;
      const widgetsArray = [];
      selectedWidgets?.forEach((widget) => {
        widgetsArray.push(widget.uuid);
      });
      const testimonies = [];
      selectedTestimonies?.forEach((testimony) => {
        testimonies.push(testimony.uuid);
      });
      const testimoniesList = testimonies.join(",");
      const widgetsList = widgetsArray.join(",");
      const richtextBody = richTextConstants.separateTextAndFiles("body");
      const richtextDescription =
        richTextConstants.separateTextAndFiles("description");
      richtextDescription.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      richtextBody.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            subtitle,
            smallsubtitle,
            body: richtextBody.editorValue,
            externallink,
            hashlink,
            isSubwidget,
            description: richtextDescription.editorValue,
            subwidgets: widgetsList,
            widgettype,
            clienttestimonials: testimoniesList,
            packagetitle,
            packageprojects,
            packagestorage,
            packageusers,
            packagemember,
            packageprice,
            playstore,
            appstore,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateProductWidget(obj));
          successUpdateNotification("Product Widget", contants.UPDATE);
          settitle("");
          setsubtitle("");
          setsmallsubtitle("");
          setbody("");
          setexternallink("");
          sethashlink("");
          setisSubwidget("");
          setdescription("");
          setwidgettype("");
          setpackagetitle("");
          setpackageprojects("");
          setpackagestorage("");
          setpackageusers("");
          setpackagemember("");
          setpackageprice("");
          setplaystore("");
          setappstore("");
          setclicked(false);
        } else {
        }
      } else {
        const obj = {
          title,
          subtitle,
          smallsubtitle,
          body: richtextBody.editorValue,
          externallink,
          hashlink,
          isSubwidget,
          description: richtextDescription.editorValue,
          productId,
          subwidgets: widgetsList,
          widgettype,
          clienttestimonials: testimoniesList,
          packagetitle,
          packageprojects,
          packagestorage,
          packageusers,
          packagemember,
          packageprice,
          playstore,
          appstore,
          creator: creatorName,
          language: contants.widgetLanguage(selectedValue),
        };
        const fd = new FormData();
        fd.append("image", image);
        fd.append("video", video);
        fd.append("objects", JSON.stringify(obj));
        dispatch(adminActionTypes.createProductWidget(fd, productId, token));
        settitle("");
        setsubtitle("");
        setsmallsubtitle("");
        setbody("");
        setexternallink("");
        sethashlink("");
        setisSubwidget("");
        setdescription("");
        setwidgettype("");
        setpackagetitle("");
        setpackageprojects("");
        setpackagestorage("");
        setpackageusers("");
        setpackagemember("");
        setpackageprice("");
        setplaystore("");
        setappstore("");
        setclicked(false);
      }
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
          <div className="flex justify-between pl-12">
            <div className="flex flex-col pt-3 w-80%">
              <div className="font-bold flex flex-col pb-3">
                <span
                  className={
                    object != null ? "hidden" : "block admin-page-title text-xl"
                  }
                >
                  New Product Widget
                </span>
                <span
                  className={
                    object != null ? "block admin-page-title text-xl" : "hidden"
                  }
                >
                  Product Widget Editing
                </span>
              </div>
              <div className="flex space-x-6 pb-10">
                <div className="w-1/2">
                  <div>
                    <label className="input-title">Product</label>
                    <AsyncSelect
                      placeholder="Select Product"
                      className="mb-4"
                      cacheOptions
                      defaultOptions={products}
                      getOptionLabel={(e) => e.name + "  (" + e.language + ")"}
                      getOptionValue={(e) => e.uuid}
                      value={selectedValue}
                      onChange={handleChange}
                      onInputChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label className="input-title">Sub Widgets</label>
                    <AsyncSelect
                      placeholder="Select Widgets"
                      className="mb-4"
                      cacheOptions
                      isMulti
                      defaultOptions={widgets}
                      getOptionLabel={(e) => e.title + "  (" + e.language + ")"}
                      getOptionValue={(e) => e.uuid}
                      value={selectedWidgets}
                      onChange={handleWidgetsChange}
                      onInputChange={handleWidgetsInputChange}
                    />
                  </div>
                  <div>
                    <label className="input-title">Testimonials</label>
                    <AsyncSelect
                      placeholder="Select Testimonials"
                      className="mb-4"
                      cacheOptions
                      isMulti
                      defaultOptions={testimonials}
                      getOptionLabel={(e) => e.name + "  (" + e.language + ")"}
                      getOptionValue={(e) => e.uuid}
                      value={selectedTestimonies}
                      onChange={handleTestimoniesChange}
                      onInputChange={handleTestimoniesInputChange}
                    />
                  </div>
                  <div>
                    <label className="input-title">Widget Type</label>
                    <AsyncSelect
                      placeholder="Select Type"
                      className={classnames("mb-2", {
                        "mb-2 border-2 border-red-500":
                          selectedWidgetType === "" && clicked === true,
                      })}
                      cacheOptions
                      defaultOptions={contants.widgetTypes}
                      getOptionLabel={(e) => e.label}
                      getOptionValue={(e) => e.value}
                      value={selectedWidgetType}
                      onChange={handleWidgetTypeChange}
                      onInputChange={handleWidgetTypeInputChange}
                    />
                    <label className="block text-red-500">
                      {selectedWidgetType === "" && clicked === true
                        ? "Select Widget Type"
                        : ""}
                    </label>
                  </div>
                  <div className="pt-3">
                    <SelectRadioButton
                      title="IsSubwidget"
                      option1="true"
                      option2="false"
                      name="isSubwidget"
                      isClicked={clicked}
                      value={isSubwidget}
                      onChange={(e) => setisSubwidget(e.target.value)}
                    />
                  </div>
                  <InputText
                    title="Title"
                    value={title}
                    isClicked={clicked}
                    name="title"
                    onChange={(e) => settitle(e.target.value)}
                  />
                  <InputText
                    title="Subtitle"
                    value={subtitle}
                    name="subtitle"
                    onChange={(e) => setsubtitle(e.target.value)}
                  />
                  <InputText
                    title="Small Subtitle"
                    value={smallsubtitle}
                    name="smallsubtitle"
                    onChange={(e) => setsmallsubtitle(e.target.value)}
                  />
                  <InputText
                    title="External Link"
                    value={externallink}
                    name="externallink"
                    onChange={(e) => setexternallink(e.target.value)}
                  />
                  <InputText
                    title="Hash Link"
                    value={hashlink}
                    name="hashlink"
                    onChange={(e) => sethashlink(e.target.value)}
                  />
                  <InputTextArea
                    title="Body"
                    id="body"
                    data={body}
                    name="body"
                    onChange={(event) => {
                      setbody(event);
                    }}
                  />
                </div>
                <div className="w-1/2">
                  <InputText
                    title="Package Title"
                    value={packagetitle}
                    name="packagetitle"
                    onChange={(e) => setpackagetitle(e.target.value)}
                  />
                  <InputText
                    title="Package Projects"
                    value={packageprojects}
                    name="packageprojects"
                    onChange={(e) => setpackageprojects(e.target.value)}
                  />
                  <InputText
                    title="Package Price"
                    value={packageprice}
                    name="packageprice"
                    onChange={(e) => setpackageprice(e.target.value)}
                  />
                  <InputText
                    title="Package Users"
                    value={packageusers}
                    name="packageusers"
                    onChange={(e) => setpackageusers(e.target.value)}
                  />
                  <InputText
                    title="Package Members"
                    value={packagemember}
                    name="packagemember"
                    onChange={(e) => setpackagemember(e.target.value)}
                  />
                  <InputText
                    title="Package Storage"
                    value={packagestorage}
                    name="packagestorage"
                    onChange={(e) => setpackagestorage(e.target.value)}
                  />
                  <InputText
                    title="App Store"
                    value={appstore}
                    name="appstore"
                    onChange={(e) => setappstore(e.target.value)}
                  />
                  <InputText
                    title="Play Store"
                    value={playstore}
                    name="playstore"
                    onChange={(e) => setplaystore(e.target.value)}
                  />

                  <div className="flex">
                    <div className="w-1/2">
                      <Uploading
                        updating={
                          object != null ? contants.isTrue : contants.isFalse
                        }
                        title="Image"
                        text1="upload image"
                        text2="view the image"
                        name="image"
                        onChange={selectImage}
                      />
                      <Uploading
                        updating={
                          object != null ? contants.isTrue : contants.isFalse
                        }
                        title="Video"
                        text1="upload video"
                        text2="view the video"
                        name="video"
                        accept={" .mp4, .mpeg"}
                        onChange={selectVideo}
                      />
                    </div>
                  </div>

                  <InputTextArea
                    title="Description"
                    data={description}
                    id="description"
                    name="description"
                    onChange={(event) => {
                      setdescription(event);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <Button
                name={object === null ? contants.Save : contants.Update}
                type="square"
              />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/product-widget/objects/list"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export const ServicesTable = () => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.adminService.adminServices);
  const serviceLoading = useSelector(
    (state) => state.adminService.adminServicesLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAllAdminServices());
  }, [dispatch]);

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {serviceLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">SERVICES</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Name</th>
                      <th className="border border-blue-400 px-4 py-2">Link</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Laguage
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {services.map((service, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {service.name}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 text-center">
                          {service.link}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {service.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {service.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(service.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {service.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={service.uuid}
                              link="/admin/services/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Service"
                  type="addLink"
                  link="/admin/services"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const ServiceWidgetsTable = () => {
  const dispatch = useDispatch();
  const widgets = useSelector(
    (state) => state.adminService.adminServiceWidgets
  );
  const widgetsLoading = useSelector(
    (state) => state.adminService.adminServiceWidgetsLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAllServicesWidgets());
  }, [dispatch]);
  const [submitted, setsubmitted] = useState(false);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {widgetsLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    SERVICES WIDGETS
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Is Subwidget
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Laguage
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {widgets.map((widget, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {widget.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 text-center">
                          {widget.isSubwidget === contants.isTrue
                            ? contants.isTrue
                            : contants.isFalse}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {widget.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {widget.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(widget.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {widget.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={widget.uuid}
                              link="/admin/service-widget/edit"
                            />
                            {widget.isSubwidget === contants.isTrue ? (
                              ""
                            ) : (
                              <WidgetPreviewButton
                                id={widget.uuid}
                                type={widget.isSubwidget}
                                link="/admin/widget/preview"
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Widget"
                  type="addLink"
                  link="/admin/service-widget"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const ProductsTable = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.adminProduct.adminProducts);
  const productLoading = useSelector(
    (state) => state.adminProduct.adminProductsLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAllAdminProducts());
  }, [dispatch]);

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {productLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">PRODUCTS</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Name</th>
                      <th className="border border-blue-400 px-4 py-2">Link</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Laguage
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {product.name}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 text-center">
                          {product.link}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {product.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {product.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(product.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {product.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={product.uuid}
                              link="/admin/products/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Product"
                  type="addLink"
                  link="/admin/products"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const ProductWidgetsTable = () => {
  const dispatch = useDispatch();
  const widgets = useSelector(
    (state) => state.adminProduct.adminProductWidgets
  );
  const widgetLoading = useSelector(
    (state) => state.adminProduct.adminProductWidgetsLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAllProductsWidgets());
  }, [dispatch]);

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {widgetLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    PRODUCT WIDGETS
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Subtitle
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Laguage
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {widgets.map((widget, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {widget.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 text-center">
                          {widget.subtitle}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {widget.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {widget.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(widget.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {widget.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={widget.uuid}
                              link="/admin/product-widget/edit"
                            />
                            {widget.isSubwidget === contants.isTrue ? (
                              ""
                            ) : (
                              <WidgetPreviewButton
                                id={widget.uuid}
                                type={widget.isSubwidget}
                                link="/admin/widget/preview"
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Widget"
                  type="addLink"
                  link="/admin/product-widget"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const ClientTestimonialsTable = () => {
  const dispatch = useDispatch();
  const testimonials = useSelector((state) => state.adminService.testimonials);
  const testimonialLoading = useSelector(
    (state) => state.adminService.testimonialsLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getClientTestimonials());
  }, [dispatch]);

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {testimonialLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    CLIENT TESTIMONIALS
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Name</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Laguage
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {testimonials.map((testimony, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {testimony.name}
                        </td>

                        <td className="border border-blue-400 px-4 py-2">
                          {testimony.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {testimony.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(testimony.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {testimony.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={testimony.uuid}
                              link="/admin/client-testimonials/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Testimony"
                  type="addLink"
                  link="/admin/client-testimonials"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
