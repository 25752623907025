import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";
import * as notifications from "../../admin/contants";

export const getExcutifLeadershipStart = () => {
  return {
    type: actionTypes.GET_EXCUTIF_LEADERSHIP_START,
  };
};

export const getExcutifLeadershipSuccess = (excutifLeadership) => {
  return {
    type: actionTypes.GET_EXCUTIF_LEADERSHIP_SUCCESS,
    excutifLeadership: excutifLeadership,
  };
};

export const getExcutifLeadershipFail = (error) => {
  return {
    type: actionTypes.GET_EXCUTIF_LEADERSHIP_FAIL,
    error: error,
  };
};

export const getExcutifLeadership = (lang) => {
  return (dispatch) => {
    dispatch(getExcutifLeadershipStart());
    axios
      .get(`/site/executive-leadership/active/${lang}`)
      .then((response) => {
        dispatch(getExcutifLeadershipSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getExcutifLeadershipFail(err.message));
      });
  };
};

export const getCareerStart = () => {
  return {
    type: actionTypes.GET_CAREER_START,
  };
};

export const getCareerSuccess = (career) => {
  return {
    type: actionTypes.GET_CAREER_SUCCESS,
    career: career,
  };
};

export const getCareerFail = (error) => {
  return {
    type: actionTypes.GET_CAREER_FAIL,
    error: error,
  };
};

export const getCareer = (lang) => {
  return (dispatch) => {
    dispatch(getCareerStart());
    axios
      .get(`/site/careers/active/${lang}`)
      .then((response) => {
        dispatch(getCareerSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCareerFail(err.message));
      });
  };
};

export const getExcutifLeadersStart = () => {
  return {
    type: actionTypes.GET_EXCUTIF_LEADERS_START,
  };
};

export const getExcutifLeadersSuccess = (excutifLeaders) => {
  return {
    type: actionTypes.GET_EXCUTIF_LEADERS_SUCCESS,
    excutifLeaders: excutifLeaders,
  };
};

export const getExcutifLeadersFail = (error) => {
  return {
    type: actionTypes.GET_EXCUTIF_LEADERS_FAIL,
    error: error,
  };
};

export const getExcutifLeaders = () => {
  return (dispatch) => {
    dispatch(getExcutifLeadersStart());
    axios
      .get(`/site/staff-members/executive/active`)
      .then((response) => {
        dispatch(getExcutifLeadersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getExcutifLeadersFail(err.message));
      });
  };
};

export const getStaffStart = () => {
  return {
    type: actionTypes.GET_STAFF_START,
  };
};

export const getStaffSuccess = (staff) => {
  return {
    type: actionTypes.GET_STAFF_SUCCESS,
    staff: staff,
  };
};

export const getStaffFail = (error) => {
  return {
    type: actionTypes.GET_STAFF_FAIL,
    error: error,
  };
};

export const getStaff = (lang) => {
  return (dispatch) => {
    dispatch(getStaffStart());
    axios
      .get(`/site/staffs/active/${lang}`)
      .then((response) => {
        dispatch(getStaffSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getStaffFail(err.message));
      });
  };
};

export const getStaffMembersStart = () => {
  return {
    type: actionTypes.GET_STAFF_MEMBERS_START,
  };
};

export const getStaffMembersSuccess = (staffMembers) => {
  return {
    type: actionTypes.GET_STAFF_MEMBERS_SUCCESS,
    staffMembers: staffMembers,
  };
};

export const getStaffMembersFail = (error) => {
  return {
    type: actionTypes.GET_STAFF_MEMBERS_FAIL,
    error: error,
  };
};

export const getUsersStart = () => {
  return {
    type: actionTypes.GET_USER_START,
  };
};

export const getUsersSuccess = (users) => {
  return {
    type: actionTypes.GET_USER_SUCCESS,
    users: users,
  };
};

export const getOneUsersSuccess = (user) => {
  return {
    type: actionTypes.GET_ONE_USER_SUCCESS,
    user: user,
  };
};

export const getUsersFail = (error) => {
  return {
    type: actionTypes.GET_USER_FAIL,
    error: error,
  };
};

export const getStaffMembers = () => {
  return (dispatch) => {
    dispatch(getStaffMembersStart());
    axios
      .get(`/site/staff-members/active`)
      .then((response) => {
        dispatch(getStaffMembersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getStaffMembersFail(err.message));
      });
  };
};

export const getAllStaffMembers = () => {
  return (dispatch) => {
    dispatch(getStaffMembersStart());
    axios
      .get(`/site/staff-members/active/all`)
      .then((response) => {
        dispatch(getStaffMembersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getStaffMembersFail(err.message));
      });
  };
};

export const getAwardsIntroStart = () => {
  return {
    type: actionTypes.GET_AWARDS_INTRO_START,
  };
};

export const getAwardsIntroSuccess = (awardsIntro) => {
  return {
    type: actionTypes.GET_AWARDS_INTRO_SUCCESS,
    awardsIntro: awardsIntro,
  };
};

export const getAwardsIntroFail = (error) => {
  return {
    type: actionTypes.GET_AWARDS_INTRO_FAIL,
    error: error,
  };
};

export const getAwardsIntro = (lang) => {
  return (dispatch) => {
    dispatch(getAwardsIntroStart());
    axios
      .get(`/site/awardsintro/active/${lang}`)
      .then((response) => {
        dispatch(getAwardsIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAwardsIntroFail(err.message));
      });
  };
};

export const getAwardsStart = () => {
  return {
    type: actionTypes.GET_AWARDS_START,
  };
};

export const getAwardsSuccess = (awards) => {
  return {
    type: actionTypes.GET_AWARDS_SUCCESS,
    awards: awards,
  };
};

export const getAwardsFail = (error) => {
  return {
    type: actionTypes.GET_AWARDS_FAIL,
    error: error,
  };
};

export const getAwards = (lang) => {
  return (dispatch) => {
    dispatch(getAwardsStart());
    axios
      .get(`/site/awards/active/${lang}`)
      .then((response) => {
        dispatch(getAwardsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAwardsFail(err.message));
      });
  };
};

export const getEthicsAndComplianceStart = () => {
  return {
    type: actionTypes.GET_ETHICS_COMPLIANCE_START,
  };
};

export const getEthicsAndComplianceSuccess = (ethicsAndCompliance) => {
  return {
    type: actionTypes.GET_ETHICS_COMPLIANCE_SUCCESS,
    ethicsAndCompliance: ethicsAndCompliance,
  };
};

export const getEthicsAndComplianceFail = (error) => {
  return {
    type: actionTypes.GET_ETHICS_COMPLIANCE_FAIL,
    error: error,
  };
};

export const getEthicsAndCompliance = (lang) => {
  return (dispatch) => {
    dispatch(getEthicsAndComplianceStart());
    axios
      .get(`/site/ethics-compliance/active/${lang}`)
      .then((response) => {
        dispatch(getEthicsAndComplianceSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getEthicsAndComplianceFail(err.message));
      });
  };
};

export const getGetInTouchStart = () => {
  return {
    type: actionTypes.GET_GET_IN_TOUCH_START,
  };
};

export const getGetInTouchSuccess = (getInTouch) => {
  return {
    type: actionTypes.GET_GET_IN_TOUCH_SUCCESS,
    getInTouch: getInTouch,
  };
};

export const getGetInTouchFail = (error) => {
  return {
    type: actionTypes.GET_GET_IN_TOUCH_FAIL,
    error: error,
  };
};

export const getGetInTouch = (lang) => {
  return (dispatch) => {
    dispatch(getGetInTouchStart());
    axios
      .get(`/site/get-in-touch/active/${lang}`)
      .then((response) => {
        dispatch(getGetInTouchSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getGetInTouchFail(err.message));
      });
  };
};

export const createExecutiveIntro = (object, token) => {
  return (dispatch) => {
    dispatch(getExcutifLeadershipStart());
    axios
      .post(`/site/executive-leadership/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getExcutifLeadershipSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getExcutifLeadershipFail(err.message));
        notifications.errorNotification("Excutive Intro");
      });
    notifications.successCreationNotification("Excutive Intro");
  };
};

export const createCareer = (object, token) => {
  return (dispatch) => {
    dispatch(getCareerStart());
    axios
      .post(`/site/careers/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getCareerSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getCareerFail(err.message));
        notifications.errorNotification("Career");
      });
    notifications.successCreationNotification("Career");
  };
};

export const createStaffIntro = (object, token) => {
  return (dispatch) => {
    dispatch(getStaffStart());
    axios
      .post(`/site/staffs/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getStaffSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getStaffFail(err.message));
        notifications.errorNotification("Staffs Intro");
      });
    notifications.successCreationNotification("Staffs Intro");
  };
};

export const createStaffMember = (object, token) => {
  console.log(object);
  return (dispatch) => {
    dispatch(getStaffMembersStart());
    axios
      .post(`/site/staff-members/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getStaffMembersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getStaffMembersFail(err.message));
        notifications.errorNotification("Staff Member");
      });
    notifications.successCreationNotification("Staff Member");
  };
};

export const createAwardsIntro = (object, token) => {
  return (dispatch) => {
    dispatch(getAwardsIntroStart());
    axios
      .post(`/site/awardsintro/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getAwardsIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAwardsIntroFail(err.message));
        notifications.errorNotification("Awards Intros");
      });
    notifications.successCreationNotification("Awards Intros");
  };
};

export const createAwards = (object, token) => {
  return (dispatch) => {
    dispatch(getAwardsStart());
    axios
      .post(`/site/awards/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getAwardsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAwardsFail(err.message));
        notifications.errorNotification("Awards");
      });
    notifications.successCreationNotification("Awards");
  };
};

export const createEthicsCompliance = (object, token) => {
  return (dispatch) => {
    dispatch(getEthicsAndComplianceStart());
    axios
      .post(`/site/ethics-compliance/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getEthicsAndComplianceSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getEthicsAndComplianceFail(err.message));
        notifications.errorNotification("Ethics And Compliance");
      });
    notifications.successCreationNotification("Ethics And Compliance");
  };
};

export const createGetInTouch = (object, token) => {
  return (dispatch) => {
    dispatch(getGetInTouchStart());
    axios
      .post(`/site//get-in-touch/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getGetInTouchSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getGetInTouchFail(err.message));
        notifications.errorNotification("Get in touch");
      });
    notifications.successCreationNotification("Get in touch");
  };
};

export const createUser = (object, token) => {
  return (dispatch) => {
    dispatch(getStaffMembersStart());
    axios
      .post(`/site/users/create/${object.staffId}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getStaffMembersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getStaffMembersFail(err.message));
        notifications.errorNotification("User");
      });
    notifications.successCreationNotification("User");
  };
};

export const getUsers = () => {
  return (dispatch) => {
    dispatch(getUsersStart());
    axios
      .get(`/site/users`)
      .then((response) => {
        dispatch(getUsersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getUsersFail(err.message));
      });
  };
};

export const getOneUsers = (userId) => {
  return (dispatch) => {
    dispatch(getUsersStart());
    axios
      .get(`/site/users/user/${userId}`)
      .then((response) => {
        dispatch(getOneUsersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getUsersFail(err.message));
      });
  };
};

export const updateUsers = (object) => {
  return (dispatch) => {
    dispatch(getUsersStart());
    axios
      .put(`/site/users/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneUsersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getUsersFail(err.message));
      });
  };
};
