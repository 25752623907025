import './servicescss.css';
import { useState } from 'react';
import { ServiceImage } from './servicePageComponents';
import IT from '../images/development.jpg';
import TestimonyCard from './TestimonyCard';
import { GoGlobe } from 'react-icons/go';
import ServiceCard from './ServiceCard';
import { Button } from '../components/Buttons';
import opdanda from '../images/opanda.mp4';
import { IoPlayOutline, IoPauseOutline } from 'react-icons/io5';
import parse from "html-react-parser";

import dotenv from "dotenv";
dotenv.config();

export const InformationTechnology = () => {
  return (
    <div
      id="itc"
      className="it-service md:h-screen-second flex flex-col py-10 md:flex-row md:space-x-16"
    >
      <div className="md:w-1/2">
        <img
          src={IT}
          alt="Information Technology"
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div className="hidden md:flex flex-col md:w-1/2">
        <div className="h-1/2">
          <div className="site-text-blue service-title text-xl">
            Information Technology Solutions
          </div>
          <div className="it-descriptor w-full overflow-scroll scrollbar-hidden h-70%">
            Aute voluptate culpa sunt cillum ad nulla laborum cupidatat.
            Incididunt eu in ipsum exercitation. Nostrud voluptate esse culpa eu
            ex id exercitation. occaecat officia aute ex pariatur voluptate.
            Anim ea aliqua dolore proident adipisicing consequat ipsum non nulla
            reprehenderit.Aute voluptate culpa sunt cillum ad nulla laborum
            cupidatat. Incididunt eu in ipsum exercitation. Nostrud voluptate
            esse culpa eu ex id exercitation. occaecat officia aute ex pariatur
            voluptate. Anim ea aliqua dolore proident adipisicing consequat
            ipsum non nulla reprehenderit.
          </div>
        </div>
        <div className="h-1/2">
          <div className="site-text-blue it-testimony-title pt-2">
            See Clients' Testimonials :)
          </div>
          <div className="flex h-full overflow-x-scroll scrollbar-hidden space-x-2">
            <div className="w-1/2 flex-shrink-0">
              <TestimonyCard
                client={'Ministry of Education'}
                testimony={
                  'Incididunt eu in ipsum exercitation. Nostrud voluptate\
            esse culpa eu ex id exercitation. occaecat officia aute ex pariatur\
            voluptate. Anim ea aliqua dolore proident adipisicing consequat\
            ipsum non nulla reprehenderit.'
                }
              />
            </div>
            <div className="w-1/2 flex-shrink-0">
              <TestimonyCard
                client={'Ministry of Education'}
                testimony={
                  'Incididunt eu in ipsum exercitation. Nostrud voluptate\
            esse culpa eu ex id exercitation. occaecat officia aute ex pariatur\
            voluptate. Anim ea aliqua dolore proident adipisicing consequat\
            ipsum non nulla reprehenderit.'
                }
              />
            </div>
            <div className="w-1/2 flex-shrink-0">
              <TestimonyCard
                client={'Ministry of Education'}
                testimony={
                  'Incididunt eu in ipsum exercitation. Nostrud voluptate\
            esse culpa eu ex id exercitation. occaecat officia aute ex pariatur\
            voluptate. Anim ea aliqua dolore proident adipisicing consequat\
            ipsum non nulla reprehenderit.'
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WebDevelopment = () => {
  return (
    <div
      id="web"
      className="web-service md:min-h-screen-second md:flex flex-col justify-between"
    >
      <div className="flex flex-col md:h-3/4 pb-0 md:pb-10 md:flex-row">
        <div
          data-aos="slide-up"
          data-aos-duration="1500"
          className="h-full hidden md:flex md:flex-col md:w-1/2 px-10 pt-16 justify-between"
        >
          <div>
            <div className="service-title site-text-blue text-xl">
              WEB DESIGN AND DEVELOPMENT
            </div>
            <div className="web-service-text text-sm">
              Fugiat et aliquip ex dolore et id non id Lorem nostrud laboriskkkk
              Ipsum enim aute dolor ipsum. Aliquip nostrud ad pariatur voluptate
              minim mollit labore voluptate ad exercitation qui. Est velit nisi
              nostrud voluptate tempor deserunt magna voluptate qui est duis
              veniam nulla commodo.
            </div>
          </div>
          <div className="flex justify-between">
            <div className="site-text-blue text-sm font-bold">
              CLICK TO SEE WHAT OUR CLIENT THINK
            </div>
            <div className="flex items-center  space-x-5 site-text-blue font-bold">
              <GoGlobe />
              <div>webpage</div>
            </div>
          </div>
        </div>
        <div className="h-full md:w-1/2">
          <img
            src={IT}
            alt="webDev"
            className="h-full w-full object-cover object-center"
          />
        </div>
      </div>
      <div
        data-aos="slide-up"
        data-aos-duration="1500"
        className="flex overflow-x-scroll scrollbar-hidden lg:justify-center py-6"
      >
        <div className="m-4 w-1/2 md:w-52 xl:w-80 flex-shrink-0">
          <ServiceImage rounded serviceImage={IT} name={'IT'} />
        </div>
        <div className="m-4 w-1/2 md:w-52 xl:w-80 flex-shrink-0">
          <ServiceImage rounded serviceImage={IT} name={'IT'} />
        </div>
        <div className="m-4 w-1/2 md:w-52 xl:w-80 flex-shrink-0">
          <ServiceImage rounded serviceImage={IT} name={'IT'} />
        </div>
        <div className="m-4 w-1/2 md:w-52 xl:w-80 flex-shrink-0">
          <ServiceImage rounded serviceImage={IT} name={'IT'} />
        </div>
      </div>
    </div>
  );
};

export const ELearning = () => {
  const panda = {
    image: IT,
    name: "O'Genius Panda",
    description: 'Programming and Design',
  };
  const labs = {
    image: IT,
    name: 'VIRTUAL LABS',
    description: 'Interactive content management and distribution',
  };
  return (
    <div
      id="elearning"
      className="elearning-service flex flex-col md:px-16 overflow-hidden scrollbar-hidden"
    >
      <div
        data-aos="zoom-out-up"
        data-aos-duration="1000"
        className="service-title py-5 site-text-blue text-center text-md"
      >
        E-LEARNING
      </div>
      <div
        data-aos="zoom-out-up"
        data-aos-duration="1000"
        className="text-center site-text-blue elearning-text-title py-5 px-20"
      >
        <p>Nostrud aliquip anim duis adipisicing nostrud nulla eiusmod</p>
      </div>
      <div
        data-aos="zoom-out-up"
        data-aos-duration="1000"
        className="flex text-center site-text-blue elearning-text-title px-10"
      >
        <p>
          Nostrud aliquip anim duis adipisicing nostrud nulla eiusmod magna ad
          ipsum ullamco anim id. Minim aliquip proident laboris enim adipisicing
          labore Lorem. NEW Ullamco deserunt labore ut velit cillum. Duis
          nostrud excepteur cillum sit. Magna irure fugiat ex non est labore
          laboris dolor officia irure fugiat est cupidatat mollit. Anim pariatur
          amet est pariatur deserunt voluptate. Dolore reprehenderit veniam ea
          ut.
        </p>
      </div>
      <div
        data-aos="zoom-out-up"
        data-aos-duration="1500"
        className="flex flex-col md:justify-center py-8 md:flex-row md:space-x-5"
      >
        <ServiceCard service={panda} className="w-full" />
        <div className="flex text-center site-text-blue elearning-text-title px-10 py-5 md:py-0 md:hidden">
          <p>
            Nostrud aliquip anim duis adipisicing nostrud nulla eiusmod magna ad
            ipsum ullamco anim id. Minim aliquip proident laboris enim
            adipisicing labore Lorem. Ullamco deserunt labore ut velit cillum.
            Duis nostrud excepteur cillum sit. Magna irure fugiat ex non est
            labore laboris dolor officia irure fugiat est cupidatat mollit. Anim
            pariatur amet est pariatur deserunt voluptate. Dolore reprehenderit
            veniam ea ut.
          </p>
        </div>
        <ServiceCard service={labs} className="w-full" />
      </div>
      <div className="hidden md:flex flex-col space-y-6 text-center justify-center site-text-blue elearning-text">
        <p>
          Nostrud aliquip anim duis adipisicing nostrud nulla eiusmod magna ad
          ipsum ullamco anim id. Minim aliquip proident laboris enim adipisicing
          labore Lorem. Ullamco deserunt labore ut velit cillum. Duis nostrud
          excepteur cillum sit. Magna irure fugiat ex non est labore laboris
          dolor officia irure fugiat est cupidatat mollit. Anim pariatur amet
          est pariatur deserunt voluptate. Dolore reprehenderit veniam ea ut.
        </p>
        <p>
          Nostrud aliquip anim duis adipisicing nostrud nulla eiusmod magna ad
          ipsum ullamco anim id. Minim aliquip proident laboris enim adipisicing
          labore Lorem. Ullamco deserunt labore ut velit cillum. Duis nostrud
          excepteur cillum sit. Magna irure fugiat ex non est labore laboris
          dolor officia irure fugiat est cupidatat mollit. Anim pariatur amet
          est pariatur deserunt voluptate. Dolore reprehenderit veniam ea ut.
        </p>
      </div>
      <div className="text-center pb-7 md:pb-12 md:pt-5">
        <Button name="Visit Platform" />
      </div>
    </div>
  );
};

export const Networking = () => {
  return (
    <div
      id="networking"
      className="md:h-50vh xl:h-60vh networking-service pb-5 flex flex-wrap flex-col-reverse md:flex-row"
    >
      <div className="h-full w-full md:w-1/2">
        <img
          alt="Networking"
          src={IT}
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div
        data-aos="slide-up"
        data-aos-duration="1500"
        className="md:h-full flex flex-col pt-6 md:w-1/2 md:text-left md:px-14"
      >
        <div className="service-title site-text-blue text-center md:text-left">
          <span>NETWORKING</span>
        </div>
        <div className="max-h-30vh md:max-h-full overflow-scroll scrollbar-hidden networking-text text-center md:text-left px-14 md:px-0 pb-8 mb-5 md:mb-0">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quiepakis nostrud exercitation ullamco laboris nsi
            ut aliquip ex ea comepmodo consetquat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cfgillum dolore eutpe fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt
            inpeku culpa qui officia deserunt mollit anim id est laborum. Sed ut
            perspiciatis unde omnis iste natus error sit voluptatem accusantium
            poeyi doloremque laudantium, totam rem aperiam, eaque ipsa quae apsb
            illo inventore veritatis et quasi architecto beiatae vitae dicta
            sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
            eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est,
            qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit,
            seprid quia non numquam eius modi tempora incidunt ut labore et
            dolore magnam aliqueam quaerat voluptatem.
          </p>
        </div>
      </div>
    </div>
  );
};

export const Simulation = () => {
  const [video, setVideo] = useState();
  const [isPlaying, setIsPlaying] = useState(true);
  const handleClick = () => {
    if (isPlaying) {
      video.pause();
      setIsPlaying(false);
    } else {
      video.play();
      setIsPlaying(true);
    }
  };
  return (
    <div
      id="simulations"
      className="simulation min-h-simulations-mobile md:min-h-0 h-screen md:max-h-90vh lg:max-h-screen relative"
    >
      <div className="flex flex-col absolute top-0 md:top-10 left-0 md:left-10 md:w-2/5 z-10 simulation-service-body">
        <div className="md:text-left service-title site-text-blue text-xl text-center py-5">
          <span>Simulations and Animations</span>
        </div>
        <div className="w-full pt-5 md:pt-0 flex flex-col relative">
          <video
            className="w-full object-cover object-center"
            src={opdanda}
            autoPlay
            muted
            ref={setVideo}
          ></video>
          {isPlaying ? (
            <IoPauseOutline
              className="absolute top-2/5 left-2/5"
              size={40}
              color="white"
              onClick={handleClick}
            />
          ) : (
            <IoPlayOutline
              className="absolute top-2/5 left-2/5"
              size={40}
              color="white"
              onClick={handleClick}
            />
          )}
        </div>
        <div className="bg-gradient-to-r from-gray-500 to-transparent h-0.5 my-6"></div>
        <div className="z-30 px-10 md:px-0 font-bold simulation-text">
          <p>
            Ipsum amet laboris mollit consectetur ullamco proident quis
            occaecat. Incididunt cupidatat sit fugiat aliqua aliqua veniam.
            Velit amet labore in non. Irure laborum enim sint laboris. Eu
            aliquip excepteur enim culpa qui. Ipsum amet laboris mollit
            consectetur ullamco proident quis occaecat. Incididunt cupidatat sit
            fugiat aliqua aliqua veniam. Velit amet labore in non.
          </p>
        </div>
      </div>
    </div>
  );
};

export const ComputerGames = () => {
  return (
    <div
      id="games"
      className="games-service flex flex-col-reverse md:flex-row md:py-10 lg:py-16 md:h-60vh lg:h-70vh"
    >
      <div className="w-full md:w-1/2 h-full">
        <img
          src={IT}
          alt="Computer Games"
          className="w-full h-full object-cover object-center"
        />
      </div>
      <div
        data-aos="slide-up"
        data-aos-duration="1500"
        className="flex flex-col md:w-1/2 pl-16 pt-6 pr-8 pb-5"
      >
        <div className="flex service-title site-text-blue ">
          Computer Games Design and Development
        </div>
        <div className="max-h-30vh md:max-h-full overflow-scroll scrollbar-hidden space-y-6 games-text flex flex-col ">
          <p>
            Nostrud aliquip anim duis adipisicing nostrud nulla eiusmod magna ad
            ipsum ullamco anim id. Minim aliquip proident laboris enim
            adipisicing labore Lorem. Ullamco deserunt labore ut velit cillum.
            Duis nostrud excepteur cillum sit. Magna irure fugiat ex non est
            labore laboris dolor officia irure fugiat est cupidatat mollit. Anim
            pariatur amet est pariatur deserunt voluptate. Dolore reprehenderit
            veniam ea ut.
          </p>
          <p>
            Nostrud aliquip anim duis adipisicing nostrud nulla eiusmod magna ad
            ipsum ullamco anim id. Minim aliquip proident laboris enim
            adipisicing labore Lorem. Ullamco deserunt labore ut velit cillum.
            Duis nostrud excepteur cillum sit. Magna irure fugiat ex non est
            labore laboris dolor officia irure fugiat est cupidatat mollit. Anim
            pariatur amet est pariatur deserunt voluptate. Dolore reprehenderit
            veniam ea ut.
          </p>
        </div>
      </div>
    </div>
  );
};

export const Training = () => {
  return (
    <div
      id="trainings"
      className="training-service flex flex-col md:flex-row md:pt-10 md:pb-6 l:pt-20 lg:pb-12 md:h-60vh lg:h-70vh"
    >
      <div
        data-aos="slide-up"
        data-aos-duration="1500"
        className="w-full md:w-1/2 flex flex-col pr-10 pt-6 pb-10 pl-5"
      >
        <div className="flex justify-end training-title-info site-text-blue py-5">
          TRAININGS
        </div>
        <div className="training-text flex flex-col overflow-scroll scrollbar-hidden">
          <p>
            Nostrud aliquip anim duis adipisicing nostrud nulla eiusmod magna ad
            ipsum ullamco anim id. Minim aliquip proident laboris enim
            adipisicing labore Lorem. Ullamco deserunt labore ut velit cillum.
            Duis nostrud excepteur cillum sit. Magna irure fugiat ex non est
            labore laboris dolor officia irure fugiat est cupidatat mollit. Anim
            pariatur amet est pariatur deserunt voluptate. Dolore reprehenderit
            veniam ea ut.
          </p>
          <p>
            Nostrud aliquip anim duis adipisicing nostrud nulla eiusmod magna ad
            ipsum ullamco anim id. Minim aliquip proident laboris enim
            adipisicing labore Lorem. Ullamco deserunt labore ut velit cillum.
            Duis nostrud excepteur cillum sit. Magna irure fugiat ex non est
            labore laboris dolor officia irure fugiat est cupidatat mollit. Anim
            pariatur amet est pariatur deserunt voluptate. Dolore reprehenderit
            veniam ea ut.
          </p>
        </div>
      </div>
      <div className="md:w-1/2">
        <img
          src={IT}
          alt="Training"
          className="object-cover object-center h-full w-full"
        />
      </div>
    </div>
  );
};

export const InteractiveContent = () => {
  return (
    <div id="interactive" className="interactive-service flex flex-col">
      <div className="flex flex-col z-20 w-full md:flex-row md:pt-20">
        <div className="interactive-service-text w-full md:w-2/5 md:p-2 p-10">
          <div className="text-right interactive-service-title site-text-blue md:py-4 py-10">
            <span>Interactive Design and Content Development</span>
          </div>
          <div className="text-right interactive-service-body">
            <p>
              Consectetur dolore magna incididunt nulla adipisicing. Qui elit
              reprehenderit anim labore Lorem est dolore ea enim cupidatat sit
              elit non. Duis dolor laborum deserunt id excepteur anim. Aute
              laboris esse anim eiusmod irure officia ad labore aute labore.
              Aliqua amet laborum amet pariatur anim amet reprehenderit mollit.
            </p>
          </div>
        </div>
        <div
          data-aos="zoom-in-down"
          data-aos-duration="1000"
          className="interactive-service-body w-full md:w-3/5"
        >
          <div className="w-full interactive-images flex flex-col items-center overflow-hidden">
            <div className="w-80% m-auto interactive-pc-image flex-shrink-0 h-30vh md:h-50vh px-6 py-3">
              <div className="interactive-bio-bg h-full object-center object-cover"></div>
            </div>
            <div className="interactive-pc-image-bottom w-full h-5"></div>
            <div
              data-aos="zoom-out-up"
              data-aos-duration="2000"
              className="z-20 absolute interactive-phone-height"
            >
              <PhoneImage />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PhoneImage = (props) => {
  let classes =
    'interactive-phone-image w-full md:w-1/2 h-25vh md:h-50vh lg:h-55vh top-28 px-2 pt-5 pb-10 rounded-xl z-20 absolute';
  if (props.page === 'publications') {
    classes =
      'interactive-phone-image-pub w-1/2 md:w-1/4 lg:w-15% h-50vh md:h-50vh lg:h-60vh xl:h-55vh px-2 pt-5 pb-10 rounded-xl z-20 absolute';
  }
  return (
    <div className={classes}>
      <img
        src={props.image}
        alt="myimage"
        className="w-full h-full object-fill object-center"
      />
    </div>
  );
};
