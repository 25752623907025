import axios from "../../axios-base";
import * as adminActionTypes from "./adminActionTypes";
import * as notifications from "../../admin/contants";

export const getAdminHomeCarouselStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_CAROUSEL_START,
  };
};

export const getAdminHomeCarouselSuccess = (adminHomeCarousel) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_CAROUSEL_SUCCESS,
    adminHomeCarousel: adminHomeCarousel,
  };
};

export const getAdminSingleHomeCarouselSuccess = (homeCarousel) => {
  return {
    type: adminActionTypes.GET_ADMIN_SINGLE_HOME_CAROUSEL_SUCCESS,
    homeCarousel: homeCarousel,
  };
};

export const getAdminHomeCarouselFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_CAROUSEL_FAIL,
    error: error,
  };
};

export const adminHomeCarousels = () => {
  return (dispatch) => {
    dispatch(getAdminHomeCarouselStart());
    axios
      .get(`/site/carousels`)
      .then((response) => {
        dispatch(getAdminHomeCarouselSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomeCarouselFail(err.message));
      });
  };
};

export const getOneAdminHomeCarousels = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminHomeCarouselStart());
    axios
      .get(`/site/carousels/carousel/${objectId}`)
      .then((response) => {
        dispatch(getAdminSingleHomeCarouselSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomeCarouselFail(err.message));
      });
  };
};

export const activateAdminHomeCarousels = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminHomeCarouselStart());
    axios
      .put(`/site/carousels/activate/${objectId}`)
      .then((response) => {
        dispatch(getAdminSingleHomeCarouselSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomeCarouselFail(err.message));
      });
  };
};

export const desactivateAdminHomeCarousels = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminHomeCarouselStart());
    axios
      .put(`/site/carousels/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getAdminSingleHomeCarouselSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomeCarouselFail(err.message));
      });
  };
};

export const archiveAdminHomeCarousels = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminHomeCarouselStart());
    axios
      .put(`/site/carousels/archive/${objectId}`)
      .then((response) => {
        dispatch(getAdminSingleHomeCarouselSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomeCarouselFail(err.message));
      });
  };
};

export const updateAdminHomeCarousels = (object) => {
  return (dispatch) => {
    dispatch(getAdminHomeCarouselStart());
    axios
      .put(`/site/carousels/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getAdminSingleHomeCarouselSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomeCarouselFail(err.message));
      });
    notifications.successUpdateNotification(
      "Home Carousel",
      notifications.UPDATE
    );
  };
};

export const deleteAdminHomeCarousels = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminHomeCarouselStart());
    axios
      .delete(`/site/carousels/delete/${objectId}`)
      .then((response) => {
        dispatch(getAdminSingleHomeCarouselSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomeCarouselFail(err.message));
      });
  };
};

export const visibleAdminHomeCarousels = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminHomeCarouselStart());
    axios
      .put(`/site/carousels/visible/${objectId}`)
      .then((response) => {
        dispatch(getAdminSingleHomeCarouselSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomeCarouselFail(err.message));
      });
  };
};

export const getAdminCommunicationLinksStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_COMMUNICATION_LINKS_START,
  };
};

export const getAdminCommunicationLinksSuccess = (
  adminHomeCommunicationLinks
) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_COMMUNICATION_LINKS_SUCCESS,
    adminHomeCommunicationLinks: adminHomeCommunicationLinks,
  };
};

export const getOneAdminCommunicationLinksSuccess = (homeCommunicationLink) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_HOME_COMMUNICATION_LINKS_SUCCESS,
    homeCommunicationLink: homeCommunicationLink,
  };
};

export const getAdminCommunicationLinksFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_COMMUNICATION_LINKS_FAIL,
    error: error,
  };
};

export const getAdminCommunicationLinks = () => {
  return (dispatch) => {
    dispatch(getAdminCommunicationLinksStart());
    axios
      .get(`/site/communication-links`)
      .then((response) => {
        dispatch(getAdminCommunicationLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminCommunicationLinksFail(err.message));
      });
  };
};
export const getAdminAboutStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_ABOUT_START,
  };
};

export const getAdminAboutSuccess = (adminHomeAbout) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_ABOUT_SUCCESS,
    adminHomeAbout: adminHomeAbout,
  };
};

export const getOneAdminAboutSuccess = (homeAbout) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_HOME_ABOUT_SUCCESS,
    homeAbout: homeAbout,
  };
};

export const getAdminAboutFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_ABOUT_FAIL,
    error: error,
  };
};

export const getAdminAbout = () => {
  return (dispatch) => {
    dispatch(getAdminAboutStart());
    axios
      .get(`/site/home-abouts`)
      .then((response) => {
        dispatch(getAdminAboutSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAboutFail(err.message));
      });
  };
};

export const getOneAdminHomeAbout = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAboutStart());
    axios
      .get(`/site/home-abouts/about/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAboutSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAboutFail(err.message));
      });
  };
};

export const deleteAdminHomeAbouts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAboutStart());
    axios
      .delete(`/site/home-abouts/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAboutSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAboutFail(err.message));
      });
  };
};

export const activateAdminHomeAbouts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAboutStart());
    axios
      .put(`/site/home-abouts/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAboutSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAboutFail(err.message));
      });
  };
};

export const updateAdminHomeAbouts = (object) => {
  return (dispatch) => {
    dispatch(getAdminAboutStart());
    axios
      .put(`/site/home-abouts/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminAboutSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAboutFail(err.message));
      });
  };
};

export const desactivateAdminHomeAbouts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAboutStart());
    axios
      .put(`/site/home-abouts/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAboutSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAboutFail(err.message));
      });
  };
};

export const archiveAdminHomeAbouts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAboutStart());
    axios
      .put(`/site/home-abouts/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAboutSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAboutFail(err.message));
      });
  };
};

export const visibleAdminHomeAbouts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAboutStart());
    axios
      .put(`/site/home-abouts/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAboutSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAboutFail(err.message));
      });
  };
};

export const getAdminProductStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_PRODUCT_START,
  };
};

export const getAdminProductSuccess = (adminHomeProduct) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_PRODUCT_SUCCESS,
    adminHomeProduct: adminHomeProduct,
  };
};

export const getOneAdminProductSuccess = (homeProduct) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_HOME_PRODUCT_SUCCESS,
    homeProduct: homeProduct,
  };
};

export const getAdminProductFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_PRODUCT_FAIL,
    error: error,
  };
};

export const getAdminProduct = () => {
  return (dispatch) => {
    dispatch(getAdminProductStart());
    axios
      .get(`/site/home-products`)
      .then((response) => {
        dispatch(getAdminProductSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductFail(err.message));
      });
  };
};

export const getOneAdminHomeProducts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductStart());
    axios
      .get(`/site/home-products/home-product/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductFail(err.message));
      });
  };
};

export const deleteAdminHomeProducts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductStart());
    axios
      .delete(`/site/home-products/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductFail(err.message));
      });
  };
};

export const activateAdminHomeProducts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductStart());
    axios
      .put(`/site/home-products/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductFail(err.message));
      });
  };
};

export const desactivateAdminHomeProducts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductStart());
    axios
      .put(`/site/home-products/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductFail(err.message));
      });
  };
};

export const updateAdminHomeProducts = (object) => {
  return (dispatch) => {
    dispatch(getAdminProductStart());
    axios
      .put(`/site/home-products/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminProductSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductFail(err.message));
      });
  };
};

export const archiveAdminHomeProducts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductStart());
    axios
      .put(`/site/home-products/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductFail(err.message));
      });
  };
};

export const visibleAdminHomeProducts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductStart());
    axios
      .put(`/site/home-products/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductFail(err.message));
      });
  };
};

export const getAdminPartnersStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_PARTNERS_START,
  };
};

export const getAdminPartnersSuccess = (adminHomePartners) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_PARTNERS_SUCCESS,
    adminHomePartners: adminHomePartners,
  };
};

export const getOneAdminPartnersSuccess = (homePartner) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_HOME_PARTNERS_SUCCESS,
    homePartner: homePartner,
  };
};

export const getAdminPartnersFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_PARTNERS_FAIL,
    error: error,
  };
};

export const getAdminPartners = () => {
  return (dispatch) => {
    dispatch(getAdminPartnersStart());
    axios
      .get(`/site/home-partners`)
      .then((response) => {
        dispatch(getAdminPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersFail(err.message));
      });
  };
};
export const getAdminServicesIntroStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_SERVICES_INTRO_START,
  };
};

export const getAdminServicesIntroSuccess = (adminHomeServicesIntro) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_SERVICES_INTRO_SUCCESS,
    adminHomeServicesIntro: adminHomeServicesIntro,
  };
};

export const getOneAdminServicesIntroSuccess = (homeServiceIntro) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_HOME_SERVICES_INTRO_SUCCESS,
    homeServiceIntro: homeServiceIntro,
  };
};

export const getAdminServicesIntroFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_SERVICES_INTRO_FAIL,
    error: error,
  };
};

export const getAdminServicesIntro = () => {
  return (dispatch) => {
    dispatch(getAdminServicesIntroStart());
    axios
      .get(`/site/home-services-intros`)
      .then((response) => {
        dispatch(getAdminServicesIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesIntroFail(err.message));
      });
  };
};

export const getOneAdminServicesIntro = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServicesIntroStart());
    axios
      .get(`/site/home-services-intros/hs-intro/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServicesIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesIntroFail(err.message));
      });
  };
};

export const getAdminServicesStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_SERVICES_START,
  };
};

export const getAdminServicesSuccess = (adminHomeServices) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_SERVICES_SUCCESS,
    adminHomeServices: adminHomeServices,
  };
};

export const getOneAdminServicesSuccess = (homeService) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_HOME_SERVICES_SUCCESS,
    homeService: homeService,
  };
};

export const getAdminServicesFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_SERVICES_FAIL,
    error: error,
  };
};

export const getAdminServices = () => {
  return (dispatch) => {
    dispatch(getAdminServicesStart());
    axios
      .get(`/site/home-services`)
      .then((response) => {
        dispatch(getAdminServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesFail(err.message));
      });
  };
};
export const getAdminTodaysQuoteStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_TODAY_QUOTE_START,
  };
};

export const getAdminTodaysQuoteSuccess = (adminHomeTodaysQuote) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_TODAY_QUOTE_SUCCESS,
    adminHomeTodaysQuote: adminHomeTodaysQuote,
  };
};

export const getOneAdminTodaysQuoteSuccess = (homeTodaysQuote) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_HOME_TODAY_QUOTE_SUCCESS,
    homeTodaysQuote: homeTodaysQuote,
  };
};

export const getAdminTodaysQuoteFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_TODAY_QUOTE_FAIL,
    error: error,
  };
};

export const getAdminTodaysQuote = () => {
  return (dispatch) => {
    dispatch(getAdminTodaysQuoteStart());
    axios
      .get(`/site/todays-quote`)
      .then((response) => {
        dispatch(getAdminTodaysQuoteSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodaysQuoteFail(err.message));
      });
  };
};

export const getOneAdminHomeTodayQuotes = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminTodaysQuoteStart());
    axios
      .get(`/site/todays-quote/quote/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminTodaysQuoteSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodaysQuoteFail(err.message));
      });
  };
};

export const deleteAdminHomeTodayQuotes = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminTodaysQuoteStart());
    axios
      .delete(`/site/todays-quote/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminTodaysQuoteSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodaysQuoteFail(err.message));
      });
  };
};

export const activateAdminHomeTodayQuotes = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminTodaysQuoteStart());
    axios
      .put(`/site/todays-quote/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminTodaysQuoteSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodaysQuoteFail(err.message));
      });
  };
};

export const desactivateAdminHomeTodayQuotes = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminTodaysQuoteStart());
    axios
      .put(`/site/todays-quote/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminTodaysQuoteSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodaysQuoteFail(err.message));
      });
  };
};

export const updateAdminHomeTodayQuotes = (object) => {
  return (dispatch) => {
    dispatch(getAdminTodaysQuoteStart());
    axios
      .put(`/site/todays-quote/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminTodaysQuoteSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodaysQuoteFail(err.message));
      });
  };
};

export const archiveAdminHomeTodayQuotes = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminTodaysQuoteStart());
    axios
      .put(`/site/todays-quote/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminTodaysQuoteSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodaysQuoteFail(err.message));
      });
  };
};

export const visibleAdminHomeTodayQuotes = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminTodaysQuoteStart());
    axios
      .put(`/site/todays-quote/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminTodaysQuoteSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodaysQuoteFail(err.message));
      });
  };
};

export const getAdminFooterSectionsStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_FOOTER_SECTIONS_START,
  };
};

export const getAdminFooterSectionsSuccess = (adminHomeFooterSections) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_FOOTER_SECTIONS_SUCCESS,
    adminHomeFooterSections: adminHomeFooterSections,
  };
};

export const getOneAdminFooterSectionsSuccess = (homeFooterSection) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_HOME_FOOTER_SECTIONS_SUCCESS,
    homeFooterSection: homeFooterSection,
  };
};

export const getAdminFooterSectionsFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_FOOTER_SECTIONS_FAIL,
    error: error,
  };
};

export const getAdminFooterSections = () => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionsStart());
    axios
      .get(`/site/footer-sections`)
      .then((response) => {
        dispatch(getAdminFooterSectionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionsFail(err.message));
      });
  };
};

export const getOneAdminHomeFooterSections = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionsStart());
    axios
      .get(`/site/footer-sections/footer-section/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterSectionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionsFail(err.message));
      });
  };
};

export const deleteAdminHomeFooterSections = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionsStart());
    axios
      .delete(`/site/footer-sections/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterSectionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionsFail(err.message));
      });
  };
};

export const activateAdminHomeFooterSections = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionsStart());
    axios
      .put(`/site/footer-sections/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterSectionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionsFail(err.message));
      });
  };
};

export const desactivateAdminHomeFooterSections = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionsStart());
    axios
      .put(`/site/footer-sections/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterSectionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionsFail(err.message));
      });
  };
};

export const updateAdminHomeFooterSections = (object) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionsStart());
    axios
      .put(`/site/footer-sections/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminFooterSectionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionsFail(err.message));
      });
  };
};

export const archiveAdminHomeFooterSections = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionsStart());
    axios
      .put(`/site/footer-sections/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterSectionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionsFail(err.message));
      });
  };
};

export const visibleAdminHomeFooterSections = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionsStart());
    axios
      .put(`/site/footer-sections/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterSectionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionsFail(err.message));
      });
  };
};

export const getAdminFooterAddressStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_FOOTER_ADDRESS_START,
  };
};

export const getAdminFooterAddressSuccess = (adminHomeFooterAddress) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_FOOTER_ADDRESS_SUCCESS,
    adminHomeFooterAddress: adminHomeFooterAddress,
  };
};

export const getOneAdminFooterAddressSuccess = (homeFooterAddress) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_HOME_FOOTER_ADDRESS_SUCCESS,
    homeFooterAddress: homeFooterAddress,
  };
};

export const getAdminFooterAddressFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_FOOTER_ADDRESS_FAIL,
    error: error,
  };
};

export const getAdminFooterAddress = () => {
  return (dispatch) => {
    dispatch(getAdminFooterAddressStart());
    axios
      .get(`/site/footer-addresses`)
      .then((response) => {
        dispatch(getAdminFooterAddressSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterAddressFail(err.message));
      });
  };
};

export const getOneAdminHomeFooterAddress = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterAddressStart());
    axios
      .get(`/site/footer-addresses/footer-address/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterAddressSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterAddressFail(err.message));
      });
  };
};

export const deleteAdminHomeFooterAddress = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterAddressStart());
    axios
      .delete(`/site/footer-addresses/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterAddressSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterAddressFail(err.message));
      });
  };
};

export const activateAdminHomeFooterAddress = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterAddressStart());
    axios
      .put(`/site/footer-addresses/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterAddressSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterAddressFail(err.message));
      });
  };
};

export const desactivateAdminHomeFooterAddress = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterAddressStart());
    axios
      .put(`/site/footer-addresses/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterAddressSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterAddressFail(err.message));
      });
  };
};

export const updateAdminHomeFooterAddress = (object) => {
  return (dispatch) => {
    dispatch(getAdminFooterAddressStart());
    axios
      .put(`/site/footer-addresses/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminFooterAddressSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterAddressFail(err.message));
      });
  };
};

export const archiveAdminHomeFooterAddress = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterAddressStart());
    axios
      .put(`/site/footer-addresses/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterAddressSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterAddressFail(err.message));
      });
  };
};

export const visibleAdminHomeFooterAddress = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterAddressStart());
    axios
      .put(`/site/footer-addresses/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterAddressSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterAddressFail(err.message));
      });
  };
};

export const getAdminFooterSectionItemsStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_FOOTER_SECTION_ITEMS_START,
  };
};

export const getAdminFooterSectionItemsSuccess = (
  adminHomeFooterSectionItems
) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_FOOTER_SECTION_ITEMS_SUCCESS,
    adminHomeFooterSectionItems: adminHomeFooterSectionItems,
  };
};

export const getOneAdminFooterSectionItemsSuccess = (homeFooterSectionItem) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_HOME_FOOTER_SECTION_ITEMS_SUCCESS,
    homeFooterSectionItem: homeFooterSectionItem,
  };
};

export const getAdminFooterSectionItemsFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_HOME_FOOTER_SECTION_ITEMS_FAIL,
    error: error,
  };
};

export const getAdminFooterSectionItems = () => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionItemsStart());
    axios
      .get(`/site/footer-section-items`)
      .then((response) => {
        dispatch(getAdminFooterSectionItemsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionItemsFail(err.message));
      });
  };
};

export const getOneAdminHomeFooterSectionItems = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionItemsStart());
    axios
      .get(`/site/footer-section-items/fs-item/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterSectionItemsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionItemsFail(err.message));
      });
  };
};

export const deleteAdminHomeFooterSectionItems = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionItemsStart());
    axios
      .delete(`/site/footer-section-items/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterSectionItemsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionItemsFail(err.message));
      });
  };
};

export const activateAdminHomeFooterSectionItems = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionItemsStart());
    axios
      .put(`/site/footer-section-items/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterSectionItemsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionItemsFail(err.message));
      });
  };
};

export const desactivateAdminHomeFooterSectionItems = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionItemsStart());
    axios
      .put(`/site/footer-section-items/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterSectionItemsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionItemsFail(err.message));
      });
  };
};

export const updateAdminHomeFooterSectionItems = (object) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionItemsStart());
    axios
      .put(`/site/footer-section-items/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminFooterSectionItemsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionItemsFail(err.message));
      });
  };
};

export const archiveAdminHomeFooterSectionItems = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionItemsStart());
    axios
      .put(`/site/footer-section-items/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterSectionItemsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionItemsFail(err.message));
      });
  };
};

export const visibleAdminHomeFooterSectionItems = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionItemsStart());
    axios
      .put(`/site/footer-section-items/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFooterSectionItemsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionItemsFail(err.message));
      });
  };
};

export const createHomePartner = (objectId, token) => {
  return (dispatch) => {
    dispatch(getAdminPartnersStart());
    axios
      .post(`/site/home-partners/create/${objectId}`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneAdminPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersFail(err.message));
      });
  };
};

export const getAdminHomePartners = () => {
  return (dispatch) => {
    dispatch(getAdminPartnersStart());
    axios
      .get(`/site/home-partners`)
      .then((response) => {
        dispatch(getAdminPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersFail(err.message));
      });
  };
};

export const createAdminHomeServices = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminServicesStart());
    axios
      .post(`/site/home-services/create/${object.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneAdminServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesFail(err.message));
      });
  };
};

export const createAdminHomeServiceIntro = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminServicesIntroStart());
    axios
      .post(`/site/home-services-intros/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getAdminServicesIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesIntroFail(err.message));
        notifications.errorNotification("Home Service Intro");
      });
    notifications.successCreationNotification("Home Service Intro");
  };
};

export const visibleAdminHomeServicesIntros = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServicesIntroStart());
    axios
      .put(`/site/home-services-intros/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServicesIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesIntroFail(err.message));
      });
  };
};

export const archiveAdminHomeServicesIntros = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServicesIntroStart());
    axios
      .put(`/site/home-services-intros/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServicesIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesIntroFail(err.message));
      });
  };
};

export const activateAdminHomeServicesIntros = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServicesIntroStart());
    axios
      .put(`/site/home-services-intros/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServicesIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesIntroFail(err.message));
      });
  };
};

export const desactivateAdminHomeServicesIntros = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServicesIntroStart());
    axios
      .put(`/site/home-services-intros/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServicesIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesIntroFail(err.message));
      });
  };
};

export const updateAdminHomeServicesIntros = (object) => {
  return (dispatch) => {
    dispatch(getAdminServicesIntroStart());
    axios
      .put(`/site/home-services-intros/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminServicesIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesIntroFail(err.message));
      });
    notifications.successUpdateNotification(
      "Home Services Intros",
      notifications.UPDATE
    );
  };
};

export const deleteAdminHomeServicesIntros = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServicesIntroStart());
    axios
      .delete(`/site/home-services-intros/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServicesIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesIntroFail(err.message));
      });
  };
};

export const createAdminCommunucationLinks = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminCommunicationLinksStart());
    axios
      .post(`/site/communication-links/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getAdminCommunicationLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminCommunicationLinksFail(err.message));
      });
  };
};
export const getAdminCommunucationLinks = () => {
  return (dispatch) => {
    dispatch(getAdminCommunicationLinksStart());
    axios
      .get(`/site/communication-links`)
      .then((response) => {
        dispatch(getAdminCommunicationLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminCommunicationLinksFail(err.message));
      });
  };
};

export const getOneAdminCommunucationLinks = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminCommunicationLinksStart());
    axios
      .get(`/site/communication-links/communication-link/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminCommunicationLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminCommunicationLinksFail(err.message));
      });
  };
};
export const updateAdminHomeCommunicationLinks = (object) => {
  return (dispatch) => {
    dispatch(getAdminCommunicationLinksStart());
    axios
      .put(`/site/communication-links/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminCommunicationLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminCommunicationLinksFail(err.message));
      });
    notifications.successUpdateNotification(
      "Home Communication Links",
      notifications.UPDATE
    );
  };
};

export const createAdminHomeAbout = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminAboutStart());
    axios
      .post(`/site/home-abouts/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getAdminAboutSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAboutFail(err.message));
        notifications.errorNotification("Home About");
      });
    notifications.successCreationNotification("Home About");
  };
};

export const createAdminHomeProduct = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminProductStart());
    axios
      .post(`/site/home-products/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getAdminProductSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductFail(err.message));
        notifications.errorNotification("Home Product");
      });
    notifications.successCreationNotification("Home Product");
  };
};

export const createAdminHomeCarousel = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminHomeCarouselStart());
    axios
      .post(`/site/carousels/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getAdminHomeCarouselSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminHomeCarouselFail(err));
        notifications.errorNotification("Home Carousel");
      });
    notifications.successCreationNotification("Home Carousel");
  };
};

export const createAdminTodaysQuote = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminTodaysQuoteStart());
    axios
      .post(`/site/todays-quote/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getAdminTodaysQuoteSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodaysQuoteFail(err.message));
        notifications.errorNotification("Today Quote");
      });
    notifications.successCreationNotification("Today Quote");
  };
};

export const createAdminFooterSection = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionsStart());
    axios
      .post(`/site/footer-sections/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getAdminFooterSectionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionsFail(err.message));
        notifications.errorNotification("Footer Section");
      });
    notifications.successCreationNotification("Footer Section");
  };
};
export const createAdminFooterAddress = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminFooterAddressStart());
    axios
      .post(`/site/footer-addresses/create/${object.language}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getAdminFooterAddressSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFooterAddressFail(err.message));
        notifications.errorNotification("Footer Address");
      });
    notifications.successCreationNotification("Footer Address");
  };
};

export const createAdminFooterSectionItem = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminFooterSectionItemsStart());
    axios
      .post(`/site/footer-section-items/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getAdminFooterSectionItemsSuccess(response.data.results));
        notifications.successCreationNotification("Footer Section Item");
      })
      .catch((err) => {
        dispatch(getAdminFooterSectionItemsFail(err.message)).then();
        notifications.errorNotification("Footer Section Item");
      });
  };
};
