//import {ReactNotifications} from "react-notifications-component";
//const ReactNotification=ReactNotifications
import "react-notifications-component/dist/theme.css";
import { Store } from "react-notifications-component";

export const successNotification = (object, language) => {
  Store.addNotification({
    title: `${object} Created`,
    message: `${object} is created in ${language}`,
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 4000,
      onScreen: true,
    },
    width: 450,
  });
};

export const confirmNotification = () => {
  Store.addNotification({
    title: `Confirm!`,
    message: `Click on Save or Update button to save permanently`,
    type: "info",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 8000,
      showIcon: true,
    },
  });
};

export const errorNotification = (object) => {
  Store.addNotification({
    title: `${object} Failed`,
    message: `${object} failed to be created`,
    type: "warning",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      showIcon: true,
      duration: 8000,
    },
    width: 450,
  });
};

export const successCreationNotification = (object) => {
  Store.addNotification({
    title: `${object} CREATED`,
    message: `${object} is created successfully`,
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      showIcon: true,
    },
    width: 450,
  });
};

export const successUpdateNotification = (object, action) => {
  Store.addNotification({
    title: `${object} UPDATED`,
    message: `${object} is update to ${action}`,
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      showIcon: true,
    },
    width: 450,
  });
};

export const successUpdateFile = (object) => {
  Store.addNotification({
    title: `${object} UPDATED`,
    message: `${object} is changed/updated`,
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      showIcon: true,
    },
    width: 450,
  });
};

export const successDeleteNotification = (object) => {
  Store.addNotification({
    title: `${object} DELETED`,
    message: `${object} is deleted successfull`,
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      showIcon: true,
    },
    width: 450,
  });
};

export const successSentNotification = (object) => {
  Store.addNotification({
    title: `${object} SENT`,
    message: `${object}  sent successfull`,
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      showIcon: true,
    },
    width: 450,
  });
};

export const active = "active";
export const visible = "visible";
export const ACTIVE = "ACTIVE";
export const INACTIVE = "INACTIVE";
export const VISIBLE = "VISIBLE";
export const ARCHIVE = "ARCHIVE";
export const UPDATE = "UPDATE";
export const isTrue = "true";
export const isFalse = "false";
export const widget = "Widget";
export const subwidget = "Subwidget";
export const Save = "Save";
export const Update = "Update";
export const Publish = "Publish";
export const generic = "generic";
export const platfom = "platfom";

export const widgetTypes = [
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C", value: "C" },
  { label: "D", value: "D" },
  { label: "E", value: "E" },
  { label: "F", value: "F" },
  { label: "G", value: "G" },
  { label: "H", value: "H" },
  { label: "I", value: "I" },
  { label: "J", value: "J" },
];

export const sitelinks = [
  { label: "Home Page", value: "/home" },
  { label: "Services Page", value: "/services" },
  { label: "Products Page", value: "/products" },
  { label: "Publications Page", value: "/publications" },
  { label: "About Us Page", value: "/about" },
  { label: "Contact Us Page", value: "/contact-us" },
];

export const facebook = "facebook";
export const linkedin = "linkedin";
export const instagram = "instagram";
export const twitter = "twitter";

export const widgetLanguage = (object) => {
  if (object.language) {
    return object.language;
  } else {
    return "";
  }
};

export const imageTypes = ["jpeg", "jpg", "png", "svg"];
export const videoTypes = ["mp4", "mpeg"];
export const filesFolder = "ogeniusFiles/";

export const imageV = "noImage";
export const videoV = "noVideo";
export const pcAnimationV = "noAnimation";
export const mobAnimationV = "noAnimation";

export default {
  successNotification,
  confirmNotification,
  widgetLanguage,
  errorNotification,
  successSentNotification,
  successUpdateFile,
  active,
  visible,
  ACTIVE,
  INACTIVE,
  VISIBLE,
  ARCHIVE,
  UPDATE,
  widgetTypes,
  isTrue,
  isFalse,
  widget,
  subwidget,
  Save,
  Update,
  Publish,
  facebook,
  linkedin,
  instagram,
  twitter,
  sitelinks,
  generic,
  platfom,
  imageTypes,
  videoTypes,
  filesFolder,
  imageV,
  videoV,
  pcAnimationV,
  mobAnimationV,
};
