import "./servicescss.css";
import parse from "html-react-parser";

import dotenv from "dotenv";
dotenv.config();

const ServiceCard = (props) => {
  return (
    <div className="h-full w-full">
      <div className={`${props.sm ? "h-56 lg:h-80" : "h-full md:h-100"}`}>
        <div className="w-full h-3/4">
          <img
            className="object-cover object-center w-full h-full pb-4"
            src={props.service.image}
            alt={props.service.name}
          ></img>
        </div>
        <div className="service-title-info site-text-blue pl-2">
          {props.service.name ? props.service.name : props.service.title}
        </div>
        <div className="service-description-info pl-2 md:max-h-24 overflow-scroll scrollbar-hidden">
          {props.service.description != undefined
            ? props.service.description
            : ""}
        </div>
      </div>
    </div>
  );
};
export default ServiceCard;
