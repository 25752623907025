import './servicescss.css';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as actionTypes from '../store/actions/';
import Spinner from '../components/Spinner';
import parse from "html-react-parser";

import dotenv from "dotenv";
dotenv.config();

export const ServicePageHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const services = useSelector((state) => state.services.services);
  const servicesLoading = useSelector(
    (state) => state.services.servicesLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getServices(language));
  }, [dispatch, language]);
  return (
    <div className="service-page-header flex flex-col mt-28">
      <div className="service-page-title pb-10 pt-8">{t('Services')}</div>
      <div className="w-full flex lg:justify-center overflow-x-scroll scrollbar-hidden">
        {servicesLoading ? (
          <div className="w-full h-full flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          services.map((service, index) => (
            <div key={index} className="px-8">
              <ServiceIcon name={service.name} href={service.link}>
                <img
                  className="w-6 h-6"
                  src={`${process.env.REACT_APP_BACKEND_URL}/site/${service.icon}`}
                  alt="alt"
                />
              </ServiceIcon>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export const ServiceIcon = (props) => {
  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };
  return (
    <HashLink
      to={props.href}
      scroll={scrollWidthOffset}
      smooth
      className="flex flex-col justify-center items-center service-icon"
    >
      <div className="service-icon-cirlce flex justify-center items-center">
        {props.children}
      </div>
      <div className="service-icon-name pt-8">{props.name}</div>
    </HashLink>
  );
};

export const ServiceImage = (props) => {
  let classes = 'w-full object-cover object-center';
  if (props.rounded) {
    classes = 'service-image-rounded w-full object-cover object-center';
  }
  return (
    <div className="w-full h-full ">
      <img
        className={classes}
        src={props.serviceImage}
        alt={props.serviceName}
      />
    </div>
  );
};
