import { Button, SelectButton, TableLink } from "../components/Buttons";
import "./admin.css";
import {
  DeleteButton,
  EditButton,
  InputText,
  InputTextArea,
  LinkComponent,
  MoreDropDown,
  PreviewButton,
  Uploading,
} from "./AdminComponentsList";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import AsyncSelect from "react-select/async";
import { successNotification, confirmNotification } from "./contants";
import {ReactNotifications} from "react-notifications-component";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { MyDataTable } from "./MyDataTable";
import * as adminActionTypes from "../store/actions/adminIndex";
const ReactNotification=ReactNotifications
export const NewsletterTable = () => {
  const dispatch = useDispatch();
  const newsletter = useSelector(
    (state) => state.adminPublications.adminNewsletter
  );
  const newsletterLoading = useSelector(
    (state) => state.adminPublications.adminNewsletterLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminNewsletter());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {newsletterLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">NEWSLETTER</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Subtitle
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {newsletter.map((news, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {news.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {news.subtitle}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(news.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {news.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {news.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {news.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={news.uuid}
                              link="/admin/publications/newsletter/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add Newsletter"
                  type="addLink"
                  link="/admin/publications/newsletter"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const EventsTable = () => {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.adminPublications.adminEvents);
  const eventsLoading = useSelector(
    (state) => state.adminPublications.adminEventsLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminEvents());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {eventsLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">EVENTS</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Subtitle
                      </th>

                      <th className="border border-blue-400 px-4 py-2">
                        Card Name
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {events.map((event, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {event.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {event.subtitle}
                        </td>

                        <td className="border border-blue-400 px-4 py-2">
                          {event.cardname}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(event.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {event.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {event.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {event.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={event.uuid}
                              link="/admin/publications/events/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Event"
                  type="addLink"
                  link="/admin/publications/events"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const MediaCoverageTable = () => {
  const dispatch = useDispatch();
  const mediaCoverage = useSelector(
    (state) => state.adminPublications.adminMediaCoverage
  );
  const mediaCoverageLoading = useSelector(
    (state) => state.adminPublications.adminMediaCoverageLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminMediaCoverage());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {mediaCoverageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    MEDIA COVERAGE
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {mediaCoverage.map((media, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {media.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(media.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {media.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {media.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {media.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={media.uuid}
                              link="/admin/publications/media-coverage/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add Media Coverage"
                  type="addLink"
                  link="/admin/publications/media-coverage"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const GiftsAndFreeBiesTable = () => {
  const dispatch = useDispatch();
  const giftFreebie = useSelector(
    (state) => state.adminPublications.adminGiftAndFreebies
  );
  const giftFreebieLoading = useSelector(
    (state) => state.adminPublications.adminGiftAndFreebiesLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminGiftsAndFreebies());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {giftFreebieLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    GIFTS AND FREEBIE
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {giftFreebie.map((gift, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {gift.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(gift.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {gift.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {gift.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {gift.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={gift.uuid}
                              link="/admin/publications/gifts-and-freebie/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add Gift/Freebie"
                  type="addLink"
                  link="/admin/publications/gifts-and-freebie"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const TodayAtOGeniusTable = () => {
  const dispatch = useDispatch();
  const today = useSelector(
    (state) => state.adminPublications.adminTodayAtOGenius
  );
  const todayLoading = useSelector(
    (state) => state.adminPublications.adminTodayAtOGeniusLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminTodayAtOgenius());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {todayLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    TODAY AT O'GENIUS
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {today.map((atOgenius, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {atOgenius.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(atOgenius.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {atOgenius.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {atOgenius.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {atOgenius.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={atOgenius.uuid}
                              link="/admin/publications/today-at-ogenius/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add Today at O'Genius"
                  type="addLink"
                  link="/admin/publications/today-at-ogenius"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const PublicationsTable = () => {
  const dispatch = useDispatch();
  const publications = useSelector(
    (state) => state.adminPublications.adminPublications
  );
  const publicationsLoading = useSelector(
    (state) => state.adminPublications.adminPublicationsLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminPublications());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {publicationsLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">PUBLICATIONS</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Name</th>
                      <th className="border border-blue-400 px-4 py-2">Link</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {publications.map((publication, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {publication.name}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {publication.link}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(publication.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {publication.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {publication.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {publication.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={publication.uuid}
                              link="/admin/publications/publications/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Publication"
                  type="addLink"
                  link="/admin/publications/publications"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
