import { useEffect, useState, useRef, useMemo } from "react";

export function videoAutoPlay(options, targetRef) {
  const myVideo = document.getElementById();

  const callBackFunction = function (entries) {
    
  };

  const observer = new IntersectionObserver(callBackFunction);

  observer.observe(myVideo);
}
