import { Button, SelectButton, TableLink } from "../components/Buttons";
import {
  InputText,
  InputTextArea,
  InsertingLanguagesTable,
  InputTextSecret,
  LinkComponent,
  MoreDropDown,
  SelectRadioButton,
} from "./AdminComponentsList";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import AsyncSelect from "react-select/async";
import { useParams } from "react-router";
import contants, {
  successNotification,
  confirmNotification,
  successUpdateNotification,
} from "./contants";
import * as adminActionTypes from "../store/actions/adminIndex";
import {ReactNotifications} from "react-notifications-component";
import moment from "moment";
import classnames from "classnames";

import dotenv from "dotenv";
dotenv.config();
const ReactNotification=ReactNotifications


export const FooterSection = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const [counter, setcounter] = useState(0);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const object = useSelector((state) => state.adminHome.homeFooterSection);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    adminActionTypes.getOneAdminHomeFooterSections(params.objectId);
  }, [dispatch]);

  const [name, setName] = useState(object != null ? object?.name : "");
  const [objects, setObjects] = useState([]);

  let i;
  const onSubmit = (e) => {
    e.preventDefault();
    if (name === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            name,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminHomeFooterSections(obj));
          successUpdateNotification("Footer Section", contants.UPDATE);
          setName("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              name,
              language: lang,
              creator: creatorName,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Footer Section", languageName);
          setName("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setName(name);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(adminActionTypes.createAdminFooterSection(objects, token));
          setcounter(0);
          setName("");
          setclicked(false);
          setdisabled(false);
          setObjects([]);
        }
      }
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Footer Section (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Footer Section (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Name"
                  value={name}
                  isClicked={clicked}
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/footer/footer-section/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const FooterSectionItem = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const footerSections = useSelector((state) => state.home.homeFooterSections);
  const footerSectionsLoading = useSelector(
    (state) => state.home.homeFooterSectionsLoading
  );
  const object = useSelector((state) => state.adminHome.homeFooterSectionItem);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(actionTypes.getFooterSections());
    dispatch(
      adminActionTypes.getOneAdminHomeFooterSectionItems(params.objectId)
    );
  }, [dispatch]);

  const [name, setname] = useState(object != null ? object?.name : "");
  const [link, setlink] = useState(object != null ? object?.link : "");

  // SELECT BUTTO IMPLEMENTATION
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (name === "" || link === "" || selectedValue === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            name,
            link,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminHomeFooterSectionItems(obj));
          successUpdateNotification("Footer Section Item", contants.UPDATE);
          setlink("");
          setname("");
          setclicked(false);
        } else {
        }
      } else {
        const id = selectedValue.uuid;
        const object = {
          name,
          link,
          sectionId: id,
          creator: creatorName,
        };
        dispatch(adminActionTypes.createAdminFooterSectionItem(object, token));
        setname("");
        setlink("");
        setclicked(false);
        setdisabled(false);
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {footerSectionsLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-2 w-3/5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Footer Section Item
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Footer Section Item Editing
                  </span>
                </div>
                <div className="pt-3 ">
                  <div>
                    <label className="input-title">Select Footer Section</label>
                    <div className="flex">
                      <AsyncSelect
                        placeholder="Select Footer Section"
                        className={classnames("mb-2 w-full", {
                          "mb-2 w-full border-2 border-red-500":
                            selectedValue === "" && clicked === true,
                        })}
                        cacheOptions
                        defaultOptions={footerSections}
                        getOptionLabel={(e) => e.name + " - " + e.language}
                        getOptionValue={(e) => e.uuid}
                        value={selectedValue}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        isSearchable
                      />
                    </div>
                    <label className="block text-red-500">
                      {selectedValue === "" && clicked === true
                        ? "Select Footer Section"
                        : ""}
                    </label>
                  </div>
                  <InputText
                    title="Name"
                    value={name}
                    isClicked={clicked}
                    name="name"
                    onChange={(e) => setname(e.target.value)}
                  />
                  <InputText
                    title="Link"
                    value={link}
                    isClicked={clicked}
                    name="link"
                    onChange={(e) => setlink(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-2">
                <Button name={contants.Publish} type="square" />
                <TableLink
                  title="List"
                  type="viewLink"
                  link="/admin/footer/footer-section-item/objects/list"
                />
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const FooterAddress = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const [counter, setcounter] = useState(0);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const object = useSelector((state) => state.adminHome.homeFooterAddress);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(
      adminActionTypes.getOneAdminHomeFooterSectionItems(params.objectId)
    );
  }, [dispatch]);

  const [location, setLocation] = useState(
    object != null ? object?.location : ""
  );
  const [contact, setContact] = useState(object != null ? object?.contact : "");
  const [privacypolicylink, setprivacypolicylink] = useState(
    object != null ? object?.privacypolicylink : ""
  );
  const [email, setEmail] = useState(object != null ? object?.email : "");
  const [copyright, setCopyright] = useState(
    object != null ? object?.copyright : ""
  );
  const [sitemapname, setsitemapname] = useState(
    object != null ? object?.sitemapname : ""
  );
  const [sitemaplink, setsitemaplink] = useState(
    object != null ? object?.sitemaplink : ""
  );
  const [privacypolicyname, setprivacypolicyname] = useState(
    object != null ? object?.privacypolicyname : ""
  );
  const [termsofservicename, settermsofservicename] = useState(
    object != null ? object?.termsofservicename : ""
  );
  const [termsofservicelink, settermsofservicelink] = useState(
    object != null ? object?.termsofservicelink : ""
  );
  const [objects, setObjects] = useState([]);

  let i;
  const onSubmit = (e) => {
    e.preventDefault();
    if (
      location === "" ||
      contact === "" ||
      email === "" ||
      copyright === "" ||
      sitemapname === "" ||
      sitemaplink === "" ||
      privacypolicyname === "" ||
      privacypolicylink === "" ||
      termsofservicename === "" ||
      termsofservicelink === ""
    ) {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            location,
            contact,
            email,
            copyright,
            sitemapname,
            sitemaplink,
            privacypolicyname,
            privacypolicylink,
            termsofservicename,
            termsofservicelink,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminHomeFooterAddress(obj));
          successUpdateNotification("Footer Address", contants.UPDATE);
          setLocation("");
          setContact("");
          setEmail("");
          setCopyright("");
          setsitemapname("");
          setsitemaplink("");
          setprivacypolicyname("");
          setprivacypolicylink("");
          settermsofservicename("");
          settermsofservicelink("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              location,
              contact,
              email,
              copyright,
              sitemapname,
              sitemaplink,
              privacypolicyname,
              privacypolicylink,
              termsofservicename,
              termsofservicelink,
              language: lang,
              creator: creatorName,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Footer Address", languageName);
          setLocation("");
          setContact("");
          setEmail("");
          setCopyright("");
          setsitemapname("");
          setsitemaplink("");
          setprivacypolicyname("");
          setprivacypolicylink("");
          settermsofservicename("");
          settermsofservicelink("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setLocation(location);
          setContact(contact);
          setEmail(email);
          setCopyright(copyright);
          setsitemapname(sitemapname);
          setsitemaplink(sitemaplink);
          setprivacypolicyname(privacypolicyname);
          setprivacypolicylink(privacypolicylink);
          settermsofservicename(termsofservicename);
          settermsofservicelink(termsofservicelink);
          setdisabled(true);
        }

        if (counter == languages.length) {
          for (i = 0; i < objects.length; i++) {
            dispatch(adminActionTypes.createAdminFooterAddress(objects[i], token));
          }
          setcounter(0);
          setLocation("");
          setContact("");
          setEmail("");
          setCopyright("");
          setsitemapname("");
          setsitemaplink("");
          setprivacypolicyname("");
          setprivacypolicylink("");
          settermsofservicename("");
          settermsofservicelink("");
          setObjects([]);
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/footer/footer-address" menuName="Footer" />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-2 w-4/5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Footer Address (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Footer Address (Language: {object?.language}) Editing
                  </span>
                </div>
                <div className="flex space-x-7">
                  <div className="w-1/2">
                    <InputText
                      title="Email"
                      value={email}
                      isClicked={clicked}
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <InputText
                      title="Phone number"
                      value={contact}
                      isClicked={clicked}
                      name="contact"
                      onChange={(e) => setContact(e.target.value)}
                    />
                    <InputText
                      title="Copyright"
                      value={copyright}
                      isClicked={clicked}
                      name="copyright"
                      onChange={(e) => setCopyright(e.target.value)}
                    />
                    <InputText
                      title="Site Map Name"
                      value={sitemapname}
                      isClicked={clicked}
                      name="sitemapname"
                      onChange={(e) => setsitemapname(e.target.value)}
                    />
                    <InputText
                      title="Site Map Link"
                      value={sitemaplink}
                      isClicked={clicked}
                      name="sitemaplink"
                      onChange={(e) => setsitemaplink(e.target.value)}
                    />
                  </div>
                  <div className="w-1/2">
                    <InputText
                      title="Location"
                      value={location}
                      isClicked={clicked}
                      name="name"
                      onChange={(e) => setLocation(e.target.value)}
                    />
                    <InputText
                      title="Privacy Policy Name"
                      value={privacypolicyname}
                      isClicked={clicked}
                      name="privacypolicyname"
                      onChange={(e) => setprivacypolicyname(e.target.value)}
                    />
                    <InputText
                      title="Privacy Policy Link"
                      value={privacypolicylink}
                      isClicked={clicked}
                      name="privacypolicylink"
                      onChange={(e) => setprivacypolicylink(e.target.value)}
                    />
                    <InputText
                      title="Terms of Service Name"
                      value={termsofservicename}
                      isClicked={clicked}
                      name="termsofservicename"
                      onChange={(e) => settermsofservicename(e.target.value)}
                    />
                    <InputText
                      title="Terms of Service Link"
                      value={termsofservicelink}
                      isClicked={clicked}
                      name="termsofservicelink"
                      onChange={(e) => settermsofservicelink(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/footer/footer-address/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const User = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const staffMembers = useSelector((state) => state.about.staffMembers);
  const staffMembersLoading = useSelector(
    (state) => state.about.staffMembersLoading
  );
  const object = useSelector((state) => state.about.user);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(actionTypes.getAllStaffMembers());
    dispatch(actionTypes.getOneUsers(params.objectId));
  }, [dispatch]);

  const [role, setrole] = useState(object != null ? object?.role : "");

  // SELECT BUTTON IMPLEMENTATION
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (object != null) {
      if (window.confirm("Are you sure you want update?")) {
        const obj = {
          role,
          id: object.uuid,
        };
        dispatch(actionTypes.updateUsers(obj));
        successUpdateNotification("User", contants.UPDATE);
      } else {
      }
    } else {
      const id = selectedValue.uuid;
      const object = {
        role: "admin",
        staffId: id,
        creator: creatorName,
      };
      dispatch(actionTypes.createUser(object, token));
      setrole("");
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {staffMembersLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 w-full ">
              <div className="flex flex-col pt-5 space-y-4 w-full ">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New User
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Editing User
                  </span>
                </div>
                <div className="pt-3 ">
                  <label className="input-title">Select Staff</label>
                  <div className="flex">
                    <AsyncSelect
                      placeholder="Select Staff Member"
                      className="mb-2 w-4/6"
                      cacheOptions
                      defaultOptions={staffMembers}
                      getOptionLabel={(e) => e.name + " - " + e.position}
                      getOptionValue={(e) => e.uuid}
                      value={selectedValue}
                      onChange={handleChange}
                      onInputChange={handleInputChange}
                      isSearchable
                    />
                  </div>
                </div>
                <div className="w-3/5"></div>
              </div>
              <div className="flex flex-col space-y-2">
                <Button name={contants.Save} type="square" />
                <TableLink
                  title="List"
                  type="viewLink"
                  link="/admin/users/objects/list"
                />
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const PlatfomLink = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const creatorName = useSelector((state) => state.auth.name);
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const token = useSelector((state) => state.auth.token);
  const object = {
    name,
    link,
    creator: "JimMy",
  };

  const onSubmit = (e) => {
    e.preventDefault();
    //  dispatch(actionTypes.createPlatfomLinks(object, token));
  };
  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/platfom-link" menuName="Footer" />
        </div>
        <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
          <div className="flex justify-between pl-12">
            <div className="flex flex-col pt-5 space-y-4">
              <div className="font-bold flex flex-col">
                <span>Platfom Link</span>
              </div>
              <InputText
                title="Name"
                value={name}
                name="name"
                onChange={(e) => setName(e.target.value)}
              />
              <InputText
                title="Link"
                value={link}
                name="link"
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <Button name="Save or Update" type="square" />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/executive-intro/objects/list"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
