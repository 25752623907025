import axios from "../../axios-base";
import * as adminActionTypes from "./adminActionTypes.js";
import * as notifications from "../../admin/contants";

export const getAdminProductsStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_PRODUCTS_START,
  };
};

export const getAdminProductsSuccess = (adminProducts) => {
  return {
    type: adminActionTypes.GET_ADMIN_PRODUCTS_SUCCESS,
    adminProducts: adminProducts,
  };
};

export const getOneAdminProductsSuccess = (product) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_PRODUCTS_SUCCESS,
    product: product,
  };
};

export const getAdminProductsFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_PRODUCTS_FAIL,
    error: error,
  };
};

export const getAllAdminProducts = () => {
  return (dispatch) => {
    dispatch(getAdminProductsStart());
    axios
      .get(`/site/products`)
      .then((response) => {
        dispatch(getAdminProductsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductsFail(err.message));
      });
  };
};

export const getOneAdminProducts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductsStart());
    axios
      .get(`/site/products/product/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductsFail(err.message));
      });
  };
};

export const deleteAdminProducts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductsStart());
    axios
      .delete(`/site/products/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductsFail(err.message));
      });
  };
};

export const activateAdminProducts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductsStart());
    axios
      .put(`/site/products/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductsFail(err.message));
      });
  };
};

export const desactivateAdminProducts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductsStart());
    axios
      .put(`/site/products/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductsFail(err.message));
      });
  };
};

export const updateAdminProducts = (object) => {
  return (dispatch) => {
    dispatch(getAdminProductsStart());
    axios
      .put(`/site/products/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminProductsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductsFail(err.message));
      });
  };
};

export const archiveAdminProducts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductsStart());
    axios
      .put(`/site/products/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductsFail(err.message));
      });
  };
};

export const visibleAdminProducts = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductsStart());
    axios
      .put(`/site/products/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductsFail(err.message));
      });
  };
};

export const createAdminProducts = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminProductsStart());
    axios
      .post(`/site/products/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneAdminProductsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductsFail(err.message));
        notifications.errorNotification("Product");
      });
    notifications.successCreationNotification("Product");
  };
};

export const getAdminProductWidgetsStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_PRODUCT_WIDGETS_START,
  };
};

export const getAdminProductWidgetsSuccess = (adminProductWidgets) => {
  return {
    type: adminActionTypes.GET_ADMIN_PRODUCT_WIDGETS_SUCCESS,
    adminProductWidgets: adminProductWidgets,
  };
};

export const getOneAdminProductWidgetsSuccess = (productWidget) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_PRODUCT_WIDGETS_SUCCESS,
    productWidget: productWidget,
  };
};

export const getAdminProductWidgetsFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_PRODUCT_WIDGETS_FAIL,
    error: error,
  };
};

export const getAllProductsSubwidgets = () => {
  return (dispatch) => {
    dispatch(getAdminProductWidgetsStart());
    axios
      .get(`/site/products-widgets/subwidgets`)
      .then((response) => {
        dispatch(getAdminProductWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductWidgetsFail(err.message));
      });
  };
};

export const getAllProductsWidgets = () => {
  return (dispatch) => {
    dispatch(getAdminProductWidgetsStart());
    axios
      .get(`/site/products-widgets`)
      .then((response) => {
        dispatch(getAdminProductWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductWidgetsFail(err.message));
      });
  };
};

export const getOneProductsWidgets = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductWidgetsStart());
    axios
      .get(`/site/products-widgets/product-widget/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductWidgetsFail(err.message));
      });
  };
};

export const createProductWidget = (object, productId, token) => {
  if (productId != undefined) {
    return (dispatch) => {
      dispatch(getAdminProductWidgetsStart());
      axios
        .post(`/site/products-widgets/create/${productId}`, object, {
          headers: { Authorization: token },
        })
        .then((response) => {
          dispatch(getOneAdminProductWidgetsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getAdminProductWidgetsFail(err.message));
          notifications.errorNotification("Product Widget");
        });
      notifications.successCreationNotification("Product Widget");
    };
  } else {
    return (dispatch) => {
      dispatch(getAdminProductWidgetsStart());
      axios
        .post(`/site/products-widgets/create`, object, {
          headers: { Authorization: token },
        })
        .then((response) => {
          dispatch(getOneAdminProductWidgetsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getAdminProductWidgetsFail(err.message));
          notifications.errorNotification("Product Widget");
        });
      notifications.successCreationNotification("Product Widget");
    };
  }
};

export const updateProductWidget = (object) => {
  return (dispatch) => {
    dispatch(getAdminProductWidgetsStart());
    axios
      .put(`/site/products-widgets/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminProductWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductWidgetsFail(err.message));
      });
  };
};

export const deleteProductWidget = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductWidgetsStart());
    axios
      .delete(`/site/products-widgets/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductWidgetsFail(err.message));
      });
  };
};

export const activateProductWidget = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductWidgetsStart());
    axios
      .put(`/site/products-widgets/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductWidgetsFail(err.message));
      });
  };
};

export const desactivateProductWidget = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductWidgetsStart());
    axios
      .put(`/site/products-widgets/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductWidgetsFail(err.message));
      });
  };
};

export const visibleProductWidget = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductWidgetsStart());
    axios
      .put(`/site/products-widgets/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductWidgetsFail(err.message));
      });
  };
};

export const archiveProductWidget = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminProductWidgetsStart());
    axios
      .put(`/site/products-widgets/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminProductWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminProductWidgetsFail(err.message));
      });
  };
};
