import { Button } from '../components/Buttons';
import PartnerCard from './PartnerCard';
import HomePageTitle from '../components/HomePageTitle';
import './partnerscss.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as actionTypes from '../store/actions/';
import Spinner from '../components/Spinner';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import dotenv from "dotenv";
dotenv.config();

const HomePartners = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const homePartners = useSelector((state) => state.home.homePartners);
  const homePartnersLoading = useSelector(
    (state) => state.home.homePartnersLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getHomePartners(language));
  }, [dispatch, language]);
  return (
    <div className="home-partners site-text-blue">
      <div data-aos="zoom-in-down" data-aos-duration="1500">
        {homePartnersLoading ? (
          <div className="">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="text-center pt-16">
              <HomePageTitle title={t('Our Partners')} />
            </div>
            <div className="flex flex-col ">
              <div className="flex flex-col items-center flex-wrap md:space-x-14  md:flex-row md:justify-center">
                {homePartners.map((homePartner) => (
                  <PartnerCard
                    key={homePartner?.partner?.uuid}
                    name={homePartner?.partner?.name}
                    logo={`${process.env.REACT_APP_BACKEND_URL}/site/${homePartner?.partner?.logo}`}
                  />
                ))}
              </div>

              <Link to="/partners" className="flex justify-center py-10">
                <Button name={t('View All')} />
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default HomePartners;
