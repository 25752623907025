import React from "react";
import { useTranslation } from "react-i18next";
import TestimonyCard from "../../servicesComponent/TestimonyCard";
import parse from "html-react-parser";

const WidgetA = (props) => {
  const { t } = useTranslation();
  return (
    <div
      id={props.widget.hashlink}
      className="it-service md:h-screen-second flex flex-col py-10 md:flex-row md:space-x-16"
    >
      <div className="md:w-1/2">
        <img
          src={`${process.env.REACT_APP_BACKEND_URL}/site/${props.widget.image}`}
          alt="Information Technology"
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div className="hidden md:flex flex-col md:w-1/2">
        <div className="h-1/2">
          <div className="site-text-blue service-title text-xl">
            {props.widget.title}
          </div>
          <div className="it-descriptor w-full overflow-scroll scrollbar-hidden h-70%">
            {
              props.widget.body != undefined ? parse(eval(props.widget.body)) : parse("<p></p>"
            )}
          </div>
        </div>
        <div className="h-1/2">
          <div className="site-text-blue it-testimony-title pt-2">
            {t("See Clients' Testimonials")} :)
          </div>
          <div className="flex h-full overflow-x-scroll scrollbar-hidden space-x-2">
            {props.widget.clienttestimonials.map((testimony, index) => (
              <div key={index} className="w-1/2 flex-shrink-0">
                <TestimonyCard
                  client={testimony.name}
                  testimony={testimony.testimony}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetA;
