import { Button, TableLink } from "../components/Buttons";
import "./admin.css";
import {
  ActivateButton,
  ActivatingButton,
  ArchivingButton,
  InputText,
  InputTextArea,
  LinkComponent,
  MoreDropDown,
  Uploading,
} from "./AdminComponentsList";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions";
import * as adminActionTypes from "../store/actions/adminIndex";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import AsyncSelect from "react-select/async";
import contants, {
  successUpdateNotification,
  successDeleteNotification,
  confirmNotification,
} from "./contants";
import {ReactNotifications} from "react-notifications-component";
import { MyDataTable } from "./MyDataTable";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { useParams } from "react-router";
import { GenericDisplayMedia } from "./AdminGallerieComponentsList";
import dotenv from "dotenv";
dotenv.config();
const ReactNotification=ReactNotifications
export const PreviewPartners = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminPartners.partner);
  const objectLoading = useSelector(
    (state) => state.adminPartners.adminPartnersLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminPartners(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminPartners(object.uuid));
        successUpdateNotification(" Partner", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminPartners(object.uuid));
        successUpdateNotification(" Partner", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminPartners(object.uuid));
        successUpdateNotification(" Partner", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminPartners(object.uuid));
        successUpdateNotification(" Partner", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminPartners(object.uuid));
      successDeleteNotification(" Partner");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">
                  PARTNER PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Name :</span>
                    <span className="site-text-blue px-3">
                      {object?.name}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Description:
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.description != undefined
                        ? parse(eval(object?.description))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Moto:</span>
                    <span className="site-text-blue px-3">
                      {object?.moto != undefined
                        ? parse(eval(object?.moto))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Quote:</span>
                    <span className="site-text-blue px-3">
                      {object?.quote != undefined
                        ? parse(eval(object?.quote))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Link :</span>
                    <span className="site-text-blue px-3">
                      {object?.link}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between h-80 ">
                <GenericDisplayMedia media={object?.logo} />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/partners"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/partners/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const PreviewPartnersIntro = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminPartners.partnersIntro);
  const objectLoading = useSelector(
    (state) => state.adminPartners.adminPartnersLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminPartnersIntro(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminPartnersIntro(object.uuid));
        successUpdateNotification("Partners Intro", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminPartnersIntro(object.uuid));
        successUpdateNotification("Partners Intro", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminPartnersIntro(object.uuid));
        successUpdateNotification("Partners Intro", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminPartnersIntro(object.uuid));
        successUpdateNotification("Partners Intro", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminPartnersIntro(object.uuid));
      successDeleteNotification("Partners Intro");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold pb-5">
                <span className="admin-page-title text-xl">
                  PARTNERS INTRO PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      DescriptionI:
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.description1 != undefined
                        ? parse(eval(object?.description1))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      DescriptionII:
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.description2 != undefined
                        ? parse(eval(object?.description2))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/partners-introduction"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/partners-introduction/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const PreviewAffiliation = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminPartners.affiliation);
  const objectLoading = useSelector(
    (state) => state.adminPartners.adminAffiliationsLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminAffiliations(params.objectId));
  }, [dispatch]);
  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminAffiliations(object.uuid));
        successUpdateNotification("Affiliation", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminAffiliations(object.uuid));
        successUpdateNotification("Affiliation", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminAffiliations(object.uuid));
        successUpdateNotification("Affiliation", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminAffiliations(object.uuid));
        successUpdateNotification("Affiliation", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminAffiliations(object.uuid));
      successDeleteNotification("Affiliation");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold pb-5">
                <span className="admin-page-title text-xl">
                  AFFILIATION PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Name :</span>
                    <span className="site-text-blue px-3">
                      {object?.name}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Description:
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.description != undefined
                        ? parse(eval(object?.description))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Quote:</span>
                    <span className="site-text-blue px-3">
                      {object?.quote != undefined
                        ? parse(eval(object?.quote))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Link :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between h-80 ">
                <GenericDisplayMedia media={object?.image} />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/affiliations"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/affiliations/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
