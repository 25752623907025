import { Button, SelectButton, TableLink } from "../components/Buttons";
import {
  InputText,
  InputTextArea,
  InsertingLanguagesTable,
  Uploading,
} from "./AdminComponentsList";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import moment from "moment";
import contants, {
  successNotification,
  confirmNotification,
  successUpdateNotification,
} from "./contants";
import {ReactNotifications} from "react-notifications-component";
import { useParams } from "react-router";
import * as adminActionTypes from "../store/actions/adminIndex";
import richTextConstants from "./richtextconstants";

import dotenv from "dotenv";
dotenv.config();
const ReactNotification=ReactNotifications


export const PartnersPage = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.adminPartners.partner);
  const params = useParams();

  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminPartners(params.objectId));
  }, [dispatch]);

  const [name, setName] = useState(object != null ? object?.name : "");
  const [description, setDescription] = useState(
    object != null ? eval(object?.description) : ""
  );
  const [link, setLink] = useState(object != null ? object?.link : "");
  const [logo, setLogo] = useState(null);
  const [quote, setQuote] = useState(object != null ? eval(object?.quote) : "");
  const [moto, setMoto] = useState(object != null ? eval(object?.moto) : "");

  const fileSelectoHandler = (event) => {
    setLogo(event.target.files[0]);
  };
  let i;
  const onSubmit = (e) => {
    e.preventDefault();
    if (
      moto === "" ||
      name === "" ||
      description === "" ||
      quote === "" ||
      link === ""
    ) {
      setclicked(true);
    } else {
      const richtextDescription =
        richTextConstants.separateTextAndFiles("description");
      const richtextMoto = richTextConstants.separateTextAndFiles("moto");
      const richtextQuote = richTextConstants.separateTextAndFiles("quote");
      richtextDescription.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      richtextMoto.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      richtextQuote.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            description: richtextDescription.editorValue,
            moto: richtextMoto.editorValue,
            name,
            link,
            quote: richtextQuote.editorValue,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminPartners(obj));
          successUpdateNotification("Partner", contants.UPDATE);
          setName("");
          setDescription("");
          setMoto("");
          setLink("");
          setQuote("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";

          setObjects([
            ...objects,
            {
              name,
              link,
              quote: richtextQuote.editorValue,
              moto: richtextMoto.editorValue,
              creator: creatorName,
              language: lang,
              description: richtextDescription.editorValue,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Partner", languageName);
          setName("");
          setDescription("");
          setMoto("");
          setLink("");
          setQuote("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setName(name);
          setDescription(description);
          setMoto(moto);
          setLink(link);
          setQuote(quote);
          setdisabled(true);
        }
        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("logo", logo);
          fd.append("objects", JSON.stringify(objects));
          dispatch(actionTypes.createPartners(fd, token));
          setcounter(0);
          setName("");
          setDescription("");
          setMoto("");
          setLink("");
          setQuote("");
          setObjects([]);
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 w-70% pb-20">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Partner (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Partner (Language: {object?.language}) Editing
                  </span>
                </div>
                <div className="pt-4">
                  <InputText
                    title="Name"
                    value={name}
                    isClicked={clicked}
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <InputTextArea
                  id="description"
                  title="Description"
                  isClicked={clicked}
                  data={description}
                  name="description"
                  onChange={(event) => {
                    setDescription(event);
                  }}
                />
                <InputTextArea
                  id="moto"
                  title="Moto"
                  data={moto}
                  isClicked={clicked}
                  name="moto"
                  onChange={(event) => {
                    setMoto(event);
                  }}
                />
                <InputTextArea
                  id="quote"
                  title="Quote"
                  data={quote}
                  isClicked={clicked}
                  name="quote"
                  onChange={(event) => {
                    setQuote(event);
                  }}
                />
                <Uploading
                  updating={object != null ? contants.isTrue : contants.isFalse}
                  title="Logo"
                  text1="upload logo"
                  text2="view the logo"
                  name="logo"
                  required
                  onChange={fileSelectoHandler}
                />
                <InputText
                  title="Link"
                  value={link}
                  isClicked={clicked}
                  name="link"
                  onChange={(e) => setLink(e.target.value)}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/partners/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const PartnersPageIntroduction = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const [counter, setcounter] = useState(0);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.adminPartners.partnersIntro);
  const params = useParams();
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminPartnersIntro(params.objectId));
  }, [dispatch]);

  const [title, setTitle] = useState(object != null ? object?.title : "");
  const [description1, setDescription1] = useState(
    object != null ? eval(object?.description1) : ""
  );
  const [description2, setDescription2] = useState(
    object != null ? eval(object?.description2) : ""
  );
  const [objects, setObjects] = useState([]);

  let i;
  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || description2 === "" || description1 === "") {
      setclicked(true);
    } else {
      const richtextDescription1 =
        richTextConstants.separateTextAndFiles("description1");
      const richtextDescription2 =
        richTextConstants.separateTextAndFiles("description2");
      richtextDescription1.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      richtextDescription2.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            description1: richtextDescription1.editorValue,
            description2: richtextDescription2.editorValue,
            title,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminPartnersIntro(obj));
          successUpdateNotification("Partners Intro", contants.UPDATE);
          setTitle("");
          setDescription1("");
          setDescription2("");
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              title,
              description1: richtextDescription1.editorValue,
              description2: richtextDescription2.editorValue,
              language: lang,
              creator: creatorName,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Partner Intro", languageName);
          setTitle("");
          setDescription1("");
          setDescription2("");
        }
        if (counter == languages.length - 1) {
          setTitle(title);
          setDescription1(description1);
          setDescription2(description2);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(actionTypes.createPartnersIntros(objects, token));
          setcounter(0);
          setObjects([]);
          setTitle("");
          setDescription1("");
          setDescription2("");
        }
      }
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Partner Introduction (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Partner Introduction (Language: {object?.language}) Editing
                  </span>
                </div>
                <div className="pt-4">
                  <InputText
                    title="Title"
                    value={title}
                    isClicked={clicked}
                    name="title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <InputTextArea
                  id="description1"
                  title="Description 1"
                  data={description1}
                  isClicked={clicked}
                  name="description1"
                  onChange={(event) => {
                    setDescription1(event);
                  }}
                />
                <InputTextArea
                  id="description2"
                  title="Description 2"
                  data={description2}
                  isClicked={clicked}
                  name="description2"
                  onChange={(event) => {
                    setDescription2(event);
                  }}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/partners-introduction/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const AffiliationsPage = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.adminPartners.affiliation);
  const params = useParams();
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminAffiliations(params.objectId));
  }, [dispatch]);

  const [name, setName] = useState(object != null ? object?.name : "");
  const [description, setDescription] = useState(
    object != null ? eval(object?.description) : ""
  );
  const [link, setLink] = useState(object != null ? object?.link : "");
  const [image, setImage] = useState(null);
  const [quote, setQuote] = useState(object != null ? eval(object?.quote) : "");

  const fileSelectoHandler = (event) => {
    setImage(event.target.files[0]);
  };
  let i;
  const onSubmit = (e) => {
    e.preventDefault();
    if (name === "" || link === "" || description === "" || quote === "") {
      setclicked(true);
    } else {
      const richtextDescription =
        richTextConstants.separateTextAndFiles("description");
      const richtextQuote = richTextConstants.separateTextAndFiles("quote");
      richtextDescription.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      richtextQuote.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            description: richtextDescription.editorValue,
            name,
            link,
            quote: richtextQuote.editorValue,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminAffiliations(obj));
          successUpdateNotification("Affiliation", contants.UPDATE);
          setName("");
          setQuote("");
          setLink("");
          setDescription("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              name,
              link,
              quote: richtextQuote.editorValue,
              creator: creatorName,
              language: lang,
              description: richtextDescription.editorValue,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Affiliation", languageName);
          setName("");
          setQuote("");
          setLink("");
          setDescription("");
          setclicked(false);
        }

        if (counter == languages.length - 1) {
          setName(name);
          setQuote(quote);
          setLink(link);
          setDescription(description);
          setdisabled(true);
        }

        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("image", image);
          fd.append("objects", JSON.stringify(objects));
          dispatch(actionTypes.createAffiliations(fd, token));
          setcounter(0);
          setObjects([]);
          setName("");
          setQuote("");
          setLink("");
          setDescription("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-3">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Affiliation (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Affiliation (Language: {object?.language}) Editing
                  </span>
                </div>
                <div className="pt-4">
                  <InputText
                    title="Name"
                    value={name}
                    isClicked={clicked}
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <InputTextArea
                  title="Description"
                  id="description"
                  data={description}
                  isClicked={clicked}
                  name="description"
                  onChange={(event) => {
                    setDescription(event);
                  }}
                />
                <InputTextArea
                  id="quote"
                  title="Quote"
                  data={quote}
                  isClicked={clicked}
                  name="quote"
                  onChange={(event) => {
                    setQuote(event);
                  }}
                />
                <Uploading
                  updating={object != null ? contants.isTrue : contants.isFalse}
                  title="Image"
                  text1="upload image"
                  text2="view the image"
                  name="image"
                  required
                  onChange={fileSelectoHandler}
                />

                <InputText
                  title="Link"
                  isClicked={clicked}
                  value={link}
                  name="link"
                  onChange={(e) => setLink(e.target.value)}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/affiliations/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
