import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import homeReducer from "./store/reducers/home";
import servicesReducer from "./store/reducers/services";
import productsReducer from "./store/reducers/products";
import partnersReducer from "./store/reducers/partners";
import publicationsReducer from "./store/reducers/publications";
import aboutReducer from "./store/reducers/about";
import contactReducer from "./store/reducers/contact";
import languageReducer from "./store/reducers/languages";
import linksReducer from "./store/reducers/links";
import authReducer from "./store/reducers/auth";
import adminHomeReducer from "./store/reducers/adminHome";
import adminContactReducer from "./store/reducers/adminContact";
import adminPartnerReducer from "./store/reducers/adminPartners";
import adminPublicationsReducer from "./store/reducers/adminPublications";
import adminAboutReducer from "./store/reducers/adminAbout";
import adminServiceReducer from "./store/reducers/adminService";
import adminProductReducer from "./store/reducers/adminProduct";
import adminFilesReducer from "./store/reducers/adminFiles";

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;
const rootReducer = combineReducers({
  home: homeReducer,
  services: servicesReducer,
  products: productsReducer,
  partners: partnersReducer,
  publications: publicationsReducer,
  about: aboutReducer,
  contact: contactReducer,
  languages: languageReducer,
  links: linksReducer,
  auth: authReducer,
  adminHome: adminHomeReducer,
  adminContact: adminContactReducer,
  adminPartners: adminPartnerReducer,
  adminPublications: adminPublicationsReducer,
  adminAbout: adminAboutReducer,
  adminService: adminServiceReducer,
  adminProduct: adminProductReducer,
  adminFiles: adminFilesReducer,
});
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
