import { useCallback, useState } from 'react';
import '../contactus/contactus.css';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/Buttons';
import ScrollToTop from '../components/ScrollToTop';
import Spinner from '../components/Spinner';
import Footer from '../footerComponents/Footer';
import Header from '../headerCompoents/Header';
import axios from '../axios-base';

const ForgottenPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const handleSubmit = useCallback(() => {
    setError(null);
    setResponse(null);
    setLoading(true);
    axios
      .post('/site/forgotten/password', { email: email })
      .then((response) => {
        setLoading(false);
        setResponse(response.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);
      });
  }, [email]);
  return (
    <div>
      <Header />
      <div className="bg-bg-second-color py-10 w-full mt-28">
        <div className="w-2/5 rounded-xl p-10 bg-white shadow-2xl flex flex-col m-auto">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <input
                className="sender-contact p-3 text-xs outline-none m-2"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              {error ? (
                <div className="font-medium py-2 text-center">{error}</div>
              ) : null}
              {response ? (
                <div className="font-medium py-2 text-center">
                  {response.message}
                </div>
              ) : null}
              <div className="m-auto m-2">
                <Button onClick={handleSubmit} name={t('Send')} />
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default ForgottenPassword;
