import { Button, TableLink } from "../components/Buttons";
import "./admin.css";
import {
  ActivateButton,
  ActivatingButton,
  ArchivingButton,
  InputText,
  InputTextArea,
  LinkComponent,
  MoreDropDown,
  Uploading,
} from "./AdminComponentsList";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions";
import * as adminActionTypes from "../store/actions/adminIndex";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import AsyncSelect from "react-select/async";
import contants, {
  successUpdateNotification,
  successDeleteNotification,
  successSentNotification,
  confirmNotification,
} from "./contants";
import {ReactNotifications} from "react-notifications-component";
import { MyDataTable } from "./MyDataTable";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { useParams } from "react-router";
import { GenericDisplayMedia } from "./AdminGallerieComponentsList";

import dotenv from "dotenv";
dotenv.config();
const  ReactNotification=ReactNotifications


export const PreviewContactUs = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminContact.contactUs);
  const objectLoading = useSelector(
    (state) => state.adminContact.adminContactUsLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminContactUs(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminContactUs(object.uuid));
        successUpdateNotification(" Contact Us", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminContactUs(object.uuid));
        successUpdateNotification(" Contact Us", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminContactUs(object.uuid));
        successUpdateNotification(" Contact Us", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminContactUs(object.uuid));
        successUpdateNotification(" Contact Us", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminContactUs(object.uuid));
      successDeleteNotification(" Contact Us");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">
                  CONTACT US PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Email :</span>
                    <span className="site-text-blue px-3">
                      {object?.email}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Mobile Number :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.mobilenumber}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Phone Number :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.phonenumber}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Address :</span>
                    <span className="site-text-blue px-3">
                      {object?.address}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between h-80 ">
                <GenericDisplayMedia media={object?.image} />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/contact-us/contacts"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/contact-us/contacts/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const PreviewQuickLinks = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminContact.quickLink);
  const objectLoading = useSelector(
    (state) => state.adminContact.adminQuickLinksLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminQuickLinks(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminQuickLinks(object.uuid));
        successUpdateNotification(" QuickLink", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminQuickLinks(object.uuid));
        successUpdateNotification(" QuickLink", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminQuickLinks(object.uuid));
        successUpdateNotification(" QuickLink", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminQuickLinks(object.uuid));
        successUpdateNotification(" QuickLink", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminQuickLinks(object.uuid));
      successDeleteNotification(" QuickLink");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold pb-5">
                <span className="admin-page-title text-xl">
                  QUICK LINK PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Description:
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.description != undefined
                        ? parse(eval(object?.description))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/contact-us/quick-links"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/contact-us/quick-links/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const PreviewFAQs = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminContact.faq);
  const objectLoading = useSelector(
    (state) => state.adminContact.adminFaqsLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminFaqs(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminFaqs(object.uuid));
        successUpdateNotification(" Faq", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminFaqs(object.uuid));
        successUpdateNotification(" Faq", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminFaqs(object.uuid));
        successUpdateNotification(" Faq", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminFaqs(object.uuid));
        successUpdateNotification(" Faq", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminFaqs(object.uuid));
      successDeleteNotification(" Faq");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">FAQ PREVIEW</span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Question:</span>
                    <span className="site-text-blue px-3">
                      {object?.question != undefined
                        ? parse(eval(object?.question))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Answer:</span>
                    <span className="site-text-blue px-3">
                      {object?.answer != undefined
                        ? parse(eval(object?.answer))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Call To Action Name :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.calltoactionname}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Call To Action Link :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.calltoactionlink}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/contact-us/faqs"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/contact-us/faqs/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const PreviewMessages = (props) => {
  const [response, setresponse] = useState("");
  const [clicked, setclicked] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminContact.message);
  const objectLoading = useSelector(
    (state) => state.adminContact.adminMessagesLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminMessage(params.objectId));
  }, [dispatch]);

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminMessage(object.uuid));
      successDeleteNotification(" Message");
    } else {
    }
  };

  const onReply = () => {
    if (window.confirm("Are you sure you want reply this message?")) {
      const obj = {
        id: object.uuid,
        response,
        email: object.email,
      };
      dispatch(adminActionTypes.replyMessage(obj, token));
      successSentNotification(" Message");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">Read Message</span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Sender :</span>
                    <span className="site-text-blue px-3">
                      {object?.name}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Email :</span>
                    <span className="site-text-blue px-3">
                      {object?.email}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Message:</span>
                    <span className="site-text-blue px-3">
                      {object?.body != undefined
                        ? parse(((object?.body))?(object?.body):"<p></p>")
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Replied:</span>
                    <span className="site-text-blue px-3">
                      {object?.replied}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Sent Date :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                </div>
              </div>
              <div className="flex w-full space-x-10 mb-5">
                <InputTextArea
                  title="Response"
                  data={response}
                  isClicked={clicked}
                  name="answer"
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setresponse(data);
                  }}
                />
              </div>
              <Button name="Submit" type="square" onClick={onReply} />
            </div>
            <div className="flex flex-col space-y-2">
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/messages/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
