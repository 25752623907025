import axios from '../../axios-base';
import * as actionTypes from './actionTypes.js';
import * as notifications from "../../admin/contants";

export const getProductsStart = () => {
  return {
    type: actionTypes.GET_PRODUCTS_START,
  };
};

export const getProductsSuccess = (products) => {
  return {
    type: actionTypes.GET_PRODUCTS_SUCCESS,
    products: products,
  };
};

export const getProductsFail = (error) => {
  return {
    type: actionTypes.GET_PRODUCTS_FAIL,
    error: error,
  };
};

export const getProducts = (lang) => {
  return (dispatch) => {
    dispatch(getProductsStart());
    axios
      .get(`/site/products/active/${lang}`)
      .then((response) => {
        dispatch(getProductsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getProductsFail(err.message));
      });
  };
};

export const getProductWidgetsStart = () => {
  return {
    type: actionTypes.GET_PRODUCT_WIDGETS_START,
  };
};

export const getProductWidgetsSuccess = (productWidgets) => {
  return {
    type: actionTypes.GET_PRODUCT_WIDGETS_SUCCESS,
    productWidgets: productWidgets,
  };
};

export const getProductWidgetsFail = (error) => {
  return {
    type: actionTypes.GET_PRODUCT_WIDGETS_FAIL,
    error: error,
  };
};

export const getProductWidgets = (lang) => {
  return (dispatch) => {
    dispatch(getProductWidgetsStart());
    axios
      .get(`/site/products-widgets/active/${lang}`)
      .then((response) => {
        dispatch(getProductWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getProductWidgetsFail(err.message));
      });
  };
};
