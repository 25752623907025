import opanda from "../images/whitelogo.png";
import "./admin.css";
import { useCallback, useState } from "react";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../store/actions";

export const AdminHeaderMenu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const logout = useCallback(() => {
    dispatch(actionTypes.logout());
    history.replace("/");
  }, [dispatch, history]);
  const name = useSelector((state) => state.auth.name);
  return (
    <div className="flex justify-between header-menu top-0 sticky z-50">
      <div className="">
        <img
          src={opanda}
          className="w-full h-full object-cover object-center"
          alt="image2"
        />
      </div>
      <div className="flex header-menu-titles items-center justify-around space-x-10">
        <div className="cursor-pointer">
          <HeaderMenuItem menu={name} />
        </div>
        <div className="cursor-pointer" onClick={logout}>
          <HeaderMenuItem menu="Logout" />
        </div>
      </div>
    </div>
  );
};

export const HeaderMenuItem = (props) => {
  return (
    <div>
      <span className="text-gray-100 site-font-family">{props.menu}</span>
    </div>
  );
};

export const SideMenu = (props) => {
  const history = useHistory();
  const location = useLocation();
  const homeItems = [
    { title: "Carousel", itemId: "/admin/home-carousel" },
    { title: "About", itemId: "/admin/home-about" },
    {
      title: "Product SpotLight",
      itemId: "/admin/home-product",
      mainPage: "Home",
    },
    { title: "Partner", itemId: "/admin/home-partner" },
    { title: "Services", itemId: "/admin/home-service" },
    { title: "Home Services Intro", itemId: "/admin/home-service-intro" },
    { title: "Quote", itemId: "/admin/home-quote" },
  ];
  const partnersItems = [
    {
      title: "Intro",
      itemId: "/admin/partners-introduction",
    },
    { title: "Partners", itemId: "/admin/partners" },
    {
      title: "Affiliations",
      itemId: "/admin/affiliations",
    },
  ];

  const publicationsItems = [
    { title: "Publications", itemId: "/admin/publications/publications" },
    { title: "Newsletter", itemId: "/admin/publications/newsletter" },
    { title: "Events", itemId: "/admin/publications/events" },
    { title: "Media coverage", itemId: "/admin/publications/media-coverage" },
    {
      title: "Gifts and Freebies",
      itemId: "/admin/publications/gifts-and-freebie",
    },
    {
      title: "Today At OGenius",
      itemId: "/admin/publications/today-at-ogenius",
    },
  ];

  const aboutUsItems = [
    { title: "Executive Intro", itemId: "/admin/about/executive-intro" },
    { title: "Careers", itemId: "/admin/about/careers" },
    { title: "Staff Intro", itemId: "/admin/about/staff-intros" },
    { title: "Staff/Team", itemId: "/admin/about/staff-members" },
    { title: "Awards Summary", itemId: "/admin/about/awards-summary" },
    { title: "Awards & Recognition", itemId: "/admin/about/awards" },
    {
      title: "Ethics & Compliance",
      itemId: "/admin/about/ethics-and-compliance",
    },
    { title: "Get IN Touch", itemId: "/admin/about/get-in-touch" },
  ];

  const contactUsItems = [
    { title: "ContactUs", itemId: "/admin/contact-us/contacts" },
    { title: "Quick Links", itemId: "/admin/contact-us/quick-links" },
    { title: "FAQs", itemId: "/admin/contact-us/faqs" },
    { title: "Messages", itemId: "/admin/messages/objects/list" },
  ];

  const footerItems = [
    { title: "Footer Section", itemId: "/admin/footer/footer-section" },
    {
      title: "Footer Section Items",
      itemId: "/admin/footer/footer-section-item",
    },
    { title: "Footer Address", itemId: "/admin/footer/footer-address" },
  ];

  const customItems = [
    { title: "Links", itemId: "/admin/links" },
    { title: "Communication Links", itemId: "/admin/communication-links" },
    { title: "Gallerie", itemId: "/admin/gallerie" },
    { title: "Language", itemId: "/admin/languages" },
  ];

  const servicesItems = [
    { title: "Services", itemId: "/admin/services" },
    { title: "Service Widgets", itemId: "/admin/service-widget" },
    { title: "Client Testimonials", itemId: "/admin/client-testimonials" },
  ];

  const productsItems = [
    { title: "Products", itemId: "/admin/products" },
    { title: "Product Widgets", itemId: "/admin/product-widget" },
  ];

  const usersItems = [{ title: "Users", itemId: "/admin/users" }];

  return (
    <div className="flex flex-col side-menu justify-between h-90vh sticky top-16">
      <div className="flex flex-col flex-1 text-left h-full overflow-scroll ">
        <>
          <Navigation
            activeItemId={location.pathname}
            onSelect={({ itemId }) => {
              history.push(itemId);
            }}
            items={[
              {
                title: "Dashboard",
                itemId: "/admin-home",
              },
              {
                title: "Home",
                itemId: "/admin/home-carousel",
                subNav: homeItems,
              },
              {
                title: "Services",
                itemId: "/admin/services",
                subNav: servicesItems,
              },
              {
                title: "Products",
                itemId: "/admin/products",
                subNav: productsItems,
              },
              {
                title: "Partners",
                itemId: "/admin/partners-introduction",
                subNav: partnersItems,
              },
              {
                title: "Publications",
                itemId: "/admin/publications/publications",
                subNav: publicationsItems,
              },
              {
                title: "About Us",
                itemId: "/admin/about/executive-intro",
                subNav: aboutUsItems,
              },
              {
                title: "Contact Us",
                itemId: "/admin/contact-us/contacts",
                subNav: contactUsItems,
              },
              {
                title: "Footer",
                itemId: "/admin/footer/footer-section",
                subNav: footerItems,
              },
              {
                title: "Custom",
                itemId: "/admin/links",
                subNav: customItems,
              },
              {
                title: "User Management",
                itemId: "/admin/users",
                subNav: usersItems,
              },
            ]}
          />
        </>
      </div>
      <div className="copyright justify-self-end text-green-50">
        Copyright(c) 2021
      </div>
    </div>
  );
};

export const MenuItem = (props) => {
  return (
    <NavLink
      to={props.page}
      activeClassName={"font-bold underline text-left text-blue-200 text-lg"}
      className={"text-left text-blue-200 text-lg"}
    >
      {props.pageName}
    </NavLink>
  );
};

export const MainMenu = (props) => {
  const [showMenuItems, setShowMenuItems] = useState(false);
  const menuList = props.menuItems.map((item, index) => (
    <li
      className={showMenuItems ? "menu-list" : "menu-list hidden"}
      key={index}
    >
      <MenuItem page={item.page} pageName={item.name} />
    </li>
  ));
  return (
    <ul>
      <li className="">
        <button
          className={
            props.menuName === props.menuTitle
              ? "w-full text-left pl-7 font-bold underline text-blue-200 text-lg"
              : "w-full text-left pl-7 text-blue-200 text-lg"
          }
          onClick={() => {
            setShowMenuItems(!showMenuItems);
          }}
        >
          {props.menuTitle}
        </button>
      </li>
      {menuList}
    </ul>
  );
};
