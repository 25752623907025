import { Button, TableLink } from "../components/Buttons";
import "./admin.css";
import {
  ActivateButton,
  ActivatingButton,
  ArchivingButton,
  InputText,
  InputTextArea,
  LinkComponent,
  MoreDropDown,
  Uploading,
} from "./AdminComponentsList";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions";
import * as adminActionTypes from "../store/actions/adminIndex";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import AsyncSelect from "react-select/async";
import contants, {
  successUpdateNotification,
  successDeleteNotification,
  confirmNotification,
} from "./contants";
import {ReactNotifications} from "react-notifications-component";
import { MyDataTable } from "./MyDataTable";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { useParams } from "react-router";
import { GenericDisplayMedia } from "./AdminGallerieComponentsList";

import dotenv from "dotenv";
dotenv.config();
const ReactNotification=ReactNotifications
export const PublicationsPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminPublications.publication);
  const objectLoading = useSelector(
    (state) => state.adminPublications.adminPublicationsLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminPublications(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminPublications(object.uuid));
        successUpdateNotification(" Publication", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminPublications(object.uuid));
        successUpdateNotification(" Publication", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminPublications(object.uuid));
        successUpdateNotification(" Publication", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminPublications(object.uuid));
        successUpdateNotification(" Publication", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminPublications(object.uuid));
      successDeleteNotification(" Publication");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">
                  PUBLICATION PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Name :</span>
                    <span className="site-text-blue px-3">
                      {object?.name}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Link :</span>
                    <span className="site-text-blue px-3">
                      {object?.link}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/publications/publications"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/publications/publications/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const NewsletterPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminPublications.newsletter);
  const objectLoading = useSelector(
    (state) => state.adminPublications.adminNewsletterLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminNewsletter(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminNewsletter(object.uuid));
        successUpdateNotification(" Newsletter", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminNewsletter(object.uuid));
        successUpdateNotification(" Newsletter", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminNewsletter(object.uuid));
        successUpdateNotification(" Newsletter", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminNewsletter(object.uuid));
        successUpdateNotification(" Newsletter", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminNewsletter(object.uuid));
      successDeleteNotification(" Newsletter");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold pb-5">
                <span className="admin-page-title text-xl">
                  NEWSLETTER PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Subtitle :</span>
                    <span className="site-text-blue px-3">
                      {object?.subtitle}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Description:
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.description != undefined
                        ? parse(eval(object?.description))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between h-80 ">
                <GenericDisplayMedia media={object?.image} />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/publications/newsletter"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/publications/newsletter/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const EventsPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminPublications.event);
  const objectLoading = useSelector(
    (state) => state.adminPublications.adminEventsLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminEvents(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminEvents(object.uuid));
        successUpdateNotification(" Event", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminEvents(object.uuid));
        successUpdateNotification(" Event", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminEvents(object.uuid));
        successUpdateNotification(" Event", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminEvents(object.uuid));
        successUpdateNotification(" Event", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminEvents(object.uuid));
      successDeleteNotification(" Event");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">EVENT PREVIEW</span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Subtitle :</span>
                    <span className="site-text-blue px-3">
                      {object?.subtitle}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Host Name :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.hostname}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Host Position :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.hostposition}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Description:
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.description != undefined
                        ? parse(eval(object?.description))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Card Name :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.cardname}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Card Price :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.cardprice}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Card Summary :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.cardsummary}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Card Rate :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.cardrate}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between h-80 space-x-7 ">
                <GenericDisplayMedia media={object?.hostprofile} />
                <GenericDisplayMedia media={object?.cardimage} />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/publications/events"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/publications/events/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const MediaCoveragePreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminPublications.mediaCoverage);
  const objectLoading = useSelector(
    (state) => state.adminPublications.adminMediaCoverageLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminMediaCoverage(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminMediaCoverage(object.uuid));
        successUpdateNotification(" Media Coverage", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminMediaCoverage(object.uuid));
        successUpdateNotification(" Media Coverage", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminMediaCoverage(object.uuid));
        successUpdateNotification(" Media Coverage", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminMediaCoverage(object.uuid));
        successUpdateNotification(" Media Coverage", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminMediaCoverage(object.uuid));
      successDeleteNotification(" Media Coverage");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold pb-5">
                <span className="admin-page-title text-xl">
                  MEDIA COVERAGE PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Description:
                    </span>
                    <span className="site-text-blue px-3">
                      {
                        object?.description != undefined
                          ? parse(eval(object?.description))
                          : parse("<p></p>"
                      )}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/publications/media-coverage"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/publications/media-coverage/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const GiftsAndFreebiesPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminPublications.giftAndFreebie);
  const objectLoading = useSelector(
    (state) => state.adminPublications.adminGiftAndFreebiesLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminGiftsAndFreebies(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(
          adminActionTypes.desactivateAdminGiftsAndFreebies(object.uuid)
        );
        successUpdateNotification(" Gift/Feebie", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminGiftsAndFreebies(object.uuid));
        successUpdateNotification(" Gift/Feebie", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminGiftsAndFreebies(object.uuid));
        successUpdateNotification(" Gift/Feebie", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminGiftsAndFreebies(object.uuid));
        successUpdateNotification(" Gift/Feebie", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminGiftsAndFreebies(object.uuid));
      successDeleteNotification(" Gift/Feebie");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">
                  GIFT AND FREEBIE PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between h-80 ">
                <GenericDisplayMedia media={object?.image} />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/publications/gifts-and-freebie"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/publications/gifts-and-freebie/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const TodayAtOGeniusPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminPublications.todayAtOGenius);
  const objectLoading = useSelector(
    (state) => state.adminPublications.adminTodayAtOGeniusLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminTodayAtOgenius(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminTodayAtOgenius(object.uuid));
        successUpdateNotification(" Today at OGenius", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminTodayAtOgenius(object.uuid));
        successUpdateNotification(" Today at OGenius", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminTodayAtOgenius(object.uuid));
        successUpdateNotification(" Today at OGenius", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminTodayAtOgenius(object.uuid));
        successUpdateNotification(" Today at OGenius", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminTodayAtOgenius(object.uuid));
      successDeleteNotification(" Today at OGenius");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold pb-5">
                <span className="admin-page-title text-xl">
                  TODAY AT O GENIUS PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Content:</span>
                    <span className="site-text-blue px-3">
                      {
                        object?.content != undefined
                          ? parse(eval(object?.content))
                          : parse("<p></p>"
                      )}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/publications/today-at-ogenius"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/publications/today-at-ogenius/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
