import { updateObject } from "../../utils/updateObject";

import * as actionsTypes from "../actions/actionTypes";

const initialReducer = {
  excutifLeadership: null,
  excutifLeadershipError: null,
  excutifLeadershipLoading: false,
  excutifLeaders: [],
  excutifLeadersError: [],
  excutifLeadersLoading: false,
  carrer: null,
  carrerError: null,
  carrerLoading: false,
  staff: null,
  staffError: null,
  staffLoading: false,
  staffMembers: [],
  staffMembersError: [],
  staffMembersLoading: false,
  awardsIntro: null,
  awardsIntroError: null,
  awardsIntroLoading: false,
  awards: [],
  awardsError: [],
  awardsLoading: false,
  ethicsAndCompliance: [],
  ethicsAndComplianceError: [],
  ethicsAndComplianceLoading: false,
  getInTouch: null,
  getInTouchError: null,
  getInTouchLoading: false,
  users: [],
  usersError: null,
  usersLoading: false,
  user: null,
};

const getExcutifLeadershipStart = (state, action) => {
  return updateObject(state, {
    excutifLeadership: null,
    excutifLeadershipError: null,
    excutifLeadershipLoading: true,
  });
};

const getExcutifLeadershipSuccess = (state, action) => {
  return updateObject(state, {
    excutifLeadership: action.excutifLeadership,
    excutifLeadershipError: null,
    excutifLeadershipLoading: false,
  });
};

const getExcutifLeadershipFail = (state, action) => {
  return updateObject(state, {
    excutifLeadership: null,
    excutifLeadershipError: action.error,
    excutifLeadershipLoading: false,
  });
};

const getExcutifLeadersStart = (state, action) => {
  return updateObject(state, {
    excutifLeaders: [],
    excutifLeadersError: null,
    excutifLeadersLoading: true,
  });
};

const getExcutifLeadersSuccess = (state, action) => {
  return updateObject(state, {
    excutifLeaders: action.excutifLeaders,
    excutifLeadersError: null,
    excutifLeadersLoading: false,
  });
};

const getExcutifLeadersFail = (state, action) => {
  return updateObject(state, {
    excutifLeaders: [],
    excutifLeadersError: action.error,
    excutifLeadersLoading: false,
  });
};

const getCareerStart = (state, action) => {
  return updateObject(state, {
    career: null,
    careerError: null,
    careerLoading: true,
  });
};

const getCareerSuccess = (state, action) => {
  return updateObject(state, {
    career: action.career,
    careerError: null,
    careerLoading: false,
  });
};

const getCareerFail = (state, action) => {
  return updateObject(state, {
    career: null,
    careerError: action.error,
    careerLoading: false,
  });
};

const getStaffStart = (state, action) => {
  return updateObject(state, {
    staff: null,
    staffError: null,
    staffLoading: true,
  });
};

const getStaffSuccess = (state, action) => {
  return updateObject(state, {
    staff: action.staff,
    staffError: null,
    staffLoading: false,
  });
};

const getStaffFail = (state, action) => {
  return updateObject(state, {
    staff: null,
    staffError: action.error,
    staffLoading: false,
  });
};

const getStaffMembersStart = (state, action) => {
  return updateObject(state, {
    staffMembers: [],
    staffMembersError: null,
    staffMembersLoading: true,
  });
};

const getStaffMembersSuccess = (state, action) => {
  return updateObject(state, {
    staffMembers: action.staffMembers,
    staffMembersError: null,
    staffMembersLoading: false,
  });
};

const getStaffMembersFail = (state, action) => {
  return updateObject(state, {
    staffMembers: [],
    staffMembersError: action.error,
    staffMembersLoading: false,
  });
};

const getAwardsIntroStart = (state, action) => {
  return updateObject(state, {
    awardsIntro: null,
    awardsIntroError: null,
    awardsIntroLoading: true,
  });
};

const getAwardsIntroSuccess = (state, action) => {
  return updateObject(state, {
    awardsIntro: action.awardsIntro,
    awardsIntroError: null,
    awardsIntroLoading: false,
  });
};

const getAwardsIntroFail = (state, action) => {
  return updateObject(state, {
    awardsIntro: null,
    awardsIntroError: action.error,
    awardsIntroLoading: false,
  });
};

const getAwardsStart = (state, action) => {
  return updateObject(state, {
    awards: [],
    awardsError: null,
    awardsLoading: true,
  });
};

const getAwardsSuccess = (state, action) => {
  return updateObject(state, {
    awards: action.awards,
    awardsError: null,
    awardsLoading: false,
  });
};

const getAwardsFail = (state, action) => {
  return updateObject(state, {
    awards: [],
    awardsError: action.error,
    awardsLoading: false,
  });
};

const getEthicsAndComplianceStart = (state, action) => {
  return updateObject(state, {
    ethicsAndCompliance: [],
    ethicsAndComplianceError: null,
    ethicsAndComplianceLoading: true,
  });
};

const getEthicsAndComplianceSuccess = (state, action) => {
  return updateObject(state, {
    ethicsAndCompliance: action.ethicsAndCompliance,
    ethicsAndComplianceError: null,
    ethicsAndComplianceLoading: false,
  });
};

const getEthicsAndComplianceFail = (state, action) => {
  return updateObject(state, {
    ethicsAndCompliance: [],
    ethicsAndComplianceError: action.error,
    ethicsAndComplianceLoading: false,
  });
};

const getGetInTouchStart = (state, action) => {
  return updateObject(state, {
    getInTouch: null,
    getInTouchError: null,
    getInTouchLoading: true,
  });
};

const getGetInTouchSuccess = (state, action) => {
  return updateObject(state, {
    getInTouch: action.getInTouch,
    getInTouchError: null,
    getInTouchLoading: false,
  });
};

const getGetInTouchFail = (state, action) => {
  return updateObject(state, {
    getInTouch: [],
    getInTouchError: action.error,
    getInTouchLoading: false,
  });
};

const getUsersStart = (state, action) => {
  return updateObject(state, {
    users: [],
    usersError: null,
    usersLoading: true,
    user: null,
  });
};

const getUsersSuccess = (state, action) => {
  return updateObject(state, {
    users: action.users,
    usersError: null,
    usersLoading: false,
    user: null,
  });
};

const getUsersFail = (state, action) => {
  return updateObject(state, {
    users: [],
    usersError: action.error,
    usersLoading: false,
    user: null,
  });
};

const getOneUsersSuccess = (state, action) => {
  return updateObject(state, {
    users: [],
    usersError: null,
    usersLoading: false,
    user: action.user,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_EXCUTIF_LEADERSHIP_START:
      return getExcutifLeadershipStart(state, action);
    case actionsTypes.GET_EXCUTIF_LEADERSHIP_SUCCESS:
      return getExcutifLeadershipSuccess(state, action);
    case actionsTypes.GET_EXCUTIF_LEADERSHIP_FAIL:
      return getExcutifLeadershipFail(state, action);
    case actionsTypes.GET_EXCUTIF_LEADERS_START:
      return getExcutifLeadersStart(state, action);
    case actionsTypes.GET_EXCUTIF_LEADERS_SUCCESS:
      return getExcutifLeadersSuccess(state, action);
    case actionsTypes.GET_EXCUTIF_LEADERS_FAIL:
      return getExcutifLeadersFail(state, action);
    case actionsTypes.GET_CAREER_START:
      return getCareerStart(state, action);
    case actionsTypes.GET_CAREER_SUCCESS:
      return getCareerSuccess(state, action);
    case actionsTypes.GET_CAREER_FAIL:
      return getCareerFail(state, action);
    case actionsTypes.GET_STAFF_START:
      return getStaffStart(state, action);
    case actionsTypes.GET_STAFF_SUCCESS:
      return getStaffSuccess(state, action);
    case actionsTypes.GET_STAFF_FAIL:
      return getStaffFail(state, action);
    case actionsTypes.GET_STAFF_MEMBERS_START:
      return getStaffMembersStart(state, action);
    case actionsTypes.GET_STAFF_MEMBERS_SUCCESS:
      return getStaffMembersSuccess(state, action);
    case actionsTypes.GET_STAFF_MEMBERS_FAIL:
      return getStaffMembersFail(state, action);
    case actionsTypes.GET_AWARDS_INTRO_START:
      return getAwardsIntroStart(state, action);
    case actionsTypes.GET_AWARDS_INTRO_SUCCESS:
      return getAwardsIntroSuccess(state, action);
    case actionsTypes.GET_AWARDS_INTRO_FAIL:
      return getAwardsIntroFail(state, action);
    case actionsTypes.GET_AWARDS_START:
      return getAwardsStart(state, action);
    case actionsTypes.GET_AWARDS_SUCCESS:
      return getAwardsSuccess(state, action);
    case actionsTypes.GET_AWARDS_FAIL:
      return getAwardsFail(state, action);
    case actionsTypes.GET_ETHICS_COMPLIANCE_START:
      return getEthicsAndComplianceStart(state, action);
    case actionsTypes.GET_ETHICS_COMPLIANCE_SUCCESS:
      return getEthicsAndComplianceSuccess(state, action);
    case actionsTypes.GET_ETHICS_COMPLIANCE_FAIL:
      return getEthicsAndComplianceFail(state, action);
    case actionsTypes.GET_GET_IN_TOUCH_START:
      return getGetInTouchStart(state, action);
    case actionsTypes.GET_GET_IN_TOUCH_SUCCESS:
      return getGetInTouchSuccess(state, action);
    case actionsTypes.GET_GET_IN_TOUCH_FAIL:
      return getGetInTouchFail(state, action);
    case actionsTypes.GET_USER_START:
      return getUsersStart(state, action);
    case actionsTypes.GET_USER_SUCCESS:
      return getUsersSuccess(state, action);
    case actionsTypes.GET_ONE_USER_SUCCESS:
      return getOneUsersSuccess(state, action);
    case actionsTypes.GET_USER_FAIL:
      return getUsersFail(state, action);
    default:
      return state;
  }
};
export default reducer;
