import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";
import * as notifications from "../../admin/contants";

export const getPartnersStart = () => {
  return {
    type: actionTypes.GET_PARTNERS_START,
  };
};

export const getPartnersSuccess = (partners) => {
  return {
    type: actionTypes.GET_PARTNERS_SUCCESS,
    partners: partners,
  };
};

export const getPartnersFail = (error) => {
  return {
    type: actionTypes.GET_PARTNERS_FAIL,
    error: error,
  };
};

export const getPartners = (lang) => {
  return (dispatch) => {
    dispatch(getPartnersStart());
    axios
      .get(`/site/partners/active/${lang}`)
      .then((response) => {
        dispatch(getPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPartnersFail(err.message));
      });
  };
};
export const getPartnersIntroStart = () => {
  return {
    type: actionTypes.GET_PARTNERS_INTRO_START,
  };
};

export const getPartnersIntroSuccess = (partnersIntro) => {
  return {
    type: actionTypes.GET_PARTNERS_INTRO_SUCCESS,
    partnersIntro: partnersIntro,
  };
};

export const getPartnersIntroFail = (error) => {
  return {
    type: actionTypes.GET_PARTNERS_INTRO_FAIL,
    error: error,
  };
};

export const getPartnersIntro = (lang) => {
  return (dispatch) => {
    dispatch(getPartnersIntroStart());
    axios
      .get(`/site/partners-intros/active/${lang}`)
      .then((response) => {
        dispatch(getPartnersIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPartnersIntroFail(err.message));
      });
  };
};
export const getAffiliationsStart = () => {
  return {
    type: actionTypes.GET_AFFILIATIONS_START,
  };
};

export const getAffiliationsSuccess = (affiliations) => {
  return {
    type: actionTypes.GET_AFFILIATIONS_SUCCESS,
    affiliations: affiliations,
  };
};

export const getAffiliationsFail = (error) => {
  return {
    type: actionTypes.GET_AFFILIATIONS_FAIL,
    error: error,
  };
};

export const getAffiliations = (lang) => {
  return (dispatch) => {
    dispatch(getAffiliationsStart());
    axios
      .get(`/site/affiliations/active/${lang}`)
      .then((response) => {
        dispatch(getAffiliationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAffiliationsFail(err.message));
      });
  };
};

export const createAffiliations = (object, token) => {
  return (dispatch) => {
    dispatch(getAffiliationsStart());
    axios
      .post(`/site/affiliations/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getAffiliationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAffiliationsFail(err.message));
        notifications.errorNotification("Affiliation");
      });
    notifications.successCreationNotification("Affiliation");
  };
};

export const createPartnersIntros = (object, token) => {
  return (dispatch) => {
    dispatch(getPartnersIntroStart());
    axios
      .post(`/site/partners-intros/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getPartnersIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPartnersIntroFail(err.message));
        notifications.errorNotification("Partner Intro");
      });
    notifications.successCreationNotification("Partner Intro");
  };
};

export const createPartners = (object, token) => {
  return (dispatch) => {
    dispatch(getPartnersStart());
    axios
      .post(`/site/partners/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getPartnersSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPartnersFail(err.message));
        notifications.errorNotification("Partner");
      });
    notifications.successCreationNotification("Partner");
  };
};
