import { FiFacebook, FiLinkedin, FiYoutube } from 'react-icons/fi';
import { RiTwitterLine } from 'react-icons/ri';
import { HiPhone } from 'react-icons/hi';
import { IoLogoInstagram, IoMdGlobe } from 'react-icons/io';
import contants from "../admin/contants"

export const TwitterLink = function (props) {
  return (
    <button className="py-2 font-semibold text-white inline-flex items-center space-x-2 rounded">
      <svg
        className="w-6 h-6 fill-current"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
      </svg>
      <span></span>
    </button>
  );
};

export const SocialMediaLink = function (props) {
  const socialMedia = props.name;
  let classes = 'social-media-button-circle flex items-center justify-center';
  if (props.circle === 'no-circle') {
    classes = 'flex items-center justify-center';
  }
  if (socialMedia === 'facebook') {
    return (
      <a target="_blank" rel="noreferrer" href={props.link} className={classes}>
        <FiFacebook className="social-media-links" />
      </a>
    );
  } else if (socialMedia === 'twitter') {
    return (
      <a target="_blank" rel="noreferrer" href={props.link} className={classes}>
        <RiTwitterLine className="social-media-links" />
      </a>
    );
  } else if (socialMedia === 'linkedin') {
    return (
      <a target="_blank" rel="noreferrer" href={props.link} className={classes}>
        <FiLinkedin className="social-media-links" />
      </a>
    );
  } else if (socialMedia === 'youtube') {
    return (
      <a target="_blank" rel="noreferrer" href={props.link} className={classes}>
        <FiYoutube className="social-media-links" />
      </a>
    );
  } else if (socialMedia === 'phone') {
    return (
      <a target="_blank" rel="noreferrer" href={props.link} className={classes}>
        <HiPhone className="social-media-links" />
      </a>
    );
  } else if (socialMedia === 'web') {
    return (
      <button className={classes}>
        <IoMdGlobe className="social-media-links" />
      </button>
    );
  } else if (socialMedia === 'instagram') {
    return (
      <button className={classes}>
        <IoLogoInstagram className="social-media-links" />
      </button>
    );
  }
};

export const FaceBookLink = function () {
  return (
    <button className="py-2 font-semibold text-white inline-flex items-center space-x-2 rounded">
      <svg
        className="w-6 h-6 fill-current"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
      </svg>
      <a href="https://web.facebook.com/OGeniusPriority/"></a>
    </button>
  );
};

export const WebLink = function () {
  return (
    <button className="py-2 font-semibold text-white inline-flex items-center space-x-2 rounded">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </button>
  );
};

export const InstagramLink = function () {
  return (
    <button className="py-2 font-semibold text-white inline-flex items-center space-x-2 rounded">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fab"
        data-icon="instagram"
        className="svg-inline--fa fa-instagram fa-w-14 h-6 w-6"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <path
          fill="currentColor"
          d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
        ></path>
      </svg>
    </button>
  );
};
