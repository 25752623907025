import AboutText from "./aboutText";
import HomePageTitle from "../components/HomePageTitle";
import "./aboutcss.css";
import { Button } from "../components/Buttons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import dotenv from "dotenv";
dotenv.config();

const HomeAbout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const homeAbout = useSelector((state) => state.home.homeAbout);
  const homeAboutLoading = useSelector((state) => state.home.homeAboutLoading);
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getHomeAbout(language));
  }, [dispatch, language]);
  const homeAboutMock = {
    title: "O'Genius?",
    subtitle: "Enim sunt et Lorem incididunt excepteur",
    description:
      "Aliquip proident voluptate laborum aliquip et aliquip commodo  jjjincididunt aliquip culpa irure aliquip proident. Proident veniamea dolore quis eu irure enim dolore tempor reprjdjjdjdjddjdjdehenderit. Nisi exercitation officia sint culpa enim nulla jsjsjsjsjsjselit. Mollit voluptate Culpa in aliquip consequat consectetur okskskskskskscca ecat eu. Cillum duis occaecat consectetur dolore anim ea snsnsnsnsns in pariatur. Amet est doloripsum enim est anim mollit. Exerc x x xmxmxmx itation voluptate est et laboris officia laborum adipisicing nostrud amet. Amet velit ex labore ipsum consequat magna voluptate nisi tempor enim eiusmod. Et ut aliqua commodo adipisicing labore mollit et minim.Aliquip cillum velit id officia ex do voluptate officia elit nulla. Id veniam cillum officia voluptate culpa officia. Sint esse officia laborum adipisicing nostrud amet. Amet velit ex labore est labore nisi quis laboris. Deserunt ipsum irure tempor non in non deserunt pariatur nostrud amet nulla anim cupidatat. Quis ad laborum ipsum ad id. Reprehenderit nisi enim fugiat cupidatat excepteur nulla qui occaecat aliquip.",
  };
  return (
    <div
      data-aos="slide-up"
      data-aos-duration="1500"
      className="h-full w-full  site-text-blue flex flex-wrap flex-col justify-center items-center"
    >
      <div className="pt-10 hidden md:block">
        <HomePageTitle title={t("About")} />
      </div>
      {homeAboutLoading ? (
        <div className="h-full w-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex-col h-full md:h-75vh w-full flex md:flex-row md:py-14">
          <div className="h-60vh md:h-full w-full md:w-1/2 relative">
            <img
              className="h-full w-full object-center object-cover absolute"
              alt="home about"
              src={`${process.env.REACT_APP_BACKEND_URL}/site/${homeAbout?.image}`}
            />
          </div>
          <div className="pt-10 md:hidden">
            <HomePageTitle title={t("About")} />
          </div>
          <div className="flex flex-col md:w-1/2 md:justify-between">
            <div className="flex-col site-default-height pl-6 pr-3 md:text-xl bottom-5">
              <AboutText
                title={homeAbout?.title}
                smallTitle={homeAbout?.subtitle}
                description={homeAbout?.description}
              />
            </div>
            <Link to="/about" className="m-auto md:ml-0 py-4 md:pb-0 md:pl-6">
              <Button name={t("Read More")} />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
export default HomeAbout;
