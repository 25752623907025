import { Button, SelectButton, TableLink } from "../components/Buttons";
import {
  ActivatingButton,
  ArchivingButton,
  InputText,
  InputTextArea,
  LinkComponent,
  MoreDropDown,
  PreviewButton,
  Uploading,
} from "./AdminComponentsList";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import * as adminActionTypes from "../store/actions/adminIndex";
import Spinner from "../components/Spinner";
import AsyncSelect from "react-select/async";
import parse from "html-react-parser";
import moment from "moment";
import contants, {
  successUpdateNotification,
  successDeleteNotification,
  confirmNotification,
} from "./contants";
import {ReactNotifications} from "react-notifications-component";
import { useParams } from "react-router";
import Widget from "../components/Widgets/Widget";
import { GenericDisplayMedia } from "./AdminGallerieComponentsList";

import dotenv from "dotenv";
dotenv.config();
const ReactNotification=ReactNotifications
export const ServicesPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminService.service);
  const objectLoading = useSelector(
    (state) => state.adminService.adminServicesLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminServices(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminServices(object.uuid));
        successUpdateNotification(" Service", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminServices(object.uuid));
        successUpdateNotification(" Service", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminServices(object.uuid));
        successUpdateNotification(" Service", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminServices(object.uuid));
        successUpdateNotification(" Service", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminServices(object.uuid));
      successDeleteNotification(" Service");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">
                  SERVICE PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Name :</span>
                    <span className="site-text-blue px-3">
                      {object?.name}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Link :</span>
                    <span className="site-text-blue px-3">
                      {object?.link}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between h-80 ">
                <GenericDisplayMedia media={object?.icon} />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/services"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/services/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const ClientTestimonialPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminService.testimonial);
  const objectLoading = useSelector(
    (state) => state.adminService.testimonialsLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneClientTestimonials(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateTestimonials(object.uuid));
        successUpdateNotification(" Client Testimonials", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateTestimonials(object.uuid));
        successUpdateNotification(" Client Testimonials", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveTestimonials(object.uuid));
        successUpdateNotification(" Client Testimonials", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleTestimonials(object.uuid));
        successUpdateNotification(" Client Testimonials", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteTestimonials(object.uuid));
      successDeleteNotification(" Client Testimonials");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold pb-5">
                <span className="admin-page-title text-xl">
                  CLIENT TESTIMONIAL PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Name :</span>
                    <span className="site-text-blue px-3">
                      {object?.name}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Testimony:
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.testimony != undefined
                        ? parse(eval(object?.testimony))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/client-testimonials"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/client-testimonials/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const ProductsPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminProduct.product);
  const objectLoading = useSelector(
    (state) => state.adminProduct.adminProductsLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminProducts(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminProducts(object.uuid));
        successUpdateNotification(" Product", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminProducts(object.uuid));
        successUpdateNotification(" Product", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminProducts(object.uuid));
        successUpdateNotification(" Product", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminProducts(object.uuid));
        successUpdateNotification(" Product", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminProducts(object.uuid));
      successDeleteNotification(" Product");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">
                  PRODUCT PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Name :</span>
                    <span className="site-text-blue px-3">
                      {object?.name}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Link :</span>
                    <span className="site-text-blue px-3">
                      {object?.link}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between h-80 ">
                <GenericDisplayMedia media={object?.icon} />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/products"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/products/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const ServiceWidgetPreview = (props) => {
  const dispatch = useDispatch();
  const [video, setVideo] = useState(null);
  const params = useParams();
  const object = useSelector((state) => state.adminService.serviceWidget);
  const objectLoading = useSelector(
    (state) => state.adminService.adminServiceWidgetsLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneServicesWidgets(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateServiceWidget(object.uuid));
        successUpdateNotification(" Service Widget", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateServiceWidget(object.uuid));
        successUpdateNotification(" Service Widget", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveServiceWidget(object.uuid));
        successUpdateNotification(" Service Widget", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleServiceWidget(object.uuid));
        successUpdateNotification(" Service Widget", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteServiceWidget(object.uuid));
      successDeleteNotification(" Service Widget");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">
                  SERVICE WIDGET PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Subtitle :</span>
                    <span className="site-text-blue px-3">
                      {object?.subtitle}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Small Subtitle :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.smallsubtitle}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      External Link :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.externallink}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Hash Link :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.hashlink}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Widget Type :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.widgettype}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Subwidget :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.isSubwidget}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Body: </span>
                    <span className="site-text-blue px-3">
                      {object?.body != undefined
                        ? parse(eval(object?.body))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Description:
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.description != undefined
                        ? parse(eval(object?.description))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex justify-between h-80 space-x-4">
                  <div
                    className={
                      object?.image === contants.imageV
                        ? "hidden"
                        : "block w-full"
                    }
                  >
                    <GenericDisplayMedia media={object?.image} widget />
                  </div>
                  <div
                    className={
                      object?.video === contants.videoV
                        ? "hidden"
                        : "block w-full"
                    }
                  >
                    <GenericDisplayMedia media={object?.video} widget />
                  </div>
                </div>
                <div className="flex justify-between h-80 space-x-4">
                  <div
                    className={
                      object?.pcanimation === contants.pcAnimationV
                        ? "hidden"
                        : "block w-1/2"
                    }
                  >
                    <GenericDisplayMedia media={object?.pcanimation} widget />
                  </div>
                  <div
                    className={
                      object?.mobileanimation === contants.mobAnimationV
                        ? "hidden"
                        : "block w-1/2"
                    }
                  >
                    <GenericDisplayMedia
                      media={object?.mobileanimation}
                      widget
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/service-widget"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/service-widget/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const ProductWidgetPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [video, setVideo] = useState(null);
  const object = useSelector((state) => state.adminProduct.productWidget);
  const objectLoading = useSelector(
    (state) => state.adminProduct.adminProductWidgetsLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneProductsWidgets(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateProductWidget(object.uuid));
        successUpdateNotification(" Product Widget", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateProductWidget(object.uuid));
        successUpdateNotification(" Product Widget", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveProductWidget(object.uuid));
        successUpdateNotification(" Product Widget", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleProductWidget(object.uuid));
        successUpdateNotification(" Product Widget", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteProductWidget(object.uuid));
      successDeleteNotification(" Product Widget");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">
                  PRODUCT WIDGET PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Subtitle :</span>
                    <span className="site-text-blue px-3">
                      {object?.subtitle}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Small Subtitle :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.smallsubtitle}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      External Link :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.externallink}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Hash Link :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.hashlink}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Widget Type :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.widgettype}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Subwidget :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.isSubwidget}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Body:</span>
                    <span className="site-text-blue px-3">
                      {object?.body != undefined
                        ? parse(eval(object?.body))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Description:
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.description != undefined
                        ? parse(eval(object?.description))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Package Title :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.packagetitle}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Package Projects :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.packageprojects}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Package Users :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.packageusers}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Package Price :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.packageprice}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Package Members :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.packagemember}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Package Storage :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.packagestorage}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      App Store :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.appstore}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Play Store :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.playstore}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between h-80 space-x-4">
                <div
                  className={
                    object?.image === contants.imageV ? "hidden" : "block w-1/2"
                  }
                >
                  <GenericDisplayMedia media={object?.image} widget />
                </div>
                <div
                  className={
                    object?.video === contants.videoV ? "hidden" : "block w-1/2"
                  }
                >
                  <GenericDisplayMedia media={object?.video} widget />
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/product-widget"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/product-widget/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const WidgetPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const service = useSelector((state) => state.adminService.serviceWidget);
  const product = useSelector((state) => state.adminProduct.productWidget);
  const object = service === null ? product : service;
  const objectLoading = useSelector(
    (state) => state.adminService.adminServiceWidgetsLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneServicesWidgets(params.objectId));
    dispatch(adminActionTypes.getOneProductsWidgets(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminServices(object.uuid));
        successUpdateNotification(" Service", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminServices(object.uuid));
        successUpdateNotification(" Service", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminServices(object.uuid));
        successUpdateNotification(" Service", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminServices(object.uuid));
        successUpdateNotification(" Service", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminServices(object.uuid));
      successDeleteNotification(" Service");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        <form className="flex justify-between pt-6 px-12 w-full space-x-5">
          <div className="flex flex-col pt-5 pb-5 w-full">
            <div className="font-bold">
              <span className="admin-page-title text-xl pb-5">
                WIDGET PREVIEW
              </span>
            </div>

            <div className="flex w-full">
              <Widget widget={object} />
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <TableLink
              title="Update"
              type="updateLink"
              link={
                service === null
                  ? "/admin/product-widget"
                  : "/admin/service-widget"
              }
              objectId={object != null ? object.uuid : ""}
            />
            <Button name="DELETE" type="delete" onClick={onDelete} />
            <TableLink
              title="List"
              type="viewLink"
              link={
                service === null
                  ? "/admin/product-widget/objects/list"
                  : "/admin/service-widget/objects/list"
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};
