import './partnerscss.css';
import parse from "html-react-parser";
import dotenv from "dotenv";
dotenv.config();

const PartnerCard = (props) => {
  return (
    <div className="text-center justify-center py-5">
      <img
        className="object-cover object-center h-72 w-72 p-12"
        src={props.logo}
        alt={props.name}
      ></img>
      <div className="partners-name site-text-blue">{props.name}</div>
    </div>
  );
};
export default PartnerCard;
