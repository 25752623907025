import React from "react";
import WidgetA from "./WidgetA";
import WidgetB from "./WidgetB";
import WidgetC from "./WidgetC";
import WidgetD from "./WidgetD";
import WidgetE from "./WidgetE";
import WidgetF from "./WidgetF";
import WidgetG from "./WidgetG";
import WidgetH from "./WidgetH";
import WidgetI from "./WidgetI";
import WidgetJ from "./WidgetJ";
import parse from "html-react-parser";

const Widget = (props) => {
  let widget = null;
  switch (props.widget?.widgettype) {
    case "A":
      widget = <WidgetA {...props} />;
      break;
    case "B":
      widget = <WidgetB {...props} />;
      break;
    case "C":
      widget = <WidgetC {...props} />;
      break;
    case "D":
      widget = <WidgetD {...props} />;
      break;
    case "E":
      widget = <WidgetE {...props} />;
      break;
    case "F":
      widget = <WidgetF {...props} />;
      break;
    case "G":
      widget = <WidgetG {...props} />;
      break;
    case "H":
      widget = <WidgetH {...props} />;
      break;
    case "I":
      widget = <WidgetI {...props} />;
      break;
    case "J":
      widget = <WidgetJ {...props} />;
      break;
    default:
      break;
  }
  return widget;
};

export default Widget;
