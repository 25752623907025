import Footer from '../footerComponents/Footer';
import Header from '../headerCompoents/Header';
import { ProductsPageHeader } from '../productsComponenents/ProductsPageComponents';
import ScrollToTop from '../components/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from '../store/actions/';
import Spinner from '../components/Spinner';
import { useEffect } from 'react';
import Widget from '../components/Widgets/Widget';

export const Products = (props) => {
  const dispatch = useDispatch();
  const productWidgets = useSelector((state) => state.products.productWidgets);
  const productWidgetsLoading = useSelector(
    (state) => state.products.productWidgetsLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getProductWidgets(language));
  }, [dispatch, language]);
  return (
    <div>
      <Header pageLink="/products" />
      <ProductsPageHeader />
      {productWidgetsLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        productWidgets.map((service, index) => (
          <Widget key={index} widget={service} />
        ))
      )}
      <Footer />
      <ScrollToTop />
    </div>
  );
};
