import React, { useCallback, useState } from 'react';
import '../contactus/contactus.css';
import ScrollToTop from '../components/ScrollToTop';
import Footer from '../footerComponents/Footer';
import Header from '../headerCompoents/Header';
import { Button } from '../components/Buttons';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from '../store/actions';
import Spinner from '../components/Spinner';

const Login = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const isAuthenticated = useSelector((state) => state.auth.token !== null);
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);
  const handleLogin = useCallback(() => {
    dispatch(actionTypes.auth(email, password));
  }, [dispatch, email, password]);
  let redirect = null;
  if (isAuthenticated) {
    redirect = <Redirect to="/admin-home" />;
  }
  return (
    <div>
      {redirect}
      <Header pageLink="/login" />
      <div className="bg-bg-second-color py-10 w-full mt-28">
        <div className="w-2/5 rounded-xl p-10 bg-white shadow-2xl flex flex-col m-auto">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <input
                className="sender-contact p-3 text-xs outline-none m-2"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <input
                className="sender-contact p-3 text-xs outline-none m-2"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <Link
                className="mx-2 underline text-main-color"
                to="/auth/forgotten_password"
              >
                Forgotten Password?
              </Link>
              {error ? (
                <div className="font-bold text-red-color text-center py-2">
                  {error}
                </div>
              ) : null}
              <div className="m-auto m-2">
                <Button onClick={handleLogin} name={t('Login')} />
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Login;
