import { Button, SelectButton, TableLink } from "../components/Buttons";
import "./admin.css";
import {
  DeleteButton,
  EditButton,
  InputText,
  InputTextArea,
  LinkComponent,
  MoreDropDown,
  PreviewButton,
  StaffSocialLinksButton,
  Uploading,
} from "./AdminComponentsList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import moment from "moment";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import AsyncSelect from "react-select/async";
import { successNotification, confirmNotification } from "./contants";
import { ReactNotifications} from "react-notifications-component";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { MyDataTable } from "./MyDataTable";
import { IoLogoInstagram, IoMdGlobe } from "react-icons/io";
import * as adminActionTypes from "../store/actions/adminIndex";
const  ReactNotification=ReactNotifications


export const ExecutiveIntroTable = () => {
  const dispatch = useDispatch();
  const executiveIntro = useSelector(
    (state) => state.adminAbout.adminExecutifLeadership
  );
  const executiveIntroLoading = useSelector(
    (state) => state.adminAbout.adminExecutifLeadershipLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminExcutifLeadership());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {executiveIntroLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    EXECUTIVE INTRO
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Subtitle
                      </th>

                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {executiveIntro.map((intro, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.subtitle}
                        </td>

                        <td className="border border-blue-400 px-4 py-2">
                          {intro.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={intro.uuid}
                              link="/admin/about/executive-intro/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add Intro"
                  type="addLink"
                  link="/admin/about/executive-intro"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const CareersTable = () => {
  const dispatch = useDispatch();
  const careers = useSelector((state) => state.adminAbout.adminCareer);
  const careersLoading = useSelector(
    (state) => state.adminAbout.adminCareerLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminCareer());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {careersLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">CAREERS</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Call To Action
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Call To Action Name
                      </th>

                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {careers.map((career, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {career.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {career.calltoaction}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {career.calltoactionname}
                        </td>

                        <td className="border border-blue-400 px-4 py-2">
                          {career.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {career.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {career.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={career.uuid}
                              link="/admin/about/careers/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add Career"
                  type="addLink"
                  link="/admin/about/careers"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const StaffIntroTable = () => {
  const dispatch = useDispatch();
  const staffIntro = useSelector((state) => state.adminAbout.adminStaff);
  const staffIntroLoading = useSelector(
    (state) => state.adminAbout.adminStaffLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminStaff());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {staffIntroLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">STAFF INTRO</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>

                      <th className="border border-blue-400 px-4 py-2">Link</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {staffIntro.map((intro, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.title}
                        </td>

                        <td className="border border-blue-400 px-4 py-2">
                          {intro.link}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={intro.uuid}
                              link="/admin/about/staff-intros/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add Intro"
                  type="addLink"
                  link="/admin/about/staff-intros"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const StaffMembersTable = () => {
  const dispatch = useDispatch();
  const staffMembers = useSelector(
    (state) => state.adminAbout.adminStaffMembers
  );
  const staffMembersLoading = useSelector(
    (state) => state.adminAbout.adminStaffMembersLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminStaffMembers());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {staffMembersLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    STAFF MEMBERS
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Name</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Email
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Position
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Executive
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {staffMembers.map((staff, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {staff.name}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {staff.email}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {staff.position}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {staff.isExecutive}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {staff.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={staff.uuid}
                              link="/admin/about/staff-members/edit"
                            />
                            <StaffSocialLinksButton
                              id={staff.uuid}
                              link="/admin/social-media-link"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add Staff Members"
                  type="addLink"
                  link="/admin/about/staff-members"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const AwardsSummaryTable = () => {
  const dispatch = useDispatch();
  const awardsIntro = useSelector((state) => state.adminAbout.adminAwardsIntro);
  const awardsIntroLoading = useSelector(
    (state) => state.adminAbout.adminAwardsIntroLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminAwardsIntro());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {awardsIntroLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    AWARDS SUMMARY
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>

                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {awardsIntro.map((intro, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.title}
                        </td>

                        <td className="border border-blue-400 px-4 py-2">
                          {intro.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={intro.uuid}
                              link="/admin/about/awards-summary/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add Intro"
                  type="addLink"
                  link="/admin/about/awards-summary"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const AwardsTable = () => {
  const dispatch = useDispatch();
  const awards = useSelector((state) => state.adminAbout.adminAwards);
  const awardsLoading = useSelector(
    (state) => state.adminAbout.adminAwardsLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminAwards());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {awardsLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">AWARDS</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Subtitle
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        IsMain
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {awards.map((award, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {award.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {award.subtitle}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {award.ismain}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {award.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {award.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {award.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={award.uuid}
                              link="/admin/about/awards/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Awards"
                  type="addLink"
                  link="/admin/about/awards"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const EthicsComplianceTable = () => {
  const dispatch = useDispatch();
  const ethics = useSelector(
    (state) => state.adminAbout.adminEthicsAndCompliance
  );
  const ethicsLoading = useSelector(
    (state) => state.adminAbout.adminEthicsAndComplianceLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminEthicsAndCompliance());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {ethicsLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    ETHICS AND COMPLIANCE
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>

                      <th className="border border-blue-400 px-4 py-2">Type</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ethics.map((ethic, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {ethic.title}
                        </td>

                        <td className="border border-blue-400 px-4 py-2">
                          {ethic.type}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {ethic.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {ethic.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {ethic.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={ethic.uuid}
                              link="/admin/about/ethics-and-compliance/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add Ethics/ Compliance"
                  type="addLink"
                  link="/admin/about/ethics-and-compliance"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const GetInTouchTable = () => {
  const dispatch = useDispatch();
  const getInTouch = useSelector((state) => state.adminAbout.adminGetInTouch);
  const getInTouchLoading = useSelector(
    (state) => state.adminAbout.adminGetInTouchLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminGetInTouch());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {getInTouchLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">GET IN TOUCH</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>

                      <th className="border border-blue-400 px-4 py-2">
                        Call to Action Name
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {getInTouch.map((inTouch, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {inTouch.title}
                        </td>

                        <td className="border border-blue-400 px-4 py-2">
                          {inTouch.calltoactionname}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {inTouch.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {inTouch.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {inTouch.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={inTouch.uuid}
                              link="/admin/about/get-in-touch/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add Get In Touch"
                  type="addLink"
                  link="/admin/about/get-in-touch"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
