import { Button, SelectButton, TableLink } from "../components/Buttons";
import "./admin.css";
import {
  ActivateButton,
  ArchiveButton,
  DeleteButton,
  EditButton,
  InputText,
  InputTextArea,
  LinkComponent,
  MoreDropDown,
  ObjectItemsButton,
  PreviewButton,
  Uploading,
  WidgetPreviewButton,
} from "./AdminComponentsList";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import { useParams } from "react-router";
import AsyncSelect from "react-select/async";
import contants from "./contants";
import {ReactNotifications} from "react-notifications-component";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { MyDataTable } from "./MyDataTable";
import * as adminActionTypes from "../store/actions/adminIndex";
const ReactNotification=ReactNotifications


export const CarouselTable = () => {
  const dispatch = useDispatch();
  const carousels = useSelector((state) => state.adminHome.adminHomeCarousel);
  const carouselLoading = useSelector(
    (state) => state.adminHome.adminHomeCarouselLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.adminHomeCarousels());
  }, [dispatch]);

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {carouselLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">CAROUSELS</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Header
                      </th>

                      <th className="border border-blue-400 px-4 py-2">
                        Laguage
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {carousels.map((carousel, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {carousel.header}
                        </td>

                        <td className="border border-blue-400 px-4 py-2">
                          {carousel.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {carousel.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(carousel.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {carousel.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={carousel.uuid}
                              link="/admin/home-carousel/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Carousel"
                  type="addLink"
                  link="/admin/home-carousel"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const HomeAboutTable = () => {
  const dispatch = useDispatch();
  const homeAbouts = useSelector((state) => state.adminHome.adminHomeAbout);
  const homeAboutsLoading = useSelector(
    (state) => state.adminHome.adminHomeAboutLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminAbout());
  }, [dispatch]);

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {homeAboutsLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">Home Abouts</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Subtitle
                      </th>

                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {homeAbouts.map((about, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {about.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 ">
                          {about.subtitle}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {about.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {about.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(about.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {about.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={about.uuid}
                              link="/admin/home-about/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Home About"
                  type="addLink"
                  link="/admin/home-about"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const HomeProductTable = () => {
  const dispatch = useDispatch();
  const homeProducts = useSelector((state) => state.adminHome.adminHomeProduct);
  const homeProductsLoading = useSelector(
    (state) => state.adminHome.adminHomeProductLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminProduct());
  }, [dispatch]);

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {homeProductsLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    Home Products
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>

                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {homeProducts.map((product, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {product.title}
                        </td>

                        <td className="border border-blue-400 px-4 py-2 ">
                          {product.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {product.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(product.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {product.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={product.uuid}
                              link="/admin/home-product/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Home Product"
                  type="addLink"
                  link="/admin/home-product"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const TodaysQuoteTable = () => {
  const dispatch = useDispatch();
  const quotes = useSelector((state) => state.adminHome.adminHomeTodaysQuote);
  const quotesLoading = useSelector(
    (state) => state.adminHome.adminHomeTodaysQuoteLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminTodaysQuote());
  }, [dispatch]);

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {quotesLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    Todays' Quote
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Owner
                      </th>

                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {quotes.map((quote, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {quote.owner}
                        </td>

                        <td className="border border-blue-400 px-4 py-2 ">
                          {quote.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {quote.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(quote.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {quote.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={quote.uuid}
                              link="/admin/home-quote/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Quote"
                  type="addLink"
                  link="/admin/home-quote"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const FooterSectionsTable = () => {
  const dispatch = useDispatch();
  const footerSections = useSelector(
    (state) => state.adminHome.adminHomeFooterSections
  );
  const footerSectionsLoading = useSelector(
    (state) => state.adminHome.adminHomeFooterSectionsLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminFooterSections());
  }, [dispatch]);

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {footerSectionsLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    Footer Sections
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Name</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {footerSections?.map((footerSection, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {footerSection.name}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 ">
                          {footerSection.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {footerSection.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(footerSection.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {footerSection.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={footerSection.uuid}
                              link="/admin/footer/footer-section/edit"
                            />
                            <ObjectItemsButton
                              id={footerSection.uuid}
                              link="/admin/footer/footer-section-item/objects/list"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Section"
                  type="addLink"
                  link="/admin/footer/footer-section"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const FooterSectionItemsTable = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminHome.homeFooterSection);
  const footerSectionItems = useSelector(
    (state) => state.adminHome.adminHomeFooterSectionItems
  );
  const fsItems = object != null ? object?.sectionItems : footerSectionItems;
  const fsItemsLoading = useSelector(
    (state) => state.adminHome.adminHomeFooterSectionItemsLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminFooterSectionItems());
    dispatch(adminActionTypes.getOneAdminHomeFooterSections(params.objectId));
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {fsItemsLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    {object != null
                      ? " Footer Section Items (Footer Section: " +
                        object?.name +
                        ")"
                      : "Footer Section Items (All)"}
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Name</th>
                      <th className="border border-blue-400 px-4 py-2">Link</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fsItems?.map((item, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {item.name}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {item.link}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 ">
                          {item.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {item.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(item.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {item.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={item.uuid}
                              link="/admin/footer/footer-section-item/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Item"
                  type="addLink"
                  link="/admin/footer/footer-section-item"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const FooterAddressesTable = () => {
  const dispatch = useDispatch();
  const footerAddress = useSelector(
    (state) => state.adminHome.adminHomeFooterAddress
  );
  const footerAddressLoading = useSelector(
    (state) => state.adminHome.adminHomeFooterAddressLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminFooterAddress());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {footerAddressLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    Footer Addresses
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Copyright
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Site Map Name
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Site Map Link
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Contact
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {footerAddress?.map((address, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {address.copyright}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {address.sitemapname}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {address.sitemaplink}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {address.contact}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 ">
                          {address.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {address.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {address.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={address.uuid}
                              link="/admin/footer/footer-address/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Address"
                  type="addLink"
                  link="/admin/footer/footer-address"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const UsersTable = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.about.users);
  const usersLoading = useSelector((state) => state.about.usersLoading);
  const userId = useSelector((state) => state.auth.userId);
  useEffect(() => {
    dispatch(actionTypes.getUsers());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {usersLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">Users</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Role</th>
                      <th className="border border-blue-400 px-4 py-2">Name</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Email
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Position
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, i) => (
                      <tr
                        key={i}
                        className={
                          userId === user.uuid
                            ? "hidden"
                            : "text-lg font-semibold"
                        }
                      >
                        <td className="border border-blue-400 px-4 py-2">
                          {user.role}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {user.staff.name}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {user.staff.email}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {user.staff.position}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={user.uuid}
                              link="/admin/users/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New User"
                  type="addLink"
                  link="/admin/users"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const HomePartnersTable = () => {
  const dispatch = useDispatch();
  const homePartners = useSelector(
    (state) => state.adminHome.adminHomePartners
  );
  const homePartnerLoading = useSelector(
    (state) => state.adminHome.adminHomePartnersLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminHomePartners());
  }, [dispatch]);

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {homePartnerLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    HOME PARTNERS
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Name</th>

                      <th className="border border-blue-400 px-4 py-2">Link</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Laguage
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {homePartners.map((partner, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {partner.partner.name}
                        </td>

                        <td className="border border-blue-400 px-4 py-2">
                          {partner.partner.link}
                        </td>

                        <td className="border border-blue-400 px-4 py-2">
                          {partner.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {partner.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {partner.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={partner.partnerId}
                              link="/admin/partners/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Partner"
                  type="addLink"
                  link="/admin/home-partner"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const HomeServiceIntrosTable = () => {
  const dispatch = useDispatch();
  const homeServices = useSelector(
    (state) => state.adminHome.adminHomeServicesIntro
  );
  const homeServiceLoading = useSelector(
    (state) => state.adminHome.adminHomeServicesIntroLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminServicesIntro());
  }, [dispatch]);

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {homeServiceLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    HOME SERVICES INTRO
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Laguage
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {homeServices.map((intro, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={intro.uuid}
                              link="/admin/home-service-intro/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Service Intro"
                  type="addLink"
                  link="/admin/home-service-intro"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const HomeServicesTable = () => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.adminHome.adminHomeServices);
  const servicesLoading = useSelector(
    (state) => state.adminHome.adminHomeServicesLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminServices());
  }, [dispatch]);
  const [submitted, setsubmitted] = useState(false);
  console.log("services");
  if (services) {
    console.log(services);
  }
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {servicesLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    HOME SERVICES
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Is Subwidget
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Laguage
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {services?.map((service, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {service.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 text-center">
                          {service.isSubwidget === contants.isTrue
                            ? contants.isTrue
                            : contants.isFalse}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {service.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {service.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(service.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {service.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={service.uuid}
                              link="/admin/service-widget/edit"
                            />
                            {service.isSubwidget === contants.isTrue ? (
                              ""
                            ) : (
                              <WidgetPreviewButton
                                id={service.uuid}
                                type={service.isSubwidget}
                                link="/admin/widget/preview"
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Service"
                  type="addLink"
                  link="/admin/home-service"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
