import '../servicesComponent/servicescss.css';
import './productscss.css';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import * as actionTypes from '../store/actions/';
import Spinner from '../components/Spinner';
import parse from "html-react-parser";

import dotenv from "dotenv";
dotenv.config();

export const ProductsPageHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products);
  const productsLoading = useSelector(
    (state) => state.products.productsLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getProducts(language));
  }, [dispatch, language]);
  return (
    <div className="service-page-header flex flex-col mt-28">
      <div className="service-page-title pb-10 pt-8">{t('Products')}</div>
      <div className="w-full flex lg:justify-center overflow-x-scroll scrollbar-hidden">
        {productsLoading ? (
          <div className="w-full h-full flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          products.map((product, index) => (
            <div key={index} className="px-8">
              <ProductIcon name={product.name} href={product.link}>
                <img
                  className="w-6 h-6"
                  src={`${process.env.REACT_APP_BACKEND_URL}/site/${product.icon}`}
                  alt="alt"
                />
              </ProductIcon>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export const ProductIcon = (props) => {
  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };
  return (
    <HashLink
      to={props.href}
      smooth
      scroll={scrollWidthOffset}
      className="flex flex-col justify-center items-center service-icon"
    >
      <div className="service-icon-cirlce flex justify-center items-center">
        {props.children}
      </div>
      <div className="service-icon-name pt-8">{props.name}</div>
    </HashLink>
  );
};

export const ProductImage = (props) => {
  return (
    <div className="w-full ">
      <img
        className="w-full h-full object-fill"
        src={props.image}
        alt={props.name}
      />
    </div>
  );
};

export const EmailCard = (props) => {
  const { t } = useTranslation();
  return (
    <div className="mx-4 my-6 w-64 flex-shrink-0 pt-14 pb-10 min-h-mail-card h-70vh xl:h-60vh rounded-3xl shadow-custom border border-white border-solid bg-gradient-to-b from-panda-mail-card-color-first to-panda-mail-card-color-second">
      <div className="email-type">
        <span>{props.type}</span>
      </div>
      <div className="m-auto py-10">
        <span className="email-currency">$</span>
        <span className="email-cost">{props.amount}</span>
        <span className="email-period">/{t('month')}</span>
      </div>
      <div className="flex flex-col justify-center email-main-info">
        <div>
          <div className="h-px bg-gradient-to-r from-panda-mail-card-color-second to-panda-mail-card-color-first"></div>
          <div className="flex justify-between py-3 px-6">
            <span>{t('Members')}</span>
            <span>{props.members}</span>
          </div>
        </div>
        <div>
          <div className="h-px bg-gradient-to-r from-panda-mail-card-color-second to-panda-mail-card-color-first"></div>
          <div className="flex justify-between py-3 px-6">
            <span>{t('Projects')}</span>
            <span>{props.projects}</span>
          </div>
        </div>
        <div>
          <div className="h-px bg-gradient-to-r from-panda-mail-card-color-second to-panda-mail-card-color-first"></div>
          <div className="flex justify-between py-3 px-6">
            <span>{t('Users')}</span>
            <span>{props.users}</span>
          </div>
        </div>
        <div>
          <div className="h-px bg-gradient-to-r from-panda-mail-card-color-second to-panda-mail-card-color-first"></div>
          <div className="flex justify-between py-3 px-6">
            <span>{t('Storage')}</span>
            <span>{props.storage}</span>
          </div>
        </div>
        <div>
          <div className="h-px bg-gradient-to-r from-panda-mail-card-color-second to-panda-mail-card-color-first"></div>
          <div className="flex justify-between py-3 px-6">
            <span>{t('More')}</span>
            <span>{props.more}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
