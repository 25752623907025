import Footer from "../footerComponents/Footer";
import Header from "../headerCompoents/Header";
import { ServicePageHeader } from "../servicesComponent/servicePageComponents";
import {
  InformationTechnology,
  WebDevelopment,
  ELearning,
  Networking,
  Simulation,
  ComputerGames,
  Training,
  InteractiveContent,
} from "../servicesComponent/ServicesListComponents";
import ScrollToTop from '../components/ScrollToTop';

export const TestServices = (props) => {
  return (
    <div>
      <Header pageLink="/services" />
      <ServicePageHeader />
      <InformationTechnology />
      <WebDevelopment />
      <ELearning />
      <Networking />
      <Simulation />
      <ComputerGames />
      <Training />
      <InteractiveContent />
      <Footer />
      <ScrollToTop />
    </div>
  );
};
