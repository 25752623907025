import { useCallback, useState } from 'react';
import '../contactus/contactus.css';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/Buttons';
import ScrollToTop from '../components/ScrollToTop';
import Spinner from '../components/Spinner';
import Footer from '../footerComponents/Footer';
import Header from '../headerCompoents/Header';
import axios from '../axios-base';
import { Redirect, useParams } from 'react-router';

const PasswordRecovery = () => {
  const { token } = useParams();
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const handleSubmit = useCallback(() => {
    setError(null);
    setLoading(true);
    axios
      .post(
        '/site/recover/password',
        { password: password, confirm: confirm },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        setLoading(false);
        setResponse(response.data.results);
        setRedirect(<Redirect to="/auth/login" />);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);
      });
  }, [password, confirm, token]);
  return (
    <div>
      {redirect}
      <Header />
      <div className="bg-bg-second-color py-10 w-full mt-28">
        <div className="w-2/5 rounded-xl p-10 bg-white shadow-2xl flex flex-col m-auto">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <input
                className="sender-contact p-3 text-xs outline-none m-2"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <input
                className="sender-contact p-3 text-xs outline-none m-2"
                type="password"
                placeholder="Confirm password"
                value={confirm}
                onChange={(event) => setConfirm(event.target.value)}
              />
              {error || response ? (
                <div className="font-medium py-2">
                  {error || response.message}
                </div>
              ) : null}
              <div className="m-auto m-2">
                <Button onClick={handleSubmit} name={t('Send')} />
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default PasswordRecovery;
