import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";
import * as notifications from "../../admin/contants";

export const getLanguagesStart = () => {
  return {
    type: actionTypes.GET_LANGUAGES_START,
  };
};

export const getLanguagesSuccess = (languages) => {
  return {
    type: actionTypes.GET_LANGUAGES_SUCCESS,
    languages: languages,
  };
};

export const getOneLanguagesSuccess = (language) => {
  return {
    type: actionTypes.GET_ONE_LANGUAGES_SUCCESS,
    language: language,
  };
};

export const getLanguagesFail = (error) => {
  return {
    type: actionTypes.GET_LANGUAGES_FAIL,
    error: error,
  };
};

export const getLanguages = () => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .get(`/site/languages/active`)
      .then((response) => {
        dispatch(getLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err.message));
      });
  };
};

export const getAllLanguages = () => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .get(`/site/languages`)
      .then((response) => {
        dispatch(getLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err.message));
      });
  };
};

export const getOneLanguages = (objectId) => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .get(`/site/languages/language/${objectId}`)
      .then((response) => {
        dispatch(getOneLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err.message));
      });
  };
};

export const deleteLanguages = (objectId) => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .delete(`/site/languages/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err.message));
      });
  };
};

export const activateLanguages = (objectId) => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .put(`/site/languages/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err.message));
      });
  };
};

export const desactivateLanguages = (objectId) => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .put(`/site/languages/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err.message));
      });
  };
};

export const updateLanguages = (object) => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .put(`/site/languages/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err.message));
      });
  };
};

export const archiveLanguages = (objectId) => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .put(`/site/languages/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err.message));
      });
  };
};

export const visibleLanguages = (objectId) => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .put(`/site/languages/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneLanguagesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err.message));
      });
  };
};

export const createLanguages = (object, token) => {
  return (dispatch) => {
    dispatch(getLanguagesStart());
    axios
      .post(`/site/languages/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getLanguagesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLanguagesFail(err.message));
        notifications.errorNotification("Language");
      });
    notifications.successCreationNotification("Language");
  };
};
