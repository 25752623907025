import axios from "../../axios-base";
import * as adminActionTypes from "./adminActionTypes.js";
import * as notifications from "../../admin/contants";

export const getAdminExcutifLeadershipStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_EXCUTIF_LEADERSHIP_START,
  };
};

export const getAdminExcutifLeadershipSuccess = (adminExecutifLeadership) => {
  return {
    type: adminActionTypes.GET_ADMIN_EXCUTIF_LEADERSHIP_SUCCESS,
    adminExecutifLeadership: adminExecutifLeadership,
  };
};

export const getOneAdminExcutifLeadershipSuccess = (executifLeadership) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_EXCUTIF_LEADERSHIP_SUCCESS,
    executifLeadership: executifLeadership,
  };
};

export const getAdminExcutifLeadershipFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_EXCUTIF_LEADERSHIP_FAIL,
    error: error,
  };
};

export const getAdminExcutifLeadership = (lang) => {
  return (dispatch) => {
    dispatch(getAdminExcutifLeadershipStart());
    axios
      .get(`/site/executive-leadership`)
      .then((response) => {
        dispatch(getAdminExcutifLeadershipSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminExcutifLeadershipFail(err.message));
      });
  };
};

export const getOneAdminExcutifLeadership = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminExcutifLeadershipStart());
    axios
      .get(`/site/executive-leadership/leader/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminExcutifLeadershipSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminExcutifLeadershipFail(err.message));
      });
  };
};

export const deleteAdminExcutifLeadership = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminExcutifLeadershipStart());
    axios
      .delete(`/site/executive-leadership/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminExcutifLeadershipSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminExcutifLeadershipFail(err.message));
      });
  };
};

export const activateAdminExcutifLeadership = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminExcutifLeadershipStart());
    axios
      .put(`/site/executive-leadership/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminExcutifLeadershipSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminExcutifLeadershipFail(err.message));
      });
  };
};

export const desactivateAdminExcutifLeadership = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminExcutifLeadershipStart());
    axios
      .put(`/site/executive-leadership/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminExcutifLeadershipSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminExcutifLeadershipFail(err.message));
      });
  };
};

export const updateAdminExcutifLeadership = (object) => {
  return (dispatch) => {
    dispatch(getAdminExcutifLeadershipStart());
    axios
      .put(`/site/executive-leadership/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminExcutifLeadershipSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminExcutifLeadershipFail(err.message));
      });
  };
};

export const archiveAdminExcutifLeadership = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminExcutifLeadershipStart());
    axios
      .put(`/site/executive-leadership/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminExcutifLeadershipSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminExcutifLeadershipFail(err.message));
      });
  };
};

export const visibleAdminExcutifLeadership = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminExcutifLeadershipStart());
    axios
      .put(`/site/executive-leadership/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminExcutifLeadershipSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminExcutifLeadershipFail(err.message));
      });
  };
};

export const getAdminCareerStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_CAREER_START,
  };
};

export const getAdminCareerSuccess = (adminCareer) => {
  return {
    type: adminActionTypes.GET_ADMIN_CAREER_SUCCESS,
    adminCareer: adminCareer,
  };
};

export const getOneAdminCareerSuccess = (career) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_CAREER_SUCCESS,
    career: career,
  };
};

export const getAdminCareerFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_CAREER_FAIL,
    error: error,
  };
};

export const getAdminCareer = (lang) => {
  return (dispatch) => {
    dispatch(getAdminCareerStart());
    axios
      .get(`/site/careers`)
      .then((response) => {
        dispatch(getAdminCareerSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminCareerFail(err.message));
      });
  };
};

export const getOneAdminCareer = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminCareerStart());
    axios
      .get(`/site/careers/career/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminCareerSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminCareerFail(err.message));
      });
  };
};

export const deleteAdminCareer = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminCareerStart());
    axios
      .delete(`/site/careers/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminCareerSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminCareerFail(err.message));
      });
  };
};

export const activateAdminCareer = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminCareerStart());
    axios
      .put(`/site/careers/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminCareerSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminCareerFail(err.message));
      });
  };
};

export const desactivateAdminCareer = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminCareerStart());
    axios
      .put(`/site/careers/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminCareerSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminCareerFail(err.message));
      });
  };
};

export const updateAdminCareer = (object) => {
  return (dispatch) => {
    dispatch(getAdminCareerStart());
    axios
      .put(`/site/careers/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminCareerSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminCareerFail(err.message));
      });
  };
};

export const archiveAdminCareer = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminCareerStart());
    axios
      .put(`/site/careers/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminCareerSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminCareerFail(err.message));
      });
  };
};

export const visibleAdminCareer = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminCareerStart());
    axios
      .put(`/site/careers/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminCareerSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminCareerFail(err.message));
      });
  };
};

export const getAdminExcutifLeadersStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_EXCUTIF_LEADERS_START,
  };
};

export const getAdminExcutifLeadersSuccess = (adminExecutifLeaders) => {
  return {
    type: adminActionTypes.GET_ADMIN_EXCUTIF_LEADERS_SUCCESS,
    adminExecutifLeaders: adminExecutifLeaders,
  };
};

export const getOneAdminExcutifLeadersSuccess = (executifLeader) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_EXCUTIF_LEADERS_SUCCESS,
    executifLeader: executifLeader,
  };
};

export const getAdminExcutifLeadersFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_EXCUTIF_LEADERS_FAIL,
    error: error,
  };
};

export const getAdminExcutifLeaders = () => {
  return (dispatch) => {
    dispatch(getAdminExcutifLeadersStart());
    axios
      .get(`/site/staff-members/executive`)
      .then((response) => {
        dispatch(getAdminExcutifLeadersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminExcutifLeadersFail(err.message));
      });
  };
};

export const getOneAdminStaffMembers = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminStaffMembersStart());
    axios
      .get(`/site/staff-members/staff-member/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminStaffMembersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffMembersFail(err.message));
      });
  };
};

export const deleteAdminStaffMembers = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminStaffMembersStart());
    axios
      .delete(`/site/staff-members/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminStaffMembersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffMembersFail(err.message));
      });
  };
};

export const activateAdminStaffMembers = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminStaffMembersStart());
    axios
      .put(`/site/staff-members/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminStaffMembersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffMembersFail(err.message));
      });
  };
};

export const desactivateAdminStaffMembers = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminStaffMembersStart());
    axios
      .put(`/site/staff-members/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminStaffMembersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffMembersFail(err.message));
      });
  };
};

export const updateAdminStaffMembers = (object) => {
  return (dispatch) => {
    dispatch(getAdminStaffMembersStart());
    axios
      .put(`/site/staff-members/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminStaffMembersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffMembersFail(err.message));
      });
  };
};

export const archiveAdminStaffMembers = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminStaffMembersStart());
    axios
      .put(`/site/staff-members/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminStaffMembersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffMembersFail(err.message));
      });
  };
};

export const visibleAdminStaffMembers = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminStaffMembersStart());
    axios
      .put(`/site/staff-members/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminStaffMembersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffMembersFail(err.message));
      });
  };
};

export const getAdminStaffStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_STAFF_START,
  };
};

export const getAdminStaffSuccess = (adminStaff) => {
  return {
    type: adminActionTypes.GET_ADMIN_STAFF_SUCCESS,
    adminStaff: adminStaff,
  };
};

export const getOneAdminStaffSuccess = (staff) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_STAFF_SUCCESS,
    staff: staff,
  };
};

export const getAdminStaffFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_STAFF_FAIL,
    error: error,
  };
};

export const getAdminStaff = (lang) => {
  return (dispatch) => {
    dispatch(getAdminStaffStart());
    axios
      .get(`/site/staffs`)
      .then((response) => {
        dispatch(getAdminStaffSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffFail(err.message));
      });
  };
};

export const getOneAdminStaff = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminStaffStart());
    axios
      .get(`/site/staffs/staff/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminStaffSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffFail(err.message));
      });
  };
};

export const deleteAdminStaff = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminStaffStart());
    axios
      .delete(`/site/staffs/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminStaffSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffFail(err.message));
      });
  };
};

export const activateAdminStaff = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminStaffStart());
    axios
      .put(`/site/staffs/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminStaffSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffFail(err.message));
      });
  };
};

export const desactivateAdminStaff = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminStaffStart());
    axios
      .put(`/site/staffs/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminStaffSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffFail(err.message));
      });
  };
};

export const updateAdminStaff = (object) => {
  return (dispatch) => {
    dispatch(getAdminStaffStart());
    axios
      .put(`/site/staffs/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminStaffSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffFail(err.message));
      });
  };
};

export const archiveAdminStaff = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminStaffStart());
    axios
      .put(`/site/staffs/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminStaffSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffFail(err.message));
      });
  };
};

export const visibleAdminStaff = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminStaffStart());
    axios
      .put(`/site/staffs/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminStaffSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffFail(err.message));
      });
  };
};

export const getAdminStaffMembersStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_STAFF_MEMBERS_START,
  };
};

export const getAdminStaffMembersSuccess = (adminStaffMembers) => {
  return {
    type: adminActionTypes.GET_ADMIN_STAFF_MEMBERS_SUCCESS,
    adminStaffMembers: adminStaffMembers,
  };
};

export const getOneAdminStaffMembersSuccess = (staffMember) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_STAFF_MEMBERS_SUCCESS,
    staffMember: staffMember,
  };
};

export const getAdminStaffMembersFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_STAFF_MEMBERS_FAIL,
    error: error,
  };
};

export const getAdminStaffMembers = () => {
  return (dispatch) => {
    dispatch(getAdminStaffMembersStart());
    axios
      .get(`/site/staff-members`)
      .then((response) => {
        dispatch(getAdminStaffMembersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffMembersFail(err.message));
      });
  };
};

export const getAdminAllStaffMembers = () => {
  return (dispatch) => {
    dispatch(getAdminStaffMembersStart());
    axios
      .get(`/site/staff-members`)
      .then((response) => {
        dispatch(getAdminStaffMembersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminStaffMembersFail(err.message));
      });
  };
};

export const getAdminAwardsIntroStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_AWARDS_INTRO_START,
  };
};

export const getAdminAwardsIntroSuccess = (adminAwardsIntro) => {
  return {
    type: adminActionTypes.GET_ADMIN_AWARDS_INTRO_SUCCESS,
    adminAwardsIntro: adminAwardsIntro,
  };
};

export const getOneAdminAwardsIntroSuccess = (awardsIntro) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_AWARDS_INTRO_SUCCESS,
    awardsIntro: awardsIntro,
  };
};

export const getAdminAwardsIntroFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_AWARDS_INTRO_FAIL,
    error: error,
  };
};

export const getAdminAwardsIntro = (lang) => {
  return (dispatch) => {
    dispatch(getAdminAwardsIntroStart());
    axios
      .get(`/site/awardsintro`)
      .then((response) => {
        dispatch(getAdminAwardsIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsIntroFail(err.message));
      });
  };
};

export const getOneAdminAwardsIntro = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAwardsIntroStart());
    axios
      .get(`/site/awardsintro/award-intro/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAwardsIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsIntroFail(err.message));
      });
  };
};

export const deleteAdminAwardsIntro = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAwardsIntroStart());
    axios
      .delete(`/site/awardsintro/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAwardsIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsIntroFail(err.message));
      });
  };
};

export const activateAdminAwardsIntro = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAwardsIntroStart());
    axios
      .put(`/site/awardsintro/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAwardsIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsIntroFail(err.message));
      });
  };
};

export const desactivateAdminAwardsIntro = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAwardsIntroStart());
    axios
      .put(`/site/awardsintro/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAwardsIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsIntroFail(err.message));
      });
  };
};

export const updateAdminAwardsIntro = (object) => {
  return (dispatch) => {
    dispatch(getAdminAwardsIntroStart());
    axios
      .put(`/site/awardsintro/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminAwardsIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsIntroFail(err.message));
      });
  };
};

export const archiveAdminAwardsIntro = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAwardsIntroStart());
    axios
      .put(`/site/awardsintro/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAwardsIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsIntroFail(err.message));
      });
  };
};

export const visibleAdminAwardsIntro = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAwardsIntroStart());
    axios
      .put(`/site/awardsintro/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAwardsIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsIntroFail(err.message));
      });
  };
};

export const getAdminAwardsStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_AWARDS_START,
  };
};

export const getAdminAwardsSuccess = (adminAwards) => {
  return {
    type: adminActionTypes.GET_ADMIN_AWARDS_SUCCESS,
    adminAwards: adminAwards,
  };
};

export const getOneAdminAwardsSuccess = (award) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_AWARDS_SUCCESS,
    award: award,
  };
};

export const getAdminAwardsFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_AWARDS_FAIL,
    error: error,
  };
};

export const getAdminAwards = (lang) => {
  return (dispatch) => {
    dispatch(getAdminAwardsStart());
    axios
      .get(`/site/awards`)
      .then((response) => {
        dispatch(getAdminAwardsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsFail(err.message));
      });
  };
};

export const getOneAdminAwards = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAwardsStart());
    axios
      .get(`/site/awards/award/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAwardsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsFail(err.message));
      });
  };
};

export const deleteAdminAwards = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAwardsStart());
    axios
      .delete(`/site/awards/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAwardsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsFail(err.message));
      });
  };
};

export const activateAdminAwards = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAwardsStart());
    axios
      .put(`/site/awards/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAwardsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsFail(err.message));
      });
  };
};

export const desactivateAdminAwards = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAwardsStart());
    axios
      .put(`/site/awards/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAwardsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsFail(err.message));
      });
  };
};

export const updateAdminAwards = (object) => {
  return (dispatch) => {
    dispatch(getAdminAwardsStart());
    axios
      .put(`/site/awards/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminAwardsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsFail(err.message));
      });
  };
};

export const archiveAdminAwards = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAwardsStart());
    axios
      .put(`/site/awards/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAwardsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsFail(err.message));
      });
  };
};

export const visibleAdminAwards = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAwardsStart());
    axios
      .put(`/site/awards/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAwardsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAwardsFail(err.message));
      });
  };
};

export const getAdminEthicsAndComplianceStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_ETHICS_COMPLIANCE_START,
  };
};

export const getAdminEthicsAndComplianceSuccess = (
  adminEthicsAndCompliance
) => {
  return {
    type: adminActionTypes.GET_ADMIN_ETHICS_COMPLIANCE_SUCCESS,
    adminEthicsAndCompliance: adminEthicsAndCompliance,
  };
};

export const getOneAdminEthicsAndComplianceSuccess = (ethicsAndCompliance) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_ETHICS_COMPLIANCE_SUCCESS,
    ethicsAndCompliance: ethicsAndCompliance,
  };
};

export const getAdminEthicsAndComplianceFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_ETHICS_COMPLIANCE_FAIL,
    error: error,
  };
};

export const getAdminEthicsAndCompliance = (lang) => {
  return (dispatch) => {
    dispatch(getAdminEthicsAndComplianceStart());
    axios
      .get(`/site/ethics-compliance`)
      .then((response) => {
        dispatch(getAdminEthicsAndComplianceSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEthicsAndComplianceFail(err.message));
      });
  };
};

export const getOneAdminEthicsAndCompliance = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminEthicsAndComplianceStart());
    axios
      .get(`/site/ethics-compliance/ethics/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminEthicsAndComplianceSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEthicsAndComplianceFail(err.message));
      });
  };
};

export const deleteAdminEthicsAndCompliance = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminEthicsAndComplianceStart());
    axios
      .delete(`/site/ethics-compliance/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminEthicsAndComplianceSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEthicsAndComplianceFail(err.message));
      });
  };
};

export const activateAdminEthicsAndCompliance = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminEthicsAndComplianceStart());
    axios
      .put(`/site/ethics-compliance/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminEthicsAndComplianceSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEthicsAndComplianceFail(err.message));
      });
  };
};

export const desactivateAdminEthicsAndCompliance = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminEthicsAndComplianceStart());
    axios
      .put(`/site/ethics-compliance/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminEthicsAndComplianceSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEthicsAndComplianceFail(err.message));
      });
  };
};

export const updateAdminEthicsAndCompliance = (object) => {
  return (dispatch) => {
    dispatch(getAdminEthicsAndComplianceStart());
    axios
      .put(`/site/ethics-compliance/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminEthicsAndComplianceSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEthicsAndComplianceFail(err.message));
      });
  };
};

export const archiveAdminEthicsAndCompliance = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminEthicsAndComplianceStart());
    axios
      .put(`/site/ethics-compliance/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminEthicsAndComplianceSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEthicsAndComplianceFail(err.message));
      });
  };
};

export const visibleAdminEthicsAndCompliance = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminEthicsAndComplianceStart());
    axios
      .put(`/site/ethics-compliance/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminEthicsAndComplianceSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEthicsAndComplianceFail(err.message));
      });
  };
};

export const getAdminGetInTouchStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_GET_IN_TOUCH_START,
  };
};

export const getAdminGetInTouchSuccess = (adminGetInTouch) => {
  return {
    type: adminActionTypes.GET_ADMIN_GET_IN_TOUCH_SUCCESS,
    adminGetInTouch: adminGetInTouch,
  };
};

export const getOneAdminGetInTouchSuccess = (getInTouch) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_GET_IN_TOUCH_SUCCESS,
    getInTouch: getInTouch,
  };
};

export const getAdminGetInTouchFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_GET_IN_TOUCH_FAIL,
    error: error,
  };
};

export const getAdminGetInTouch = (lang) => {
  return (dispatch) => {
    dispatch(getAdminGetInTouchStart());
    axios
      .get(`/site/get-in-touch`)
      .then((response) => {
        dispatch(getAdminGetInTouchSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGetInTouchFail(err.message));
      });
  };
};

export const getOneAdminGetInTouch = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminGetInTouchStart());
    axios
      .get(`/site/get-in-touch/in-touch/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminGetInTouchSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGetInTouchFail(err.message));
      });
  };
};

export const deleteAdminGetInTouch = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminGetInTouchStart());
    axios
      .delete(`/site/get-in-touch/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminGetInTouchSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGetInTouchFail(err.message));
      });
  };
};

export const activateAdminGetInTouch = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminGetInTouchStart());
    axios
      .put(`/site/get-in-touch/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminGetInTouchSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGetInTouchFail(err.message));
      });
  };
};

export const desactivateAdminGetInTouch = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminGetInTouchStart());
    axios
      .put(`/site/get-in-touch/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminGetInTouchSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGetInTouchFail(err.message));
      });
  };
};

export const updateAdminGetInTouch = (object) => {
  return (dispatch) => {
    dispatch(getAdminGetInTouchStart());
    axios
      .put(`/site/get-in-touch/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminGetInTouchSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGetInTouchFail(err.message));
      });
  };
};

export const archiveAdminGetInTouch = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminGetInTouchStart());
    axios
      .put(`/site/get-in-touch/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminGetInTouchSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGetInTouchFail(err.message));
      });
  };
};

export const visibleAdminGetInTouch = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminGetInTouchStart());
    axios
      .put(`/site/get-in-touch/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminGetInTouchSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGetInTouchFail(err.message));
      });
  };
};

export const getAdminSocialMediaLinkStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_SOCIAL_MEDIA_LINK_START,
  };
};

export const getAdminSocialMediaLinkSuccess = (adminSocialMedia) => {
  return {
    type: adminActionTypes.GET_ADMIN_SOCIAL_MEDIA_LINK_SUCCESS,
    adminSocialMedia: adminSocialMedia,
  };
};

export const getOneAdminSocialMediaLinkSuccess = (socialMedia) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_SOCIAL_MEDIA_LINK_SUCCESS,
    socialMedia: socialMedia,
  };
};

export const getAdminSocialMediaLinkFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_SOCIAL_MEDIA_LINK_FAIL,
    error: error,
  };
};

export const createAdminSocialMediaLink = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminSocialMediaLinkStart());
    axios
      .post(`/site/social-media-links/create/${object.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getAdminSocialMediaLinkSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminSocialMediaLinkFail(err.message));
        notifications.errorNotification("Social Media Link");
      });
    notifications.successCreationNotification("Social Media Link");
  };
};
