import "./servicescss.css";
import { Button } from "../components/Buttons";
import HomePageTitle from "../components/HomePageTitle";
import ServiceCard from "./ServiceCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as actionTypes from "../store/actions/";
import { useTranslation } from "react-i18next";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import dotenv from "dotenv";
dotenv.config();

const HomeServices = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notMainHomeServices = useSelector(
    (state) => state.home.notMainHomeServices
  );
  const mainHomeServices = useSelector((state) => state.home.mainHomeServices);
  const homeServicesLoading = useSelector(
    (state) => state.home.homeServicesLoading
  );
  const homeServicesIntro = useSelector(
    (state) => state.home.homeServicesIntro
  );
  const homeServicesIntroLoading = useSelector(
    (state) => state.home.homeServicesIntroLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getMainHomeServices(language));
    dispatch(actionTypes.getNotMainHomeServices(language));
    dispatch(actionTypes.getHomeServicesIntro(language));
  }, [dispatch, language]);
  return (
    <div className="text-left h-full site-text-blue home-services">
      {homeServicesIntroLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div data-aos="zoom-out-up" data-aos-duration="1000" className="pt-8">
            <HomePageTitle title={homeServicesIntro?.title} />
          </div>
          <div
            data-aos="zoom-out-up"
            data-aos-duration="1000"
            className="home-services-text hidden md:block md:px-60 md:pb-16"
          >
            {
              homeServicesIntro?.description != undefined
                ? parse(eval(homeServicesIntro?.description))
                : parse("<p></p>"
            )}
          </div>
        </>
      )}
      <div className="flex flex-wrap flex-col md:px-10 lg:px-16 xl:px-32">
        <div
          data-aos="zoom-out-up"
          data-aos-duration="1500"
          className="flex flex-col w-full md:flex-row md:space-x-6 md:justify-between"
        >
          {homeServicesLoading ? (
            <div className="w-full h-full flex justify-center items-center">
              <Spinner />
            </div>
          ) : (
            mainHomeServices.map((homeService, index) => {
              return (
                <ServiceCard
                  key={index}
                  service={{
                    ...homeService,
                    name: homeService.title,
                    description: homeService.summary,
                    image: `${process.env.REACT_APP_BACKEND_URL}/site/${homeService.service.image}`,
                  }}
                />
              );
            })
          )}
        </div>
        <div
          data-aos="zoom-out-up"
          data-aos-duration="2000"
          className="flex flex-col w-full px-5 md:flex-row md:space-x-6 md:px-0"
        >
          {homeServicesLoading ? (
            <div className="w-full h-full flex justify-center items-center">
              <Spinner />
            </div>
          ) : (
            notMainHomeServices.map((homeService, index) => {
              return (
                <div key={index} className="w-full md:w-1/3">
                  <ServiceCard
                    service={{
                      ...homeService,
                      name: homeService.title,
                      description: homeService.summary,
                      image: `${process.env.REACT_APP_BACKEND_URL}/site/${homeService.service.image}`,
                    }}
                    sm
                  />
                </div>
              );
            })
          )}
        </div>
        <Link to="/services" className="flex justify-center py-9">
          <Button name={t("View All")} />
        </Link>
      </div>
    </div>
  );
};
export default HomeServices;
