import axios from "../../axios-base";
import * as adminActionTypes from "./adminActionTypes.js";
import * as notifications from "../../admin/contants";

export const getAdminPartnersStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_PARTNERS_START,
  };
};

export const getAdminPartnersSuccess = (adminPartners) => {
  return {
    type: adminActionTypes.GET_ADMIN_PARTNERS_SUCCESS,
    adminPartners: adminPartners,
  };
};

export const getOneAdminPartnersSuccess = (partner) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_PARTNERS_SUCCESS,
    partner: partner,
  };
};

export const getAdminPartnersFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_PARTNERS_FAIL,
    error: error,
  };
};

export const getAllAdminPartners = (lang) => {
  return (dispatch) => {
    dispatch(getAdminPartnersStart());
    axios
      .get(`/site/partners`)
      .then((response) => {
        dispatch(getAdminPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersFail(err.message));
      });
  };
};

export const getAllActivePartners = () => {
  return (dispatch) => {
    dispatch(getAdminPartnersStart());
    axios
      .get(`/site/partners/active`)
      .then((response) => {
        dispatch(getAdminPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersFail(err.message));
      });
  };
};

export const getOneAdminPartners = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPartnersStart());
    axios
      .get(`/site/partners/partner/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersFail(err.message));
      });
  };
};

export const deleteAdminPartners = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPartnersStart());
    axios
      .delete(`/site/partners/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersFail(err.message));
      });
  };
};

export const activateAdminPartners = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPartnersStart());
    axios
      .put(`/site/partners/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersFail(err.message));
      });
  };
};

export const desactivateAdminPartners = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPartnersStart());
    axios
      .put(`/site/partners/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersFail(err.message));
      });
  };
};

export const updateAdminPartners = (object) => {
  return (dispatch) => {
    dispatch(getAdminPartnersStart());
    axios
      .put(`/site/partners/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersFail(err.message));
      });
  };
};

export const archiveAdminPartners = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPartnersStart());
    axios
      .put(`/site/partners/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersFail(err.message));
      });
  };
};

export const visibleAdminPartners = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPartnersStart());
    axios
      .put(`/site/partners/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersFail(err.message));
      });
  };
};

export const getAdminPartnersIntroStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_PARTNERS_INTRO_START,
  };
};

export const getAdminPartnersIntroSuccess = (adminPartnersIntro) => {
  return {
    type: adminActionTypes.GET_ADMIN_PARTNERS_INTRO_SUCCESS,
    adminPartnersIntro: adminPartnersIntro,
  };
};

export const getOneAdminPartnersIntroSuccess = (partnersIntro) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_PARTNERS_INTRO_SUCCESS,
    partnersIntro: partnersIntro,
  };
};

export const getAdminPartnersIntroFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_PARTNERS_INTRO_FAIL,
    error: error,
  };
};

export const getAdminPartnersIntro = (lang) => {
  return (dispatch) => {
    dispatch(getAdminPartnersIntroStart());
    axios
      .get(`/site/partners-intros`)
      .then((response) => {
        dispatch(getAdminPartnersIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersIntroFail(err.message));
      });
  };
};

export const getOneAdminPartnersIntro = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPartnersIntroStart());
    axios
      .get(`/site/partners-intros/partner-intro/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPartnersIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersIntroFail(err.message));
      });
  };
};

export const deleteAdminPartnersIntro = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPartnersIntroStart());
    axios
      .delete(`/site/partners-intros/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPartnersIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersIntroFail(err.message));
      });
  };
};

export const activateAdminPartnersIntro = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPartnersIntroStart());
    axios
      .put(`/site/partners-intros/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPartnersIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersIntroFail(err.message));
      });
  };
};

export const desactivateAdminPartnersIntro = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPartnersIntroStart());
    axios
      .put(`/site/partners-intros/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPartnersIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersIntroFail(err.message));
      });
  };
};

export const updateAdminPartnersIntro = (object) => {
  return (dispatch) => {
    dispatch(getAdminPartnersIntroStart());
    axios
      .put(`/site/partners-intros/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminPartnersIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersIntroFail(err.message));
      });
  };
};

export const archiveAdminPartnersIntro = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPartnersIntroStart());
    axios
      .put(`/site/partners-intros/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPartnersIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersIntroFail(err.message));
      });
  };
};

export const visibleAdminPartnersIntro = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPartnersIntroStart());
    axios
      .put(`/site/partners-intros/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPartnersIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPartnersIntroFail(err.message));
      });
  };
};

export const getAdminAffiliationsStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_AFFILIATIONS_START,
  };
};

export const getAdminAffiliationsSuccess = (adminAffiliations) => {
  return {
    type: adminActionTypes.GET_ADMIN_AFFILIATIONS_SUCCESS,
    adminAffiliations: adminAffiliations,
  };
};

export const getOneAdminAffiliationsSuccess = (affiliation) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_AFFILIATIONS_SUCCESS,
    affiliation: affiliation,
  };
};

export const getAdminAffiliationsFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_AFFILIATIONS_FAIL,
    error: error,
  };
};

export const getAdminAffiliations = (lang) => {
  return (dispatch) => {
    dispatch(getAdminAffiliationsStart());
    axios
      .get(`/site/affiliations`)
      .then((response) => {
        dispatch(getAdminAffiliationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAffiliationsFail(err.message));
      });
  };
};

export const getOneAdminAffiliations = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAffiliationsStart());
    axios
      .get(`/site/affiliations/affiliation/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAffiliationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAffiliationsFail(err.message));
      });
  };
};

export const deleteAdminAffiliations = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAffiliationsStart());
    axios
      .delete(`/site/affiliations/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAffiliationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAffiliationsFail(err.message));
      });
  };
};

export const activateAdminAffiliations = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAffiliationsStart());
    axios
      .put(`/site/affiliations/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAffiliationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAffiliationsFail(err.message));
      });
  };
};

export const desactivateAdminAffiliations = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAffiliationsStart());
    axios
      .put(`/site/affiliations/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAffiliationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAffiliationsFail(err.message));
      });
  };
};

export const updateAdminAffiliations = (object) => {
  return (dispatch) => {
    dispatch(getAdminAffiliationsStart());
    axios
      .put(`/site/affiliations/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminAffiliationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAffiliationsFail(err.message));
      });
  };
};

export const archiveAdminAffiliations = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAffiliationsStart());
    axios
      .put(`/site/affiliations/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAffiliationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAffiliationsFail(err.message));
      });
  };
};

export const visibleAdminAffiliations = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminAffiliationsStart());
    axios
      .put(`/site/affiliations/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminAffiliationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminAffiliationsFail(err.message));
      });
  };
};
