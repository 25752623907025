import React from "react";
import { useTranslation } from "react-i18next";
import ServiceCard from "../../servicesComponent/ServiceCard";
import { Button } from "../Buttons";
import parse from "html-react-parser";

const WidgetC = (props) => {
  const { t } = useTranslation();
  return (
    <div
      id={props.widget?.hashlink}
      className="elearning-service flex flex-col md:px-16 overflow-hidden scrollbar-hidden"
    >
      <div
        data-aos="zoom-out-up"
        data-aos-duration="1000"
        className="service-title py-5 site-text-blue text-center text-md"
      >
        {props.widget?.title}
      </div>
      <div
        data-aos="zoom-out-up"
        data-aos-duration="1000"
        className="text-center site-text-blue elearning-text-title py-5 px-20"
      >
        <p>{props.widget?.subtitle}</p>
      </div>
      <div
        data-aos="zoom-out-up"
        data-aos-duration="1000"
        className="flex text-center site-text-blue elearning-text-title px-10"
      >
        {parse(props.widget.body != undefined ? props.widget?.body : "<p></p>")}
      </div>
      <div
        data-aos="zoom-out-up"
        data-aos-duration="1500"
        className="flex flex-col md:justify-center  pt-8 md:flex-row md:space-x-5"
      >
        <ServiceCard
          service={{
            ...props.widget?.subwidgets[0],
            image: `${process.env.REACT_APP_BACKEND_URL}/site/${props.widget?.subwidgets[0]?.image}`,
          }}
          className="w-full"
        />
        <div className="flex text-center site-text-blue elearning-text-title px-10 py-5 md:py-0 md:hidden">
          {
            props.widget.description != undefined
              ? parse(eval(props.widget.description))
              : parse("<p></p>"
          )}
        </div>
        <ServiceCard
          service={{
            ...props.widget?.subwidgets[1],
            image: `${process.env.REACT_APP_BACKEND_URL}/site/${props.widget?.subwidgets[1]?.image}`,
          }}
          className="w-full"
        />
      </div>
      <div className="hidden md:flex flex-col space-y-6  text-center justify-center site-text-blue elearning-text">
        {
          props.widget?.description != undefined
            ? parse(eval(props.widget?.description))
            : parse("<p></p>"
        )}
      </div>
      <a
        href="https://opanda.xyz"
        target="_blank"
        rel="noreferrer"
        className="text-center pb-7 md:pb-12 md:pt-5"
      >
        <Button name={t("Visit Platform")} />
      </a>
    </div>
  );
};

export default WidgetC;
