import FooterSectionItem from './FooterSectionItem';
import './footercss.css';
import { IoAddCircleOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import { useState } from 'react';

import dotenv from "dotenv";
dotenv.config();

export const SummaryCard = (props) => {
  const [showSectionItems, setShowSectionItems] = useState(false);
  const servicesList = props.services.map((service, index) => (
    <li
      className={
        showSectionItems
          ? 'footer-items-list p-2 lg:px-0 lg:block'
          : 'footer-items-list p-2 lg:px-0 hidden lg:block'
      }
      key={index}
    >
      <FooterSectionItem link={service.link} name={service.name} />
    </li>
  ));

  return (
    <ul className="text-white">
      <li className="footer-item-title pb-1">
        {props.serviceTitle}
        <button
          className="text-white font-bold lg:hidden ft-menu-btn px-2"
          onClick={() => {setShowSectionItems(!showSectionItems)}}
        >
          {showSectionItems ? (
            <IoRemoveCircleOutline size={16} className="inline add-circle" />
          ) : (
            <IoAddCircleOutline size={16} className="inline add-circle" />
          )}
        </button>
      </li>
      {servicesList}
    </ul>
  );
};
