import axios from '../../axios-base';
import * as actionTypes from './actionTypes.js';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (authData) => {
  localStorage.setItem('jwt', authData.token);
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: authData.token,
    userId: authData.user.uuid,
    name: authData.staffMember.name,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error.message,
  };
};
export const authLogout = () => {
  localStorage.removeItem('jwt');
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};
export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};

export const auth = (email, password = '') => {
  return (dispatch) => {
    dispatch(authStart());
    let authData = {
      email,
      password,
    };
    axios
      .post('/site/login', authData)
      .then((response) => {
        dispatch(authSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(authFail(err.response.data));
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch(authLogout());
  };
};

export const checkToken = (token) => {
  return (dispatch) => {
    axios
      .post(`/site/users/validate`, null, { headers: { Authorization: token } })
      .then((res) => {
        dispatch(authSuccess(res.data.results));
      })
      .catch((err) => {
        dispatch(authLogout());
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem('jwt');
    if (!token) {
      dispatch(logout());
    } else {
      dispatch(checkToken(token));
    }
  };
};
