import { updateObject } from "../../utils/updateObject";

import * as adminActionTypes from "../actions/adminActionTypes";

const initialReducer = {
  adminHomeCarousel: [],
  adminHomeCarouselError: null,
  adminHomeCarouselLoading: false,
  homeCarousel: null,
  adminHomeCommunicationLinks: [],
  adminHomeCommunicationLinksError: null,
  adminHomeCommunicationLinksLoading: false,
  homeCommunicationLink: null,
  adminHomeAbout: [],
  adminHomeAboutError: null,
  adminHomeAboutLoading: false,
  homeAbout: null,
  adminHomeProduct: [],
  adminHomeProductError: null,
  adminHomeProductLoading: false,
  homeProduct: null,
  adminHomePartners: [],
  adminHomePartnersError: null,
  adminHomePartnersLoading: false,
  homePartner: null,
  adminHomeServices: [],
  adminHomeServicesError: null,
  adminHomeServicesLoading: false,
  homeService: null,
  adminHomeServicesIntro: [],
  adminHomeServicesIntroError: null,
  adminHomeServicesIntroLoading: false,
  homeServiceIntro: null,
  adminHomeTodaysQuote: [],
  adminHomeTodaysQuoteError: null,
  adminHomeTodaysQuoteLoading: false,
  homeTodaysQuote: null,
  adminHomeFooterSections: [],
  adminHomeFooterSectionsError: null,
  adminHomeFooterSectionsLoading: false,
  homeFooterSection: null,
  adminHomeFooterAddress: [],
  adminHomeFooterAddressError: null,
  adminHomeFooterAddressLoading: false,
  homeFooterAddress: null,
  adminHomeFooterSectionItems: [],
  adminHomeFooterSectionItemsError: null,
  adminHomeFooterSectionItemsLoading: false,
  homeFooterSectionItem: null,
};

const getAdminHomeCarouselStart = (state, action) => {
  return updateObject(state, {
    adminHomeCarousel: [],
    adminHomeCarouselError: null,
    adminHomeCarouselLoading: true,
    homeCarousel: null,
  });
};

const getAdminHomeCarouselSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeCarousel: action.adminHomeCarousel,
    adminHomeCarouselError: null,
    adminHomeCarouselLoading: false,
    homeCarousel: null,
  });
};

const getAdminSingleHomeCarouselSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeCarousel: [],
    adminHomeCarouselError: null,
    adminHomeCarouselLoading: false,
    homeCarousel: action.homeCarousel,
  });
};

const getAdminHomeCarouselFail = (state, action) => {
  return updateObject(state, {
    adminHomeCarousel: [],
    adminHomeCarouselError: action.error,
    adminHomeCarouselLoading: false,
    homeCarousel: null,
  });
};

const getAdminHomeCommunicationLinksStart = (state, action) => {
  return updateObject(state, {
    adminHomeCommunicationLinks: [],
    adminHomeCommunicationLinksError: null,
    adminHomeCommunicationLinksLoading: true,
    homeCommunicationLink: null,
  });
};

const getAdminHomeCommunicationLinksSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeCommunicationLinks: action.adminHomeCommunicationLinks,
    adminHomeCommunicationLinksError: null,
    adminHomeCommunicationLinksLoading: false,
    homeCommunicationLink: null,
  });
};

const getAdminHomeCommunicationLinksFail = (state, action) => {
  return updateObject(state, {
    adminHomeCommunicationLinks: [],
    adminHomeCommunicationLinksError: action.error,
    adminHomeCommunicationLinksLoading: false,
    homeCommunicationLink: null,
  });
};

const getOneAdminHomeCommunicationLinksSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeCommunicationLinks: [],
    adminHomeCommunicationLinksError: null,
    adminHomeCommunicationLinksLoading: false,
    homeCommunicationLink: action.homeCommunicationLink,
  });
};

const getAdminHomeAboutStart = (state, action) => {
  return updateObject(state, {
    adminHomeAbout: [],
    adminHomeAboutError: null,
    adminHomeAboutLoading: true,
    homeAbout: null,
  });
};

const getAdminHomeAboutSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeAbout: action.adminHomeAbout,
    adminHomeAboutError: null,
    adminHomeAboutLoading: false,
    homeAbout: null,
  });
};

const getAdminHomeAboutFail = (state, action) => {
  return updateObject(state, {
    adminHomeAbout: [],
    adminHomeAboutError: action.error,
    adminHomeAboutLoading: false,
    homeAbout: null,
  });
};

const getOneAdminHomeAboutSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeAbout: [],
    adminHomeAboutError: null,
    adminHomeAboutLoading: false,
    homeAbout: action.homeAbout,
  });
};

const getAdminHomeProductStart = (state, action) => {
  return updateObject(state, {
    adminHomeProduct: [],
    adminHomeProductError: null,
    adminHomeProductLoading: true,
    homeProduct: null,
  });
};

const getAdminHomeProductSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeProduct: action.adminHomeProduct,
    adminHomeProductError: null,
    adminHomeProductLoading: false,
    homeProduct: null,
  });
};

const getAdminHomeProductFail = (state, action) => {
  return updateObject(state, {
    adminHomeProduct: [],
    adminHomeProductError: action.error,
    adminHomeProductLoading: false,
    homeProduct: null,
  });
};

const getOneAdminHomeProductSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeProduct: [],
    adminHomeProductError: null,
    adminHomeProductLoading: false,
    homeProduct: action.homeProduct,
  });
};

const getAdminHomePartnersStart = (state, action) => {
  return updateObject(state, {
    adminHomePartners: [],
    adminHomePartnersError: null,
    adminHomePartnersLoading: true,
    homePartner: null,
  });
};

const getAdminHomePartnersSuccess = (state, action) => {
  return updateObject(state, {
    adminHomePartners: action.adminHomePartners,
    adminHomePartnersError: null,
    adminHomePartnersLoading: false,
    homePartner: null,
  });
};

const getAdminHomePartnersFail = (state, action) => {
  return updateObject(state, {
    adminHomePartners: [],
    adminHomePartnersError: action.error,
    adminHomePartnersLoading: false,
    homePartner: null,
  });
};

const getOneAdminHomePartnersSuccess = (state, action) => {
  return updateObject(state, {
    adminHomePartners: [],
    adminHomePartnersError: null,
    adminHomePartnersLoading: false,
    homePartner: action.homePartner,
  });
};
const getAdminHomeServicesIntroStart = (state, action) => {
  return updateObject(state, {
    adminHomeServicesIntro: [],
    adminHomeServicesIntroError: null,
    adminHomeServicesIntroLoading: true,
    homeServiceIntro: null,
  });
};

const getAdminHomeServicesIntroSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeServicesIntro: action.adminHomeServicesIntro,
    adminHomeServicesIntroError: null,
    adminHomeServicesIntroLoading: false,
    homeServiceIntro: null,
  });
};

const getAdminHomeServicesIntroFail = (state, action) => {
  return updateObject(state, {
    adminHomeServicesIntro: [],
    adminHomeServicesIntroError: action.error,
    adminHomeServicesIntroLoading: false,
    homeServiceIntro: null,
  });
};

const getOneAdminHomeServicesIntroSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeServicesIntro: [],
    adminHomeServicesIntroError: null,
    adminHomeServicesIntroLoading: false,
    homeServiceIntro: action.homeServiceIntro,
  });
};

const getAdminHomeServicesStart = (state, action) => {
  return updateObject(state, {
    adminHomeServices: [],
    adminHomeServicesError: null,
    adminHomeServicesLoading: true,
    homeService: null,
  });
};

const getAdminHomeServicesSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeServices: action.adminHomeServices,
    adminHomeServicesError: null,
    adminHomeServicesLoading: false,
    homeService: null,
  });
};

const getAdminHomeServicesFail = (state, action) => {
  return updateObject(state, {
    adminHomeServices: [],
    adminHomeServicesError: action.error,
    adminHomeServicesLoading: false,
    homeService: null,
  });
};

const getOneAdminHomeServicesSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeServices: [],
    adminHomeServicesError: null,
    adminHomeServicesLoading: false,
    homeService: action.homeService,
  });
};

const getAdminHomeTodaysQuoteStart = (state, action) => {
  return updateObject(state, {
    adminHomeTodaysQuote: [],
    adminHomeTodaysQuoteError: null,
    adminHomeTodaysQuoteLoading: true,
    homeTodaysQuote: null,
  });
};

const getAdminHomeTodaysQuoteSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeTodaysQuote: action.adminHomeTodaysQuote,
    adminHomeTodaysQuoteError: null,
    adminHomeTodaysQuoteLoading: false,
    homeTodaysQuote: null,
  });
};

const getAdminHomeTodaysQuoteFail = (state, action) => {
  return updateObject(state, {
    adminHomeTodaysQuote: [],
    adminHomeTodaysQuoteError: action.error,
    adminHomeTodaysQuoteLoading: false,
    homeTodaysQuote: null,
  });
};

const getOneAdminHomeTodaysQuoteSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeTodaysQuote: [],
    adminHomeTodaysQuoteError: null,
    adminHomeTodaysQuoteLoading: false,
    homeTodaysQuote: action.homeTodaysQuote,
  });
};

const getAdminHomeFooterSectionsStart = (state, action) => {
  return updateObject(state, {
    adminHomeFooterSections: [],
    adminHomeFooterSectionsError: null,
    adminHomeFooterSectionsLoading: true,
    homeFooterSection: null,
  });
};

const getAdminHomeFooterSectionsSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeFooterSections: action.adminHomeFooterSections,
    adminHomeFooterSectionsError: null,
    adminHomeFooterSectionsLoading: false,
    homeFooterSection: null,
  });
};

const getAdminHomeFooterSectionsFail = (state, action) => {
  return updateObject(state, {
    adminHomeFooterSections: [],
    adminHomeFooterSectionsError: action.error,
    adminHomeFooterSectionsLoading: false,
    homeFooterSection: null,
  });
};

const getOneAdminHomeFooterSectionsSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeFooterSections: [],
    adminHomeFooterSectionsError: null,
    adminHomeFooterSectionsLoading: false,
    homeFooterSection: action.homeFooterSection,
  });
};

const getAdminHomeFooterAddressStart = (state, action) => {
  return updateObject(state, {
    adminHomeFooterAddress: [],
    adminHomeFooterAddressError: null,
    adminHomeFooterAddressLoading: true,
    homeFooterAddress: null,
  });
};

const getAdminHomeFooterAddressSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeFooterAddress: action.adminHomeFooterAddress,
    adminHomeFooterAddressError: null,
    adminHomeFooterAddressLoading: false,
    homeFooterAddress: null,
  });
};

const getAdminHomeFooterAddressFail = (state, action) => {
  return updateObject(state, {
    adminHomeFooterAddress: [],
    adminHomeFooterAddressError: action.error,
    adminHomeFooterAddressLoading: false,
    homeFooterAddress: null,
  });
};

const getOneAdminHomeFooterAddressSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeFooterAddress: [],
    adminHomeFooterAddressError: null,
    adminHomeFooterAddressLoading: false,
    homeFooterAddress: action.homeFooterAddress,
  });
};

const getAdminHomeFooterSectionItemsStart = (state, action) => {
  return updateObject(state, {
    adminHomeFooterSectionItems: [],
    adminHomeFooterSectionItemsError: null,
    adminHomeFooterSectionItemsLoading: true,
    homeFooterSectionItem: null,
  });
};

const getAdminHomeFooterSectionItemsSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeFooterSectionItems: action.adminHomeFooterSectionItems,
    adminHomeFooterSectionItemsError: null,
    adminHomeFooterSectionItemsLoading: false,
    homeFooterSectionItem: null,
  });
};

const getAdminHomeFooterSectionItemsFail = (state, action) => {
  return updateObject(state, {
    adminHomeFooterSectionItems: [],
    adminHomeFooterSectionItemsError: action.error,
    adminHomeFooterSectionItemsLoading: false,
    homeFooterSectionItem: null,
  });
};

const getOneAdminHomeFooterSectionItemsSuccess = (state, action) => {
  return updateObject(state, {
    adminHomeFooterSectionItems: [],
    adminHomeFooterSectionItemsError: null,
    adminHomeFooterSectionItemsLoading: false,
    homeFooterSectionItem: action.homeFooterSectionItem,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case adminActionTypes.GET_ADMIN_HOME_CAROUSEL_START:
      return getAdminHomeCarouselStart(state, action);
    case adminActionTypes.GET_ADMIN_HOME_CAROUSEL_SUCCESS:
      return getAdminHomeCarouselSuccess(state, action);
    case adminActionTypes.GET_ADMIN_HOME_CAROUSEL_FAIL:
      return getAdminHomeCarouselFail(state, action);
    case adminActionTypes.GET_ADMIN_SINGLE_HOME_CAROUSEL_SUCCESS:
      return getAdminSingleHomeCarouselSuccess(state, action);
    case adminActionTypes.GET_ADMIN_HOME_COMMUNICATION_LINKS_START:
      return getAdminHomeCommunicationLinksStart(state, action);
    case adminActionTypes.GET_ADMIN_HOME_COMMUNICATION_LINKS_SUCCESS:
      return getAdminHomeCommunicationLinksSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_HOME_COMMUNICATION_LINKS_SUCCESS:
      return getOneAdminHomeCommunicationLinksSuccess(state, action);
    case adminActionTypes.GET_ADMIN_HOME_COMMUNICATION_LINKS_FAIL:
      return getAdminHomeCommunicationLinksFail(state, action);
    case adminActionTypes.GET_ADMIN_HOME_ABOUT_START:
      return getAdminHomeAboutStart(state, action);
    case adminActionTypes.GET_ADMIN_HOME_ABOUT_SUCCESS:
      return getAdminHomeAboutSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_HOME_ABOUT_SUCCESS:
      return getOneAdminHomeAboutSuccess(state, action);
    case adminActionTypes.GET_ADMIN_HOME_ABOUT_FAIL:
      return getAdminHomeAboutFail(state, action);
    case adminActionTypes.GET_ADMIN_HOME_PRODUCT_START:
      return getAdminHomeProductStart(state, action);
    case adminActionTypes.GET_ADMIN_HOME_PRODUCT_SUCCESS:
      return getAdminHomeProductSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_HOME_PRODUCT_SUCCESS:
      return getOneAdminHomeProductSuccess(state, action);
    case adminActionTypes.GET_ADMIN_HOME_PRODUCT_FAIL:
      return getAdminHomeProductFail(state, action);
    case adminActionTypes.GET_ADMIN_HOME_PARTNERS_START:
      return getAdminHomePartnersStart(state, action);
    case adminActionTypes.GET_ADMIN_HOME_PARTNERS_SUCCESS:
      return getAdminHomePartnersSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_HOME_PARTNERS_SUCCESS:
      return getOneAdminHomePartnersSuccess(state, action);
    case adminActionTypes.GET_ADMIN_HOME_PARTNERS_FAIL:
      return getAdminHomePartnersFail(state, action);
    case adminActionTypes.GET_ADMIN_HOME_SERVICES_START:
      return getAdminHomeServicesStart(state, action);
    case adminActionTypes.GET_ADMIN_HOME_SERVICES_SUCCESS:
      return getAdminHomeServicesSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_HOME_SERVICES_SUCCESS:
      return getOneAdminHomeServicesSuccess(state, action);
    case adminActionTypes.GET_ADMIN_HOME_SERVICES_FAIL:
      return getAdminHomeServicesFail(state, action);
    case adminActionTypes.GET_ADMIN_HOME_SERVICES_INTRO_START:
      return getAdminHomeServicesIntroStart(state, action);
    case adminActionTypes.GET_ADMIN_HOME_SERVICES_INTRO_SUCCESS:
      return getAdminHomeServicesIntroSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_HOME_SERVICES_INTRO_SUCCESS:
      return getOneAdminHomeServicesIntroSuccess(state, action);
    case adminActionTypes.GET_ADMIN_HOME_SERVICES_INTRO_FAIL:
      return getAdminHomeServicesIntroFail(state, action);
    case adminActionTypes.GET_ADMIN_HOME_TODAY_QUOTE_START:
      return getAdminHomeTodaysQuoteStart(state, action);
    case adminActionTypes.GET_ADMIN_HOME_TODAY_QUOTE_SUCCESS:
      return getAdminHomeTodaysQuoteSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_HOME_TODAY_QUOTE_SUCCESS:
      return getOneAdminHomeTodaysQuoteSuccess(state, action);
    case adminActionTypes.GET_ADMIN_HOME_TODAY_QUOTE_FAIL:
      return getAdminHomeTodaysQuoteFail(state, action);
    case adminActionTypes.GET_ADMIN_HOME_FOOTER_SECTIONS_START:
      return getAdminHomeFooterSectionsStart(state, action);
    case adminActionTypes.GET_ADMIN_HOME_FOOTER_SECTIONS_SUCCESS:
      return getAdminHomeFooterSectionsSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_HOME_FOOTER_SECTIONS_SUCCESS:
      return getOneAdminHomeFooterSectionsSuccess(state, action);
    case adminActionTypes.GET_ADMIN_HOME_FOOTER_SECTIONS_FAIL:
      return getAdminHomeFooterSectionsFail(state, action);
    case adminActionTypes.GET_ADMIN_HOME_FOOTER_SECTION_ITEMS_START:
      return getAdminHomeFooterSectionItemsStart(state, action);
    case adminActionTypes.GET_ADMIN_HOME_FOOTER_SECTION_ITEMS_SUCCESS:
      return getAdminHomeFooterSectionItemsSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_HOME_FOOTER_SECTION_ITEMS_SUCCESS:
      return getOneAdminHomeFooterSectionItemsSuccess(state, action);
    case adminActionTypes.GET_ADMIN_HOME_FOOTER_SECTION_ITEMS_FAIL:
      return getAdminHomeFooterSectionItemsFail(state, action);
    case adminActionTypes.GET_ADMIN_HOME_FOOTER_ADDRESS_START:
      return getAdminHomeFooterAddressStart(state, action);
    case adminActionTypes.GET_ADMIN_HOME_FOOTER_ADDRESS_SUCCESS:
      return getAdminHomeFooterAddressSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_HOME_FOOTER_ADDRESS_SUCCESS:
      return getOneAdminHomeFooterAddressSuccess(state, action);
    case adminActionTypes.GET_ADMIN_HOME_FOOTER_ADDRESS_FAIL:
      return getAdminHomeFooterAddressFail(state, action);
    default:
      return state;
  }
};

export default reducer;
