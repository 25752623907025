import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";
import * as notifications from "../../admin/contants";

export const getFilesStart = () => {
  return {
    type: actionTypes.GET_ADMIN_FILES_START,
  };
};

export const getFilesSuccess = (files) => {
  return {
    type: actionTypes.GET_ADMIN_FILES_SUCCESS,
    files: files,
  };
};

export const getOneFilesSuccess = (file) => {
  return {
    type: actionTypes.GET_ONE_ADMIN_FILES_SUCCESS,
    file: file,
  };
};

export const getFilesFail = (error) => {
  return {
    type: actionTypes.GET_ADMIN_FILES_FAIL,
    error: error,
  };
};

export const getFiles = () => {
  return (dispatch) => {
    dispatch(getFilesStart());
    axios
      .get(`/site/ogenius-files`)
      .then((response) => {
        dispatch(getFilesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getFilesFail(err.message));
      });
  };
};

export const updateFiles = (object, filename) => {
  return (dispatch) => {
    dispatch(getFilesStart());
    axios
      .post(`/site/ogenius-files/edit/${filename}`, object)
      .then((response) => {
        dispatch(getFilesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getFilesFail(err.message));
      });
  };
};

export const uploadFilesInText = (object) => {
  return (dispatch) => {
    dispatch(getFilesStart());
    axios
      .post(`/site/ogenius-files/upload-in-text`, object)
      .then((response) => {
        dispatch(getFilesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getFilesFail(err.message));
      });
  };
};
