import React, { useState, useRef, useMemo } from "react";
import { IoPlayOutline, IoPauseOutline } from "react-icons/io5";
import parse from "html-react-parser";

const WidgetE = (props) => {
  const targetRef = useState();
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = () => {
    if (isPlaying) {
      targetRef.current.pause();
      setIsPlaying(false);
    } else {
      targetRef.current.play();
      setIsPlaying(true);
    }
  };

  // PAUSING A VIDEO WHEN NOT VISIBLE

  const callBackFunction = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      targetRef?.current?.play();
      setIsPlaying(true);
    } else {
      targetRef?.current?.pause();
      setIsPlaying(false);
    }
  };

  const options = useMemo(() => {
    return {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
  }, []);

  const refreshing = () => {
    const observer = new IntersectionObserver(callBackFunction, options);
    const currentTarget = targetRef.current;
    if (currentTarget) observer.observe(currentTarget);
    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  };

  document.addEventListener("scroll", () => {
    refreshing();
  });

  // END OF PAUSING A VIDEO WHEN NOT VISIBLE

  return (
    <div
      id={props.widget.hashlink}
      className="simulation min-h-simulations-mobile md:min-h-0 h-screen md:max-h-90vh lg:max-h-screen relative overflow-scroll scrollbar-hidden"
    >
      <div className="flex flex-col absolute top-0 md:top-10 left-0 md:left-10 md:w-2/5 z-10 simulation-service-body ">
        <div className="md:text-left service-title site-text-blue text-xl text-center py-5">
          <span>{props.widget.title}</span>
        </div>
        <div className="w-full pt-5 md:pt-0 flex flex-col relative">
          <video
            className="w-full object-cover object-center"
            src={`${process.env.REACT_APP_BACKEND_URL}/site/${props.widget.video}`}
            autoPlay
            ref={targetRef}
          ></video>
          {isPlaying ? (
            <IoPauseOutline
              className="absolute top-2/5 left-2/5"
              size={40}
              color="white"
              onClick={handleClick}
            />
          ) : (
            <IoPlayOutline
              className="absolute top-2/5 left-2/5"
              size={40}
              color="white"
              onClick={handleClick}
            />
          )}
        </div>
        <div className="bg-gradient-to-r from-gray-500 to-transparent h-0.5 my-6"></div>
        <div className="z-30 px-10 md:px-0 font-bold simulation-text h-2/5 max-h-2/5">
          {props.widget.body != undefined
            ? parse(eval(props.widget.body))
            : parse("<p></p>")}
        </div>
      </div>
    </div>
  );
};

export default WidgetE;
