import { updateObject } from "../../utils/updateObject";

import * as adminActionTypes from "../actions/adminActionTypes";

const initialReducer = {
  adminPartners: [],
  adminPartnersError: null,
  adminPartnersLoading: false,
  partner: null,
  adminPartnersIntro: [],
  adminPartnersIntroError: null,
  adminPartnersIntroLoading: false,
  partnersIntro: null,
  adminAffiliations: [],
  adminAffiliationsError: null,
  adminAffiliationsLoading: false,
  affiliation: null,
};

const getAdminPartnersStart = (state, action) => {
  return updateObject(state, {
    adminPartners: [],
    adminPartnersError: null,
    adminPartnersLoading: true,
    partner: null,
  });
};

const getAdminPartnersSuccess = (state, action) => {
  return updateObject(state, {
    adminPartners: action.adminPartners,
    adminPartnersError: null,
    adminPartnersLoading: false,
    partner: null,
  });
};

const getAdminPartnersFail = (state, action) => {
  return updateObject(state, {
    adminPartners: [],
    adminPartnersError: action.error,
    adminPartnersLoading: false,
    partner: null,
  });
};

const getOneAdminPartnersSuccess = (state, action) => {
  return updateObject(state, {
    adminPartners: [],
    adminPartnersError: null,
    adminPartnersLoading: false,
    partner: action.partner,
  });
};

const getAdminPartnersIntroStart = (state, action) => {
  return updateObject(state, {
    adminPartnersIntro: [],
    adminPartnersIntroError: null,
    adminPartnersIntroLoading: true,
    partnersIntro: null,
  });
};

const getAdminPartnersIntroSuccess = (state, action) => {
  return updateObject(state, {
    adminPartnersIntro: action.adminPartnersIntro,
    adminPartnersIntroError: null,
    adminPartnersIntroLoading: false,
    partnersIntro: null,
  });
};

const getAdminPartnersIntroFail = (state, action) => {
  return updateObject(state, {
    adminPartnersIntro: [],
    adminPartnersIntroError: action.error,
    adminPartnersIntroLoading: false,
    partnersIntro: null,
  });
};

const getOneAdminPartnersIntroSuccess = (state, action) => {
  return updateObject(state, {
    adminPartnersIntro: [],
    adminPartnersIntroError: null,
    adminPartnersIntroLoading: false,
    partnersIntro: action.partnersIntro,
  });
};

const getAdminAffiliationsStart = (state, action) => {
  return updateObject(state, {
    adminAffiliations: [],
    adminAffiliationsError: null,
    adminAffiliationsLoading: true,
    affiliation: null,
  });
};

const getAdminAffiliationsSuccess = (state, action) => {
  return updateObject(state, {
    adminAffiliations: action.adminAffiliations,
    adminAffiliationsError: null,
    adminAffiliationsLoading: false,
    affiliation: null,
  });
};

const getAdminAffiliationsFail = (state, action) => {
  return updateObject(state, {
    adminAffiliations: [],
    adminAffiliationsError: action.error,
    adminAffiliationsLoading: false,
    affiliation: null,
  });
};

const getOneAdminAffiliationsSuccess = (state, action) => {
  return updateObject(state, {
    adminAffiliations: [],
    adminAffiliationsError: null,
    adminAffiliationsLoading: false,
    affiliation: action.affiliation,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case adminActionTypes.GET_ADMIN_PARTNERS_START:
      return getAdminPartnersStart(state, action);
    case adminActionTypes.GET_ADMIN_PARTNERS_SUCCESS:
      return getAdminPartnersSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_PARTNERS_SUCCESS:
      return getOneAdminPartnersSuccess(state, action);
    case adminActionTypes.GET_ADMIN_PARTNERS_FAIL:
      return getAdminPartnersFail(state, action);
    case adminActionTypes.GET_ADMIN_PARTNERS_INTRO_START:
      return getAdminPartnersIntroStart(state, action);
    case adminActionTypes.GET_ADMIN_PARTNERS_INTRO_SUCCESS:
      return getAdminPartnersIntroSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_PARTNERS_INTRO_SUCCESS:
      return getOneAdminPartnersIntroSuccess(state, action);
    case adminActionTypes.GET_ADMIN_PARTNERS_INTRO_FAIL:
      return getAdminPartnersIntroFail(state, action);
    case adminActionTypes.GET_ADMIN_AFFILIATIONS_START:
      return getAdminAffiliationsStart(state, action);
    case adminActionTypes.GET_ADMIN_AFFILIATIONS_SUCCESS:
      return getAdminAffiliationsSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_AFFILIATIONS_SUCCESS:
      return getOneAdminAffiliationsSuccess(state, action);
    case adminActionTypes.GET_ADMIN_AFFILIATIONS_FAIL:
      return getAdminAffiliationsFail(state, action);
    default:
      return state;
  }
};
export default reducer;
