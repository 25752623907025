import React from "react";
import Slider from "react-slick";
import { EmailCard } from "../../productsComponenents/ProductsPageComponents";
import parse from "html-react-parser";

const WidgetJ = (props) => {
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={{ ...className, className: "rounded-full" }}
        style={{ ...style, display: "block", background: "#173f71" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={{ ...className, className: "rounded-full" }}
        style={{ ...style, display: "block", background: "#173f71" }}
        onClick={onClick}
      />
    );
  }
  const sliderSettings = {
    dots: true,
    centerMode: true,
    lazyLoad: true,
    infinite: true,
    swipeToSlide: true,
    focusOnSelect: true,
    speed: 500,
    slidesToShow: 4,
    initialSlide: 2,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          initialSlide: 0,
          centerMode: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          initialSlide: 0,
          centerMode: false,
        },
      },
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 3,
          initialSlide: 0,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <div
      id={props.widget?.hashlink}
      className="ogenius-mail py-16 flex flex-col text-center overflow-hidden scrollbar-hidden"
    >
      <div className="product-name text-xl pb-5">
        <span>{props.widget?.title}</span>
      </div>
      <div
        data-aos="zoom-out-up"
        data-aos-duration="1500"
        className="w-5/6 m-auto mt-10"
      >
        <Slider {...sliderSettings}>
          {props.widget?.subwidgets?.map((subwidget) => (
            <EmailCard
              type={subwidget?.packagetitle}
              amount={subwidget?.packageprice}
              members={subwidget?.packagemember}
              projects={subwidget?.packageprojects}
              users={subwidget?.packageusers}
              storage={subwidget?.packagestorage}
              more={"+"}
            />
          ))}
        </Slider>
      </div>
      <div className="ogenius-mail-text text-base text-center pt-5">
        {
          props.widget?.body != undefined ? parse(eval(props.widget?.body)) : parse("<p></p>"
        )}
      </div>
    </div>
  );
};

export default WidgetJ;
