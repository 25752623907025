import { updateObject } from "../../utils/updateObject";

import * as actionsTypes from "../actions/actionTypes";

const initialReducer = {
  language: "en",
  homeCarousel: null,
  homeCarouselError: null,
  homeCarouselLoading: false,
  homeCommunicationLinks: [],
  homeCommunicationLinksError: null,
  homeCommunicationLinksLoading: false,
  homeAbout: null,
  homeAboutError: null,
  homeAboutLoading: false,
  homeProduct: null,
  homeProductError: null,
  homeProductLoading: false,
  homePartners: [],
  homePartnersError: null,
  homePartnersLoading: false,
  homeServices: [],
  mainHomeServices: [],
  notMainHomeServices: [],
  homeServicesError: null,
  homeServicesLoading: false,
  homeServicesIntro: null,
  homeServicesIntroError: null,
  homeServicesIntroLoading: false,
  homeTodaysQuote: null,
  homeTodaysQuoteError: null,
  homeTodaysQuoteLoading: false,
  homeFooterSections: [],
  homeFooterSectionsError: null,
  homeFooterSectionsLoading: false,
  homeFooterAddress: null,
  homeFooterAddressError: null,
  homeFooterAddressLoading: false,
  footerSectionItems: [],
  footerSectionItemsError: null,
  footerSectionItemsLoading: false,
};

const changeLanguage = (state, action) => {
  return updateObject(state, {
    language: action.language,
  });
};

const getHomeCarouselStart = (state, action) => {
  return updateObject(state, {
    homeCarousel: null,
    homeCarouselError: null,
    homeCarouselLoading: true,
  });
};

const getHomeCarouselSuccess = (state, action) => {
  return updateObject(state, {
    homeCarousel: action.homeCarousel,
    homeCarouselError: null,
    homeCarouselLoading: false,
  });
};

const getHomeCarouselFail = (state, action) => {
  return updateObject(state, {
    homeCarousel: null,
    homeCarouselError: action.error,
    homeCarouselLoading: false,
  });
};

const getHomeCommunicationLinksStart = (state, action) => {
  return updateObject(state, {
    homeCommunicationLinks: [],
    homeCommunicationLinksError: null,
    homeCommunicationLinksLoading: true,
  });
};

const getHomeCommunicationLinksSuccess = (state, action) => {
  return updateObject(state, {
    homeCommunicationLinks: action.homeCommunicationLinks,
    homeCommunicationLinksError: null,
    homeCommunicationLinksLoading: false,
  });
};

const getHomeCommunicationLinksFail = (state, action) => {
  return updateObject(state, {
    homeCommunicationLinks: [],
    homeCommunicationLinksError: action.error,
    homeCommunicationLinksLoading: false,
  });
};
const getHomeAboutStart = (state, action) => {
  return updateObject(state, {
    homeAbout: null,
    homeAboutError: null,
    homeAboutLoading: true,
  });
};

const getHomeAboutSuccess = (state, action) => {
  return updateObject(state, {
    homeAbout: action.homeAbout,
    homeAboutError: null,
    homeAboutLoading: false,
  });
};

const getHomeAboutFail = (state, action) => {
  return updateObject(state, {
    homeAbout: null,
    homeAboutError: action.error,
    homeAboutLoading: false,
  });
};
const getHomeProductStart = (state, action) => {
  return updateObject(state, {
    homeProduct: null,
    homeProductError: null,
    homeProductLoading: true,
  });
};

const getHomeProductSuccess = (state, action) => {
  return updateObject(state, {
    homeProduct: action.homeProduct,
    homeProductError: null,
    homeProductLoading: false,
  });
};

const getHomeProductFail = (state, action) => {
  return updateObject(state, {
    homeProduct: null,
    homeProductError: action.error,
    homeProductLoading: false,
  });
};
const getHomePartnersStart = (state, action) => {
  return updateObject(state, {
    homePartners: [],
    homePartnersError: null,
    homePartnersLoading: true,
  });
};

const getHomePartnersSuccess = (state, action) => {
  return updateObject(state, {
    homePartners: action.homePartners,
    homePartnersError: null,
    homePartnersLoading: false,
  });
};

const getHomePartnersFail = (state, action) => {
  return updateObject(state, {
    homePartners: [],
    homePartnersError: action.error,
    homePartnersLoading: false,
  });
};
const getHomeServicesIntroStart = (state, action) => {
  return updateObject(state, {
    homeServicesIntro: null,
    homeServicesIntroError: null,
    homeServicesIntroLoading: true,
  });
};

const getHomeServicesIntroSuccess = (state, action) => {
  return updateObject(state, {
    homeServicesIntro: action.homeServicesIntro,
    homeServicesIntroError: null,
    homeServicesIntroLoading: false,
  });
};

const getHomeServicesIntroFail = (state, action) => {
  return updateObject(state, {
    homeServicesIntro: null,
    homeServicesIntroError: action.error,
    homeServicesIntroLoading: false,
  });
};
const getHomeServicesStart = (state, action) => {
  return updateObject(state, {
    homeServices: [],
    mainHomeServices: [],
    notMainHomeServices: [],
    homeServicesError: null,
    homeServicesLoading: true,
  });
};

const getHomeServicesSuccess = (state, action) => {
  return updateObject(state, {
    homeServices: action.homeServices,
    mainHomeServices: [],
    notMainHomeServices: [],
    homeServicesError: null,
    homeServicesLoading: false,
  });
};

const getMainHomeServicesSuccess = (state, action) => {
  return updateObject(state, {
    homeServices: [],
    mainHomeServices: action.mainHomeServices,
    homeServicesError: null,
    homeServicesLoading: false,
  });
};

const getNotMainHomeServicesSuccess = (state, action) => {
  return updateObject(state, {
    homeServices: [],
    notMainHomeServices: action.notMainHomeServices,
    homeServicesError: null,
    homeServicesLoading: false,
  });
};

const getHomeServicesFail = (state, action) => {
  return updateObject(state, {
    homeServices: [],
    mainHomeServices: [],
    notMainHomeServices: [],
    homeServicesError: action.error,
    homeServicesLoading: false,
  });
};
const getHomeTodaysQuoteStart = (state, action) => {
  return updateObject(state, {
    homeTodaysQuote: null,
    homeTodaysQuoteError: null,
    homeTodaysQuoteLoading: true,
  });
};

const getHomeTodaysQuoteSuccess = (state, action) => {
  return updateObject(state, {
    homeTodaysQuote: action.homeTodaysQuote,
    homeTodaysQuoteError: null,
    homeTodaysQuoteLoading: false,
  });
};

const getHomeTodaysQuoteFail = (state, action) => {
  return updateObject(state, {
    homeTodaysQuote: null,
    homeTodaysQuoteError: action.error,
    homeTodaysQuoteLoading: false,
  });
};

const getHomeFooterSectionsStart = (state, action) => {
  return updateObject(state, {
    homeFooterSections: [],
    homeFooterSectionsError: null,
    homeFooterSectionsLoading: true,
  });
};

const getHomeFooterSectionsSuccess = (state, action) => {
  return updateObject(state, {
    homeFooterSections: action.homeFooterSections,
    homeFooterSectionsError: null,
    homeFooterSectionsLoading: false,
  });
};

const getHomeFooterSectionsFail = (state, action) => {
  return updateObject(state, {
    homeFooterSections: [],
    homeFooterSectionsError: action.error,
    homeFooterSectionsLoading: false,
  });
};
const getHomeFooterAddressStart = (state, action) => {
  return updateObject(state, {
    homeFooterAddress: null,
    homeFooterAddressError: null,
    homeFooterAddressLoading: true,
  });
};

const getHomeFooterAddressSuccess = (state, action) => {
  return updateObject(state, {
    homeFooterAddress: action.homeFooterAddress,
    homeFooterAddressError: null,
    homeFooterAddressLoading: false,
  });
};

const getHomeFooterAddressFail = (state, action) => {
  return updateObject(state, {
    homeFooterAddress: null,
    homeFooterAddressError: action.error,
    homeFooterAddressLoading: false,
  });
};

const getFooterSectionItemsStart = (state, action) => {
  return updateObject(state, {
    footerSectionItems: [],
    footerSectionItemsError: null,
    footerSectionItemsLoading: true,
  });
};

const getFooterSectionItemsSuccess = (state, action) => {
  return updateObject(state, {
    footerSectionItems: action.footerSectionItems,
    footerSectionItemsError: null,
    footerSectionItemsLoading: false,
  });
};

const getFooterSectionItemsFail = (state, action) => {
  return updateObject(state, {
    footerSectionItems: [],
    footerSectionItemsError: action.error,
    footerSectionItemsLoading: false,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.CHANGE_LANGUAGE:
      return changeLanguage(state, action);
    case actionsTypes.GET_HOME_CAROUSEL_START:
      return getHomeCarouselStart(state, action);
    case actionsTypes.GET_HOME_CAROUSEL_SUCCESS:
      return getHomeCarouselSuccess(state, action);
    case actionsTypes.GET_HOME_CAROUSEL_FAIL:
      return getHomeCarouselFail(state, action);
    case actionsTypes.GET_HOME_COMMUNICATION_LINKS_START:
      return getHomeCommunicationLinksStart(state, action);
    case actionsTypes.GET_HOME_COMMUNICATION_LINKS_SUCCESS:
      return getHomeCommunicationLinksSuccess(state, action);
    case actionsTypes.GET_HOME_COMMUNICATION_LINKS_FAIL:
      return getHomeCommunicationLinksFail(state, action);
    case actionsTypes.GET_HOME_ABOUT_START:
      return getHomeAboutStart(state, action);
    case actionsTypes.GET_HOME_ABOUT_SUCCESS:
      return getHomeAboutSuccess(state, action);
    case actionsTypes.GET_HOME_ABOUT_FAIL:
      return getHomeAboutFail(state, action);
    case actionsTypes.GET_HOME_PRODUCT_START:
      return getHomeProductStart(state, action);
    case actionsTypes.GET_HOME_PRODUCT_SUCCESS:
      return getHomeProductSuccess(state, action);
    case actionsTypes.GET_HOME_PRODUCT_FAIL:
      return getHomeProductFail(state, action);
    case actionsTypes.GET_HOME_PARTNERS_START:
      return getHomePartnersStart(state, action);
    case actionsTypes.GET_HOME_PARTNERS_SUCCESS:
      return getHomePartnersSuccess(state, action);
    case actionsTypes.GET_HOME_PARTNERS_FAIL:
      return getHomePartnersFail(state, action);
    case actionsTypes.GET_HOME_SERVICES_START:
      return getHomeServicesStart(state, action);
    case actionsTypes.GET_HOME_SERVICES_SUCCESS:
      return getHomeServicesSuccess(state, action);
    case actionsTypes.GET_MAIN_HOME_SERVICES_SUCCESS:
      return getMainHomeServicesSuccess(state, action);
    case actionsTypes.GET_NOT_MAIN_HOME_SERVICES_SUCCESS:
      return getNotMainHomeServicesSuccess(state, action);
    case actionsTypes.GET_HOME_SERVICES_FAIL:
      return getHomeServicesFail(state, action);
    case actionsTypes.GET_HOME_SERVICES_INTRO_START:
      return getHomeServicesIntroStart(state, action);
    case actionsTypes.GET_HOME_SERVICES_INTRO_SUCCESS:
      return getHomeServicesIntroSuccess(state, action);
    case actionsTypes.GET_HOME_SERVICES_INTRO_FAIL:
      return getHomeServicesIntroFail(state, action);
    case actionsTypes.GET_HOME_TODAY_QUOTE_START:
      return getHomeTodaysQuoteStart(state, action);
    case actionsTypes.GET_HOME_TODAY_QUOTE_SUCCESS:
      return getHomeTodaysQuoteSuccess(state, action);
    case actionsTypes.GET_HOME_TODAY_QUOTE_FAIL:
      return getHomeTodaysQuoteFail(state, action);
    case actionsTypes.GET_HOME_FOOTER_SECTIONS_START:
      return getHomeFooterSectionsStart(state, action);
    case actionsTypes.GET_HOME_FOOTER_SECTIONS_SUCCESS:
      return getHomeFooterSectionsSuccess(state, action);
    case actionsTypes.GET_HOME_FOOTER_SECTIONS_FAIL:
      return getHomeFooterSectionsFail(state, action);
    case actionsTypes.GET_FOOTER_SECTION_ITEMS_START:
      return getFooterSectionItemsStart(state, action);
    case actionsTypes.GET_FOOTER_SECTION_ITEMS_SUCCESS:
      return getFooterSectionItemsSuccess(state, action);
    case actionsTypes.GET_FOOTER_SECTION_ITEMS_FAIL:
      return getFooterSectionItemsFail(state, action);
    case actionsTypes.GET_HOME_FOOTER_ADDRESS_START:
      return getHomeFooterAddressStart(state, action);
    case actionsTypes.GET_HOME_FOOTER_ADDRESS_SUCCESS:
      return getHomeFooterAddressSuccess(state, action);
    case actionsTypes.GET_HOME_FOOTER_ADDRESS_FAIL:
      return getHomeFooterAddressFail(state, action);
    default:
      return state;
  }
};
export default reducer;
