import { updateObject } from "../../utils/updateObject";
import * as actionsTypes from "../actions/actionTypes";

const initialReducer = {
  languages: [],
  languagesError: null,
  languagesLoading: false,
  language: null,
};

const getLanguageStart = (state, action) => {
  return updateObject(state, {
    languages: [],
    languagesError: null,
    languagesLoading: true,
    language: null,
  });
};

const getLanguageSuccess = (state, action) => {
  return updateObject(state, {
    languages: action.languages,
    languagesError: null,
    languagesLoading: false,
    language: null,
  });
};

const getLanguageFail = (state, action) => {
  return updateObject(state, {
    languages: [],
    languagesError: action.error,
    languagesLoading: false,
    language: null,
  });
};

const getOneLanguageSuccess = (state, action) => {
  return updateObject(state, {
    languages: [],
    languagesError: null,
    languagesLoading: false,
    language: action.language,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_LANGUAGES_START:
      return getLanguageStart(state, action);
    case actionsTypes.GET_LANGUAGES_SUCCESS:
      return getLanguageSuccess(state, action);
    case actionsTypes.GET_ONE_LANGUAGES_SUCCESS:
      return getOneLanguageSuccess(state, action);
    case actionsTypes.GET_LANGUAGES_FAIL:
      return getLanguageFail(state, action);
    default:
      return state;
  }
};

export default reducer;
