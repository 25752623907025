export const GET_ADMIN_HOME_CAROUSEL_START = "GET_ADMIN_HOME_CAROUSEL_START";
export const GET_ADMIN_HOME_CAROUSEL_SUCCESS =
  "GET_ADMIN_HOME_CAROUSEL_SUCCESS";
export const GET_ADMIN_HOME_CAROUSEL_FAIL = "GET_ADMIN_HOME_CAROUSEL_FAIL";
export const GET_ADMIN_SINGLE_HOME_CAROUSEL_SUCCESS =
  "GET_ADMIN_SINGLE_HOME_CAROUSEL_SUCCESS";

export const GET_ADMIN_HOME_COMMUNICATION_LINKS_START =
  "GET_ADMIN_HOME_COMMUNICATION_LINKS_START";
export const GET_ADMIN_HOME_COMMUNICATION_LINKS_SUCCESS =
  "GET_ADMIN_HOME_COMMUNICATION_LINKS_SUCCESS";
export const GET_ONE_ADMIN_HOME_COMMUNICATION_LINKS_SUCCESS =
  "GET_ONE_ADMIN_HOME_COMMUNICATION_LINKS_SUCCESS";
export const GET_ADMIN_HOME_COMMUNICATION_LINKS_FAIL =
  "GET_ADMIN_HOME_COMMUNICATION_LINKS_FAIL";
export const GET_ADMIN_HOME_ABOUT_START = "GET_ADMIN_HOME_ABOUT_START";
export const GET_ADMIN_HOME_ABOUT_SUCCESS = "GET_ADMIN_HOME_ABOUT_SUCCESS";
export const GET_ONE_ADMIN_HOME_ABOUT_SUCCESS =
  "GET_ONE_ADMIN_HOME_ABOUT_SUCCESS";
export const GET_ADMIN_HOME_ABOUT_FAIL = "GET_ADMIN_HOME_ABOUT_FAIL";
export const GET_ADMIN_HOME_PRODUCT_START = "GET_ADMIN_HOME_PRODUCT_START";
export const GET_ADMIN_HOME_PRODUCT_SUCCESS = "GET_ADMIN_HOME_PRODUCT_SUCCESS";
export const GET_ONE_ADMIN_HOME_PRODUCT_SUCCESS =
  "GET_ONE_ADMIN_HOME_PRODUCT_SUCCESS";
export const GET_ADMIN_HOME_PRODUCT_FAIL = "GET_ADMIN_HOME_PRODUCT_FAIL";
export const GET_ADMIN_HOME_PARTNERS_START = "GET_ADMIN_HOME_PARTNERS_START";
export const GET_ADMIN_HOME_PARTNERS_SUCCESS =
  "GET_ADMIN_HOME_PARTNERS_SUCCESS";
export const GET_ONE_ADMIN_HOME_PARTNERS_SUCCESS =
  "GET_ONE_ADMIN_HOME_PARTNERS_SUCCESS";
export const GET_ADMIN_HOME_PARTNERS_FAIL = "GET_ADMIN_HOME_PARTNERS_FAIL";
export const GET_ADMIN_HOME_SERVICES_INTRO_START =
  "GET_ADMIN_HOME_SERVICES_INTRO_START";
export const GET_ADMIN_HOME_SERVICES_INTRO_SUCCESS =
  "GET_ADMIN_HOME_SERVICES_INTRO_SUCCESS";
export const GET_ONE_ADMIN_HOME_SERVICES_INTRO_SUCCESS =
  "GET_ONE_ADMIN_HOME_SERVICES_INTRO_SUCCESS";
export const GET_ADMIN_HOME_SERVICES_INTRO_FAIL =
  "GET_ADMIN_HOME_SERVICES_INTRO_FAIL";
export const GET_ADMIN_HOME_SERVICES_START = "GET_ADMIN_HOME_SERVICES_START";
export const GET_ADMIN_HOME_SERVICES_SUCCESS =
  "GET_ADMIN_HOME_SERVICES_SUCCESS";
export const GET_ONE_ADMIN_HOME_SERVICES_SUCCESS =
  "GET_ONE_ADMIN_HOME_SERVICES_SUCCESS";
export const GET_ADMIN_HOME_SERVICES_FAIL = "GET_ADMIN_HOME_SERVICES_FAIL";
export const GET_ADMIN_HOME_TODAY_QUOTE_START =
  "GET_ADMIN_HOME_TODAY_QUOTE_START";
export const GET_ADMIN_HOME_TODAY_QUOTE_SUCCESS =
  "GET_ADMIN_HOME_TODAY_QUOTE_SUCCESS";
export const GET_ONE_ADMIN_HOME_TODAY_QUOTE_SUCCESS =
  "GET_ONE_ADMIN_HOME_TODAY_QUOTE_SUCCESS";
export const GET_ADMIN_HOME_TODAY_QUOTE_FAIL =
  "GET_ADMIN_HOME_TODAY_QUOTE_FAIL";
export const GET_ADMIN_HOME_FOOTER_SECTIONS_START =
  "GET_ADMIN_HOME_FOOTER_SECTIONS_START";
export const GET_ADMIN_HOME_FOOTER_SECTIONS_SUCCESS =
  "GET_ADMIN_HOME_FOOTER_SECTIONS_SUCCESS";
export const GET_ONE_ADMIN_HOME_FOOTER_SECTIONS_SUCCESS =
  "GET_ONE_ADMIN_HOME_FOOTER_SECTIONS_SUCCESS";
export const GET_ADMIN_HOME_FOOTER_SECTIONS_FAIL =
  "GET_ADMIN_HOME_FOOTER_SECTIONS_FAIL";
export const GET_ADMIN_HOME_FOOTER_ADDRESS_START =
  "GET_ADMIN_HOME_FOOTER_ADDRESS_START";
export const GET_ADMIN_HOME_FOOTER_ADDRESS_SUCCESS =
  "GET_ADMIN_HOME_FOOTER_ADDRESS_SUCCESS";
export const GET_ONE_ADMIN_HOME_FOOTER_ADDRESS_SUCCESS =
  "GET_ONE_ADMIN_HOME_FOOTER_ADDRESS_SUCCESS";
export const GET_ADMIN_HOME_FOOTER_ADDRESS_FAIL =
  "GET_ADMIN_HOME_FOOTER_ADDRESS_FAIL";
export const GET_ADMIN_HOME_FOOTER_SECTION_ITEMS_START =
  "GET_ADMIN_HOME_FOOTER_SECTION_ITEMS_START";
export const GET_ADMIN_HOME_FOOTER_SECTION_ITEMS_SUCCESS =
  "GET_ADMIN_HOME_FOOTER_SECTION_ITEMS_SUCCESS";
export const GET_ONE_ADMIN_HOME_FOOTER_SECTION_ITEMS_SUCCESS =
  "GET_ONE_ADMIN_HOME_FOOTER_SECTION_ITEMS_SUCCESS";
export const GET_ADMIN_HOME_FOOTER_SECTION_ITEMS_FAIL =
  "GET_ADMIN_HOME_FOOTER_SECTION_ITEMS_FAIL";
  export const GET_ADMIN_COMMUNICATION_LINKS_START =
  "GET_ADMIN_COMMUNICATION_LINKS_START";
export const GET_ADMIN_COMMUNICATION_LINKS_SUCCESS =
  "GET_ADMIN_COMMUNICATION_LINKS_SUCCESS";
export const GET_ONE_ADMIN_COMMUNICATION_LINKS_SUCCESS =
  "GET_ONE_ADMIN_COMMUNICATION_LINKS_SUCCESS";
export const GET_ADMIN_COMMUNICATION_LINKS_FAIL =
  "GET_ADMIN_COMMUNICATION_LINKS_FAIL";

export const GET_ADMIN_CONTACT_US_START = "GET_ADMIN_CONTACT_US_START";
export const GET_ADMIN_CONTACT_US_SUCCESS = "GET_ADMIN_CONTACT_US_SUCCESS";
export const GET_ONE_ADMIN_CONTACT_US_SUCCESS =
  "GET_ONE_ADMIN_CONTACT_US_SUCCESS";
export const GET_ADMIN_CONTACT_US_FAIL = "GET_ADMIN_CONTACT_US_FAIL";
export const GET_ADMIN_QUICK_LINKS_START = "GET_ADMIN_QUICK_LINKS_START";
export const GET_ADMIN_QUICK_LINKS_SUCCESS = "GET_ADMIN_QUICK_LINKS_SUCCESS";
export const GET_ONE_ADMIN_QUICK_LINKS_SUCCESS =
  "GET_ONE_ADMIN_QUICK_LINKS_SUCCESS";
export const GET_ADMIN_QUICK_LINKS_FAIL = "GET_ADMIN_QUICK_LINKS_FAIL";
export const GET_ADMIN_FAQS_START = "GET_ADMIN_FAQS_START";
export const GET_ADMIN_FAQS_SUCCESS = "GET_ADMIN_FAQS_SUCCESS";
export const GET_ONE_ADMIN_FAQS_SUCCESS = "GET_ONE_ADMIN_FAQS_SUCCESS";
export const GET_ADMIN_FAQS_FAIL = "GET_ADMIN_FAQS_FAIL";
export const ADMIN_MESSAGE_START = "ADMIN_MESSAGE_START";
export const ADMIN_MESSAGE_SUCCESS = "ADMIN_MESSAGE_SUCCESS";
export const ONE_ADMIN_MESSAGE_SUCCESS = "ONE_ADMIN_MESSAGE_SUCCESS";
export const ADMIN_MESSAGE_FAIL = "ADMIN_MESSAGE_FAIL";

export const GET_ADMIN_PARTNERS_START = "GET_ADMIN_PARTNERS_START";
export const GET_ADMIN_PARTNERS_SUCCESS = "GET_ADMIN_PARTNERS_SUCCESS";
export const GET_ONE_ADMIN_PARTNERS_SUCCESS = "GET_ONE_ADMIN_PARTNERS_SUCCESS";
export const GET_ADMIN_PARTNERS_FAIL = "GET_ADMIN_PARTNERS_FAIL";
export const GET_ADMIN_PARTNERS_INTRO_START = "GET_ADMIN_PARTNERS_INTRO_START";
export const GET_ADMIN_PARTNERS_INTRO_SUCCESS =
  "GET_ADMIN_PARTNERS_INTRO_SUCCESS";
export const GET_ONE_ADMIN_PARTNERS_INTRO_SUCCESS =
  "GET_ONE_ADMIN_PARTNERS_INTRO_SUCCESS";
export const GET_ADMIN_PARTNERS_INTRO_FAIL = "GET_ADMIN_PARTNERS_INTRO_FAIL";
export const GET_ADMIN_AFFILIATIONS_START = "GET_ADMIN_AFFILIATIONS_START";
export const GET_ADMIN_AFFILIATIONS_SUCCESS = "GET_ADMIN_AFFILIATIONS_SUCCESS";
export const GET_ONE_ADMIN_AFFILIATIONS_SUCCESS =
  "GET_ONE_ADMIN_AFFILIATIONS_SUCCESS";
export const GET_ADMIN_AFFILIATIONS_FAIL = "GET_ADMIN_AFFILIATIONS_FAIL";

export const GET_ADMIN_PUBLICATIONS_START = "GET_ADMIN_PUBLICATIONS_START";
export const GET_ADMIN_PUBLICATIONS_SUCCESS = "GET_ADMIN_PUBLICATIONS_SUCCESS";
export const GET_ONE_ADMIN_PUBLICATIONS_SUCCESS =
  "GET_ONE_ADMIN_PUBLICATIONS_SUCCESS";
export const GET_ADMIN_PUBLICATIONS_FAIL = "GET_ADMIN_PUBLICATIONS_FAIL";
export const GET_ADMIN_NEWSLETTER_START = "GET_ADMIN_NEWSLETTER_START";
export const GET_ADMIN_NEWSLETTER_SUCCESS = "GET_ADMIN_NEWSLETTER_SUCCESS";
export const GET_ONE_ADMIN_NEWSLETTER_SUCCESS =
  "GET_ONE_ADMIN_NEWSLETTER_SUCCESS";
export const GET_ADMIN_NEWSLETTER_FAIL = "GET_ADMIN_NEWSLETTER_FAIL";
export const GET_ADMIN_EVENTS_START = "GET_ADMIN_EVENTS_START";
export const GET_ADMIN_EVENTS_SUCCESS = "GET_ADMIN_EVENTS_SUCCESS";
export const GET_ONE_ADMIN_EVENTS_SUCCESS = "GET_ONE_ADMIN_EVENTS_SUCCESS";
export const GET_ADMIN_EVENTS_FAIL = "GET_ADMIN_EVENTS_FAIL";
export const GET_ADMIN_MEDIA_COVERAGE_START = "GET_ADMIN_MEDIA_COVERAGE_START";
export const GET_ADMIN_MEDIA_COVERAGE_SUCCESS =
  "GET_ADMIN_MEDIA_COVERAGE_SUCCESS";
export const GET_ONE_ADMIN_MEDIA_COVERAGE_SUCCESS =
  "GET_ONE_ADMIN_MEDIA_COVERAGE_SUCCESS";
export const GET_ADMIN_MEDIA_COVERAGE_FAIL = "GET_ADMIN_MEDIA_COVERAGE_FAIL";
export const GET_ADMIN_GIFTS_AND_FREEBIES_START =
  "GET_ADMIN_GIFTS_AND_FREEBIES_START";
export const GET_ADMIN_GIFTS_AND_FREEBIES_SUCCESS =
  "GET_ADMIN_GIFTS_AND_FREEBIES_SUCCESS";
export const GET_ONE_ADMIN_GIFTS_AND_FREEBIES_SUCCESS =
  "GET_ONE_ADMIN_GIFTS_AND_FREEBIES_SUCCESS";
export const GET_ADMIN_GIFTS_AND_FREEBIES_FAIL =
  "GET_ADMIN_GIFTS_AND_FREEBIES_FAIL";
export const GET_ADMIN_TODAY_AT_OGENIUS_START =
  "GET_ADMIN_TODAY_AT_OGENIUS_START";
export const GET_ADMIN_TODAY_AT_OGENIUS_SUCCESS =
  "GET_ADMIN_TODAY_AT_OGENIUS_SUCCESS";
export const GET_ONE_ADMIN_TODAY_AT_OGENIUS_SUCCESS =
  "GET_ONE_ADMIN_TODAY_AT_OGENIUS_SUCCESS";
export const GET_ADMIN_TODAY_AT_OGENIUS_FAIL =
  "GET_ADMIN_TODAY_AT_OGENIUS_FAIL";

export const GET_ADMIN_EXCUTIF_LEADERSHIP_START =
  "GET_ADMIN_EXCUTIF_LEADERSHIP_START";
export const GET_ADMIN_EXCUTIF_LEADERSHIP_SUCCESS =
  "GET_ADMIN_EXCUTIF_LEADERSHIP_SUCCESS";
export const GET_ONE_ADMIN_EXCUTIF_LEADERSHIP_SUCCESS =
  "GET_ONE_ADMIN_EXCUTIF_LEADERSHIP_SUCCESS";
export const GET_ADMIN_EXCUTIF_LEADERSHIP_FAIL =
  "GET_ADMIN_EXCUTIF_LEADERSHIP_FAIL";
export const GET_ADMIN_CAREER_START = "GET_ADMIN_CAREER_START";
export const GET_ADMIN_CAREER_SUCCESS = "GET_ADMIN_CAREER_SUCCESS";
export const GET_ONE_ADMIN_CAREER_SUCCESS = "GET_ONE_ADMIN_CAREER_SUCCESS";
export const GET_ADMIN_CAREER_FAIL = "GET_ADMIN_CAREER_FAIL";
export const GET_ADMIN_EXCUTIF_LEADERS_START =
  "GET_ADMIN_EXCUTIF_LEADERS_START";
export const GET_ADMIN_EXCUTIF_LEADERS_SUCCESS =
  "GET_ADMIN_EXCUTIF_LEADERS_SUCCESS";
export const GET_ONE_ADMIN_EXCUTIF_LEADERS_SUCCESS =
  "GET_ONE_ADMIN_EXCUTIF_LEADERS_SUCCESS";
export const GET_ADMIN_EXCUTIF_LEADERS_FAIL = "GET_ADMIN_EXCUTIF_LEADERS_FAIL";
export const GET_ADMIN_STAFF_START = "GET_ADMIN_STAFF_START";
export const GET_ADMIN_STAFF_SUCCESS = "GET_ADMIN_STAFF_SUCCESS";
export const GET_ONE_ADMIN_STAFF_SUCCESS = "GET_ONE_ADMIN_STAFF_SUCCESS";
export const GET_ADMIN_STAFF_FAIL = "GET_ADMIN_STAFF_FAIL";
export const GET_ADMIN_STAFF_MEMBERS_START = "GET_ADMIN_STAFF_MEMBERS_START";
export const GET_ADMIN_STAFF_MEMBERS_SUCCESS =
  "GET_ADMIN_STAFF_MEMBERS_SUCCESS";
export const GET_ONE_ADMIN_STAFF_MEMBERS_SUCCESS =
  "GET_ONE_ADMIN_STAFF_MEMBERS_SUCCESS";
export const GET_ADMIN_STAFF_MEMBERS_FAIL = "GET_ADMIN_STAFF_MEMBERS_FAIL";
export const GET_ADMIN_AWARDS_INTRO_START = "GET_ADMIN_AWARDS_INTRO_START";
export const GET_ONE_ADMIN_AWARDS_INTRO_SUCCESS =
  "GET_ONE_ADMIN_AWARDS_INTRO_SUCCESS";
export const GET_ADMIN_AWARDS_INTRO_SUCCESS = "GET_ADMIN_AWARDS_INTRO_SUCCESS";
export const GET_ADMIN_AWARDS_INTRO_FAIL = "GET_ADMIN_AWARDS_INTRO_FAIL";
export const GET_ADMIN_AWARDS_START = "GET_ADMIN_AWARDS_START";
export const GET_ADMIN_AWARDS_SUCCESS = "GET_ADMIN_AWARDS_SUCCESS";
export const GET_ONE_ADMIN_AWARDS_SUCCESS = "GET_ONE_ADMIN_AWARDS_SUCCESS";
export const GET_ADMIN_AWARDS_FAIL = "GET_ADMIN_AWARDS_FAIL";
export const GET_ADMIN_ETHICS_COMPLIANCE_START =
  "GET_ADMIN_ETHICS_COMPLIANCE_START";
export const GET_ONE_ADMIN_ETHICS_COMPLIANCE_SUCCESS =
  "GET_ONE_ADMIN_ETHICS_COMPLIANCE_SUCCESS";
export const GET_ADMIN_ETHICS_COMPLIANCE_SUCCESS =
  "GET_ADMIN_ETHICS_COMPLIANCE_SUCCESS";
export const GET_ADMIN_ETHICS_COMPLIANCE_FAIL =
  "GET_ADMIN_ETHICS_COMPLIANCE_FAIL";
export const GET_ADMIN_GET_IN_TOUCH_START = "GET_ADMIN_GET_IN_TOUCH_START";
export const GET_ADMIN_GET_IN_TOUCH_SUCCESS = "GET_ADMIN_GET_IN_TOUCH_SUCCESS";
export const GET_ONE_ADMIN_GET_IN_TOUCH_SUCCESS =
  "GET_ONE_ADMIN_GET_IN_TOUCH_SUCCESS";
export const GET_ADMIN_GET_IN_TOUCH_FAIL = "GET_ADMIN_GET_IN_TOUCH_FAIL";

export const GET_ADMIN_SERVICES_START = "GET_ADMIN_SERVICES_START";
export const GET_ADMIN_SERVICES_SUCCESS = "GET_ADMIN_SERVICES_SUCCESS";
export const GET_ONE_ADMIN_SERVICES_SUCCESS = "GET_ONE_ADMIN_SERVICES_SUCCESS";
export const GET_ADMIN_SERVICES_FAIL = "GET_ADMIN_SERVICES_FAIL";

export const GET_ADMIN_SERVICE_WIDGETS_START =
  "GET_ADMIN_SERVICE_WIDGETS_START";
export const GET_ADMIN_SERVICE_WIDGETS_SUCCESS =
  "GET_ADMIN_SERVICE_WIDGETS_SUCCESS";
export const GET_ONE_ADMIN_SERVICE_WIDGETS_SUCCESS =
  "GET_ONE_ADMIN_SERVICE_WIDGETS_SUCCESS";
export const GET_ADMIN_SERVICE_WIDGETS_FAIL = "GET_ADMIN_SERVICE_WIDGETS_FAIL";

export const GET_ADMIN_PRODUCTS_START = "GET_ADMIN_PRODUCTS_START";
export const GET_ADMIN_PRODUCTS_SUCCESS = "GET_ADMIN_PRODUCTS_SUCCESS";
export const GET_ONE_ADMIN_PRODUCTS_SUCCESS = "GET_ONE_ADMIN_PRODUCTS_SUCCESS";
export const GET_ADMIN_PRODUCTS_FAIL = "GET_ADMIN_PRODUCTS_FAIL";

export const GET_ADMIN_PRODUCT_WIDGETS_START =
  "GET_ADMIN_PRODUCT_WIDGETS_START";
export const GET_ADMIN_PRODUCT_WIDGETS_SUCCESS =
  "GET_ADMIN_PRODUCT_WIDGETS_SUCCESS";
export const GET_ONE_ADMIN_PRODUCT_WIDGETS_SUCCESS =
  "GET_ONE_ADMIN_PRODUCT_WIDGETS_SUCCESS";
export const GET_ADMIN_PRODUCT_WIDGETS_FAIL = "GET_ADMIN_PRODUCT_WIDGETS_FAIL";

export const GET_TESTIMONIALS_START = "GET_TESTIMONIALS_START";
export const GET_TESTIMONIALS_SUCCESS = "GET_TESTIMONIALS_SUCCESS";
export const GET_ONE_TESTIMONIALS_SUCCESS = "GET_ONE_TESTIMONIALS_SUCCESS";
export const GET_TESTIMONIALS_FAIL = "GET_TESTIMONIALS_FAIL";

export const GET_ADMIN_SOCIAL_MEDIA_LINK_START =
  "GET_ADMIN_SOCIAL_MEDIA_LINK_START";
export const GET_ADMIN_SOCIAL_MEDIA_LINK_SUCCESS =
  "GET_ADMIN_SOCIAL_MEDIA_LINK_SUCCESS";
export const GET_ONE_ADMIN_SOCIAL_MEDIA_LINK_SUCCESS =
  "GET_ONE_ADMIN_SOCIAL_MEDIA_LINK_SUCCESS";
export const GET_ADMIN_SOCIAL_MEDIA_LINK_FAIL =
  "GET_ADMIN_SOCIAL_MEDIA_LINK_FAIL";
