import { Button, SelectButton, TableLink } from "../components/Buttons";
import {
  InputText,
  InputTextArea,
  InsertingLanguagesTable,
  LinkComponent,
  MoreDropDown,
  SelectRadioButton,
  ShowOrHideLinks,
  Uploading,
} from "./AdminComponentsList";
import moment from "moment";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import AsyncSelect from "react-select/async";
import {ReactNotifications} from "react-notifications-component";
import { useParams } from "react-router";
import contants, {
  successNotification,
  confirmNotification,
  successUpdateNotification,
} from "./contants";
import classnames from "classnames";
import * as adminActionTypes from "../store/actions/adminIndex";
import richTextConstants from "./richtextconstants";

import dotenv from "dotenv";
dotenv.config();
const  ReactNotification=ReactNotifications


export const AdminContactUs = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const links = useSelector((state) => state.links.links);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const object = useSelector((state) => state.adminContact.contactUs);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const [title, settitle] = useState(object != null ? object?.title : "");
  const [address, setaddress] = useState(object != null ? object?.address : "");
  const [mobilenumber, setmobilenumber] = useState(
    object != null ? object?.mobilenumber : ""
  );
  const [phonenumber, setphonenumber] = useState(
    object != null ? object?.phonenumber : ""
  );
  const [email, setemail] = useState(object != null ? object?.email : "");
  const [image, setimage] = useState("");

  const selectImage = (event) => {
    setimage(event.target.files[0]);
  };

  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(actionTypes.getLinks());
    dispatch(adminActionTypes.getOneAdminContactUs(params.objectId));
  }, [dispatch]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      email === "" ||
      title === "" ||
      mobilenumber === "" ||
      address === "" ||
      phonenumber === ""
    ) {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            email,
            title,
            mobilenumber,
            address,
            phonenumber,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminContactUs(obj));
          successUpdateNotification("Contact Us", contants.UPDATE);
          settitle("");
          setaddress("");
          setmobilenumber("");
          setphonenumber("");
          setemail("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              title,
              address,
              mobilenumber,
              phonenumber,
              email,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Contact Us", languageName);
          settitle("");
          setaddress("");
          setmobilenumber("");
          setphonenumber("");
          setemail("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setaddress(address);
          setmobilenumber(mobilenumber);
          setphonenumber(phonenumber);
          setemail(email);
          setdisabled(true);
        }
        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("image", image);
          fd.append("objects", JSON.stringify(objects));
          dispatch(actionTypes.createContactUs(fd, token));
          settitle("");
          setaddress("");
          setmobilenumber("");
          setphonenumber("");
          setemail("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Contact Us (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Contact Us (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Title"
                  value={title}
                  isClicked={clicked}
                  name="title"
                  onChange={(e) => settitle(e.target.value)}
                />
                <InputText
                  title="Address"
                  value={address}
                  isClicked={clicked}
                  name="address"
                  onChange={(e) => setaddress(e.target.value)}
                />
                <InputText
                  title="Mobile number"
                  value={mobilenumber}
                  isClicked={clicked}
                  name="mobilenumber"
                  onChange={(e) => setmobilenumber(e.target.value)}
                />
                <InputText
                  title="Phone number"
                  value={phonenumber}
                  isClicked={clicked}
                  name="phonenumber"
                  onChange={(e) => setphonenumber(e.target.value)}
                />
                <Uploading
                  updating={object != null ? contants.isTrue : contants.isFalse}
                  title="Image"
                  text1="Upload Image"
                  text2="View the Image"
                  name="image"
                  required
                  onChange={selectImage}
                />
                <InputText
                  title="Email"
                  value={email}
                  isClicked={clicked}
                  name="email"
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/contact-us/contacts/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const QuickLinks = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const platfomLinks = useSelector((state) => state.links.platfomLinks);
  const genericLinks = useSelector((state) => state.links.genericLinks);
  const links = useSelector((state) => state.links.links);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.adminContact.quickLink);
  const params = useParams();
  const linksLoading = useSelector((state) => state.links.linksLoading);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(actionTypes.getAdminPlatfomLinks());
    dispatch(actionTypes.getAdminGenericLinks());
    dispatch(adminActionTypes.getOneAdminQuickLinks(params.objectId));
  }, [dispatch]);

  const [title, settitle] = useState(object != null ? object?.title : "");
  const [description, setdescription] = useState(
    object != null ? eval(object?.description) : ""
  );

  //SHOWING SITE LINKS
  const [sitelinks, setsitelinks] = useState(false);
  const showOrHideSiteLinks = () => {
    setsitelinks(!sitelinks);
  };

  // SELECT BUTTO IMPLEMENTATION
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  // ON SUBMIT FUNCTION

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || description === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            description: richtext.editorValue,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminQuickLinks(obj));
          successUpdateNotification("Quick Link", contants.UPDATE);
          settitle("");
          setdescription("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const id = selectedValue.uuid;
          setObjects([
            ...objects,
            {
              title,
              description: richtext.editorValue,
              link: id,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Quick Link", languageName);
          settitle("");
          setdescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setdescription(title);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(actionTypes.createQuickLink(objects, token));
          setcounter(0);
          setObjects([]);
          settitle("");
          setdescription("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-3">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Quick Link (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Quick Link (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Title"
                  value={title}
                  isClicked={clicked}
                  name="title"
                  onChange={(e) => settitle(e.target.value)}
                />
                <InputTextArea
                  title="Description"
                  data={description}
                  isClicked={clicked}
                  name="description"
                  onChange={(event) => {
                    setdescription(event);
                  }}
                />
                <div className="pt-5 flex flex-col">
                  <ShowOrHideLinks
                    title={"Select From Site Links"}
                    name={"showLinks"}
                    option1="True"
                    option2="False"
                    value={sitelinks}
                    onChange={showOrHideSiteLinks}
                  />
                  <div
                    className={
                      sitelinks === true ? "pt-3 w-full block" : "hidden"
                    }
                  >
                    <label className="input-title">Platfom Links</label>
                    <div className="flex">
                      <AsyncSelect
                        placeholder="Select Platfom Link"
                        className={classnames("mb-2 w-90%", {
                          "mb-2 w-90% border-2 border-red-500":
                            selectedValue === "" && clicked === true,
                        })}
                        cacheOptions
                        defaultOptions={platfomLinks}
                        getOptionLabel={(e) =>
                          e.name + "  (" + e.language + ")"
                        }
                        getOptionValue={(e) => e.uuid}
                        value={selectedValue}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        isSearchable
                      />
                    </div>
                    <label className="block text-red-500">
                      {selectedValue === "" && clicked === true
                        ? "Select Link"
                        : ""}
                    </label>
                  </div>
                </div>
                <div
                  className={
                    sitelinks !== true ? "pt-3 w-full block" : "hidden"
                  }
                >
                  <label className="input-title">Custom Link</label>
                  <div className="flex">
                    <AsyncSelect
                      placeholder="Select Link"
                      className={classnames("mb-2 w-90%", {
                        "mb-2 w-90% border-2 border-red-500":
                          selectedValue === "" && clicked === true,
                      })}
                      cacheOptions
                      defaultOptions={genericLinks}
                      getOptionLabel={(e) => e.name + "  (" + e.language + ")"}
                      getOptionValue={(e) => e.uuid}
                      value={selectedValue}
                      onChange={handleChange}
                      onInputChange={handleInputChange}
                      isSearchable
                    />
                  </div>
                  <label className="block text-red-500">
                    {selectedValue === "" && clicked === true
                      ? "Select Link"
                      : ""}
                  </label>
                </div>
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/contact-us/quick-links/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const FAQs = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminFaqs(params.objectId));
  }, [dispatch]);
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.adminContact.faq);
  const params = useParams();
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );

  const [answer, setanswer] = useState(
    object != null ? eval(object?.answer) : ""
  );
  const [question, setquestion] = useState(
    object != null ? eval(object?.question) : ""
  );
  const [calltoactionname, setcalltoactionname] = useState(
    object != null ? object?.calltoactionname : ""
  );
  const [calltoactionlink, setcalltoactionlink] = useState(
    object != null ? object?.calltoactionlink : ""
  );

  // ON SUBMIT FUNCTION

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      question === "" ||
      calltoactionlink === "" ||
      calltoactionname === "" ||
      answer === ""
    ) {
      setclicked(true);
    } else {
      const richtextQuestion =
        richTextConstants.separateTextAndFiles("question");
      const richtextAnswer = richTextConstants.separateTextAndFiles("answer");
      richtextQuestion.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      richtextAnswer.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            answer: richtextAnswer.editorValue,
            question: richtextQuestion.editorValue,
            calltoactionlink,
            calltoactionname,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminFaqs(obj));
          successUpdateNotification("FAQ", contants.UPDATE);
          setquestion("");
          setanswer("");
          setcalltoactionname("");
          setcalltoactionlink("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              answer: richtextAnswer.editorValue,
              question: richtextQuestion.editorValue,
              calltoactionlink,
              calltoactionname,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("FAQ", languageName);
          setquestion("");
          setanswer("");
          setcalltoactionname("");
          setcalltoactionlink("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setquestion(question);
          setanswer(answer);
          setcalltoactionname(calltoactionname);
          setcalltoactionlink(calltoactionlink);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(actionTypes.createFAQ(objects, token));
          setcounter(0);
          setObjects([]);
          setquestion("");
          setanswer("");
          setcalltoactionname("");
          setcalltoactionlink("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-3">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New FAQ (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    FAQ (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputTextArea
                  id="question"
                  rows={2}
                  title="Question"
                  data={question}
                  isClicked={clicked}
                  name="question"
                  onChange={(event) => {
                    setquestion(event);
                  }}
                />
                <InputTextArea
                  rows={3}
                  title="Answer"
                  id="answer"
                  data={answer}
                  isClicked={clicked}
                  name="answer"
                  onChange={(event) => {
                    setanswer(event);
                  }}
                />
                <InputText
                  title="Call to Action Name"
                  value={calltoactionname}
                  name="calltoactionname"
                  isClicked={clicked}
                  onChange={(e) => setcalltoactionname(e.target.value)}
                />
                <InputText
                  title={"Call to Action Link"}
                  value={calltoactionlink}
                  isClicked={clicked}
                  name="calltoactionlink"
                  onChange={(e) => setcalltoactionlink(e.target.value)}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/contact-us/faqs/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
