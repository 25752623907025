import { Button, SelectButton, TableLink } from "../components/Buttons";
import "./admin.css";
import {
  DeleteButton,
  EditButton,
  InputText,
  InputTextArea,
  LinkComponent,
  MoreDropDown,
  PreviewButton,
  Uploading,
} from "./AdminComponentsList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import AsyncSelect from "react-select/async";
import { successNotification, confirmNotification } from "./contants";
import {ReactNotifications} from "react-notifications-component";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { MyDataTable } from "./MyDataTable";
import * as adminActionTypes from "../store/actions/adminIndex";
import moment from "moment";
const  ReactNotification=ReactNotifications


export const ContactUsTable = () => {
  const dispatch = useDispatch();
  const contactus = useSelector((state) => state.adminContact.adminContactUs);
  const contactusLoading = useSelector(
    (state) => state.adminContact.adminContactUsLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminContactUs());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {contactusLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">CONTACT US</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Address
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Mobile Number
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Laguage
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contactus.map((contact, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {contact.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {contact.address}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {contact.mobilenumber}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(contact.updatedAt).format("DD-MM-YYYY")}
                        </td>

                        <td className="border border-blue-400 px-4 py-2">
                          {contact.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {contact.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {contact.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={contact.uuid}
                              link="/admin/contact-us/contacts/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Contact"
                  type="addLink"
                  link="/admin/contact-us/contacts"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const QuickLinksTable = () => {
  const dispatch = useDispatch();
  const qucikLinks = useSelector((state) => state.adminContact.adminQuickLinks);
  const qucikLinksLoading = useSelector(
    (state) => state.adminContact.adminQuickLinksLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminQuickLinks());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {qucikLinksLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">Quick Links</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>

                      <th className="border border-blue-400 px-4 py-2">
                        Laguage
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {qucikLinks.map((link, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {link.title}
                        </td>

                        <td className="border border-blue-400 px-4 py-2">
                          {link.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {link.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(link.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {link.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={link.uuid}
                              link="/admin/contact-us/quick-links/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Quick Link"
                  type="addLink"
                  link="/admin/contact-us/quick-links"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const FAQsTable = () => {
  const dispatch = useDispatch();
  const faqs = useSelector((state) => state.adminContact.adminFaqs);
  const faqsLoading = useSelector(
    (state) => state.adminContact.adminFaqsLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminFaqs());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {faqsLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">FAQS</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Call to Action Name
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Call to Action Link
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {faqs.map((faq, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {faq.calltoactionname}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {faq.calltoactionlink}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(faq.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {faq.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {faq.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={faq.uuid}
                              link="/admin/contact-us/faqs/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New FAQ"
                  type="addLink"
                  link="/admin/contact-us/faqs"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const MessagesTable = () => {
  const [replied, setreplied] = useState(true);
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.adminContact.adminMessage);
  const messagesLoading = useSelector(
    (state) => state.adminContact.adminMessageLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminMessage());
  }, [dispatch]);

  const viewMessages = () => {
    setreplied(!replied);
    dispatch(adminActionTypes.getAdminMessageByReply(replied));
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {messagesLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">MESSAGES</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Name</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Email
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Replied
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {messages.map((message, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {message.name}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {message.email}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {message.replied}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(message.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={message.uuid}
                              link="/admin/messages/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <Button
                  onClick={viewMessages}
                  name={
                    replied === true ? "Replied Messages" : "Unreplied Messages"
                  }
                  type="square"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
