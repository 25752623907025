import axios from "../../axios-base";
import * as adminActionTypes from "./adminActionTypes.js";
import * as notifications from "../../admin/contants";

export const getAdminServicesStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_SERVICES_START,
  };
};

export const getAdminServicesSuccess = (adminServices) => {
  return {
    type: adminActionTypes.GET_ADMIN_SERVICES_SUCCESS,
    adminServices: adminServices,
  };
};

export const getOneAdminServicesSuccess = (service) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_SERVICES_SUCCESS,
    service: service,
  };
};

export const getAdminServicesFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_SERVICES_FAIL,
    error: error,
  };
};

export const getAdminServiceWidgetsStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_SERVICE_WIDGETS_START,
  };
};

export const getAdminServiceWidgetsSuccess = (adminServiceWidgets) => {
  return {
    type: adminActionTypes.GET_ADMIN_SERVICE_WIDGETS_SUCCESS,
    adminServiceWidgets: adminServiceWidgets,
  };
};

export const getOneAdminServiceWidgetsSuccess = (serviceWidget) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_SERVICE_WIDGETS_SUCCESS,
    serviceWidget: serviceWidget,
  };
};

export const getAdminServiceWidgetsFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_SERVICE_WIDGETS_FAIL,
    error: error,
  };
};

export const getTestimonialsStart = () => {
  return {
    type: adminActionTypes.GET_TESTIMONIALS_START,
  };
};

export const getTestimonialsSuccess = (testimonials) => {
  return {
    type: adminActionTypes.GET_TESTIMONIALS_SUCCESS,
    testimonials: testimonials,
  };
};

export const getOneTestimonialsSuccess = (testimonial) => {
  return {
    type: adminActionTypes.GET_ONE_TESTIMONIALS_SUCCESS,
    testimonial: testimonial,
  };
};

export const getTestimonialsFail = (error) => {
  return {
    type: adminActionTypes.GET_TESTIMONIALS_FAIL,
    error: error,
  };
};

export const getAllAdminServices = () => {
  return (dispatch) => {
    dispatch(getAdminServicesStart());
    axios
      .get(`/site/services`)
      .then((response) => {
        dispatch(getAdminServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesFail(err.message));
      });
  };
};

export const getOneAdminServices = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServicesStart());
    axios
      .get(`/site/services/service/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesFail(err.message));
      });
  };
};

export const deleteAdminServices = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServicesStart());
    axios
      .delete(`/site/services/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesFail(err.message));
      });
  };
};

export const activateAdminServices = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServicesStart());
    axios
      .put(`/site/services/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesFail(err.message));
      });
  };
};

export const desactivateAdminServices = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServicesStart());
    axios
      .put(`/site/services/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesFail(err.message));
      });
  };
};

export const updateAdminServices = (object) => {
  return (dispatch) => {
    dispatch(getAdminServicesStart());
    axios
      .put(`/site/services/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesFail(err.message));
      });
  };
};

export const archiveAdminServices = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServicesStart());
    axios
      .put(`/site/services/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesFail(err.message));
      });
  };
};

export const visibleAdminServices = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServicesStart());
    axios
      .put(`/site/services/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesFail(err.message));
      });
  };
};

export const createAdminServices = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminServicesStart());
    axios
      .post(`/site/services/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneAdminServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServicesFail(err.message));
        notifications.errorNotification("Service");
      });
    notifications.successCreationNotification("Service");
  };
};

export const createClientTestimonials = (object, token) => {
  return (dispatch) => {
    dispatch(getTestimonialsStart());
    axios
      .post(`/site/client-testimonials/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneTestimonialsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestimonialsFail(err.message));
        notifications.errorNotification("Client Testimonial");
      });
    notifications.successCreationNotification("Client Testimonial");
  };
};

export const getClientTestimonials = () => {
  return (dispatch) => {
    dispatch(getTestimonialsStart());
    axios
      .get(`/site/client-testimonials`)
      .then((response) => {
        dispatch(getTestimonialsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestimonialsFail(err.message));
      });
  };
};

export const getActiveClientTestimonials = () => {
  return (dispatch) => {
    dispatch(getTestimonialsStart());
    axios
      .get(`/site/client-testimonials/active`)
      .then((response) => {
        dispatch(getTestimonialsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestimonialsFail(err.message));
      });
  };
};

export const getOneClientTestimonials = (objectId) => {
  return (dispatch) => {
    dispatch(getTestimonialsStart());
    axios
      .get(`/site/client-testimonials/client-testimony/${objectId}`)
      .then((response) => {
        dispatch(getOneTestimonialsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestimonialsFail(err.message));
      });
  };
};

export const deleteTestimonials = (objectId) => {
  return (dispatch) => {
    dispatch(getTestimonialsStart());
    axios
      .delete(`/site/client-testimonials/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneTestimonialsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestimonialsFail(err.message));
      });
  };
};

export const activateTestimonials = (objectId) => {
  return (dispatch) => {
    dispatch(getTestimonialsStart());
    axios
      .put(`/site/client-testimonials/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneTestimonialsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestimonialsFail(err.message));
      });
  };
};

export const desactivateTestimonials = (objectId) => {
  return (dispatch) => {
    dispatch(getTestimonialsStart());
    axios
      .put(`/site/client-testimonials/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneTestimonialsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestimonialsFail(err.message));
      });
  };
};

export const updateTestimonials = (object) => {
  return (dispatch) => {
    dispatch(getTestimonialsStart());
    axios
      .put(`/site/client-testimonials/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneTestimonialsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestimonialsFail(err.message));
      });
  };
};

export const archiveTestimonials = (objectId) => {
  return (dispatch) => {
    dispatch(getTestimonialsStart());
    axios
      .put(`/site/client-testimonials/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneTestimonialsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestimonialsFail(err.message));
      });
  };
};

export const visibleTestimonials = (objectId) => {
  return (dispatch) => {
    dispatch(getTestimonialsStart());
    axios
      .put(`/site/client-testimonials/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneTestimonialsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTestimonialsFail(err.message));
      });
  };
};

export const getAllServicesSubwidgets = () => {
  return (dispatch) => {
    dispatch(getAdminServiceWidgetsStart());
    axios
      .get(`/site/services-widgets/subwidgets`)
      .then((response) => {
        dispatch(getAdminServiceWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServiceWidgetsFail(err));
      });
  };
};

export const getAllServicesWidgets = () => {
  return (dispatch) => {
    dispatch(getAdminServiceWidgetsStart());
    axios
      .get(`/site/services-widgets`)
      .then((response) => {
        dispatch(getAdminServiceWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServiceWidgetsFail(err.message));
      });
  };
};

export const getAllServicesActiveMainWidgets = () => {
  return (dispatch) => {
    dispatch(getAdminServiceWidgetsStart());
    axios
      .get(`/site/services-widgets/main/active`)
      .then((response) => {
        dispatch(getAdminServiceWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServiceWidgetsFail(err.message));
      });
  };
};

export const createServiceWidget = (object, serviceId, token) => {
  if (serviceId != undefined) {
    return (dispatch) => {
      dispatch(getAdminServiceWidgetsStart());
      axios
        .post(`/site/services-widgets/create/${serviceId}`, object, {
          headers: { Authorization: token },
        })
        .then((response) => {
          dispatch(getOneAdminServiceWidgetsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getAdminServiceWidgetsFail(err.message));
          notifications.errorNotification("Service Widget");
        });
      notifications.successCreationNotification("Service Widget");
    };
  } else {
    return (dispatch) => {
      dispatch(getAdminServiceWidgetsStart());
      axios
        .post(`/site/services-widgets/create`, object, {
          headers: { Authorization: token },
        })
        .then((response) => {
          dispatch(getOneAdminServiceWidgetsSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getAdminServiceWidgetsFail(err.message));
          notifications.errorNotification("Service Widget");
        });
      notifications.successCreationNotification("Service Widget");
    };
  }
};

export const updateServiceWidget = (object) => {
  return (dispatch) => {
    dispatch(getAdminServiceWidgetsStart());
    axios
      .put(`/site/services-widgets/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminServiceWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServiceWidgetsFail(err.message));
      });
  };
};

export const getOneServicesWidgets = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServiceWidgetsStart());
    axios
      .get(`/site/services-widgets/service-widget/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServiceWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServiceWidgetsFail(err.message));
      });
  };
};

export const deleteServiceWidget = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServiceWidgetsStart());
    axios
      .delete(`/site/services-widgets/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServiceWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServiceWidgetsFail(err.message));
      });
  };
};

export const activateServiceWidget = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServiceWidgetsStart());
    axios
      .put(`/site/services-widgets/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServiceWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServiceWidgetsFail(err.message));
      });
  };
};

export const desactivateServiceWidget = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServiceWidgetsStart());
    axios
      .put(`/site/services-widgets/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServiceWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServiceWidgetsFail(err.message));
      });
  };
};

export const visibleServiceWidget = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServiceWidgetsStart());
    axios
      .put(`/site/services-widgets/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServiceWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServiceWidgetsFail(err.message));
      });
  };
};

export const archiveServiceWidget = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminServiceWidgetsStart());
    axios
      .put(`/site/services-widgets/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminServiceWidgetsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminServiceWidgetsFail(err.message));
      });
  };
};
