import {
  AboutPageHeader,
  AwardsAndRecognition,
  EthicsCompliance,
  ExecutifLeadership,
  StaffAndTeam,
  WorkWithYou,
} from '../aboutComponents/AboutComponentsList';
import Footer from '../footerComponents/Footer';
import Header from '../headerCompoents/Header';
import ScrollToTop from '../components/ScrollToTop';

export const About = () => {
  return (
    <div>
      <Header pageLink="/about" />
      <AboutPageHeader />
      <ExecutifLeadership />
      <StaffAndTeam />
      <AwardsAndRecognition />
      <EthicsCompliance />
      <WorkWithYou />
      <Footer />
      <ScrollToTop />
    </div>
  );
};
