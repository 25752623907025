import "./index.css";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Partners } from "./pages/partners";
import { Products } from "./pages/products";
import { Services } from "./pages/services";
import { Home } from "./pages/home";
import { Publications } from "./pages/publications";
import { Generic } from "./pages/generic";
import {
  QuickLinks,
  FAQs,
  AdminContactUs,
} from "./admin/AdminContactUsComponentsList";
import { About } from "./pages/about";
import {
  AdminCarousel,
  AdminHome,
  AdminHomeAbout,
  AdminHomeService,
  AdminPartner,
  AdminProductSpotlight,
  AdminQuote,
} from "./pages/adminHome";
import {
  ClientTestimonialPage,
  ClientTestimonialsTable,
  ProductsPage,
  ProductsTable,
  ProductWidgetPage,
  ProductWidgetsTable,
  ServicesPage,
  ServicesTable,
  ServiceWidgetPage,
  ServiceWidgetsTable,
} from "./admin/AdminServicesComponents";
import {
  AffiliationsPage,
  PartnersPage,
  PartnersPageIntroduction,
} from "./admin/AdminPartnersComponents";
import {
  NewsLetterPage,
  EventsPage,
  MediaCoveragePage,
  GiftFeebiePage,
  TodayAtOGenius,
  AdminPublications,
} from "./admin/AdminPublicationsComponents";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import {
  Awards,
  AwardsSummary,
  Career,
  EthicsCompliance,
  ExecutiveIntro,
  GetInTouch,
  Leader,
  SocialMediaLinkPage,
  StaffIntro,
  StaffMember,
} from "./admin/AdminAboutUsComponentsList";
import {
  FooterAddress,
  FooterSection,
  FooterSectionItem,
  PlatfomLink,
  User,
} from "./admin/AdminFooterComponentsList";
import {
  Analytics,
  CommunicationLinks,
  CommunicationsLinksTable,
  Language,
  LanguageTable,
  Links,
  LinksTable,
  Page,
  PreviewCommunicationLink,
  PreviewLanguage,
  PreviewLink,
} from "./admin/AdminPageComponent";
import {
  CarouselTable,
  FooterAddressesTable,
  FooterSectionItemsTable,
  FooterSectionsTable,
  HomeAboutTable,
  HomePartnersTable,
  HomeProductTable,
  HomeServiceIntrosTable,
  HomeServicesTable,
  OneFooterSectionItemsTable,
  TodaysQuoteTable,
  UsersTable,
} from "./admin/AdminHomeTables";
import {
  ContactUsTable,
  FAQsTable,
  MessagesTable,
  QuickLinksTable,
} from "./admin/AdminContactUsTables";
import { ContactUs } from "./pages/contactus";
import ScrollToTop from "./HOC/ScrollToTop";
import { PreviewButton, RichTextArea } from "./admin/AdminComponentsList";
import { TestServices } from "./pages/TestServices";
import Login from "./pages/login";
import { useSelector } from "react-redux";
import AutoSignIn from "./components/AutoSignIn";
import ForgottenPassword from "./pages/ForgottenPassword";
import PasswordRecovery from "./pages/PasswordRecovery";
import {
  AffiliationsTable,
  PartnersIntroTable,
  PartnersTable,
} from "./admin/AdminPartnersTable";
import {
  EventsTable,
  GiftsAndFreeBiesTable,
  MediaCoverageTable,
  NewsletterTable,
  PublicationsTable,
  TodayAtOGeniusTable,
} from "./admin/AdminPublicationsTables";
import {
  AwardsSummaryTable,
  AwardsTable,
  CareersTable,
  EthicsComplianceTable,
  ExecutiveIntroTable,
  GetInTouchTable,
  StaffIntroTable,
  StaffMembersTable,
} from "./admin/AdminAboutUsTables";
import {
  EditCarousel,
  PreviewCarousel,
  PreviewHomeAbout,
  PreviewHomeProduct,
  PreviewHomeServiceIntro,
  PreviewHomeTodayQuote,
} from "./admin/AdminHomePreviewComponents";
import {
  PreviewAffiliaction,
  PreviewAffiliation,
  PreviewPartners,
  PreviewPartnersIntro,
} from "./admin/AdminPartnersPreviewComponents";
import {
  PreviewContactUs,
  PreviewFAQs,
  PreviewMessages,
  PreviewQuickLinks,
} from "./admin/AdminContactUsPreviewComponents";
import {
  PreviewFooterAddress,
  PreviewFooterSection,
  PreviewFooterSectionItem,
  PreviewUser,
} from "./admin/AdminFooterComponentsPreview";
import {
  EventsPreview,
  GiftsAndFreebiesPreview,
  MediaCoveragePreview,
  NewsletterPreview,
  PublicationsPreview,
  TodayAtOGeniusPreview,
} from "./admin/AdminPublicationsComponentsPreview";
import {
  AwardsIntroPreview,
  AwardsPreview,
  CareersPreview,
  EthicsAndCompliancePreview,
  ExecutiveIntroPreview,
  GetInTouchPreview,
  StaffAndTeamPreview,
  StaffIntroPreview,
} from "./admin/AdminAboutComponentsPreview";
import {
  ClientTestimonialPreview,
  ProductsPreview,
  ProductWidgetPreview,
  ServicesPreview,
  ServiceWidgetPreview,
  WidgetPreview,
} from "./admin/AdminServicesComponentsPreview";
import { HomeServiceIntro } from "./admin/AdminHomeComponentsList";
import {
  AdminGallery,
  ImageAndVideoPeview,
} from "./admin/AdminGallerieComponentsList";

import dotenv from "dotenv";
dotenv.config();

function App() {
  useEffect(() => {
    Aos.init({ once: true });
    window.process = {
      env: {
        REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
      },
    };
  }, []);
  const isAuthenticated = useSelector((state) => state.auth.token !== null);
  let routes = (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/testservices" component={TestServices} />
      <Route exact path="/products" component={Products} />
      <Route exact path="/partners" component={Partners} />
      <Route exact path="/publications" component={Publications} />
      <Route exact path="/about" component={About} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/auth/login" component={Login} />
      <Route exact path="/generic" component={Generic} />
      <Route
        exact
        path="/auth/forgotten_password"
        component={ForgottenPassword}
      />
      <Route
        exact
        path="/auth/recover_password/:token"
        component={PasswordRecovery}
      />
      <Route exact path="/services" component={Services} />
      <Route exact path="/testservices" component={TestServices} />
      <Route exact path="/products" component={Products} />
      <Route exact path="/partners" component={Partners} />
      <Route exact path="/publications" component={Publications} />
      <Route exact path="/about" component={About} />
      <Route exact path="/contact-us" component={ContactUs} />
      <Route exact path="/generic" component={Generic} />
    </Switch>
  );
  if (isAuthenticated) {
    routes = (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/testservices" component={TestServices} />
        <Route exact path="/products" component={Products} />
        <Route exact path="/partners" component={Partners} />
        <Route exact path="/publications" component={Publications} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/auth/login" component={Login} />
        <Route exact path="/generic" component={Generic} />
        <Route
          exact
          path="/admin/home-carousel/:objectId?"
          component={AdminCarousel}
        />
        <Route
          exact
          path="/admin/home-about/:objectId?"
          component={AdminHomeAbout}
        />
        <Route
          exact
          path="/admin/home-product/:objectId?"
          component={AdminProductSpotlight}
        />
        <Route
          exact
          path="/admin/home-partner/:objectId?"
          component={AdminPartner}
        />
        <Route
          exact
          path="/admin/home-service/:objectId?"
          component={AdminHomeService}
        />
        <Route
          exact
          path="/admin/home-quote/:objectId?"
          component={AdminQuote}
        />
        <Route
          exact
          path="/admin/services/:objectId?"
          component={ServicesPage}
        />
        <Route
          exact
          path="/admin/products/:objectId?"
          component={ProductsPage}
        />
        <Route
          exact
          path="/admin/partners/:objectId?"
          component={PartnersPage}
        />
        <Route
          exact
          path="/admin/partners-introduction/:objectId?"
          component={PartnersPageIntroduction}
        />
        <Route
          exact
          path="/admin/affiliations/:objectId?"
          component={AffiliationsPage}
        />
        <Route
          exact
          path="/admin/publications/newsletter/:objectId?"
          component={NewsLetterPage}
        />
        <Route
          exact
          path="/admin/publications/events/:objectId?"
          component={EventsPage}
        />
        <Route
          exact
          path="/admin/publications/media-coverage/:objectId?"
          component={MediaCoveragePage}
        />
        <Route
          exact
          path="/admin/publications/gifts-and-freebie/:objectId?"
          component={GiftFeebiePage}
        />
        <Route
          exact
          path="/admin/publications/today-at-ogenius/:objectId?"
          component={TodayAtOGenius}
        />
        <Route
          exact
          path="/admin/about/executive-intro/:objectId?"
          component={ExecutiveIntro}
        />
        <Route
          exact
          path="/admin/about/leaders/:objectId?"
          component={Leader}
        />
        <Route
          exact
          path="/admin/about/careers/:objectId?"
          component={Career}
        />
        <Route
          exact
          path="/admin/about/staff-intros/:objectId?"
          component={StaffIntro}
        />
        <Route
          exact
          path="/admin/about/staff-members/:objectId?"
          component={StaffMember}
        />
        <Route
          exact
          path="/admin/about/awards-summary/:objectId?"
          component={AwardsSummary}
        />
        <Route exact path="/admin/about/awards/:objectId?" component={Awards} />
        <Route
          exact
          path="/admin/about/ethics-and-compliance/:objectId?"
          component={EthicsCompliance}
        />
        <Route
          exact
          path="/admin/about/get-in-touch/:objectId?"
          component={GetInTouch}
        />
        <Route
          exact
          path="/admin/contact-us/contacts/:objectId?"
          component={AdminContactUs}
        />
        <Route
          exact
          path="/admin/contact-us/quick-links/:objectId?"
          component={QuickLinks}
        />
        <Route
          exact
          path="/admin/contact-us/faqs/:objectId?"
          component={FAQs}
        />
        <Route
          exact
          path="/admin/footer/footer-section/:objectId?"
          component={FooterSection}
        />
        <Route
          exact
          path="/admin/footer/footer-section-item/:objectId?"
          component={FooterSectionItem}
        />
        <Route
          exact
          path="/admin/footer/footer-address/:objectId?"
          component={FooterAddress}
        />
        <Route exact path="/admin/users/:objectId?" component={User} />
        <Route
          exact
          path="/admin/platfom-link/:objectId?"
          component={PlatfomLink}
        />
        <Route exact path="/admin/page/:objectId?" component={Page} />
        <Route exact path="/admin/languages/:objectId?" component={Language} />
        <Route
          exact
          path="/admin/languages/objects/list"
          component={LanguageTable}
        />
        <Route exact path="/admin-home" component={Analytics} />
        <Route exact path="/admin/links" component={Links} />
        <Route exact path="/admin/links/objects/list" component={LinksTable} />
        <Route exact path="/admin/rich/:objectId?" component={RichTextArea} />
        <Route
          exact
          path="/admin/home-carousel/objects/list"
          component={CarouselTable}
        />
        <Route
          exact
          path="/admin/home-about/objects/list"
          component={HomeAboutTable}
        />
        <Route
          exact
          path="/admin/home-product/objects/list"
          component={HomeProductTable}
        />
        <Route
          exact
          path="/admin/home-quote/objects/list"
          component={TodaysQuoteTable}
        />{" "}
        <Route
          exact
          path="/admin/home-service/objects/list"
          component={HomeServicesTable}
        />
        <Route
          exact
          path="/admin/footer/footer-section/objects/list"
          component={FooterSectionsTable}
        />
        <Route
          exact
          path="/admin/footer/footer-section-item/objects/list/:objectId?"
          component={FooterSectionItemsTable}
        />
        <Route
          exact
          path="/admin/footer/footer-address/objects/list"
          component={FooterAddressesTable}
        />
        <Route exact path="/admin/users/objects/list" component={UsersTable} />
        <Route
          exact
          path="/admin/contact-us/contacts/objects/list"
          component={ContactUsTable}
        />
        <Route
          exact
          path="/admin/contact-us/quick-links/objects/list"
          component={QuickLinksTable}
        />
        <Route
          exact
          path="/admin/contact-us/faqs/objects/list"
          component={FAQsTable}
        />
        <Route
          exact
          path="/admin/partners/objects/list"
          component={PartnersTable}
        />
        <Route
          exact
          path="/admin/partners-introduction/objects/list"
          component={PartnersIntroTable}
        />
        <Route
          exact
          path="/admin/affiliations/objects/list"
          component={AffiliationsTable}
        />
        <Route
          exact
          path="/admin/publications/publications/:objectId?"
          component={AdminPublications}
        />
        <Route
          exact
          path="/admin/publications/publications/objects/list"
          component={PublicationsTable}
        />
        <Route
          exact
          path="/admin/publications/newsletter/objects/list"
          component={NewsletterTable}
        />
        <Route
          exact
          path="/admin/publications/events/objects/list"
          component={EventsTable}
        />
        <Route
          exact
          path="/admin/publications/media-coverage/objects/list"
          component={MediaCoverageTable}
        />
        <Route
          exact
          path="/admin/publications/gifts-and-freebie/objects/list"
          component={GiftsAndFreeBiesTable}
        />
        <Route
          exact
          path="/admin/publications/today-at-ogenius/objects/list"
          component={TodayAtOGeniusTable}
        />
        <Route
          exact
          path="/admin/about/executive-intro/objects/list"
          component={ExecutiveIntroTable}
        />
        <Route
          exact
          path="/admin/about/careers/objects/list"
          component={CareersTable}
        />
        <Route
          exact
          path="/admin/about/staff-intros/objects/list"
          component={StaffIntroTable}
        />
        <Route
          exact
          path="/admin/about/awards-summary/objects/list"
          component={AwardsSummaryTable}
        />
        <Route
          exact
          path="/admin/about/awards/objects/list"
          component={AwardsTable}
        />
        <Route
          exact
          path="/admin/about/staff-members/objects/list"
          component={StaffMembersTable}
        />
        <Route
          exact
          path="/admin/about/ethics-and-compliance/objects/list"
          component={EthicsComplianceTable}
        />
        <Route
          exact
          path="/admin/about/get-in-touch/objects/list"
          component={GetInTouchTable}
        />
        <Route
          exact
          path="/admin/home-carousel/edit/:objectId?"
          component={PreviewCarousel}
        />
        <Route
          exact
          path="/admin/home-about/edit/:objectId?"
          component={PreviewHomeAbout}
        />
        <Route
          exact
          path="/admin/home-product/edit/:objectId?"
          component={PreviewHomeProduct}
        />
        <Route
          exact
          path="/admin/home-quote/edit/:objectId?"
          component={PreviewHomeTodayQuote}
        />
        <Route
          exact
          path="/admin/partners/edit/:objectId?"
          component={PreviewPartners}
        />
        <Route
          exact
          path="/admin/partners-introduction/edit/:objectId?"
          component={PreviewPartnersIntro}
        />
        <Route
          exact
          path="/admin/affiliations/edit/:objectId?"
          component={PreviewAffiliation}
        />
        <Route
          exact
          path="/admin/contact-us/contacts/edit/:objectId?"
          component={PreviewContactUs}
        />
        <Route
          exact
          path="/admin/contact-us/quick-links/edit/:objectId?"
          component={PreviewQuickLinks}
        />
        <Route
          exact
          path="/admin/contact-us/faqs/edit/:objectId?"
          component={PreviewFAQs}
        />
        <Route
          exact
          path="/admin/footer/footer-section/edit/:objectId?"
          component={PreviewFooterSection}
        />
        <Route
          exact
          path="/admin/footer/footer-section-item/edit/:objectId?"
          component={PreviewFooterSectionItem}
        />
        <Route
          exact
          path="/admin/footer/footer-address/edit/:objectId?"
          component={PreviewFooterAddress}
        />
        <Route
          exact
          path="/admin/users/edit/:objectId?"
          component={PreviewUser}
        />
        <Route
          exact
          path="/admin/languages/edit/:objectId?"
          component={PreviewLanguage}
        />
        <Route
          exact
          path="/admin/links/edit/:objectId?"
          component={PreviewLink}
        />
        <Route
          exact
          path="/admin/publications/publications/edit/:objectId?"
          component={PublicationsPreview}
        />
        <Route
          exact
          path="/admin/publications/newsletter/edit/:objectId?"
          component={NewsletterPreview}
        />
        <Route
          exact
          path="/admin/publications/events/edit/:objectId?"
          component={EventsPreview}
        />
        <Route
          exact
          path="/admin/publications/media-coverage/edit/:objectId?"
          component={MediaCoveragePreview}
        />
        <Route
          exact
          path="/admin/publications/gifts-and-freebie/edit/:objectId?"
          component={GiftsAndFreebiesPreview}
        />
        <Route
          exact
          path="/admin/publications/today-at-ogenius/edit/:objectId?"
          component={TodayAtOGeniusPreview}
        />
        <Route
          exact
          path="/admin/about/executive-intro/edit/:objectId?"
          component={ExecutiveIntroPreview}
        />
        <Route
          exact
          path="/admin/about/careers/edit/:objectId?"
          component={CareersPreview}
        />
        <Route
          exact
          path="/admin/about/staff-intros/edit/:objectId?"
          component={StaffIntroPreview}
        />
        <Route
          exact
          path="/admin/about/staff-members/edit/:objectId?"
          component={StaffAndTeamPreview}
        />
        <Route
          exact
          path="/admin/about/awards-summary/edit/:objectId?"
          component={AwardsIntroPreview}
        />
        <Route
          exact
          path="/admin/about/awards/edit/:objectId?"
          component={AwardsPreview}
        />
        <Route
          exact
          path="/admin/about/ethics-and-compliance/edit/:objectId?"
          component={EthicsAndCompliancePreview}
        />
        <Route
          exact
          path="/admin/about/get-in-touch/edit/:objectId?"
          component={GetInTouchPreview}
        />
        <Route
          exact
          path="/admin/home-partner/objects/list"
          component={HomePartnersTable}
        />
        <Route
          exact
          path="/admin/service-widget/:objectId?"
          component={ServiceWidgetPage}
        />
        <Route
          exact
          path="/admin/product-widget/:objectId?"
          component={ProductWidgetPage}
        />
        <Route
          exact
          path="/admin/services/objects/list"
          component={ServicesTable}
        />
        <Route
          exact
          path="/admin/products/objects/list"
          component={ProductsTable}
        />
        <Route
          exact
          path="/admin/services/edit/:objectId?"
          component={ServicesPreview}
        />
        <Route
          exact
          path="/admin/products/edit/:objectId?"
          component={ProductsPreview}
        />
        <Route
          exact
          path="/admin/service-widget/edit/:objectId?"
          component={ServiceWidgetPreview}
        />
        <Route
          exact
          path="/admin/product-widget/edit/:objectId?"
          component={ProductWidgetPreview}
        />
        <Route
          exact
          path="/admin/home-service-intro/:objectId?"
          component={HomeServiceIntro}
        />
        <Route
          exact
          path="/admin/client-testimonials/:objectId?"
          component={ClientTestimonialPage}
        />
        <Route
          exact
          path="/admin/client-testimonials/objects/list"
          component={ClientTestimonialsTable}
        />
        <Route
          exact
          path="/admin/client-testimonials/edit/:objectId?"
          component={ClientTestimonialPreview}
        />
        <Route
          exact
          path="/admin/service-widget/objects/list"
          component={ServiceWidgetsTable}
        />
        <Route
          exact
          path="/admin/product-widget/objects/list"
          component={ProductWidgetsTable}
        />
        <Route
          exact
          path="/admin/home-service-intro/objects/list"
          component={HomeServiceIntrosTable}
        />
        <Route
          exact
          path="/admin/widget/preview/:objectId?"
          component={WidgetPreview}
        />
        <Route
          exact
          path="/admin/social-media-link/:objectId?"
          component={SocialMediaLinkPage}
        />
        <Route
          exact
          path="/admin/communication-links/:objectId?"
          component={CommunicationLinks}
        />
        <Route
          exact
          path="/admin/communication-links/objects/list"
          component={CommunicationsLinksTable}
        />
        <Route
          exact
          path="/admin/communication-links/edit/:objectId?"
          component={PreviewCommunicationLink}
        />
        <Route
          exact
          path="/admin/messages/objects/list"
          component={MessagesTable}
        />
        <Route
          exact
          path="/admin/messages/edit/:objectId?"
          component={PreviewMessages}
        />
        <Route
          exact
          path="/admin/home-service-intro/edit/:objectId?"
          component={PreviewHomeServiceIntro}
        />
        <Route exact path="/admin/gallerie" component={AdminGallery} />
        <Route
          exact
          path="/admin/gallerie/view/:objectId?"
          component={ImageAndVideoPeview}
        />
      </Switch>
    );
  }
  return (
    <Router>
      <AutoSignIn />
      <ScrollToTop />
      {routes}
    </Router>
  );
}

export default App;
