import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";
import * as notifications from "../../admin/contants";

export const getContactUsStart = () => {
  return {
    type: actionTypes.GET_CONTACT_US_START,
  };
};

export const getContactUsSuccess = (contactUs) => {
  return {
    type: actionTypes.GET_CONTACT_US_SUCCESS,
    contactUs: contactUs,
  };
};

export const getContactUsFail = (error) => {
  return {
    type: actionTypes.GET_CONTACT_US_FAIL,
    error: error,
  };
};

export const getContactUs = (lang) => {
  return (dispatch) => {
    dispatch(getContactUsStart());
    axios
      .get(`/site/contact-us/active/${lang}`)
      .then((response) => {
        dispatch(getContactUsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getContactUsFail(err.message));
      });
  };
};

export const getQuickLinksStart = () => {
  return {
    type: actionTypes.GET_QUICK_LINKS_START,
  };
};

export const getQuickLinksSuccess = (quickLinks) => {
  return {
    type: actionTypes.GET_QUICK_LINKS_SUCCESS,
    quickLinks: quickLinks,
  };
};

export const getQuickLinksFail = (error) => {
  return {
    type: actionTypes.GET_QUICK_LINKS_FAIL,
    error: error,
  };
};

export const getQuickLinks = (lang) => {
  return (dispatch) => {
    dispatch(getQuickLinksStart());
    axios
      .get(`/site/quick-links/active/${lang}`)
      .then((response) => {
        dispatch(getQuickLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getQuickLinksFail(err.message));
      });
  };
};

export const getFaqsStart = () => {
  return {
    type: actionTypes.GET_FAQS_START,
  };
};

export const getFaqsSuccess = (faqs) => {
  return {
    type: actionTypes.GET_FAQS_SUCCESS,
    faqs: faqs,
  };
};

export const getFaqsFail = (error) => {
  return {
    type: actionTypes.GET_FAQS_FAIL,
    error: error,
  };
};

export const getFaqs = (lang) => {
  return (dispatch) => {
    dispatch(getFaqsStart());
    axios
      .get(`/site/faqs/active/${lang}`)
      .then((response) => {
        dispatch(getFaqsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getFaqsFail(err.message));
      });
  };
};

export const sendMessageStart = () => {
  return {
    type: actionTypes.SEND_MESSAGE_START,
  };
};

export const sendMessageSuccess = (message) => {
  return {
    type: actionTypes.SEND_MESSAGE_SUCCESS,
    message: message,
  };
};

export const sendMessageFail = (error) => {
  return {
    type: actionTypes.SEND_MESSAGE_FAIL,
    error: error,
  };
};

export const sendMessage = (message) => {
  return (dispatch) => {
    dispatch(sendMessageStart());
    axios
      .post("/site/messages/create/", message)
      .then((response) => {
        dispatch(sendMessageSuccess(response.data.message));
      })
      .catch((err) => {
        dispatch(sendMessageFail(err.message));
      });
  };
};

export const createContactUs = (object, token) => {
  return (dispatch) => {
    dispatch(getContactUsStart());
    axios
      .post("/site/contact-us/create", object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getContactUsSuccess(response.data.message));
      })
      .catch((err) => {
        dispatch(getContactUsFail(err.message));
        notifications.errorNotification("Contact Us");
      });
    notifications.successCreationNotification("Contact Us");
  };
};

export const createQuickLink = (object, token) => {
  return (dispatch) => {
    dispatch(getQuickLinksStart());
    axios
      .post("/site/quick-links/create", object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getQuickLinksSuccess(response.data.message));
      })
      .catch((err) => {
        dispatch(getQuickLinksFail(err.message));
        notifications.errorNotification("Quick Links");
      });
    notifications.successCreationNotification("Quick Links");
  };
};

export const createFAQ = (object, token) => {
  return (dispatch) => {
    dispatch(getFaqsStart());
    axios
      .post("/site/faqs/create", object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getFaqsSuccess(response.data.message));
      })
      .catch((err) => {
        dispatch(getFaqsFail(err.message));
        notifications.errorNotification("FAQ");
      });
    notifications.successCreationNotification("FAQ");
  };
};
