import { Button, SelectButton, TableLink } from "../components/Buttons";
import {
  ActivatingButton,
  ArchivingButton,
  InputText,
  InputTextArea,
  InputTextSecret,
  LinkComponent,
  MoreDropDown,
  PreviewButton,
  SelectRadioButton,
  InsertingLanguagesTable,
  Uploading,
} from "./AdminComponentsList";
import classnames from "classnames";
import moment from "moment";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import "./admin.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import AsyncSelect from "react-select/async";
import { useHistory, useLocation } from "react-router-dom";
import {ReactNotifications} from "react-notifications-component";
import contants, {
  successCreationNotification,
  successNotification,
  confirmNotification,
  successUpdateNotification,
  successDeleteNotification,
} from "./contants";
import { useParams } from "react-router";
import * as adminActionTypes from "../store/actions/adminIndex";
import { GenericDisplayMedia } from "./AdminGallerieComponentsList";
import richTextConstants from "./richtextconstants";

import dotenv from "dotenv";
dotenv.config();
const ReactNotification=ReactNotifications


export const Page = () => {
  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/page" />
        </div>
        <div className="pt-4 pr-16 w-full">
          <div className="flex justify-between pl-12">
            <div className="flex flex-col pt-5 space-y-3">
              <div className="font-bold flex flex-col">
                <span>Page</span>
              </div>
              <div className="font-bold flex flex-col">
                <span>Choose a widget type</span>
                <div className="flex space-x-2">
                  <SelectButton advanced />
                  <Button name="REMOVE" type="remove" />
                </div>
              </div>
              <InputText title="Title" />
              <InputTextArea title="Description" />
              <Uploading
                title="Image"
                text1="Upload Image"
                text2="View the Image"
              />
              <Uploading
                title="Video"
                text1="Upload Video"
                text2="View the Video"
              />
              <div className="flex space-x-2">
                <SelectButton advanced item="WIDGET" />
                <Button name="Add" type="add" />
              </div>
              <div className="border-b-2 border-gray-700 pt-4"></div>
            </div>
            <div className="flex flex-col space-y-2">
              <Button name="Save or Update" type="square" />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/executive-intro/objects/list"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Analytics = () => {
  const history = useHistory();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        <div className="pt-4 pr-16 w-full">
          <div className="flex justify-center pl-12">
            <div className="flex flex-col pt-5 space-y-6">
              <div className="font-bold flex flex-col">
                <span>Analytics</span>
              </div>
              <div className="flex space-x-5">
                <div className="font-bold flex flex-col">
                  <span>Time</span>
                  <div className="flex space-x-2">
                    <SelectButton advanced />
                  </div>
                </div>
                <div className="font-bold flex flex-col">
                  <span>Page</span>
                  <div className="flex space-x-2">
                    <SelectButton advanced />
                  </div>
                </div>
                <div className="font-bold flex flex-col">
                  <span>Location</span>
                  <div className="flex space-x-2">
                    <SelectButton advanced />
                  </div>
                </div>
                <div className=" self-end">
                  <button className="bg-blue-500 font-bold rounded-md px-4 py-1 text-white">
                    Filter
                  </button>
                </div>
              </div>
              <div className="flex space-x-5 text-white">
                <div className="bg-indigo-900 w-1/5">
                  <AnalyticData data={200} title="Unique Visitors" />
                </div>
                <div className="bg-blue-400 w-1/5">
                  <AnalyticData data={150} title="Total Visits" />
                </div>
                <div className="bg-purple-300 w-1/5">
                  <AnalyticData data={200} title="Unique Pageviews" />
                </div>
                <div className="bg-green-600 w-1/5">
                  <AnalyticData data={200} title="Pageviews" />
                </div>
                <div className="bg-publication-header-link-line w-1/5">
                  <AnalyticData
                    data={2.8 + " min"}
                    subtitle="Average session duartion"
                  />
                </div>
              </div>
              <div className="w-2/5 bg-yellow-200">
                <table class="text-md w-full font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-gray-600">
                  <thead>
                    <tr>
                      <th scope="col">Top Active Pages</th>
                      <th scope="col">Visitors</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Home</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td>Products</td>
                      <td>10</td>
                    </tr>
                    <tr>
                      <td>About Us</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>Contact Us</td>
                      <td>5</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AnalyticData = (props) => {
  return (
    <div className="text-center flex-col font-semibold px-3 pt-7 pb-5">
      <div>
        <span className="text-4xl">{props.data}</span>
      </div>
      <div>
        <span className="text-xl">{props.title}</span>
        <span className="text-xs">{props.subtitle}</span>
      </div>
    </div>
  );
};

export const Language = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
  }, [dispatch]);

  const [name, setname] = useState("");
  const [abbreviation, setabbreviation] = useState("");
  const [flag, setflag] = useState(null);

  const fileSelectoHandler = (event) => {
    setflag(event.target.files[0]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (name === "" || abbreviation === "") {
      setclicked(true);
    } else {
      const fd = new FormData();
      fd.append("flag", flag);
      fd.append("name", name);
      fd.append("creator", creatorName);
      fd.append("abbreviation", abbreviation);
      dispatch(actionTypes.createLanguages(fd, token));
      setname("");
      setabbreviation("");
      setclicked(false);
      setdisabled(false);
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        <form
          onSubmit={onSubmit}
          enctype="multipart/form-data"
          method="post"
          className="pt-4 pr-16 w-full"
        >
          <div className="flex justify-between pl-12">
            <div className="flex flex-col pt-5 space-y-4">
              <div className="font-bold flex flex-col">
                <span className="admin-page-title text-xl">LANGUAGES</span>
              </div>
              <InputText
                title="Name"
                name="name"
                value={name}
                isClicked={clicked}
                onChange={(e) => setname(e.target.value)}
              />
              <InputText
                title="Abbreviation"
                value={abbreviation}
                isClicked={clicked}
                name="abbreviation"
                onChange={(e) => setabbreviation(e.target.value)}
              />
              <Uploading
                title="Flag"
                text1="Upload Image"
                text2="View the Image"
                name="flag"
                required
                onChange={fileSelectoHandler}
              />
            </div>
            <div className="flex flex-col space-y-2">
              <Button name={contants.Publish} type="square" />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/languages/objects/list"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export const LanguageTable = () => {
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const languagesLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  useEffect(() => {
    dispatch(actionTypes.getAllLanguages());
  }, [dispatch]);

  const options = {
    label: ["Edit", "Delete"],
  };

  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Abbreviation", accessor: "abbreviation" },
    { Header: "Status", accessor: "status" },
    { Header: "Creator", accessor: "creator" },
    {
      Header: "Actions",
      accessor: "actionColumn",
      disableSortBy: true,
      Cell: ({ original }) => (
        <div className="cursor__pointer ">
          <AsyncSelect
            placeholder="Actions"
            className="mb-2 w-4/6"
            cacheOptions
            defaultOptions={languages}
            getOptionLabel={(e) => e.name}
            getOptionValue={(e) => e.uuid}
            isSearchable
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/language" />
        </div>
        {languagesLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">LANGUAGES</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Name</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Abbreviation
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {languages.map((language, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {language.name}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 bg-blue-100 text-center">
                          {language.abbreviation}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {language.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 bg-blue-100">
                          {moment(language.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {language.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          <PreviewButton
                            id={language.uuid}
                            link="/admin/languages/edit"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Language"
                  type="addLink"
                  link="/admin/languages"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const Links = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.links.link);
  const params = useParams();
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const linksLoading = useSelector((state) => state.links.linksLoading);
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
  }, [dispatch]);

  const [link, setlink] = useState("/generic");
  const [name, setname] = useState("");
  const [content, setcontent] = useState("");
  const [type, settype] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    if (link === "" || name === "" || type === "") {
      setclicked(true);
    } else if (type === contants.generic && content === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            name,
            link,
            content: richtext.editorValue,
            type,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminHomeCarousels(obj));
          setname("");
          setlink("/generic");
          setcontent("");
          settype("");
          setclicked(false);
          //succ
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              link,
              content: richtext.editorValue,
              name,
              type,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          setlink(link);
          setname("");
          setcontent("");
          setclicked(false);
          setdisabled(false);
          successNotification("Link", languageName);
        }
        if (counter == languages.length - 1) {
          setlink(link);
          setname(name);
          setcontent(content);
          settype(type);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(actionTypes.createLinks(objects, token));
          successCreationNotification("Link ");
          setcounter(0);
          setObjects([]);
          setlink("/generic");
          setname("");
          settype("");
          setcontent("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div className="h-full">
          <SideMenu />
        </div>
        {linksLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-4 w-70%">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Generic Links (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Generic Links (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Link"
                  name="link"
                  value={link}
                  isClicked={clicked}
                  onChange={(e) => setlink(e.target.value)}
                />
                <InputText
                  title="Name"
                  name="name"
                  value={name}
                  isClicked={clicked}
                  onChange={(e) => setname(e.target.value)}
                />
                <SelectRadioButton
                  title="Type"
                  option1={contants.generic}
                  option2={contants.platfom}
                  name="type"
                  isClicked={clicked}
                  value={type}
                  onChange={(e) => settype(e.target.value)}
                />
                <InputTextArea
                  title="Content"
                  data={content}
                  isClicked={clicked && type === contants.generic}
                  name="content"
                  onChange={(event) => {
                    setcontent(event);
                  }}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={contants.Save}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/links/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const CommunicationLinks = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminHome.homeCommunicationLink);
  const params = useParams();
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const linksLoading = useSelector((state) => state.links.linksLoading);
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminCommunucationLinks(params.objectId));
  }, [dispatch]);

  const [link, setlink] = useState(object != null ? object.link : "/generic");
  const [name, setname] = useState(object != null ? object?.name : "");
  const [content, setcontent] = useState(object != null ? eval(object?.content) : "");

  const onSubmit = (e) => {
    e.preventDefault();
    if (link === "" || content === "" || name === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            name,
            link,
            content: richtext.editorValue,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminHomeCommunicationLinks(obj));
          setname("");
          setlink("/generic");
          setcontent("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              link,
              content: richtext.editorValue,
              name,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          //  successNotification("Communication Links", languageName);
          setlink(link);
          setname("");
          setcontent("");
          setclicked(false);
          setdisabled(false);
        }
        if (counter == languages.length - 1) {
          setlink(link);
          setname(name);
          setcontent(content);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(
            adminActionTypes.createAdminCommunucationLinks(objects, token)
          );
          setcounter(0);
          setObjects([]);
          setlink("/generic");
          setname("");
          setcontent("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };

  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div className="h-full">
          <SideMenu />
        </div>
        {linksLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-4 w-70%">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Communication Links (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Communication Links (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Link"
                  name="link"
                  value={link}
                  isClicked={clicked}
                  onChange={(e) => setlink(e.target.value)}
                />
                <InputText
                  title="Name"
                  name="name"
                  value={name}
                  isClicked={clicked}
                  onChange={(e) => setname(e.target.value)}
                />
                <InputTextArea
                  title="Content"
                  data={content}
                  isClicked={clicked}
                  name="content"
                  onChange={(e) => {
                    setcontent(e);
                  }}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={contants.Save}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/communication-links/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const LinksTable = () => {
  const dispatch = useDispatch();
  const links = useSelector((state) => state.links.links);
  const linksLoading = useSelector((state) => state.links.linksLoading);
  useEffect(() => {
    dispatch(actionTypes.getLinks());
  }, [dispatch]);
  const options = [
    { value: "edit", label: "Edit", name: "hindura" },
    { value: "delete", label: "Edit", name: "siba" },
  ];
  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/links" />
        </div>
        {linksLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-4">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    GENERAL LINKS{" "}
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Name</th>
                      <th className="border border-blue-400 px-4 py-2">Link</th>
                      <th className="border border-blue-400 px-4 py-2">Type</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {links.map((link, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {link.name}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 bg-blue-100 text-center">
                          {link.link}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 bg-blue-100 text-center">
                          {link.type}
                        </td>
                        <td className="border border-blue-400 px-4 py-2  text-center">
                          {link.language}
                        </td>
                        <td className="border border-blue-400 bg-blue-100 px-4 py-2">
                          {link.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 ">
                          {moment(link.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 bg-blue-100">
                          {link.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          <PreviewButton
                            id={link.uuid}
                            link="/admin/links/edit"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add Link"
                  type="addLink"
                  link="/admin/links"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const CommunicationsLinksTable = () => {
  const dispatch = useDispatch();
  const links = useSelector(
    (state) => state.adminHome.adminHomeCommunicationLinks
  );
  const linksLoading = useSelector(
    (state) => state.adminHome.adminHomeCommunicationLinksLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminCommunucationLinks());
  }, [dispatch]);
  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/links" />
        </div>
        {linksLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-4">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    COMMUNICATION LINKS{" "}
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Name</th>
                      <th className="border border-blue-400 px-4 py-2">Link</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {links.map((link, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {link.name}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 bg-blue-100 text-center">
                          {link.link}
                        </td>
                        <td className="border border-blue-400 px-4 py-2  text-center">
                          {link.language}
                        </td>
                        <td className="border border-blue-400 bg-blue-100 px-4 py-2">
                          {link.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 ">
                          {moment(link.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 bg-blue-100">
                          {link.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          <PreviewButton
                            id={link.uuid}
                            link="/admin/communication-links/edit"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add Link"
                  type="addLink"
                  link="/admin/communication-links"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const PreviewLanguage = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.languages.language);
  const objectLoading = useSelector(
    (state) => state.languages.languagesLoading
  );

  useEffect(() => {
    dispatch(actionTypes.getOneLanguages(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(actionTypes.desactivateLanguages(object.uuid));
        successUpdateNotification(" Language", contants.INACTIVE);
      } else {
        dispatch(actionTypes.activateLanguages(object.uuid));
        successUpdateNotification(" Language", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(actionTypes.archiveLanguages(object.uuid));
        successUpdateNotification(" Language", contants.ARCHIVE);
      } else {
        dispatch(actionTypes.visibleLanguages(object.uuid));
        successUpdateNotification(" Language", contants.VISIBLE);
      }
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">
                  LANGUAGE PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.name}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Abbreviation :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.abbreviation}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between h-80 ">
                <GenericDisplayMedia media={object?.flag} />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Add Language"
                type="updateLink"
                link="/admin/languages"
                objectId={object != null ? object.uuid : ""}
              />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/languages/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const PreviewLink = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.links.link);
  const objectLoading = useSelector((state) => state.links.linksLoading);

  useEffect(() => {
    dispatch(actionTypes.getOneLinks(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(actionTypes.desactivateLinks(object.uuid));
        successUpdateNotification(" Link", contants.INACTIVE);
      } else {
        dispatch(actionTypes.activateLinks(object.uuid));
        successUpdateNotification(" Link", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(actionTypes.archiveLinks(object.uuid));
        successUpdateNotification(" Link", contants.ARCHIVE);
      } else {
        dispatch(actionTypes.visibleLinks(object.uuid));
        successUpdateNotification(" Link", contants.VISIBLE);
      }
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">LINK PREVIEW</span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Name:</span>
                    <span className="site-text-blue px-3">
                      {object?.name}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Link:</span>
                    <span className="site-text-blue px-3">
                      {object?.link}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Content:</span>
                    <span className="site-text-blue px-3">
                      {object?.content !== undefined
                        ? parse(eval(object?.content))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator:</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At:
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At:
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status:</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Add Link"
                type="updateLink"
                link="/admin/links"
                objectId={object != null ? object.uuid : ""}
              />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/links/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const PreviewCommunicationLink = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminHome.homeCommunicationLink);
  const objectLoading = useSelector(
    (state) => state.adminHome.adminHomeCommunicationLinksLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminCommunucationLinks(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(actionTypes.desactivateLinks(object.uuid));
        successUpdateNotification(" Link", contants.INACTIVE);
      } else {
        dispatch(actionTypes.activateLinks(object.uuid));
        successUpdateNotification(" Link", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(actionTypes.archiveLinks(object.uuid));
        successUpdateNotification(" Link", contants.ARCHIVE);
      } else {
        dispatch(actionTypes.visibleLinks(object.uuid));
        successUpdateNotification(" Link", contants.VISIBLE);
      }
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">LINK PREVIEW</span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Name:</span>
                    <span className="site-text-blue px-3">
                      {object?.name}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Link:</span>
                    <span className="site-text-blue px-3">
                      {object?.link}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Content:</span>
                    <span className="site-text-blue px-3">
                      {object?.content !== undefined
                        ? parse(eval(object?.content))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator:</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At:
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At:
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status:</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/communication-links"
                objectId={object != null ? object.uuid : ""}
              />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/communication-links/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
