import quote from "../images/today-bg.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";

const TodayQuote = () => {
  const dispatch = useDispatch();
  const homeTodaysQuote = useSelector((state) => state.home.homeTodaysQuote);
  const homeTodaysQuoteLoading = useSelector(
    (state) => state.home.homeTodaysQuoteLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getHomeTodaysQuote(language));
  }, [dispatch, language]);
  const todaysQuoteMock = {
    quote: `"Many of life's failers are people who did not realize how close
  they were to success when they gave up Many of life's failers are
  people who did not realize how close they were to success when they
  gave up Many of life's failers are people who did not realize how
  close they were to success when they gave up"`,
    owner: "Thomas A. Edison",
  };
  return (
    <div className="w-full h-96 overflow-auto scrollbar-hidden flex justify-center items-center relative blured-bg">
      {homeTodaysQuoteLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          <img
            src={quote}
            alt="quote"
            className="today-quote w-full h-full object-cover  absolute top-0 left-0"
          />
          <div
            data-aos="flip-down"
            data-aos-duration="2000"
            className="absolute z-10 top-5 left-0 w-full h-full flex justify-center items-center"
          >
            <div className="flex flex-col items-center px-6 py-6">
              <div className="quote py-5">Todays Quote</div>
              <div className="quote-description py-5 md:px-52">
                {homeTodaysQuote?.quote != undefined
                  ? parse(eval(homeTodaysQuote?.quote))
                  : parse("<p></p>") ?? todaysQuoteMock.quote}
              </div>
              <div className="quote-author">
                {homeTodaysQuote?.owner ?? todaysQuoteMock.owner}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default TodayQuote;
