import { Button, SelectButton, TableLink } from "../components/Buttons";
import {
  InputText,
  InputTextArea,
  LinkComponent,
  MoreDropDown,
  InsertingLanguagesTable,
  Uploading,
  ShowOrHideLinks,
} from "./AdminComponentsList";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import AsyncSelect from "react-select/async";
import contants, {
  successNotification,
  confirmNotification,
  successUpdateNotification,
} from "./contants";
import { useParams } from "react-router";
import * as adminActionTypes from "../store/actions/adminIndex";
import {ReactNotifications} from "react-notifications-component";
import moment from "moment";
import classnames from "classnames";
import richTextConstants from "./richtextconstants";

import dotenv from "dotenv";
dotenv.config();
const ReactNotification=ReactNotifications
export const NewsLetterPage = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const links = useSelector((state) => state.links.links);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminPublications.newsletter);
  const params = useParams();
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminNewsletter(params.objectId));
  }, [dispatch]);

  const [title, setTitle] = useState(object != null ? object?.title : "");
  const [subtitle, setSubtitle] = useState(
    object != null ? object?.subtitle : ""
  );
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState(
    object != null ? eval(object?.description) : ""
  );

  const fileSelectorHandler = (event) => {
    setImage(event.target.files[0]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (subtitle === "" || title === "" || description === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            subtitle,
            title,
            description:richtext.editorValue,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminNewsletter(obj));
          successUpdateNotification("Newsletter", contants.UPDATE);
          setTitle("");
          setSubtitle("");
          setDescription("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              title,
              subtitle,
              creator: creatorName,
              language: lang,
              description:richtext.editorValue,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Newsletter", languageName);
          setTitle("");
          setSubtitle("");
          setDescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setTitle(title);
          setSubtitle(subtitle);
          setDescription(description);
          setdisabled(true);
        }
        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("image", image);
          fd.append("objects", JSON.stringify(objects));
          dispatch(actionTypes.createNewsletter(fd, token));
          setcounter(0);
          setObjects([]);
          setTitle("");
          setSubtitle("");
          setDescription("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Newsletter (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Newsletter (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Title"
                  value={title}
                  isClicked={clicked}
                  name="title"
                  onChange={(e) => setTitle(e.target.value)}
                />
                <InputText
                  title="Subtitle"
                  value={subtitle}
                  isClicked={clicked}
                  name="subtitle"
                  onChange={(e) => setSubtitle(e.target.value)}
                />
                <div className="">
                  <InputTextArea
                    title="Description"
                    data={description}
                    isClicked={clicked}
                    name="description"
                    onChange={(event) => {
                      setDescription(event);
                    }}
                  />
                </div>
                <Uploading
                  updating={object != null ? contants.isTrue : contants.isFalse}
                  title="Image"
                  text1="upload image"
                  text2="view the image"
                  name="image"
                  required
                  onChange={fileSelectorHandler}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/publications/newsletter/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const EventsPage = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const links = useSelector((state) => state.links.links);
  const platfomLinks = useSelector((state) => state.links.platfomLinks);
  const genericLinks = useSelector((state) => state.links.genericLinks);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminPublications.event);
  const params = useParams();
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const linksLoading = useSelector((state) => state.links.linksLoading);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(actionTypes.getAdminPlatfomLinks());
    dispatch(actionTypes.getAdminGenericLinks());
    dispatch(adminActionTypes.getOneAdminEvents(params.objectId));
  }, [dispatch]);

  const [title, settitle] = useState(object != null ? object?.title : "");
  const [subtitle, setsubtitle] = useState(
    object != null ? object?.subtitle : ""
  );
  const [hostname, sethostname] = useState(
    object != null ? object?.hostname : ""
  );
  const [hostposition, sethostposition] = useState(
    object != null ? object?.hostposition : ""
  );
  const [cardname, setcardname] = useState(
    object != null ? object?.cardname : ""
  );
  const [cardsummary, setcardsummary] = useState(
    object != null ? object?.cardsummary : ""
  );
  const [description, setdescription] = useState(
    object != null ? eval(object?.description) : ""
  );
  const [cardprice, setcardprice] = useState(
    object != null ? object?.cardprice : ""
  );
  const [cardpicture, setcardpicture] = useState(null);
  const [hostprofile, sethostprofile] = useState(null);

  const hostprofileSelectoHandler = (event) => {
    sethostprofile(event.target.files[0]);
  };

  const cardpictureSelectoHandler = (event) => {
    setcardpicture(event.target.files[0]);
  };

  //SHOWING SITE LINKS
  const [sitelinks, setsitelinks] = useState(false);
  const showOrHideSiteLinks = () => {
    setsitelinks(!sitelinks);
  };

  // SELECT BUTTO IMPLEMENTATION
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };
  // ON SUBMIT FUNCTION

  const onSubmit = (e) => {
    e.preventDefault();
    if (
      title === "" ||
      subtitle === "" ||
      cardname === "" ||
      cardsummary === "" ||
      description === "" ||
      cardprice === "" ||
      hostname === "" ||
      hostposition === ""
    ) {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            subtitle,
            cardname,
            cardsummary,
            description:richtext.editorValue,
            cardprice,
            hostname,
            hostposition,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminEvents(obj));
          successUpdateNotification("Event", contants.UPDATE);
          settitle("");
          setsubtitle("");
          sethostname("");
          sethostposition("");
          setcardname("");
          setcardprice("");
          setcardsummary("");
          setdescription("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const id = selectedValue.uuid;
          setObjects([
            ...objects,
            {
              title,
              subtitle,
              cardname,
              cardsummary,
              description:richtext.editorValue,
              cardprice,
              hostname,
              hostposition,
              link: id,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Event", languageName);
          settitle("");
          setsubtitle("");
          sethostname("");
          sethostposition("");
          setcardname("");
          setcardprice("");
          setcardsummary("");
          setdescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setsubtitle(subtitle);
          sethostname(hostname);
          sethostposition(hostposition);
          setcardname(cardname);
          setcardprice(cardprice);
          setcardsummary(cardsummary);
          setdescription(description);
          setdisabled(true);
        }
        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("cardpicture", cardpicture);
          fd.append("hostprofile", hostprofile);
          fd.append("objects", JSON.stringify(objects));
          dispatch(actionTypes.createEvent(fd, token));
          setcounter(0);
          setObjects([]);
          settitle("");
          setsubtitle("");
          sethostname("");
          sethostposition("");
          setcardname("");
          setcardprice("");
          setcardsummary("");
          setdescription("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12  space-x-8">
              <div className="flex flex-col pt-5 w-full ">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Event (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Event (Language: {object?.language}) Editing
                  </span>
                </div>
                <div className="flex space-x-10">
                  <div className="w-1/2">
                    <InputText
                      title="Title"
                      value={title}
                      isClicked={clicked}
                      name="title"
                      onChange={(e) => settitle(e.target.value)}
                    />
                    <InputText
                      title="Subtitle"
                      value={subtitle}
                      isClicked={clicked}
                      name="subtitle"
                      onChange={(e) => setsubtitle(e.target.value)}
                    />
                    <InputText
                      title="Host Name"
                      value={hostname}
                      isClicked={clicked}
                      name="hostname"
                      onChange={(e) => sethostname(e.target.value)}
                    />
                    <InputText
                      title="Host Position"
                      value={hostposition}
                      isClicked={clicked}
                      name="hostposition"
                      onChange={(e) => sethostposition(e.target.value)}
                    />
                    <Uploading
                      updating={
                        object != null ? contants.isTrue : contants.isFalse
                      }
                      title="Host Profile"
                      text1="Upload Profile"
                      text2="View the Profile"
                      name="hostpofile"
                      required
                      onChange={hostprofileSelectoHandler}
                    />
                    <Uploading
                      updating={
                        object != null ? contants.isTrue : contants.isFalse
                      }
                      title="Card Picture"
                      text1="Upload Card Picture"
                      text2="View the Card Picture"
                      name="cardpicture"
                      required
                      onChange={cardpictureSelectoHandler}
                    />
                  </div>
                  <div className="w-1/2">
                    <InputText
                      title="Card Name"
                      value={cardname}
                      isClicked={clicked}
                      name="cardname"
                      onChange={(e) => setcardname(e.target.value)}
                    />
                    <InputText
                      title="Card Price"
                      value={cardprice}
                      isClicked={clicked}
                      name="cardprice"
                      onChange={(e) => setcardprice(e.target.value)}
                    />
                    <InputText
                      title="Card Summary"
                      value={cardsummary}
                      isClicked={clicked}
                      name="cardsummary"
                      onChange={(e) => setcardsummary(e.target.value)}
                    />
                    <div className="pt-5 flex flex-col">
                      <ShowOrHideLinks
                        title={"Select From Platfom Links"}
                        name={"showLinks"}
                        option1="True"
                        option2="False"
                        value={sitelinks}
                        onChange={showOrHideSiteLinks}
                      />
                      <div
                        className={
                          sitelinks === true ? "pt-3 w-full block" : "hidden"
                        }
                      >
                        <label className="input-title">Platfom Links</label>
                        <div className="flex">
                          <AsyncSelect
                            placeholder="Select Platfom Link"
                            className={classnames("mb-2 w-90%", {
                              "mb-2 w-90% border-2 border-red-500":
                                selectedValue === "" && clicked === true,
                            })}
                            cacheOptions
                            defaultOptions={platfomLinks}
                            getOptionLabel={(e) =>
                              e.name + "  (" + e.language + ")"
                            }
                            getOptionValue={(e) => e.uuid}
                            value={selectedValue}
                            onChange={handleChange}
                            onInputChange={handleInputChange}
                            isSearchable
                          />
                        </div>
                        <label className="block text-red-500">
                          {selectedValue === "" && clicked === true
                            ? "Select Link"
                            : ""}
                        </label>
                      </div>
                    </div>
                    <div
                      className={
                        sitelinks !== true ? "pt-3 w-full block" : "hidden"
                      }
                    >
                      <label className="input-title"> Link</label>
                      <div className="flex">
                        <AsyncSelect
                          placeholder="Select Link"
                          className={classnames("mb-2 w-90%", {
                            "mb-2 w-90% border-2 border-red-500":
                              selectedValue === "" && clicked === true,
                          })}
                          cacheOptions
                          defaultOptions={genericLinks}
                          getOptionLabel={(e) =>
                            e.name + "  (" + e.language + ")"
                          }
                          getOptionValue={(e) => e.uuid}
                          value={selectedValue}
                          onChange={handleChange}
                          onInputChange={handleInputChange}
                          isSearchable
                        />
                      </div>
                      <label className="block text-red-500">
                        {selectedValue === "" && clicked === true
                          ? "Select Link"
                          : ""}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <InputTextArea
                    title="Description"
                    data={description}
                    isClicked={clicked}
                    name="description"
                    onChange={(event) => {
                      setdescription(event);
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col space-y-20 ">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/publications/events/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const MediaCoveragePage = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const links = useSelector((state) => state.links.links);
  const platfomLinks = useSelector((state) => state.links.platfomLinks);
  const genericLinks = useSelector((state) => state.links.genericLinks);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminPublications.mediaCoverage);
  const params = useParams();
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const linksLoading = useSelector((state) => state.links.linksLoading);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(actionTypes.getAdminPlatfomLinks());
    dispatch(actionTypes.getAdminGenericLinks());
    dispatch(adminActionTypes.getOneAdminMediaCoverage(params.objectId));
  }, [dispatch]);

  const [title, settitle] = useState(object != null ? object?.title : "");
  const [description, setdescription] = useState(
    object != null ? eval(object?.description) : ""
  );

  //SHOWING SITE LINKS
  const [sitelinks, setsitelinks] = useState(false);
  const showOrHideSiteLinks = () => {
    setsitelinks(!sitelinks);
  };

  // SELECT BUTTO IMPLEMENTATION
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };
  // ON SUBMIT FUNCTION

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || description === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            description:richtext.editorValue,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminMediaCoverage(obj));
          successUpdateNotification("Media Coverage", contants.UPDATE);
          settitle("");
          setdescription("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const id = selectedValue.uuid;
          setObjects([
            ...objects,
            {
              title,
              description:richtext.editorValue,
              link: id,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Media Coverage", languageName);
          settitle("");
          setdescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setdescription(description);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(actionTypes.createMediaCoverage(objects, token));
          setcounter(0);
          setObjects([]);
          settitle("");
          setdescription("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Media Coverage (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Media Coverage (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Title"
                  value={title}
                  isClicked={clicked}
                  name="title"
                  onChange={(e) => settitle(e.target.value)}
                />
                <InputTextArea
                  title="Description"
                  data={description}
                  isClicked={clicked}
                  name="description"
                  onChange={(event) => {
                    setdescription(event);
                  }}
                />
                <div className="pt-5 flex flex-col">
                  <ShowOrHideLinks
                    title={"Select From Platfom Links"}
                    name={"showLinks"}
                    option1="True"
                    option2="False"
                    value={sitelinks}
                    onChange={showOrHideSiteLinks}
                  />
                  <div
                    className={
                      sitelinks === true ? "pt-3 w-full block" : "hidden"
                    }
                  >
                    <label className="input-title">Platfom Links</label>
                    <div className="flex">
                      <AsyncSelect
                        placeholder="Select Platfom Link"
                        className={classnames("mb-2 w-90%", {
                          "mb-2 w-90% border-2 border-red-500":
                            selectedValue === "" && clicked === true,
                        })}
                        cacheOptions
                        defaultOptions={platfomLinks}
                        getOptionLabel={(e) =>
                          e.name + "  (" + e.language + ")"
                        }
                        getOptionValue={(e) => e.uuid}
                        value={selectedValue}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        isSearchable
                      />
                    </div>
                    <label className="block text-red-500">
                      {selectedValue === "" && clicked === true
                        ? "Select Link"
                        : ""}
                    </label>
                  </div>
                </div>
                <div
                  className={
                    sitelinks !== true ? "pt-3 w-full block" : "hidden"
                  }
                >
                  <label className="input-title"> Link</label>
                  <div className="flex">
                    <AsyncSelect
                      placeholder="Select Link"
                      className={classnames("mb-2 w-90%", {
                        "mb-2 w-90% border-2 border-red-500":
                          selectedValue === "" && clicked === true,
                      })}
                      cacheOptions
                      defaultOptions={genericLinks}
                      getOptionLabel={(e) => e.name + "  (" + e.language + ")"}
                      getOptionValue={(e) => e.uuid}
                      value={selectedValue}
                      onChange={handleChange}
                      onInputChange={handleInputChange}
                      isSearchable
                    />
                  </div>
                  <label className="block text-red-500">
                    {selectedValue === "" && clicked === true
                      ? "Select Link"
                      : ""}
                  </label>
                </div>
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/publications/media-coverage/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const GiftFeebiePage = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const links = useSelector((state) => state.links.links);
  const platfomLinks = useSelector((state) => state.links.platfomLinks);
  const genericLinks = useSelector((state) => state.links.genericLinks);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminPublications.giftAndFreebie);
  const params = useParams();
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const linksLoading = useSelector((state) => state.links.linksLoading);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(actionTypes.getAdminPlatfomLinks());
    dispatch(actionTypes.getAdminGenericLinks());
    dispatch(adminActionTypes.getOneAdminGiftsAndFreebies(params.objectId));
  }, [dispatch]);

  const [title, settitle] = useState(object != null ? object.title : "");
  const [image, setimage] = useState("");

  const fileSelectorHandler = (e) => {
    setimage(e.target.files[0]);
  };

  //SHOWING SITE LINKS
  const [sitelinks, setsitelinks] = useState(false);
  const showOrHideSiteLinks = () => {
    setsitelinks(!sitelinks);
  };

  // SELECT BUTTO IMPLEMENTATION
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };
  // ON SUBMIT FUNCTION

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminGiftsAndFreebies(obj));
          successUpdateNotification("Gift/Freebie", contants.UPDATE);
          settitle("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const id = selectedValue.uuid;
          setObjects([
            ...objects,
            {
              title,
              link: id,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Gifts and Freebie", languageName);
          settitle("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setdisabled(true);
        }
        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("image", image);
          fd.append("objects", JSON.stringify(objects));
          dispatch(actionTypes.createGiftAndFreebie(fd, token));
          setcounter(0);
          setObjects([]);
          settitle("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu
            pageLink="/admin/publications/gifts-and-freebie"
            menuName="Publications"
          />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Gift and Freebie (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Gift and Freebie (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Title"
                  value={title}
                  isClicked={clicked}
                  name="title"
                  onChange={(e) => settitle(e.target.value)}
                />
                <Uploading
                  updating={object != null ? contants.isTrue : contants.isFalse}
                  title="Profile"
                  text1="Upload Profile"
                  text2="View the Profile"
                  name="image"
                  required
                  onChange={fileSelectorHandler}
                />
                <div className="pt-5 flex flex-col">
                  <ShowOrHideLinks
                    title={"Select From Platfom Links"}
                    name={"showLinks"}
                    option1="True"
                    option2="False"
                    value={sitelinks}
                    onChange={showOrHideSiteLinks}
                  />
                  <div
                    className={
                      sitelinks === true ? "pt-3 w-full block" : "hidden"
                    }
                  >
                    <label className="input-title">Platfom Links</label>
                    <div className="flex">
                      <AsyncSelect
                        placeholder="Select Platfom Link"
                        className={classnames("mb-2 w-90%", {
                          "mb-2 w-90% border-2 border-red-500":
                            selectedValue === "" && clicked === true,
                        })}
                        cacheOptions
                        defaultOptions={platfomLinks}
                        getOptionLabel={(e) =>
                          e.name + "  (" + e.language + ")"
                        }
                        getOptionValue={(e) => e.uuid}
                        value={selectedValue}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        isSearchable
                      />
                    </div>
                    <label className="block text-red-500">
                      {selectedValue === "" && clicked === true
                        ? "Select Link"
                        : ""}
                    </label>
                  </div>
                </div>
                <div
                  className={
                    sitelinks !== true ? "pt-3 w-full block" : "hidden"
                  }
                >
                  <label className="input-title"> Link</label>
                  <div className="flex">
                    <AsyncSelect
                      placeholder="Select Link"
                      className={classnames("mb-2 w-90%", {
                        "mb-2 w-90% border-2 border-red-500":
                          selectedValue === "" && clicked === true,
                      })}
                      cacheOptions
                      defaultOptions={genericLinks}
                      getOptionLabel={(e) => e.name + "  (" + e.language + ")"}
                      getOptionValue={(e) => e.uuid}
                      value={selectedValue}
                      onChange={handleChange}
                      onInputChange={handleInputChange}
                      isSearchable
                    />
                  </div>
                  <label className="block text-red-500">
                    {selectedValue === "" && clicked === true
                      ? "Select Link"
                      : ""}
                  </label>
                </div>
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/publications/gifts-and-freebie/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const TodayAtOGenius = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminPublications.todayAtOGenius);
  const params = useParams();
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const linksLoading = useSelector((state) => state.links.linksLoading);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminTodayAtOgenius(params.objectId));
  }, [dispatch]);

  const [content, setcontent] = useState(object != null ? eval(object.content) : "");
  const [title, settitle] = useState(object != null ? object.title : "");

  // ON SUBMIT FUNCTION

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || content === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            content:richtext.editorValue,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminTodayAtOgenius(obj));
          successUpdateNotification("Today at OGenius", contants.UPDATE);
          settitle("");
          setcontent("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              content:richtext.editorValue,
              title,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Gifts and Freebie", languageName);
          setcontent("");
          settitle("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setcontent(content);
          settitle(title);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(actionTypes.createTodaysAtOGenius(objects, token));
          setcounter(0);
          setObjects([]);
          setcontent("");
          settitle("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Today At O'Genius (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Today At O'Genius (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Title"
                  value={title}
                  isClicked={clicked}
                  name="title"
                  onChange={(e) => settitle(e.target.value)}
                />
                <InputTextArea
                  title="Content"
                  data={content}
                  isClicked={clicked}
                  name="content"
                  onChange={(event) => {
                    setcontent(event);
                  }}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/publications/today-at-ogenius/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const AdminPublications = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminPublications.publication);
  const params = useParams();
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminPublications(params.objectId));
  }, [dispatch]);

  const [name, setname] = useState(object != null ? object.name : "");
  const [link, setlink] = useState(object != null ? object.link : "");

  // ON SUBMIT FUNCTION

  const onSubmit = (e) => {
    e.preventDefault();
    if (name === "" || link === "") {
      setclicked(true);
    } else {
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            name,
            link,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminPublications(obj));
          successUpdateNotification("Publication", contants.UPDATE);
          setname("");
          setlink("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              name,
              link,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Publications", languageName);
          setname("");
          setlink("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setname(name);
          setlink(link);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(actionTypes.createPublications(objects, token));
          setcounter(0);
          setObjects([]);
          setname("");
          setlink("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form onSubmit={onSubmit} className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12">
              <div className="flex flex-col pt-5 space-y-5">
                <div className="font-bold flex flex-col">
                  <span
                    className={
                      object != null
                        ? "hidden"
                        : "block admin-page-title text-xl"
                    }
                  >
                    New Publication (Language:{" "}
                    {languages[counter] ? languages[counter].name : ""})
                  </span>
                  <span
                    className={
                      object != null
                        ? "block admin-page-title text-xl"
                        : "hidden"
                    }
                  >
                    Publication (Language: {object?.language}) Editing
                  </span>
                </div>
                <InputText
                  title="Name"
                  value={name}
                  isClicked={clicked}
                  name="name"
                  onChange={(e) => setname(e.target.value)}
                />
                <InputText
                  title="Link"
                  value={link}
                  isClicked={clicked}
                  name="link"
                  onChange={(e) => setlink(e.target.value)}
                />
              </div>
              <div className="flex flex-col space-y-20">
                <div className="flex flex-col space-y-2">
                  <Button
                    disabled={disabled}
                    name={object === null ? contants.Save : contants.Update}
                    type="square"
                  />
                  <Button
                    name={contants.Publish}
                    disabled={!disabled}
                    type="square"
                  />
                  <TableLink
                    title="List"
                    type="viewLink"
                    link="/admin/publications/publications/objects/list"
                  />
                </div>
                <div>
                  <InsertingLanguagesTable
                    languages={languages}
                    counter={counter}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
