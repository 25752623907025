import { updateObject } from "../../utils/updateObject";

import * as adminActionTypes from "../actions/adminActionTypes";

const initialReducer = {
  adminServices: [],
  adminServicesError: null,
  adminServicesLoading: false,
  service: null,
  adminServiceWidgets: [],
  adminServiceWidgetsError: null,
  adminServiceWidgetsLoading: false,
  serviceWidget: null,
  testimonials: [],
  testimonialsError: null,
  testimonialsLoading: false,
  testimonial: null,
};

const getAdminServicesStart = (state, action) => {
  return updateObject(state, {
    adminServices: [],
    adminServicesError: null,
    adminServicesLoading: true,
    service: null,
  });
};

const getAdminServicesSuccess = (state, action) => {
  return updateObject(state, {
    adminServices: action.adminServices,
    adminServicesError: null,
    adminServicesLoading: false,
    service: null,
  });
};

const getAdminServicesFail = (state, action) => {
  return updateObject(state, {
    adminServices: [],
    adminServicesError: action.error,
    adminServicesLoading: false,
    service: null,
  });
};

const getOneAdminServicesSuccess = (state, action) => {
  return updateObject(state, {
    adminServices: [],
    adminServicesError: null,
    adminServicesLoading: false,
    service: action.service,
  });
};

const getAdminServiceWidgetsStart = (state, action) => {
  return updateObject(state, {
    adminServiceWidgets: [],
    adminServiceWidgetsError: null,
    adminServiceWidgetsLoading: true,
    serviceWidget: null,
  });
};

const getAdminServiceWidgetsSuccess = (state, action) => {
  return updateObject(state, {
    adminServiceWidgets: action.adminServiceWidgets,
    adminServiceWidgetsError: null,
    adminServiceWidgetsLoading: false,
    serviceWidget: null,
  });
};

const getAdminServiceWidgetsFail = (state, action) => {
  return updateObject(state, {
    adminServiceWidgets: [],
    adminServiceWidgetsError: action.error,
    adminServiceWidgetsLoading: false,
  });
};

const getOneAdminServiceWidgetsSuccess = (state, action) => {
  return updateObject(state, {
    adminServiceWidgetsError: null,
    adminServiceWidgetsLoading: false,
    serviceWidget: action.serviceWidget,
  });
};

const getTestimonialsStart = (state, action) => {
  return updateObject(state, {
    testimonials: [],
    testimonialsError: null,
    testimonialsLoading: true,
    testimonial: null,
  });
};

const getTestimonialsSuccess = (state, action) => {
  return updateObject(state, {
    testimonials: action.testimonials,
    testimonialsError: null,
    testimonialsLoading: false,
    testimonial: null,
  });
};

const getTestimonialsFail = (state, action) => {
  return updateObject(state, {
    testimonials: [],
    testimonialsError: action.error,
    testimonialsLoading: false,
    testimonial: null,
  });
};

const getOneTestimonialsSuccess = (state, action) => {
  return updateObject(state, {
    testimonials: [],
    testimonialsError: null,
    testimonialsLoading: false,
    testimonial: action.testimonial,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case adminActionTypes.GET_ADMIN_SERVICES_START:
      return getAdminServicesStart(state, action);
    case adminActionTypes.GET_ADMIN_SERVICES_SUCCESS:
      return getAdminServicesSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_SERVICES_SUCCESS:
      return getOneAdminServicesSuccess(state, action);
    case adminActionTypes.GET_ADMIN_SERVICES_FAIL:
      return getAdminServicesFail(state, action);
    case adminActionTypes.GET_ADMIN_SERVICE_WIDGETS_START:
      return getAdminServiceWidgetsStart(state, action);
    case adminActionTypes.GET_ADMIN_SERVICE_WIDGETS_SUCCESS:
      return getAdminServiceWidgetsSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_SERVICE_WIDGETS_SUCCESS:
      return getOneAdminServiceWidgetsSuccess(state, action);
    case adminActionTypes.GET_ADMIN_SERVICE_WIDGETS_FAIL:
      return getAdminServiceWidgetsFail(state, action);
    case adminActionTypes.GET_TESTIMONIALS_START:
      return getTestimonialsStart(state, action);
    case adminActionTypes.GET_TESTIMONIALS_SUCCESS:
      return getTestimonialsSuccess(state, action);
    case adminActionTypes.GET_ONE_TESTIMONIALS_SUCCESS:
      return getOneTestimonialsSuccess(state, action);
    case adminActionTypes.GET_TESTIMONIALS_FAIL:
      return getTestimonialsFail(state, action);
    default:
      return state;
  }
};
export default reducer;
