import React from "react";
import { PhoneImage } from "../../servicesComponent/ServicesListComponents";
import parse from "html-react-parser";

const WidgetG = (props) => {
  return (
    <div
      id={props.widget.hashlink}
      className="interactive-service flex flex-col"
    >
      <div className="flex flex-col z-20 w-full md:flex-row md:pt-20">
        <div className="interactive-service-text w-full md:w-2/5 md:p-2 p-10">
          <div className="text-right interactive-service-title site-text-blue md:py-4 py-10">
            <span>{props.widget.title}</span>
          </div>
          <div className="text-right interactive-service-body">
            {
              props.widget.body != undefined ? parse(eval(props.widget.body)) : parse("<p></p>"
            )}
          </div>
        </div>
        <div
          data-aos="zoom-in-down"
          data-aos-duration="1000"
          className="interactive-service-body w-full md:w-3/5"
        >
          <div className="w-full interactive-images flex flex-col items-center overflow-hidden">
            <div className="w-80% m-auto interactive-pc-image flex-shrink-0 h-30vh md:h-50vh px-6 py-3">
              <div className="interactive-bio-bg h-full object-center object-cover"></div>
            </div>
            <div className="interactive-pc-image-bottom w-full h-5"></div>
            <div
              data-aos="zoom-out-up"
              data-aos-duration="2000"
              className="z-20 absolute interactive-phone-height"
            >
              <PhoneImage
                image={`${process.env.REACT_APP_BACKEND_URL}/site/${props.widget.image}`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetG;
