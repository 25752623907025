import {
  ContactUsCard,
  FAQs,
  InformationCard,
} from '../contactus/ContactUsComponentsList';
import Footer from '../footerComponents/Footer';
import Header from '../headerCompoents/Header';
import ScrollToTop from '../components/ScrollToTop';

export const ContactUs = () => {
  return (
    <div>
      <Header pageLink="/contact-us" />
      <ContactUsCard />
      <InformationCard />
      <FAQs />
      <Footer />
      <ScrollToTop />
    </div>
  );
};
