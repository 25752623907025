import axios from "../../axios-base";
import * as adminActionTypes from "./adminActionTypes.js";
import * as notifications from "../../admin/contants";

export const getAdminPublicationsStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_PUBLICATIONS_START,
  };
};

export const getAdminPublicationsSuccess = (adminPublications) => {
  return {
    type: adminActionTypes.GET_ADMIN_PUBLICATIONS_SUCCESS,
    adminPublications: adminPublications,
  };
};

export const getOneAdminPublicationsSuccess = (publication) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_PUBLICATIONS_SUCCESS,
    publication: publication,
  };
};

export const getAdminPublicationsFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_PUBLICATIONS_FAIL,
    error: error,
  };
};

export const getAdminPublications = (lang) => {
  return (dispatch) => {
    dispatch(getAdminPublicationsStart());
    axios
      .get(`/site/publications`)
      .then((response) => {
        dispatch(getAdminPublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPublicationsFail(err.message));
      });
  };
};

export const getOneAdminPublications = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPublicationsStart());
    axios
      .get(`/site/publications/publication/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPublicationsFail(err.message));
      });
  };
};

export const deleteAdminPublications = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPublicationsStart());
    axios
      .delete(`/site/publications/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPublicationsFail(err.message));
      });
  };
};

export const activateAdminPublications = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPublicationsStart());
    axios
      .put(`/site/publications/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPublicationsFail(err.message));
      });
  };
};

export const desactivateAdminPublications = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPublicationsStart());
    axios
      .put(`/site/publications/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPublicationsFail(err.message));
      });
  };
};

export const updateAdminPublications = (object) => {
  return (dispatch) => {
    dispatch(getAdminPublicationsStart());
    axios
      .put(`/site/publications/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminPublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPublicationsFail(err.message));
      });
  };
};

export const archiveAdminPublications = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPublicationsStart());
    axios
      .put(`/site/publications/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPublicationsFail(err.message));
      });
  };
};

export const visibleAdminPublications = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminPublicationsStart());
    axios
      .put(`/site/publications/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminPublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminPublicationsFail(err.message));
      });
  };
};

export const getAdminNewsletterStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_NEWSLETTER_START,
  };
};

export const getAdminNewsletterSuccess = (adminNewsletter) => {
  return {
    type: adminActionTypes.GET_ADMIN_NEWSLETTER_SUCCESS,
    adminNewsletter: adminNewsletter,
  };
};

export const getOneAdminNewsletterSuccess = (newsletter) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_NEWSLETTER_SUCCESS,
    newsletter: newsletter,
  };
};

export const getAdminNewsletterFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_NEWSLETTER_FAIL,
    error: error,
  };
};

export const getAdminNewsletter = (lang) => {
  return (dispatch) => {
    dispatch(getAdminNewsletterStart());
    axios
      .get(`/site/newsletters`)
      .then((response) => {
        dispatch(getAdminNewsletterSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminNewsletterFail(err.message));
      });
  };
};

export const getOneAdminNewsletter = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminNewsletterStart());
    axios
      .get(`/site/newsletters/newsletter/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminNewsletterSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminNewsletterFail(err.message));
      });
  };
};

export const deleteAdminNewsletter = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminNewsletterStart());
    axios
      .delete(`/site/newsletters/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminNewsletterSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminNewsletterFail(err.message));
      });
  };
};

export const activateAdminNewsletter = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminNewsletterStart());
    axios
      .put(`/site/newsletters/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminNewsletterSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminNewsletterFail(err.message));
      });
  };
};

export const desactivateAdminNewsletter = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminNewsletterStart());
    axios
      .put(`/site/newsletters/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminNewsletterSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminNewsletterFail(err.message));
      });
  };
};

export const updateAdminNewsletter = (object) => {
  return (dispatch) => {
    dispatch(getAdminNewsletterStart());
    axios
      .put(`/site/newsletters/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminNewsletterSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminNewsletterFail(err.message));
      });
  };
};

export const archiveAdminNewsletter = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminNewsletterStart());
    axios
      .put(`/site/newsletters/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminNewsletterSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminNewsletterFail(err.message));
      });
  };
};

export const visibleAdminNewsletter = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminNewsletterStart());
    axios
      .put(`/site/newsletters/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminNewsletterSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminNewsletterFail(err.message));
      });
  };
};

export const getAdminEventsStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_EVENTS_START,
  };
};

export const getAdminEventsSuccess = (adminEvents) => {
  return {
    type: adminActionTypes.GET_ADMIN_EVENTS_SUCCESS,
    adminEvents: adminEvents,
  };
};

export const getOneAdminEventsSuccess = (event) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_EVENTS_SUCCESS,
    event: event,
  };
};

export const getAdminEventsFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_EVENTS_FAIL,
    error: error,
  };
};

export const getAdminEvents = (lang) => {
  return (dispatch) => {
    dispatch(getAdminEventsStart());
    axios
      .get(`/site/events`)
      .then((response) => {
        dispatch(getAdminEventsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEventsFail(err.message));
      });
  };
};

export const getOneAdminEvents = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminEventsStart());
    axios
      .get(`/site/events/event/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminEventsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEventsFail(err.message));
      });
  };
};

export const deleteAdminEvents = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminEventsStart());
    axios
      .delete(`/site/events/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminEventsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEventsFail(err.message));
      });
  };
};

export const activateAdminEvents = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminEventsStart());
    axios
      .put(`/site/events/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminEventsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEventsFail(err.message));
      });
  };
};

export const desactivateAdminEvents = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminEventsStart());
    axios
      .put(`/site/events/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminEventsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEventsFail(err.message));
      });
  };
};

export const updateAdminEvents = (object) => {
  return (dispatch) => {
    dispatch(getAdminEventsStart());
    axios
      .put(`/site/events/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminEventsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEventsFail(err.message));
      });
  };
};

export const archiveAdminEvents = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminEventsStart());
    axios
      .put(`/site/events/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminEventsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEventsFail(err.message));
      });
  };
};

export const visibleAdminEvents = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminEventsStart());
    axios
      .put(`/site/events/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminEventsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminEventsFail(err.message));
      });
  };
};

export const getAdminMediaCoverageStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_MEDIA_COVERAGE_START,
  };
};

export const getAdminMediaCoverageSuccess = (adminMediaCoverage) => {
  return {
    type: adminActionTypes.GET_ADMIN_MEDIA_COVERAGE_SUCCESS,
    adminMediaCoverage: adminMediaCoverage,
  };
};

export const getOneAdminMediaCoverageSuccess = (mediaCoverage) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_MEDIA_COVERAGE_SUCCESS,
    mediaCoverage: mediaCoverage,
  };
};

export const getAdminMediaCoverageFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_MEDIA_COVERAGE_FAIL,
    error: error,
  };
};

export const getAdminMediaCoverage = (lang) => {
  return (dispatch) => {
    dispatch(getAdminMediaCoverageStart());
    axios
      .get(`/site/media-coverages`)
      .then((response) => {
        dispatch(getAdminMediaCoverageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMediaCoverageFail(err.message));
      });
  };
};

export const getOneAdminMediaCoverage = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminMediaCoverageStart());
    axios
      .get(`/site/media-coverages/coverage/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminMediaCoverageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMediaCoverageFail(err.message));
      });
  };
};

export const deleteAdminMediaCoverage = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminMediaCoverageStart());
    axios
      .delete(`/site/media-coverages/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminMediaCoverageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMediaCoverageFail(err.message));
      });
  };
};

export const activateAdminMediaCoverage = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminMediaCoverageStart());
    axios
      .put(`/site/media-coverages/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminMediaCoverageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMediaCoverageFail(err.message));
      });
  };
};

export const desactivateAdminMediaCoverage = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminMediaCoverageStart());
    axios
      .put(`/site/media-coverages/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminMediaCoverageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMediaCoverageFail(err.message));
      });
  };
};

export const updateAdminMediaCoverage = (object) => {
  return (dispatch) => {
    dispatch(getAdminMediaCoverageStart());
    axios
      .put(`/site/media-coverages/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminMediaCoverageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMediaCoverageFail(err.message));
      });
  };
};

export const archiveAdminMediaCoverage = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminMediaCoverageStart());
    axios
      .put(`/site/media-coverages/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminMediaCoverageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMediaCoverageFail(err.message));
      });
  };
};

export const visibleAdminMediaCoverage = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminMediaCoverageStart());
    axios
      .put(`/site/media-coverages/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminMediaCoverageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMediaCoverageFail(err.message));
      });
  };
};

export const getAdminGiftsAndFreebiesStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_GIFTS_AND_FREEBIES_START,
  };
};

export const getAdminGiftsAndFreebiesSuccess = (adminGiftAndFreebies) => {
  return {
    type: adminActionTypes.GET_ADMIN_GIFTS_AND_FREEBIES_SUCCESS,
    adminGiftAndFreebies: adminGiftAndFreebies,
  };
};

export const getOneAdminGiftsAndFreebiesSuccess = (giftAndFreebie) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_GIFTS_AND_FREEBIES_SUCCESS,
    giftAndFreebie: giftAndFreebie,
  };
};

export const getAdminGiftsAndFreebiesFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_GIFTS_AND_FREEBIES_FAIL,
    error: error,
  };
};

export const getAdminGiftsAndFreebies = (lang) => {
  return (dispatch) => {
    dispatch(getAdminGiftsAndFreebiesStart());
    axios
      .get(`/site/gift-freebies`)
      .then((response) => {
        dispatch(getAdminGiftsAndFreebiesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGiftsAndFreebiesFail(err.message));
      });
  };
};

export const getOneAdminGiftsAndFreebies = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminGiftsAndFreebiesStart());
    axios
      .get(`/site/gift-freebies/gift/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminGiftsAndFreebiesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGiftsAndFreebiesFail(err.message));
      });
  };
};

export const deleteAdminGiftsAndFreebies = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminGiftsAndFreebiesStart());
    axios
      .delete(`/site/gift-freebies/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminGiftsAndFreebiesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGiftsAndFreebiesFail(err.message));
      });
  };
};

export const activateAdminGiftsAndFreebies = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminGiftsAndFreebiesStart());
    axios
      .put(`/site/gift-freebies/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminGiftsAndFreebiesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGiftsAndFreebiesFail(err.message));
      });
  };
};

export const desactivateAdminGiftsAndFreebies = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminGiftsAndFreebiesStart());
    axios
      .put(`/site/gift-freebies/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminGiftsAndFreebiesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGiftsAndFreebiesFail(err.message));
      });
  };
};

export const updateAdminGiftsAndFreebies = (object) => {
  return (dispatch) => {
    dispatch(getAdminGiftsAndFreebiesStart());
    axios
      .put(`/site/gift-freebies/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminGiftsAndFreebiesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGiftsAndFreebiesFail(err.message));
      });
  };
};

export const archiveAdminGiftsAndFreebies = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminGiftsAndFreebiesStart());
    axios
      .put(`/site/gift-freebies/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminGiftsAndFreebiesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGiftsAndFreebiesFail(err.message));
      });
  };
};

export const visibleAdminGiftsAndFreebies = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminGiftsAndFreebiesStart());
    axios
      .put(`/site/gift-freebies/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminGiftsAndFreebiesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminGiftsAndFreebiesFail(err.message));
      });
  };
};

export const getAdminTodayAtOgeniusStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_TODAY_AT_OGENIUS_START,
  };
};

export const getAdminTodayAtOgeniusSuccess = (adminTodayAtOGenius) => {
  return {
    type: adminActionTypes.GET_ADMIN_TODAY_AT_OGENIUS_SUCCESS,
    adminTodayAtOGenius: adminTodayAtOGenius,
  };
};

export const getOneAdminTodayAtOgeniusSuccess = (todayAtOGenius) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_TODAY_AT_OGENIUS_SUCCESS,
    todayAtOGenius: todayAtOGenius,
  };
};

export const getAdminTodayAtOgeniusFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_TODAY_AT_OGENIUS_FAIL,
    error: error,
  };
};

export const getAdminTodayAtOgenius = (lang) => {
  return (dispatch) => {
    dispatch(getAdminTodayAtOgeniusStart());
    axios
      .get(`/site/today-at-ogenius`)
      .then((response) => {
        dispatch(getAdminTodayAtOgeniusSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodayAtOgeniusFail(err.message));
      });
  };
};

export const getOneAdminTodayAtOgenius = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminTodayAtOgeniusStart());
    axios
      .get(`/site/today-at-ogenius/today/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminTodayAtOgeniusSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodayAtOgeniusFail(err.message));
      });
  };
};

export const deleteAdminTodayAtOgenius = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminTodayAtOgeniusStart());
    axios
      .delete(`/site/today-at-ogenius/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminTodayAtOgeniusSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodayAtOgeniusFail(err.message));
      });
  };
};

export const activateAdminTodayAtOgenius = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminTodayAtOgeniusStart());
    axios
      .put(`/site/today-at-ogenius/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminTodayAtOgeniusSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodayAtOgeniusFail(err.message));
      });
  };
};

export const desactivateAdminTodayAtOgenius = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminTodayAtOgeniusStart());
    axios
      .put(`/site/today-at-ogenius/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminTodayAtOgeniusSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodayAtOgeniusFail(err.message));
      });
  };
};

export const updateAdminTodayAtOgenius = (object) => {
  return (dispatch) => {
    dispatch(getAdminTodayAtOgeniusStart());
    axios
      .put(`/site/today-at-ogenius/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminTodayAtOgeniusSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodayAtOgeniusFail(err.message));
      });
  };
};

export const archiveAdminTodayAtOgenius = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminTodayAtOgeniusStart());
    axios
      .put(`/site/today-at-ogenius/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminTodayAtOgeniusSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodayAtOgeniusFail(err.message));
      });
  };
};

export const visibleAdminTodayAtOgenius = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminTodayAtOgeniusStart());
    axios
      .put(`/site/today-at-ogenius/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminTodayAtOgeniusSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminTodayAtOgeniusFail(err.message));
      });
  };
};
