import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Buttons";
import parse from "html-react-parser";

const WidgetI = (props) => {
  const { t } = useTranslation();
  return (
    <div
      id={props.widget.hashlink}
      className="ogenius-panda flex flex-col py-10 lg:pl-10 lg:flex-row"
    >
      <div
        data-aos="slide-up"
        data-aos-duration="1500"
        className="w-full ogenius-panda-body flex flex-col text-right pr-10 justify-between"
      >
        <div className="product-name text-xl pb-5">
          <span>{props.widget.title}</span>
        </div>
        <div className="space-y-6 ogenius-panda-text lg:max-h-30vh overflow-scroll scrollbar-hidden">
          {
            props.widget.body != undefined ? parse(eval(props.widget.body)) : parse("<p></p>"
          )}
        </div>
        <a
          href={props.widget.externallink}
          target="_blank"
          rel="noreferrer"
          className="py-5 lg:p-0"
        >
          <Button name={t("Visit Platform")} />
        </a>
      </div>
      <div className="w-full h-35vh md:h-50vh">
        <img
          className="h-full w-full object-cover object-center"
          alt="Swiftmonitor"
          src={`${process.env.REACT_APP_BACKEND_URL}/site/${props.widget.image}`}
        />
      </div>
    </div>
  );
};

export default WidgetI;
