import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";
import * as notifications from "../../admin/contants";

export const getLinksStart = () => {
  return {
    type: actionTypes.GET_LINKS_START,
  };
};

export const getLinksSuccess = (links) => {
  return {
    type: actionTypes.GET_LINKS_SUCCESS,
    links: links,
  };
};

export const getAdminGenericLinksSuccess = (links) => {
  return {
    type: actionTypes.GET_ADMIN_GENERIC_LINKS_SUCCESS,
    genericLinks: links,
  };
};

export const getAdminPlatfomLinksSuccess = (links) => {
  return {
    type: actionTypes.GET_ADMIN_PLATFOM_LINKS_SUCCESS,
    platfomLinks: links,
  };
};

export const getOneLinksSuccess = (link) => {
  return {
    type: actionTypes.GET_ONE_LINKS_SUCCESS,
    link: link,
  };
};

export const getLinksFail = (error) => {
  return {
    type: actionTypes.GET_LINKS_FAIL,
    error: error,
  };
};

export const getActiveLinks = () => {
  return (dispatch) => {
    dispatch(getLinksStart());
    axios
      .get(`/site/links/active`)
      .then((response) => {
        dispatch(getLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLinksFail(err.message));
      });
  };
};

export const getLinks = () => {
  return (dispatch) => {
    dispatch(getLinksStart());
    axios
      .get(`/site/links`)
      .then((response) => {
        dispatch(getLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLinksFail(err.message));
      });
  };
};

export const getAdminGenericLinks = () => {
  return (dispatch) => {
    dispatch(getLinksStart());
    axios
      .get(`/site/links/generic-links`)
      .then((response) => {
        dispatch(getAdminGenericLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLinksFail(err.message));
      });
  };
};

export const getAdminPlatfomLinks = () => {
  return (dispatch) => {
    dispatch(getLinksStart());
    axios
      .get(`/site/links/platfom-links`)
      .then((response) => {
        dispatch(getAdminPlatfomLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLinksFail(err.message));
      });
  };
};

export const getOneLinks = (objectId) => {
  return (dispatch) => {
    dispatch(getLinksStart());
    axios
      .get(`/site/links/link/${objectId}`)
      .then((response) => {
        dispatch(getOneLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLinksFail(err.message));
      });
  };
};

export const deleteLinks = (objectId) => {
  return (dispatch) => {
    dispatch(getLinksStart());
    axios
      .delete(`/site/links/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLinksFail(err.message));
      });
  };
};

export const activateLinks = (objectId) => {
  return (dispatch) => {
    dispatch(getLinksStart());
    axios
      .put(`/site/links/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLinksFail(err.message));
      });
  };
};

export const desactivateLinks = (objectId) => {
  return (dispatch) => {
    dispatch(getLinksStart());
    axios
      .put(`/site/links/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLinksFail(err.message));
      });
  };
};

export const updateLinks = (object) => {
  return (dispatch) => {
    dispatch(getLinksStart());
    axios
      .put(`/site/links/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLinksFail(err.message));
      });
  };
};

export const archiveLinks = (objectId) => {
  return (dispatch) => {
    dispatch(getLinksStart());
    axios
      .put(`/site/links/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLinksFail(err.message));
      });
  };
};

export const visibleLinks = (objectId) => {
  return (dispatch) => {
    dispatch(getLinksStart());
    axios
      .put(`/site/links/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getLinksFail(err.message));
      });
  };
};

export const createLinks = (object, token) => {
  return (dispatch) => {
    axios
      .post(`/site/links/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getLinksSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLinksFail(err.message));
      });
  };
};
