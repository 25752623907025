import { updateObject } from '../../utils/updateObject';

import * as actionsTypes from '../actions/actionTypes';

const initialReducer = {
  publications: [],
  publicationsError: null,
  publicationsLoading: false,
  newsletter: null,
  newsletterError: null,
  newsletterLoading: false,
  events: [],
  eventsError: null,
  eventsLoading: false,
  mediaCoverage: [],
  mediaCoverageError: null,
  mediaCoverageLoading: false,
  giftsAndFreebies: [],
  giftsAndFreebiesError: null,
  giftsAndFreebiesLoading: false,
  todayAtOgenius: null,
  todayAtOgeniusError: null,
  todayAtOgeniusLoading: false,
};

const getPublicationsStart = (state, action) => {
  return updateObject(state, {
    publications: [],
    publicationsError: null,
    publicationsLoading: true,
  });
};

const getPublicationsSuccess = (state, action) => {
  return updateObject(state, {
    publications: action.publications,
    publicationsError: null,
    publicationsLoading: false,
  });
};

const getPublicationsFail = (state, action) => {
  return updateObject(state, {
    publications: [],
    publicationsError: action.error,
    publicationsLoading: false,
  });
};

const getNewsletterStart = (state, action) => {
  return updateObject(state, {
    newsletter: null,
    newsletterError: null,
    newsletterLoading: true,
  });
};

const getNewsletterSuccess = (state, action) => {
  return updateObject(state, {
    newsletter: action.newsletter,
    newsletterError: null,
    newsletterLoading: false,
  });
};

const getNewsletterFail = (state, action) => {
  return updateObject(state, {
    newsletter: null,
    newsletterError: action.error,
    newsletterLoading: false,
  });
};

const getEventsStart = (state, action) => {
  return updateObject(state, {
    events: [],
    eventsError: null,
    eventsLoading: true,
  });
};

const getEventsSuccess = (state, action) => {
  return updateObject(state, {
    events: action.events,
    eventsError: null,
    eventsLoading: false,
  });
};

const getEventsFail = (state, action) => {
  return updateObject(state, {
    events: [],
    eventsError: action.error,
    eventsLoading: false,
  });
};

const getMediaCoverageStart = (state, action) => {
  return updateObject(state, {
    mediaCoverage: [],
    mediaCoverageError: null,
    mediaCoverageLoading: true,
  });
};

const getMediaCoverageSuccess = (state, action) => {
  return updateObject(state, {
    mediaCoverage: action.mediaCoverage,
    mediaCoverageError: null,
    mediaCoverageLoading: false,
  });
};

const getMediaCoverageFail = (state, action) => {
  return updateObject(state, {
    mediaCoverage: [],
    mediaCoverageError: action.error,
    mediaCoverageLoading: false,
  });
};

const getGiftsAndFreebiesStart = (state, action) => {
  return updateObject(state, {
    giftsAndFreebies: [],
    giftsAndFreebiesError: null,
    giftsAndFreebiesLoading: true,
  });
};

const getGiftsAndFreebiesSuccess = (state, action) => {
  return updateObject(state, {
    giftsAndFreebies: action.giftsAndFreebies,
    giftsAndFreebiesError: null,
    giftsAndFreebiesLoading: false,
  });
};

const getGiftsAndFreebiesFail = (state, action) => {
  return updateObject(state, {
    giftsAndFreebies: [],
    giftsAndFreebiesError: action.error,
    giftsAndFreebiesLoading: false,
  });
};

const getTodayAtOgeniusStart = (state, action) => {
  return updateObject(state, {
    todayAtOgenius: null,
    todayAtOgeniusError: null,
    todayAtOgeniusLoading: true,
  });
};

const getTodayAtOgeniusSuccess = (state, action) => {
  return updateObject(state, {
    todayAtOgenius: action.todayAtOgenius,
    todayAtOgeniusError: null,
    todayAtOgeniusLoading: false,
  });
};

const getTodayAtOgeniusFail = (state, action) => {
  return updateObject(state, {
    todayAtOgenius: null,
    todayAtOgeniusError: action.error,
    todayAtOgeniusLoading: false,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_PUBLICATIONS_START:
      return getPublicationsStart(state, action);
    case actionsTypes.GET_PUBLICATIONS_SUCCESS:
      return getPublicationsSuccess(state, action);
    case actionsTypes.GET_PUBLICATIONS_FAIL:
      return getPublicationsFail(state, action);
    case actionsTypes.GET_NEWSLETTER_START:
      return getNewsletterStart(state, action);
    case actionsTypes.GET_NEWSLETTER_SUCCESS:
      return getNewsletterSuccess(state, action);
    case actionsTypes.GET_NEWSLETTER_FAIL:
      return getNewsletterFail(state, action);
    case actionsTypes.GET_EVENTS_START:
      return getEventsStart(state, action);
    case actionsTypes.GET_EVENTS_SUCCESS:
      return getEventsSuccess(state, action);
    case actionsTypes.GET_EVENTS_FAIL:
      return getEventsFail(state, action);
    case actionsTypes.GET_MEDIA_COVERAGE_START:
      return getMediaCoverageStart(state, action);
    case actionsTypes.GET_MEDIA_COVERAGE_SUCCESS:
      return getMediaCoverageSuccess(state, action);
    case actionsTypes.GET_MEDIA_COVERAGE_FAIL:
      return getMediaCoverageFail(state, action);
    case actionsTypes.GET_GIFTS_AND_FREEBIES_START:
      return getGiftsAndFreebiesStart(state, action);
    case actionsTypes.GET_GIFTS_AND_FREEBIES_SUCCESS:
      return getGiftsAndFreebiesSuccess(state, action);
    case actionsTypes.GET_GIFTS_AND_FREEBIES_FAIL:
      return getGiftsAndFreebiesFail(state, action);
    case actionsTypes.GET_TODAY_AT_OGENIUS_START:
      return getTodayAtOgeniusStart(state, action);
    case actionsTypes.GET_TODAY_AT_OGENIUS_SUCCESS:
      return getTodayAtOgeniusSuccess(state, action);
    case actionsTypes.GET_TODAY_AT_OGENIUS_FAIL:
      return getTodayAtOgeniusFail(state, action);
    default:
      return state;
  }
};
export default reducer;
