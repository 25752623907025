import { updateObject } from '../../utils/updateObject';

import * as actionsTypes from '../actions/actionTypes';

const initialReducer = {
  partners: [],
  partnersError: null,
  partnersLoading: false,
  partnersIntro: null,
  partnersIntroError: null,
  partnersIntroLoading: false,
  affiliations: [],
  affiliationsError: null,
  affiliationsLoading: false,
};

const getPartnersStart = (state, action) => {
  return updateObject(state, {
    partners: [],
    partnersError: null,
    partnersLoading: true,
  });
};

const getPartnersSuccess = (state, action) => {
  return updateObject(state, {
    partners: action.partners,
    partnersError: null,
    partnersLoading: false,
  });
};

const getPartnersFail = (state, action) => {
  return updateObject(state, {
    partners: [],
    partnersError: action.error,
    partnersLoading: false,
  });
};

const getPartnersIntroStart = (state, action) => {
  return updateObject(state, {
    partnersIntro: null,
    partnersIntroError: null,
    partnersIntroLoading: true,
  });
};

const getPartnersIntroSuccess = (state, action) => {
  return updateObject(state, {
    partnersIntro: action.partnersIntro,
    partnersIntroError: null,
    partnersIntroLoading: false,
  });
};

const getPartnersIntroFail = (state, action) => {
  return updateObject(state, {
    partnersIntro: null,
    partnersIntroError: action.error,
    partnersIntroLoading: false,
  });
};

const getAffiliationsStart = (state, action) => {
  return updateObject(state, {
    affiliations: [],
    affiliationsError: null,
    affiliationsLoading: true,
  });
};

const getAffiliationsSuccess = (state, action) => {
  return updateObject(state, {
    affiliations: action.affiliations,
    affiliationsError: null,
    affiliationsLoading: false,
  });
};

const getAffiliationsFail = (state, action) => {
  return updateObject(state, {
    affiliations: [],
    affiliationsError: action.error,
    affiliationsLoading: false,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_PARTNERS_START:
      return getPartnersStart(state, action);
    case actionsTypes.GET_PARTNERS_SUCCESS:
      return getPartnersSuccess(state, action);
    case actionsTypes.GET_PARTNERS_FAIL:
      return getPartnersFail(state, action);
    case actionsTypes.GET_PARTNERS_INTRO_START:
      return getPartnersIntroStart(state, action);
    case actionsTypes.GET_PARTNERS_INTRO_SUCCESS:
      return getPartnersIntroSuccess(state, action);
    case actionsTypes.GET_PARTNERS_INTRO_FAIL:
      return getPartnersIntroFail(state, action);
    case actionsTypes.GET_AFFILIATIONS_START:
      return getAffiliationsStart(state, action);
    case actionsTypes.GET_AFFILIATIONS_SUCCESS:
      return getAffiliationsSuccess(state, action);
    case actionsTypes.GET_AFFILIATIONS_FAIL:
      return getAffiliationsFail(state, action);
    default:
      return state;
  }
};
export default reducer;
