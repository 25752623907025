import { Button, SelectButton } from "../components/Buttons";
import { VscChromeClose } from "react-icons/vsc";
import { CKEditor } from "ckeditor4-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parser from "html-react-parser";
import { useState } from "react";
import {
  AiFillEdit,
  AiFillDelete,
  AiFillEye,
  AiOutlineUnorderedList,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import { BsArchiveFill, BsCheckCircle } from "react-icons/bs";
import { GiRadioactive } from "react-icons/gi";
import * as adminActionTypes from "../store/actions/adminIndex";
import { Link } from "react-router-dom";
import moment from "moment";
import contants, {
  successUpdateNotification,
  successDeleteNotification,
  confirmNotification,
} from "./contants";
import classnames from "classnames";
import { IoAddCircleOutline } from "react-icons/io5";
import { MdWidgets } from "react-icons/md";
import { SocialMediaLink } from "../components/SocialMediaLink";
import { IoLogoInstagram, IoMdGlobe } from "react-icons/io";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import dotenv from "dotenv";
dotenv.config();

export const InputText = (props) => {
  let classes =
    "text-left shadow appearance-none border-2  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";
  if (props.value === null || props.value === "") {
    classes =
      "text-left shadow appearance-none border-2 text-red-500  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";
  }

  let actualValue = false;
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }

  return (
    <div className="flex flex-col pt-2">
      <label className="input-title">{props.title}</label>
      <input
        hidden={props.hidden}
        required={props.required}
        className={classnames(
          "text-left shadow appearance-none border-2  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline",
          { "border-2 border-red-500": actualValue }
        )}
        type="text"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
      />
      <label className="text-red-500">
        {actualValue === true ? props.title + " Can Not Be Null" : ""}
      </label>
    </div>
  );
};

export const InputTextSecret = (props) => {
  return (
    <div className="flex flex-col pt-2">
      <label className="input-title">{props.title}</label>
      <input
        className="text-left shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type="password"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        required={props.required}
      />
    </div>
  );
};

export const InputSocialMediaLink = (props) => {
  return (
    <div className="flex flex-col pt-2">
      <label className="input-title">{props.title}</label>
      <input
        className="text-left shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        type="text"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        required={props.required}
      />
    </div>
  );
};

export const InputTextAreaMove = (props) => {
  let actualValue = false;
  if (props.data == "" && props.isClicked == true) {
    actualValue = true;
  }
  return (
    <div className="flex flex-col pt-2">
      <label className="input-title">{props.title}</label>
      <div
        className={classnames("", { "border-2 border-red-500": actualValue })}
      >
        <CKEditor
          initData={props.data}
          onChange={props.onChange}
          className="border-4"
        />
      </div>
      <label className="text-red-500">
        {actualValue === true ? props.title + " Can Not Be Null" : ""}
      </label>
    </div>
  );
};

export const InputTextArea = (props) => {
  let actualValue = false;
  let id = "quill-editor";
  if (props.id) {
    id = id + "-" + props.id;
  }
  if (props.data == "" && props.isClicked == true) {
    actualValue = true;
  }
  /*
   * Quill modules to attach to editor
   * See https://quilljs.com/docs/modules/ for complete options
   */
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  /*
   * PropType validation
   */
  const propTypes = {
    placeholder: "Write something",
  };

  let editorValue = "";

  return (
    <div id={id} className="flex flex-col pt-2">
      <label className="input-title">{props.title}</label>
      <div
        className={classnames("", { "border-2 border-red-500": actualValue })}
      >
        <ReactQuill
          theme="snow"
          editorHtml="html"
          value={props.data}
          onChange={props.onChange}
          modules={modules}
          formats={formats}
          placeholder={""}
        />
      </div>
      <label className="text-red-500">
        {actualValue === true ? props.title + " Can Not Be Null" : ""}
      </label>
    </div>
  );
};

export const RichTextArea = (props) => {
  const [text, setText] = useState("");

  return (
    <div className="flex pt-2">
      <div className="w-1/2">
        <label className="input-title">{props.title}</label>
      </div>
      <div>
        <label className="input-title">TEXT</label>
        <p>{text}</p>
      </div>
    </div>
  );
};

export const Uploading = (props) => {
  let accept = ".jpeg, .jpg, .png, .svg";
  let classes = "flex flex-col pt-4";
  let required = props.required;
  if (props.accept) {
    accept = " .mp4, .mpeg";
  }
  if (props.updating == contants.isTrue) {
    classes = "hidden";
    required = false;
  }
  return (
    <div className={classes}>
      <div>
        <span className="input-title">{props.title}</span>
      </div>
      <div className="flex">
        <div>
          <input
            type="file"
            name={props.name}
            accept={accept}
            onChange={props.onChange}
            required={required}
          />
        </div>
      </div>
    </div>
  );
};

export const ActivatingButton = (props) => {
  const status = props.status;
  return (
    <button
      onClick={props.onClick}
      className={
        status == contants.active
          ? "bg-red-600 px-4 text-white text-md rounded-md ml-4"
          : "bg-green-600 px-4 text-white rounded-md ml-4"
      }
    >
      {status == contants.active ? "DESACTIVATE" : "ACTIVATE"}{" "}
    </button>
  );
};

export const ArchivingButton = (props) => {
  const access = props.access;
  return (
    <button
      onClick={props.onClick}
      className={
        access == contants.visible
          ? "bg-gray-500 px-4 text-white text-md rounded-md ml-4"
          : "bg-blue-600 px-4 text-white rounded-md ml-4"
      }
    >
      {" "}
      {access == contants.visible ? "ARCHIVE" : "VISIBLE"}{" "}
    </button>
  );
};

export const MoreDropDown = (props) => {
  return (
    <div className="pt-8 space-y-3">
      <div className="flex space-x-16">
        <div>
          <span className="font-bold">{props.title}</span>
        </div>
        <div>
          <Button name="create" type="create" />
        </div>
      </div>
      <div className="flex space-x-3 w-full">
        <div>
          <SelectButton options={props.options} item={props.item} />
        </div>
      </div>
    </div>
  );
};

export const LinkComponent = (props) => {
  return (
    <div className="flex flex-col">
      <div>
        <span className="font-bold">Link</span>
      </div>
      <div className="flex items-center space-x-2">
        <InputText
          placeholder="enter link here"
          value={props.value}
          name={props.name}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
};

export const SelectRadioButton = (props) => {
  let actualValue = false;
  if (props.value == "" && props.isClicked == true) {
    actualValue = true;
  }
  return (
    <div className="flex flex-col" on>
      <label>{props.title} </label>
      <div className="flex space-x-8" onChange={props.onChange}>
        <div className="space-x-2">
          <input
            type="radio"
            value={props.option1}
            id="true"
            name={props.name}
          />
          <label for="true">{props.option1}</label>
        </div>
        <div className="space-x-2">
          <input
            type="radio"
            value={props.option2}
            id="false"
            name={props.name}
          />
          <label for="false">{props.option2}</label>
        </div>
      </div>
      <label className="text-red-500">
        {actualValue === true ? " Select One Option" : ""}
      </label>
    </div>
  );
};

export const ShowOrHideLinks = (props) => {
  return (
    <div className="flex flex-col" on>
      <label>{props.title} </label>
      <div className="flex space-x-8" onChange={props.onChange}>
        <div className="space-x-2">
          <input type="radio" value={true} id="true" name={props.name} />
          <label for="true">{props.option1}</label>
        </div>
        <div className="space-x-2">
          <input type="radio" value={false} id="false" name={props.name} />
          <label for="false">{props.option2}</label>
        </div>
      </div>
    </div>
  );
};

export const EditButton = (props) => {
  return (
    <Link to={props.link + "/" + props.id} className="tooltip">
      <span class="tooltiptext">Edit</span>
      <AiFillEdit className="text-blue-900" />
    </Link>
  );
};

export const DeleteButton = (props) => {
  return (
    <button className="tooltip">
      <span class="tooltiptext">Delete</span>
      <AiFillDelete className="text-red-600" />
    </button>
  );
};

export const PreviewButton = (props) => {
  return (
    <Link to={props.link + "/" + props.id} className="tooltip">
      <span class="tooltiptext">Preview</span>
      <AiFillEye className="text-green-600" />
    </Link>
  );
};

export const StaffSocialLinksButton = (props) => {
  return (
    <Link to={props.link + "/" + props.id} className="tooltip">
      <span class="tooltiptext">social media</span>
      <IoMdGlobe className="text-blue-600" />
    </Link>
  );
};

export const WidgetPreviewButton = (props) => {
  return (
    <Link to={props.link + "/" + props.id} className="tooltip">
      <span class="tooltiptext">Widget</span>
      <MdWidgets className="text-blue-600" />
    </Link>
  );
};

export const ObjectItemsButton = (props) => {
  return (
    <Link to={props.link + "/" + props.id} className="tooltip">
      <span class="tooltiptext">Items</span>
      <AiOutlineUnorderedList className="text-blue-800 font-bold" />
    </Link>
  );
};

export const ArchiveButton = (props) => {
  return (
    <button className="tooltip">
      <span class="tooltiptext">Archive</span>
      <BsArchiveFill className="text-white" />
    </button>
  );
};

export const ActivateButton = (props) => {
  return (
    <button className="tooltip">
      <span class="tooltiptext">Activate</span>
      <GiRadioactive className="text-green-800" />
    </button>
  );
};

export const InsertingLanguagesTable = (props) => {
  return (
    <table>
      <tbody>
        {props.languages.map((lang, i) => (
          <tr key={i} className="text-md font-semibold">
            <td
              className={
                i <= props.counter
                  ? i == props.counter
                    ? " bg-publication-header-link-circle px-2 py-1"
                    : "bg-green-500 text-white px-2 py-1"
                  : "px-2 py-1"
              }
            >
              <div className="flex items-center space-x-1">
                <span>
                  {i <= props.counter ? (
                    i == props.counter ? (
                      <IoAddCircleOutline />
                    ) : (
                      <BsCheckCircle />
                    )
                  ) : (
                    <AiOutlineMinusCircle />
                  )}
                </span>
                <span>{lang.name}</span>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
