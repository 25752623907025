import { HeaderLink } from "./headerlinks";
import logo from "../images/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import i18n from "../utils/i18n";
import { useTranslation } from "react-i18next";
import RW from "../images/rw.svg";
import EN from "../images/en.svg";
import FR from "../images/fr.svg";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../store/actions";
import axios from "../axios-base";
import Spinner from "../components/Spinner";
import { HashLink } from "react-router-hash-link";
import { BottomMargin } from "../partnersComponents/PartnersComponentsList";
import { FaRegWindowClose } from "react-icons/fa";

const Header = (props) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [showChooseLng, setShowChooseLng] = useState(false);
  const [selectLng, setSelectLng] = useState(
    localStorage.getItem("i18nextLng")
  );
  const languages = useSelector((state) => state.languages.languages);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearchCard, setShowSearchCard] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [productsAndServices, setProductsAndServices] = useState([]);
  const [searchError, setSearchError] = useState(null);
  const { t } = useTranslation();
  const handleLang = (value) => {
    i18n.changeLanguage(value);
    setSelectLng(value);
    setShowChooseLng(false);
  };
  const selectedLanguage = languages.filter(
    (language) => language.abbreviation === selectLng
  );
  const pageLink = props.pageLink;
  const [mobButton, setMobButton] = useState(true);
  const showMobileMenu = (e) => {
    const btn = document.querySelector(".mobile-menu-button");
    const closebtn = document.querySelector(".close-mobile-menu-button");
    const menu = document.querySelector(".mobile-menu-list");
    if (btn) {
      menu.classList.toggle("hidden");
      setMobButton(false);
    }
    if (closebtn) {
      menu.classList.toggle("hidden");
      setMobButton(true);
    }
  };

  useEffect(() => {
    dispatch(actionTypes.changeLanguage(selectLng));
    const checkIfClickedOutside = (e) => {
      if (showChooseLng && ref.current && !ref.current.contains(e.target)) {
        setShowChooseLng(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showChooseLng, selectLng, dispatch]);

  useEffect(() => {
    dispatch(actionTypes.getLanguages());
  }, [dispatch]);

  const handleSearchTermChange = useCallback(
    (event) => {
      setSearchLoading(true);
      setSearchError(null);
      if (event.target.value.trim() !== "") {
        setShowSearchCard(true);
      } else {
        setShowSearchCard(false);
      }
      setSearchTerm(event.target.value);
      axios
        .get(`/site/search/${selectLng}/${event.target.value}`)
        .then((response) => {
          setSearchLoading(false);
          setProductsAndServices(response.data.results);
        })
        .catch((err) => {
          setSearchLoading(false);
          setSearchError("No Match");
        });
    },
    [selectLng]
  );
  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <nav className="header-bar fixed inset-x-0 top-0 z-50" ref={ref}>
      <div className="flex h-28 items-center">
        <div className="hidden lg:flex w-3/5 items-center space-x-2 lg:space-x-5 xl:space-x-8 pl-4 xl:pl-20">
          <HeaderLink headerLinkName={t("Home")} page="/" pageLink={pageLink} />
          <HeaderLink
            headerLinkName={t("Services")}
            page="/services"
            pageLink={pageLink}
          />
          <HeaderLink
            headerLinkName={t("Products")}
            page="/products"
            pageLink={pageLink}
          />
          <HeaderLink
            headerLinkName={t("Partners")}
            page="/partners"
            pageLink={pageLink}
          />
          <HeaderLink
            headerLinkName={t("Publications")}
            page="/publications"
            pageLink={pageLink}
          />
          <HeaderLink
            headerLinkName={t("About")}
            page="/about"
            pageLink={pageLink}
          />
          <HeaderLink
            headerLinkName={t("Contact")}
            page="/contact-us"
            pageLink={pageLink}
          />
        </div>
        <div className="hidden lg:flex xl:flex w-1/5 justify-center items-center">
          <input
            className="header-search w-24 md:w-20 lg:w-56  text-center"
            type="text"
            placeholder={t("search placeholder")}
            value={searchTerm}
            onChange={handleSearchTermChange}
          />
        </div>
        <div className="w-full lg:w-1/5 flex flex-row-reverse lg:flex-row xl:flex-row justify-center items-center ">
          <button
            className="w-1/4 flex justify-center items-center lg:hidden"
            onClick={showMobileMenu}
          >
            {mobButton ? (
              <GiHamburgerMenu size={30} className="mobile-menu-button" />
            ) : (
              <FaRegWindowClose
                size={30}
                className="close-mobile-menu-button"
              />
            )}
          </button>
          <div className="w-3/4 flex justify-center items-center">
            <Link to="/">
              <img
                className="w-40 h-12 md:w-32 object-center object-cover"
                src={logo}
                alt="O'Genius"
              ></img>
            </Link>
          </div>
          <div className="w-1/4 px-2 xl:pr-10 relative">
            <img
              className="cursor-pointer w-2/5 md:w-1/5 lg:w-full"
              onClick={() => {
                setShowChooseLng(!showChooseLng);
              }}
              src={`${process.env.REACT_APP_BACKEND_URL}/site/${selectedLanguage[0]?.flag}`}
              alt=""
            />
            <div
              className={`${
                showChooseLng
                  ? "mt-2 absolute bg-white text-main-color lg:right-0 shadow-2xl"
                  : "hidden"
              }`}
            >
              <ul>
                {languages.map((language) => (
                  <li
                    className={`cursor-pointer hover:border-b-2 border-main-color ${
                      language.abbreviation === selectLng
                        ? 'text-header-main-color'
                        : 'text-header-link-color'
                    } hover:text-header-main-color font-semibold p-1`}
                    onClick={() => handleLang(language.abbreviation)}
                  >
                    {language.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col hidden mobile-menu-list ">
        <HeaderLink small headerLinkName={t("Home")} page="/" />
        <HeaderLink small headerLinkName={t("Services")} page="/services" />
        <HeaderLink small headerLinkName={t("Products")} page="/products" />
        <HeaderLink small headerLinkName={t("Partners")} page="/partners" />
        <HeaderLink
          small
          headerLinkName={t("Publications")}
          page="/publications"
        />
        <HeaderLink small headerLinkName={t("About")} page="/about" />
        <HeaderLink small headerLinkName={t("Contact")} page="/contact-us" />
      </div>
      {showSearchCard ? (
        <div className="absolute flex flex-col top-28 inset-x-0 max-h-96 overflow-y-scroll scrollbar-hidden bg-white z-50">
          {searchLoading ? (
            <div className="w-full h-full m-auto pt-5 pb-10">
              <Spinner />
            </div>
          ) : searchError ? (
            <div className="hidden lg:flex lg:fex-col">
              <div className="py-2.5 px-14  hover:bg-search-error-color font-semibold w-full border-t-2">
                {searchError}
              </div>
            </div>
          ) : (
            <div className="hidden lg:flex lg:fex-col">
              {productsAndServices.map((item, index) => (
                <HashLink
                  onClick={() => setShowSearchCard(false)}
                  to={item.link}
                  scroll={scrollWidthOffset}
                  smooth
                  className="py-2.5 px-14 hover:bg-panda-mail-card-color-first font-semibold w-full border-t-2"
                >
                  {item.name}
                </HashLink>
              ))}
            </div>
          )}
        </div>
      ) : null}
    </nav>
  );
};
export default Header;
