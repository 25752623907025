import { updateObject } from "../../utils/updateObject";

import * as adminActionTypes from "../actions/adminActionTypes";

const initialReducer = {
  adminExecutifLeadership: [],
  adminExecutifLeadershipError: null,
  adminExecutifLeadershipLoading: false,
  executifLeadership: null,
  adminExecutifLeaders: [],
  adminExecutifLeadersError: [],
  adminExecutifLeadersLoading: false,
  executifLeader: null,
  adminCareer: [],
  adminCareerError: null,
  adminCareerLoading: false,
  career: null,
  adminStaff: [],
  adminStaffError: null,
  adminStaffLoading: false,
  staff: null,
  adminStaffMembers: [],
  adminStaffMembersError: [],
  adminStaffMembersLoading: false,
  staffMember: null,
  adminAwardsIntro: [],
  adminAwardsIntroError: null,
  adminAwardsIntroLoading: false,
  awardsIntro: null,
  adminAwards: [],
  adminAwardsError: null,
  adminAwardsLoading: false,
  award: null,
  adminEthicsAndCompliance: [],
  adminEthicsAndComplianceError: null,
  adminEthicsAndComplianceLoading: false,
  ethicsAndCompliance: null,
  adminGetInTouch: [],
  adminGetInTouchError: null,
  adminGetInTouchLoading: false,
  getInTouch: null,
  adminSocialMedia: [],
  adminSocialMediaError: null,
  adminSocialMediaLoading: false,
  socialMedia: null,
};

const getAdminExcutifLeadershipStart = (state, action) => {
  return updateObject(state, {
    adminExecutifLeadership: [],
    adminExecutifLeadershipError: null,
    adminExecutifLeadershipLoading: true,
    executifLeadership: null,
  });
};

const getAdminExcutifLeadershipSuccess = (state, action) => {
  return updateObject(state, {
    adminExecutifLeadership: action.adminExecutifLeadership,
    adminExecutifLeadershipError: null,
    adminExecutifLeadershipLoading: false,
    executifLeadership: null,
  });
};

const getAdminExcutifLeadershipFail = (state, action) => {
  return updateObject(state, {
    adminExecutifLeadership: [],
    adminExecutifLeadershipError: action.error,
    adminExecutifLeadershipLoading: false,
    executifLeadership: null,
  });
};

const getOneAdminExcutifLeadershipSuccess = (state, action) => {
  return updateObject(state, {
    adminExecutifLeadership: [],
    adminExecutifLeadershipError: null,
    adminExecutifLeadershipLoading: false,
    executifLeadership: action.executifLeadership,
  });
};

const getAdminExcutifLeadersStart = (state, action) => {
  return updateObject(state, {
    adminExecutifLeaders: [],
    adminExecutifLeadersError: null,
    adminExecutifLeadersLoading: true,
    executifLeader: null,
  });
};

const getAdminExcutifLeadersSuccess = (state, action) => {
  return updateObject(state, {
    adminExecutifLeaders: action.adminExecutifLeaders,
    adminExecutifLeadersError: null,
    adminExecutifLeadersLoading: false,
  });
};

const getAdminExcutifLeadersFail = (state, action) => {
  return updateObject(state, {
    adminExecutifLeaders: [],
    adminExecutifLeadersError: action.error,
    adminExecutifLeadersLoading: false,
  });
};

const getOneAdminExcutifLeadersSuccess = (state, action) => {
  return updateObject(state, {
    adminExecutifLeaders: [],
    adminExecutifLeadersError: null,
    adminExecutifLeadersLoading: true,
    executifLeader: action.executifLeader,
  });
};

const getAdminCareerStart = (state, action) => {
  return updateObject(state, {
    adminCareer: [],
    adminCareerError: null,
    adminCareerLoading: true,
    career: null,
  });
};

const getAdminCareerSuccess = (state, action) => {
  return updateObject(state, {
    adminCareer: action.adminCareer,
    adminCareerError: null,
    adminCareerLoading: false,
    career: null,
  });
};

const getAdminCareerFail = (state, action) => {
  return updateObject(state, {
    adminCareer: [],
    adminCareerError: action.error,
    adminCareerLoading: false,
    career: null,
  });
};

const getOneAdminCareerSuccess = (state, action) => {
  return updateObject(state, {
    adminCareer: [],
    adminCareerError: null,
    adminCareerLoading: false,
    career: action.career,
  });
};

const getAdminStaffStart = (state, action) => {
  return updateObject(state, {
    adminStaff: [],
    adminStaffError: null,
    adminStaffLoading: true,
    staff: null,
  });
};

const getAdminStaffSuccess = (state, action) => {
  return updateObject(state, {
    adminStaff: action.adminStaff,
    adminStaffError: null,
    adminStaffLoading: false,
    staff: null,
  });
};

const getAdminStaffFail = (state, action) => {
  return updateObject(state, {
    adminStaff: [],
    adminStaffError: action.error,
    adminStaffLoading: false,
    staff: null,
  });
};

const getOneAdminStaffSuccess = (state, action) => {
  return updateObject(state, {
    adminStaff: [],
    adminStaffError: null,
    adminStaffLoading: false,
    staff: action.staff,
  });
};

const getAdminStaffMembersStart = (state, action) => {
  return updateObject(state, {
    adminStaffMembers: [],
    adminStaffMembersError: null,
    adminStaffMembersLoading: true,
    staffMember: null,
  });
};

const getAdminStaffMembersSuccess = (state, action) => {
  return updateObject(state, {
    adminStaffMembers: action.adminStaffMembers,
    adminStaffMembersError: null,
    adminStaffMembersLoading: false,
    staffMember: null,
  });
};

const getAdminStaffMembersFail = (state, action) => {
  return updateObject(state, {
    adminStaffMembers: [],
    adminStaffMembersError: action.error,
    adminStaffMembersLoading: false,
    staffMember: null,
  });
};

const getOneAdminStaffMembersSuccess = (state, action) => {
  return updateObject(state, {
    adminStaffMembers: [],
    adminStaffMembersError: null,
    adminStaffMembersLoading: false,
    staffMember: action.staffMember,
  });
};

const getAdminSocialMediaLinkStart = (state, action) => {
  return updateObject(state, {
    adminSocialMedia: [],
    adminSocialMediaError: null,
    adminSocialMediaLoading: false,
    socialMedia: null,
  });
};

const getAdminSocialMediaLinkSuccess = (state, action) => {
  return updateObject(state, {
    adminSocialMedia: action.adminSocialMedia,
    adminSocialMediaError: null,
    adminSocialMediaLoading: false,
    socialMedia: null,
  });
};

const getAdminSocialMediaLinkFail = (state, action) => {
  return updateObject(state, {
    adminSocialMedia: [],
    adminSocialMediaError: action.error,
    adminSocialMediaLoading: false,
    socialMedia: null,
  });
};

const getOneAdminSocialMediaLinkSuccess = (state, action) => {
  return updateObject(state, {
    adminSocialMedia: [],
    adminSocialMediaError: null,
    adminSocialMediaLoading: false,
    socialMedia: action.socialMedia,
  });
};


const getAdminAwardsIntroStart = (state, action) => {
  return updateObject(state, {
    adminAwardsIntro: [],
    adminAwardsIntroError: null,
    adminAwardsIntroLoading: true,
    awardsIntro: null,
  });
};

const getAdminAwardsIntroSuccess = (state, action) => {
  return updateObject(state, {
    adminAwardsIntro: action.adminAwardsIntro,
    adminAwardsIntroError: null,
    adminAwardsIntroLoading: false,
    awardsIntro: null,
  });
};

const getAdminAwardsIntroFail = (state, action) => {
  return updateObject(state, {
    adminAwardsIntro: [],
    adminAwardsIntroError: action.error,
    adminAwardsIntroLoading: false,
    awardsIntro: null,
  });
};

const getOneAdminAwardsIntroSuccess = (state, action) => {
  return updateObject(state, {
    adminAwardsIntro: [],
    adminAwardsIntroError: null,
    adminAwardsIntroLoading: false,
    awardsIntro: action.awardsIntro,
  });
};

const getAdminAwardsStart = (state, action) => {
  return updateObject(state, {
    adminAwards: [],
    adminAwardsError: null,
    adminAwardsLoading: true,
    award: null,
  });
};

const getAdminAwardsSuccess = (state, action) => {
  return updateObject(state, {
    adminAwards: action.adminAwards,
    adminAwardsError: null,
    adminAwardsLoading: false,
    award: null,
  });
};

const getAdminAwardsFail = (state, action) => {
  return updateObject(state, {
    adminAwards: [],
    adminAwardsError: action.error,
    adminAwardsLoading: false,
    award: null,
  });
};

const getOneAdminAwardsSuccess = (state, action) => {
  return updateObject(state, {
    adminAwards: [],
    adminAwardsError: null,
    adminAwardsLoading: false,
    award: action.award,
  });
};

const getAdminEthicsAndComplianceStart = (state, action) => {
  return updateObject(state, {
    adminEthicsAndCompliance: [],
    adminEthicsAndComplianceError: null,
    adminEthicsAndComplianceLoading: true,
    ethicsAndCompliance: null,
  });
};

const getAdminEthicsAndComplianceSuccess = (state, action) => {
  return updateObject(state, {
    adminEthicsAndCompliance: action.adminEthicsAndCompliance,
    adminEthicsAndComplianceError: null,
    adminEthicsAndComplianceLoading: false,
    ethicsAndCompliance: null,
  });
};

const getAdminEthicsAndComplianceFail = (state, action) => {
  return updateObject(state, {
    adminEthicsAndCompliance: [],
    adminEthicsAndComplianceError: action.error,
    adminEthicsAndComplianceLoading: false,
    ethicsAndCompliance: null,
  });
};

const getOneAdminEthicsAndComplianceSuccess = (state, action) => {
  return updateObject(state, {
    adminEthicsAndCompliance: [],
    adminEthicsAndComplianceError: null,
    adminEthicsAndComplianceLoading: false,
    ethicsAndCompliance: action.ethicsAndCompliance,
  });
};

const getAdminGetInTouchStart = (state, action) => {
  return updateObject(state, {
    adminGetInTouch: [],
    adminGetInTouchError: null,
    adminGetInTouchLoading: true,
    getInTouch: null,
  });
};

const getAdminGetInTouchSuccess = (state, action) => {
  return updateObject(state, {
    adminGetInTouch: action.adminGetInTouch,
    adminGetInTouchError: null,
    adminGetInTouchLoading: false,
    getInTouch: null,
  });
};

const getAdminGetInTouchFail = (state, action) => {
  return updateObject(state, {
    adminGetInTouch: [],
    adminGetInTouchError: action.error,
    adminGetInTouchLoading: false,
    getInTouch: null,
  });
};

const getOneAdminGetInTouchSuccess = (state, action) => {
  return updateObject(state, {
    adminGetInTouch: [],
    adminGetInTouchError: null,
    adminGetInTouchLoading: false,
    getInTouch: action.getInTouch,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case adminActionTypes.GET_ADMIN_EXCUTIF_LEADERSHIP_START:
      return getAdminExcutifLeadershipStart(state, action);
    case adminActionTypes.GET_ADMIN_EXCUTIF_LEADERSHIP_SUCCESS:
      return getAdminExcutifLeadershipSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_EXCUTIF_LEADERSHIP_SUCCESS:
      return getOneAdminExcutifLeadershipSuccess(state, action);
    case adminActionTypes.GET_ADMIN_EXCUTIF_LEADERSHIP_FAIL:
      return getAdminExcutifLeadershipFail(state, action);
    case adminActionTypes.GET_ADMIN_EXCUTIF_LEADERS_START:
      return getAdminExcutifLeadersStart(state, action);
    case adminActionTypes.GET_ADMIN_EXCUTIF_LEADERS_SUCCESS:
      return getAdminExcutifLeadersSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_EXCUTIF_LEADERS_SUCCESS:
      return getOneAdminExcutifLeadersSuccess(state, action);
    case adminActionTypes.GET_ADMIN_EXCUTIF_LEADERS_FAIL:
      return getAdminExcutifLeadersFail(state, action);
    case adminActionTypes.GET_ADMIN_CAREER_START:
      return getAdminCareerStart(state, action);
    case adminActionTypes.GET_ADMIN_CAREER_SUCCESS:
      return getAdminCareerSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_CAREER_SUCCESS:
      return getOneAdminCareerSuccess(state, action);
    case adminActionTypes.GET_ADMIN_CAREER_FAIL:
      return getAdminCareerFail(state, action);
    case adminActionTypes.GET_ADMIN_STAFF_START:
      return getAdminStaffStart(state, action);
    case adminActionTypes.GET_ADMIN_STAFF_SUCCESS:
      return getAdminStaffSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_STAFF_SUCCESS:
      return getOneAdminStaffSuccess(state, action);
    case adminActionTypes.GET_ADMIN_STAFF_FAIL:
      return getAdminStaffFail(state, action);
    case adminActionTypes.GET_ADMIN_STAFF_MEMBERS_START:
      return getAdminStaffMembersStart(state, action);
    case adminActionTypes.GET_ADMIN_STAFF_MEMBERS_SUCCESS:
      return getAdminStaffMembersSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_STAFF_MEMBERS_SUCCESS:
      return getOneAdminStaffMembersSuccess(state, action);
    case adminActionTypes.GET_ADMIN_STAFF_MEMBERS_FAIL:
      return getAdminStaffMembersFail(state, action);
    case adminActionTypes.GET_ADMIN_AWARDS_INTRO_START:
      return getAdminAwardsIntroStart(state, action);
    case adminActionTypes.GET_ADMIN_AWARDS_INTRO_SUCCESS:
      return getAdminAwardsIntroSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_AWARDS_INTRO_SUCCESS:
      return getOneAdminAwardsIntroSuccess(state, action);
    case adminActionTypes.GET_ADMIN_AWARDS_INTRO_FAIL:
      return getAdminAwardsIntroFail(state, action);
    case adminActionTypes.GET_ADMIN_AWARDS_START:
      return getAdminAwardsStart(state, action);
    case adminActionTypes.GET_ADMIN_AWARDS_SUCCESS:
      return getAdminAwardsSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_AWARDS_SUCCESS:
      return getOneAdminAwardsSuccess(state, action);
    case adminActionTypes.GET_ADMIN_AWARDS_FAIL:
      return getAdminAwardsFail(state, action);
    case adminActionTypes.GET_ADMIN_ETHICS_COMPLIANCE_START:
      return getAdminEthicsAndComplianceStart(state, action);
    case adminActionTypes.GET_ADMIN_ETHICS_COMPLIANCE_SUCCESS:
      return getAdminEthicsAndComplianceSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_ETHICS_COMPLIANCE_SUCCESS:
      return getOneAdminEthicsAndComplianceSuccess(state, action);
    case adminActionTypes.GET_ADMIN_ETHICS_COMPLIANCE_FAIL:
      return getAdminEthicsAndComplianceFail(state, action);
    case adminActionTypes.GET_ADMIN_GET_IN_TOUCH_START:
      return getAdminGetInTouchStart(state, action);
    case adminActionTypes.GET_ADMIN_GET_IN_TOUCH_SUCCESS:
      return getAdminGetInTouchSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_GET_IN_TOUCH_SUCCESS:
      return getOneAdminGetInTouchSuccess(state, action);
    case adminActionTypes.GET_ADMIN_GET_IN_TOUCH_FAIL:
      return getAdminGetInTouchFail(state, action);
      case adminActionTypes.GET_ADMIN_SOCIAL_MEDIA_LINK_START:
      return getAdminSocialMediaLinkStart(state, action);
    case adminActionTypes.GET_ADMIN_SOCIAL_MEDIA_LINK_SUCCESS:
      return getAdminSocialMediaLinkSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_SOCIAL_MEDIA_LINK_SUCCESS:
      return getOneAdminSocialMediaLinkSuccess(state, action);
    case adminActionTypes.GET_ADMIN_SOCIAL_MEDIA_LINK_FAIL:
      return getAdminSocialMediaLinkFail(state, action);
    default:
      return state;
  }
};
export default reducer;
