import React from "react";
import parse from "html-react-parser";

const WidgetD = (props) => {
  return (
    <div
      id={props.widget.hashlink}
      className="md:h-50vh xl:h-60vh networking-service pb-5 flex flex-wrap flex-col-reverse md:flex-row"
    >
      <div className="h-full w-full md:w-1/2">
        <img
          alt="Networking"
          src={`${process.env.REACT_APP_BACKEND_URL}/site/${props.widget.image}`}
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div
        data-aos="slide-up"
        data-aos-duration="1500"
        className="md:h-full flex flex-col pt-6 md:w-1/2 md:text-left md:px-14"
      >
        <div className="service-title site-text-blue text-center md:text-left">
          <span>{props.widget.title}</span>
        </div>
        <div className="max-h-30vh md:max-h-full overflow-scroll scrollbar-hidden networking-text text-center md:text-left px-14 md:px-0 pb-8 mb-5 md:mb-0">
          {
            props.widget.body != undefined ? parse(eval(props.widget.body)) : parse("<p></p>"
          )}
        </div>
      </div>
    </div>
  );
};

export default WidgetD;
