import "./partnerscss.css";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

import dotenv from "dotenv";
dotenv.config();

export const PartnersHeader = () => {
  const { t } = useTranslation();
  return (
    <div className="partners-header-bg w-full py-32 mt-28">
      <span className="partners-header text-center text-5xl">
        {t("Partners")}
      </span>
    </div>
  );
};

export const PartnersBody = () => {
  const dispatch = useDispatch();
  const partners = useSelector((state) => state.partners.partners);
  const partnersLoading = useSelector(
    (state) => state.partners.partnersLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getPartners(language));
  }, [dispatch, language]);
  return (
    <div className="partners-body">
      <div className="text-center site-text-blue">
        <PartnersTopPart />
        {partnersLoading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <div className="pl-8 pr-5 pt-2 pb-10 lg:pl-36 lg:pr-36 2xl:pl-96 2xl:pr-96">
            {partners.map((partner, index) => (
              <PartnerInfoCard key={index} partner={partner} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const Affiliation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const affiliations = useSelector((state) => state.partners.affiliations);
  const affiliationsLoading = useSelector(
    (state) => state.partners.affiliationsLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getAffiliations(language));
  }, [dispatch, language]);
  const sliderSettings = {
    dots: true,
    centerMode: true,
    lazyLoad: true,
    infinite: true,
    swipeToSlide: true,
    focusOnSelect: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 2,
    responsive: [
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          centerMode: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          centerMode: false,
        },
      },
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 0,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <div id="affliations" className="partners-affiliation flex flex-col py-10">
      <div className="affiliation-title site-text-blue py-4">
        <span>{t("Affiliation")}</span>
      </div>
      {affiliationsLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="w-full overflow-x-scroll scrollbar-hidden justify-center">
          <Slider {...sliderSettings}>
            {affiliations.map((affiliation) => (
              <div className="justify-center">
                <AffiliatorCard affiliation={affiliation} />
              </div>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export const PartnersTopPart = () => {
  const dispatch = useDispatch();
  const partnersIntro = useSelector((state) => state.partners.partnersIntro);
  const partnersIntroLoading = useSelector(
    (state) => state.partners.partnersIntroLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getPartnersIntro(language));
  }, [dispatch, language]);
  return (
    <div
      className="flex flex-wrap flex-col text-center pt-5 pb-16 pl-8 pr-5 lg:bg-transparent bg-gray-50 lg:pt-16
    lg:pl-40 lg:pr-40 2xl:pl-96 2xl:pr-96"
    >
      {partnersIntroLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          <div className="py-5 xl:text-xl">Wardhuprat</div>
          <div className="partners-top-part flex flex-col">
            <p className="partners-top-title py-10 font-medium">
              {partnersIntro?.title}
            </p>
            <div className="flex flex-col lg:flex-row  text-sm partners-top-text space-y-5 lg:space-y-0 lg:space-x-10 lg:text-left">
              <div className="">
                {partnersIntro?.description1 != undefined
                  ? parse(eval(partnersIntro?.description1))
                  : parse("<p></p>")}
              </div>
              <div className="">
                {partnersIntro?.description2 != undefined
                  ? parse(eval(partnersIntro?.description2))
                  : parse("<p></p>")}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const BottomMargin = (props) => {
  let classes = "border-gray-300 border-b-2";
  if (props.dark) {
    classes = "border-gray-800 border-b-2";
  }
  return <div className={classes}></div>;
};

export const PartnerInfoCard = (props) => {
  return (
    <div id={props.partner.link} className="flex flex-row">
      <div className="partners-info-card flex flex-col">
        <div className="py-7 px-16 lg:px-0">
          <BottomMargin />
        </div>
        <div className=" flex flex-col md:flex-row md:py-10 md:space-x-10">
          <div className="partner-logo-part w-full flex flex-col justify-center flex-wrap">
            <div className="w-56 h-48 mx-auto mt-10">
              <img
                alt="partner"
                className="h-full w-auto mx-auto object-center"
                src={`${process.env.REACT_APP_BACKEND_URL}/site/${props.partner.logo}`}
              />
            </div>
            <div className="partner-logo-part-text text-xl p-10">
              {props.partner.moto != undefined
                ? parse(eval(props.partner.moto))
                : parse("<p></p>")}
            </div>
          </div>
          <div className="partner-details w-full flex flex-col space-y-8">
            <div className="partner-name text-center text-4xl pt-2 md:text-left">
              <span>{props.partner.name}</span>
            </div>
            <div className="partner-detail-text-top text-left text-sm">
              {props.partner.description != undefined
                ? parse(eval(props.partner.description))
                : parse("<p></p>")}
            </div>
            <div className="text-3xl font-bold"></div>
            <div className="partner-detail-text-bottom text-left text-xl">
              {props.partner.quote != undefined
                ? parse(eval(props.partner.quote))
                : parse("<p></p>")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AffiliatorCard = (props) => {
  return (
    <div className="flex flex-col affiliator-card justify-self-center">
      <div className="affiliator-image">
        <img
          alt="affiliator"
          className="h-full w-full object-center object-cover"
          src={`${process.env.REACT_APP_BACKEND_URL}/site/${props.affiliation.image}`}
        />
      </div>
      <div className="affiliator-details">
        <div className="affiliator-title">
          <span>{props.affiliation.name}</span>
        </div>
        <div className="affiliator-text">
          {props.affiliation.description != undefined
            ? parse(eval(props.affiliation.description))
            : parse("<p></p>")}
        </div>
        <div className="affiliation-small-text">
          {props.affiliation.quote != undefined
            ? parse(eval(props.affiliation.quote))
            : parse("<p></p>")}
        </div>
      </div>
    </div>
  );
};
