import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";
import * as notifications from "../../admin/contants";

export const changeLanguage = (lang) => {
  return {
    type: actionTypes.CHANGE_LANGUAGE,
    language: lang,
  };
};

export const getHomeCarouselStart = () => {
  return {
    type: actionTypes.GET_HOME_CAROUSEL_START,
  };
};

export const getHomeCarouselSuccess = (homeCarousel) => {
  return {
    type: actionTypes.GET_HOME_CAROUSEL_SUCCESS,
    homeCarousel: homeCarousel,
  };
};

export const getHomeCarouselFail = (error) => {
  return {
    type: actionTypes.GET_HOME_CAROUSEL_FAIL,
    homeCarouselError: error,
  };
};

export const getHomeCarousel = (lang) => {
  return (dispatch) => {
    dispatch(getHomeCarouselStart());
    axios
      .get(`/site/carousels/active/${lang}`)
      .then((response) => {
        dispatch(getHomeCarouselSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeCarouselFail(err.message));
      });
  };
};

export const getHomeCommunicationLinksStart = () => {
  return {
    type: actionTypes.GET_HOME_COMMUNICATION_LINKS_START,
  };
};

export const getHomeCommunicationLinksSuccess = (homeCommunicationLinks) => {
  return {
    type: actionTypes.GET_HOME_COMMUNICATION_LINKS_SUCCESS,
    homeCommunicationLinks: homeCommunicationLinks,
  };
};

export const getHomeCommunicationLinksFail = (error) => {
  return {
    type: actionTypes.GET_HOME_COMMUNICATION_LINKS_FAIL,
    error: error,
  };
};

export const getHomeCommunicationLinks = (lang) => {
  return (dispatch) => {
    dispatch(getHomeCommunicationLinksStart());
    axios
      .get(`/site/communication-links/active/${lang}`)
      .then((response) => {
        dispatch(getHomeCommunicationLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeCommunicationLinksFail(err.message));
      });
  };
};
export const getHomeAboutStart = () => {
  return {
    type: actionTypes.GET_HOME_ABOUT_START,
  };
};

export const getHomeAboutSuccess = (homeAbout) => {
  return {
    type: actionTypes.GET_HOME_ABOUT_SUCCESS,
    homeAbout: homeAbout,
  };
};

export const getHomeAboutFail = (error) => {
  return {
    type: actionTypes.GET_HOME_ABOUT_FAIL,
    error: error,
  };
};

export const getHomeAbout = (lang) => {
  return (dispatch) => {
    dispatch(getHomeAboutStart());
    axios
      .get(`/site/home-abouts/active/${lang}`)
      .then((response) => {
        dispatch(getHomeAboutSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeAboutFail(err.message));
      });
  };
};
export const getHomeProductStart = () => {
  return {
    type: actionTypes.GET_HOME_PRODUCT_START,
  };
};

export const getHomeProductSuccess = (homeProduct) => {
  return {
    type: actionTypes.GET_HOME_PRODUCT_SUCCESS,
    homeProduct: homeProduct,
  };
};

export const getHomeProductFail = (error) => {
  return {
    type: actionTypes.GET_HOME_PRODUCT_FAIL,
    error: error,
  };
};

export const getHomeProduct = (lang) => {
  return (dispatch) => {
    dispatch(getHomeProductStart());
    axios
      .get(`/site/home-products/active/${lang}`)
      .then((response) => {
        dispatch(getHomeProductSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeProductFail(err.message));
      });
  };
};
export const getHomePartnersStart = () => {
  return {
    type: actionTypes.GET_HOME_PARTNERS_START,
  };
};

export const getHomePartnersSuccess = (homePartners) => {
  return {
    type: actionTypes.GET_HOME_PARTNERS_SUCCESS,
    homePartners: homePartners,
  };
};

export const getHomePartnersFail = (error) => {
  return {
    type: actionTypes.GET_HOME_PARTNERS_FAIL,
    error: error,
  };
};

export const getHomePartners = (lang) => {
  return (dispatch) => {
    dispatch(getHomePartnersStart());
    axios
      .get(`/site/home-partners/active/${lang}`)
      .then((response) => {
        dispatch(getHomePartnersSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomePartnersFail(err.message));
      });
  };
};
export const getHomeServicesIntroStart = () => {
  return {
    type: actionTypes.GET_HOME_SERVICES_INTRO_START,
  };
};

export const getHomeServicesIntroSuccess = (homeServicesIntro) => {
  return {
    type: actionTypes.GET_HOME_SERVICES_INTRO_SUCCESS,
    homeServicesIntro: homeServicesIntro,
  };
};

export const getHomeServicesIntroFail = (error) => {
  return {
    type: actionTypes.GET_HOME_SERVICES_INTRO_FAIL,
    error: error,
  };
};

export const getHomeServicesIntro = (lang) => {
  return (dispatch) => {
    dispatch(getHomeServicesIntroStart());
    axios
      .get(`/site/home-services-intros/active/${lang}`)
      .then((response) => {
        dispatch(getHomeServicesIntroSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeServicesIntroFail(err.message));
      });
  };
};
export const getHomeServicesStart = () => {
  return {
    type: actionTypes.GET_HOME_SERVICES_START,
  };
};

export const getHomeServicesSuccess = (homeServices) => {
  return {
    type: actionTypes.GET_HOME_SERVICES_SUCCESS,
    homeServices: homeServices,
  };
};

export const getMainHomeServicesSuccess = (homeServices) => {
  return {
    type: actionTypes.GET_MAIN_HOME_SERVICES_SUCCESS,
    mainHomeServices: homeServices,
  };
};

export const getNotMainHomeServicesSuccess = (homeServices) => {
  return {
    type: actionTypes.GET_NOT_MAIN_HOME_SERVICES_SUCCESS,
    notMainHomeServices: homeServices,
  };
};

export const getHomeServicesFail = (error) => {
  return {
    type: actionTypes.GET_HOME_SERVICES_FAIL,
    error: error,
  };
};

export const getHomeServices = (lang) => {
  return (dispatch) => {
    dispatch(getHomeServicesStart());
    axios
      .get(`/site/home-services/active/${lang}`)
      .then((response) => {
        dispatch(getHomeServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeServicesFail(err.message));
      });
  };
};

export const getMainHomeServices = (lang) => {
  return (dispatch) => {
    dispatch(getHomeServicesStart());
    axios
      .get(`/site/home-services/active/main/${lang}`)
      .then((response) => {
        dispatch(getMainHomeServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeServicesFail(err.message));
      });
  };
};

export const getNotMainHomeServices = (lang) => {
  return (dispatch) => {
    dispatch(getHomeServicesStart());
    axios
      .get(`/site/home-services/active/not-main/${lang}`)
      .then((response) => {
        dispatch(getNotMainHomeServicesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeServicesFail(err.message));
      });
  };
};

export const getHomeTodaysQuoteStart = () => {
  return {
    type: actionTypes.GET_HOME_TODAY_QUOTE_START,
  };
};

export const getHomeTodaysQuoteSuccess = (homeTodaysQuote) => {
  return {
    type: actionTypes.GET_HOME_TODAY_QUOTE_SUCCESS,
    homeTodaysQuote: homeTodaysQuote,
  };
};

export const getHomeTodaysQuoteFail = (error) => {
  return {
    type: actionTypes.GET_HOME_TODAY_QUOTE_FAIL,
    error: error,
  };
};

export const getHomeTodaysQuote = (lang) => {
  return (dispatch) => {
    dispatch(getHomeTodaysQuoteStart());
    axios
      .get(`/site/todays-quote/active/${lang}`)
      .then((response) => {
        dispatch(getHomeTodaysQuoteSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeTodaysQuoteFail(err.message));
      });
  };
};

export const getHomeFooterSectionsStart = () => {
  return {
    type: actionTypes.GET_HOME_FOOTER_SECTIONS_START,
  };
};

export const getHomeFooterSectionsSuccess = (homeFooterSections) => {
  return {
    type: actionTypes.GET_HOME_FOOTER_SECTIONS_SUCCESS,
    homeFooterSections: homeFooterSections,
  };
};

export const getHomeFooterSectionsFail = (error) => {
  return {
    type: actionTypes.GET_HOME_FOOTER_SECTIONS_FAIL,
    error: error,
  };
};

export const getHomeFooterSections = (lang) => {
  return (dispatch) => {
    dispatch(getHomeFooterSectionsStart());
    axios
      .get(`/site/footer-sections/active/${lang}`)
      .then((response) => {
        dispatch(getHomeFooterSectionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeFooterSectionsFail(err.message));
      });
  };
};

export const getActiveHomeFooterSections = () => {
  return (dispatch) => {
    dispatch(getHomeFooterSectionsStart());
    axios
      .get(`/site/footer-sections/active`)
      .then((response) => {
        dispatch(getHomeFooterSectionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeFooterSectionsFail(err.message));
      });
  };
};

export const getHomeFooterAddressStart = () => {
  return {
    type: actionTypes.GET_HOME_FOOTER_ADDRESS_START,
  };
};

export const getHomeFooterAddressSuccess = (homeFooterAddress) => {
  return {
    type: actionTypes.GET_HOME_FOOTER_ADDRESS_SUCCESS,
    homeFooterAddress: homeFooterAddress,
  };
};

export const getHomeFooterAddressFail = (error) => {
  return {
    type: actionTypes.GET_HOME_FOOTER_ADDRESS_FAIL,
    error: error,
  };
};

export const getHomeFooterAddress = (lang) => {
  return (dispatch) => {
    dispatch(getHomeFooterAddressStart());
    axios
      .get(`/site/footer-addresses/active/${lang}`)
      .then((response) => {
        dispatch(getHomeFooterAddressSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeFooterAddressFail(err.message));
      });
  };
};


export const getFooterSectionItemsStart = () => {
  return {
    type: actionTypes.GET_FOOTER_SECTION_ITEMS_START,
  };
};

export const getFooterSectionItemsSuccess = (footerSectionsItems) => {
  return {
    type: actionTypes.GET_FOOTER_SECTION_ITEMS_SUCCESS,
    footerSectionsItems: footerSectionsItems,
  };
};

export const getFooterSectionItemsFail = (error) => {
  return {
    type: actionTypes.GET_FOOTER_SECTION_ITEMS_FAIL,
    error: error,
  };
};


export const getFooterSections = (lang) => {
  return (dispatch) => {
    dispatch(getHomeFooterSectionsStart());
    axios
      .get(`/site/footer-sections/active`)
      .then((response) => {
        dispatch(getHomeFooterSectionsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeFooterSectionsFail(err.message));
      });
  };
};

export const homeCarouselsList = () => {
  alert("homec");
  return (dispatch) => {
    dispatch(getHomeCarouselStart());
    axios
      .get(`/site/carousels`)
      .then((response) => {
        dispatch(getHomeCarouselSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getHomeCarouselFail(err.message));
      });
  };
};
