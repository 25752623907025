export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const GET_HOME_CAROUSEL_START = "GET_HOME_CAROUSEL_START";
export const GET_HOME_CAROUSEL_SUCCESS = "GET_HOME_CAROUSEL_SUCCESS";
export const GET_HOME_CAROUSEL_FAIL = "GET_HOME_CAROUSEL_FAIL";
export const GET_HOME_COMMUNICATION_LINKS_START =
  "GET_HOME_COMMUNICATION_LINKS_START";
export const GET_HOME_COMMUNICATION_LINKS_SUCCESS =
  "GET_HOME_COMMUNICATION_LINKS_SUCCESS";
export const GET_HOME_COMMUNICATION_LINKS_FAIL =
  "GET_HOME_COMMUNICATION_LINKS_FAIL";
export const GET_HOME_ABOUT_START = "GET_HOME_ABOUT_START";
export const GET_HOME_ABOUT_SUCCESS = "GET_HOME_ABOUT_SUCCESS";
export const GET_HOME_ABOUT_FAIL = "GET_HOME_ABOUT_FAIL";
export const GET_HOME_PRODUCT_START = "GET_HOME_PRODUCT_START";
export const GET_HOME_PRODUCT_SUCCESS = "GET_HOME_PRODUCT_SUCCESS";
export const GET_HOME_PRODUCT_FAIL = "GET_HOME_PRODUCT_FAIL";
export const GET_HOME_PARTNERS_START = "GET_HOME_PARTNERS_START";
export const GET_HOME_PARTNERS_SUCCESS = "GET_HOME_PARTNERS_SUCCESS";
export const GET_HOME_PARTNERS_FAIL = "GET_HOME_PARTNERS_FAIL";
export const GET_HOME_SERVICES_INTRO_START = "GET_HOME_SERVICES_INTRO_START";
export const GET_HOME_SERVICES_INTRO_SUCCESS =
  "GET_HOME_SERVICES_INTRO_SUCCESS";
export const GET_HOME_SERVICES_INTRO_FAIL = "GET_HOME_SERVICES_INTRO_FAIL";
export const GET_HOME_SERVICES_START = "GET_HOME_SERVICES_START";
export const GET_HOME_SERVICES_SUCCESS = "GET_HOME_SERVICES_SUCCESS";
export const GET_HOME_SERVICES_FAIL = "GET_HOME_SERVICES_FAIL";
export const GET_HOME_TODAY_QUOTE_START = "GET_HOME_TODAY_QUOTE_START";
export const GET_HOME_TODAY_QUOTE_SUCCESS = "GET_HOME_TODAY_QUOTE_SUCCESS";
export const GET_HOME_TODAY_QUOTE_FAIL = "GET_HOME_TODAY_QUOTE_FAIL";
export const GET_HOME_FOOTER_SECTIONS_START = "GET_HOME_FOOTER_SECTIONS_START";
export const GET_HOME_FOOTER_SECTIONS_SUCCESS =
  "GET_HOME_FOOTER_SECTIONS_SUCCESS";
export const GET_HOME_FOOTER_SECTIONS_FAIL = "GET_HOME_FOOTER_SECTIONS_FAIL";
export const GET_HOME_FOOTER_ADDRESS_START = "GET_HOME_FOOTER_ADDRESS_START";
export const GET_HOME_FOOTER_ADDRESS_SUCCESS =
  "GET_HOME_FOOTER_ADDRESS_SUCCESS";
export const GET_HOME_FOOTER_ADDRESS_FAIL = "GET_HOME_FOOTER_ADDRESS_FAIL";

export const GET_SERVICES_START = "GET_SERVICES_START";
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAIL = "GET_SERVICES_FAIL";
export const GET_SERVICE_WIDGETS_START = "GET_SERVICE_WIDGETS_START";
export const GET_SERVICE_WIDGETS_SUCCESS = "GET_SERVICE_WIDGETS_SUCCESS";
export const GET_SERVICE_WIDGETS_FAIL = "GET_SERVICE_WIDGETS_FAIL";

export const GET_PRODUCTS_START = "GET_PRODUCTS_START";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";
export const GET_PRODUCT_WIDGETS_START = "GET_PRODUCT_WIDGETS_START";
export const GET_PRODUCT_WIDGETS_SUCCESS = "GET_PRODUCT_WIDGETS_SUCCESS";
export const GET_PRODUCT_WIDGETS_FAIL = "GET_PRODUCT_WIDGETS_FAIL";

export const GET_PARTNERS_START = "GET_PARTNERS_START";
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS";
export const GET_PARTNERS_FAIL = "GET_PARTNERS_FAIL";
export const GET_PARTNERS_INTRO_START = "GET_PARTNERS_INTRO_START";
export const GET_PARTNERS_INTRO_SUCCESS = "GET_PARTNERS_INTRO_SUCCESS";
export const GET_PARTNERS_INTRO_FAIL = "GET_PARTNERS_INTRO_FAIL";
export const GET_AFFILIATIONS_START = "GET_AFFILIATIONS_START";
export const GET_AFFILIATIONS_SUCCESS = "GET_AFFILIATIONS_SUCCESS";
export const GET_AFFILIATIONS_FAIL = "GET_AFFILIATIONS_FAIL";

export const GET_PUBLICATIONS_START = "GET_PUBLICATIONS_START";
export const GET_PUBLICATIONS_SUCCESS = "GET_PUBLICATIONS_SUCCESS";
export const GET_PUBLICATIONS_FAIL = "GET_PUBLICATIONS_FAIL";
export const GET_NEWSLETTER_START = "GET_NEWSLETTER_START";
export const GET_NEWSLETTER_SUCCESS = "GET_NEWSLETTER_SUCCESS";
export const GET_NEWSLETTER_FAIL = "GET_NEWSLETTER_FAIL";
export const GET_EVENTS_START = "GET_EVENTS_START";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";
export const GET_MEDIA_COVERAGE_START = "GET_MEDIA_COVERAGE_START";
export const GET_MEDIA_COVERAGE_SUCCESS = "GET_MEDIA_COVERAGE_SUCCESS";
export const GET_MEDIA_COVERAGE_FAIL = "GET_MEDIA_COVERAGE_FAIL";
export const GET_GIFTS_AND_FREEBIES_START = "GET_GIFTS_AND_FREEBIES_START";
export const GET_GIFTS_AND_FREEBIES_SUCCESS = "GET_GIFTS_AND_FREEBIES_SUCCESS";
export const GET_GIFTS_AND_FREEBIES_FAIL = "GET_GIFTS_AND_FREEBIES_FAIL";
export const GET_TODAY_AT_OGENIUS_START = "GET_TODAY_AT_OGENIUS_START";
export const GET_TODAY_AT_OGENIUS_SUCCESS = "GET_TODAY_AT_OGENIUS_SUCCESS";
export const GET_TODAY_AT_OGENIUS_FAIL = "GET_TODAY_AT_OGENIUS_FAIL";

export const GET_EXCUTIF_LEADERSHIP_START = "GET_EXCUTIF_LEADERSHIP_START";
export const GET_EXCUTIF_LEADERSHIP_SUCCESS = "GET_EXCUTIF_LEADERSHIP_SUCCESS";
export const GET_EXCUTIF_LEADERSHIP_FAIL = "GET_EXCUTIF_LEADERSHIP_FAIL";
export const GET_CAREER_START = "GET_CAREER_START";
export const GET_CAREER_SUCCESS = "GET_CAREER_SUCCESS";
export const GET_CAREER_FAIL = "GET_CAREER_FAIL";
export const GET_EXCUTIF_LEADERS_START = "GET_EXCUTIF_LEADERS_START";
export const GET_EXCUTIF_LEADERS_SUCCESS = "GET_EXCUTIF_LEADERS_SUCCESS";
export const GET_EXCUTIF_LEADERS_FAIL = "GET_EXCUTIF_LEADERS_FAIL";
export const GET_STAFF_START = "GET_STAFF_START";
export const GET_STAFF_SUCCESS = "GET_STAFF_SUCCESS";
export const GET_STAFF_FAIL = "GET_STAFF_FAIL";
export const GET_STAFF_MEMBERS_START = "GET_STAFF_MEMBERS_START";
export const GET_STAFF_MEMBERS_SUCCESS = "GET_STAFF_MEMBERS_SUCCESS";
export const GET_STAFF_MEMBERS_FAIL = "GET_STAFF_MEMBERS_FAIL";
export const GET_AWARDS_INTRO_START = "GET_AWARDS_INTRO_START";
export const GET_AWARDS_INTRO_SUCCESS = "GET_AWARDS_INTRO_SUCCESS";
export const GET_AWARDS_INTRO_FAIL = "GET_AWARDS_INTRO_FAIL";
export const GET_AWARDS_START = "GET_AWARDS_START";
export const GET_AWARDS_SUCCESS = "GET_AWARDS_SUCCESS";
export const GET_AWARDS_FAIL = "GET_AWARDS_FAIL";
export const GET_ETHICS_COMPLIANCE_START = "GET_ETHICS_COMPLIANCE_START";
export const GET_ETHICS_COMPLIANCE_SUCCESS = "GET_ETHICS_COMPLIANCE_SUCCESS";
export const GET_ETHICS_COMPLIANCE_FAIL = "GET_ETHICS_COMPLIANCE_FAIL";
export const GET_GET_IN_TOUCH_START = "GET_GET_IN_TOUCH_START";
export const GET_GET_IN_TOUCH_SUCCESS = "GET_GET_IN_TOUCH_SUCCESS";
export const GET_GET_IN_TOUCH_FAIL = "GET_GET_IN_TOUCH_FAIL";

export const GET_CONTACT_US_START = "GET_CONTACT_US_START";
export const GET_CONTACT_US_SUCCESS = "GET_CONTACT_US_SUCCESS";
export const GET_CONTACT_US_FAIL = "GET_CONTACT_US_FAIL";
export const GET_QUICK_LINKS_START = "GET_QUICK_LINKS_START";
export const GET_QUICK_LINKS_SUCCESS = "GET_QUICK_LINKS_SUCCESS";
export const GET_QUICK_LINKS_FAIL = "GET_QUICK_LINKS_FAIL";
export const GET_FAQS_START = "GET_FAQS_START";
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS";
export const GET_FAQS_FAIL = "GET_FAQS_FAIL";
export const SEND_MESSAGE_START = "SEND_MESSAGE_START";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAIL = "SEND_MESSAGE_FAIL";

export const GET_LANGUAGES_START = "GET_LANGUAGES_START";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_ONE_LANGUAGES_SUCCESS = "GET_ONE_LANGUAGES_SUCCESS";
export const GET_LANGUAGES_FAIL = "GET_LANGUAGES_FAIL";

export const GET_LINKS_START = "GET_LINKS_START";
export const GET_LINKS_SUCCESS = "GET_LINKS_SUCCESS";
export const GET_ONE_LINKS_SUCCESS = "GET_ONE_LINKS_SUCCESS";
export const GET_LINKS_FAIL = "GET_LINKS_FAIL";
export const GET_ADMIN_GENERIC_LINKS_SUCCESS = "GE_ADMIN_GENERIC_LINKS_SUCCESS";
export const GET_ADMIN_PLATFOM_LINKS_SUCCESS = "GE_ADMIN_PLATFOM_LINKS_SUCCESS";

export const GET_PLATFOM_LINKS_START = "GET_PLATFOM_LINKS_START";
export const GET_PLATFOM_LINKS_SUCCESS = "GET_PLATFOM_LINKS_SUCCESS";
export const GET_PLATFOM_LINKS_FAIL = "GET_PLATFOM_LINKS_FAIL";

export const GET_FOOTER_SECTION_ITEMS_START = "GET_FOOTER_SECTION_ITEMS_START";
export const GET_FOOTER_SECTION_ITEMS_SUCCESS =
  "GET_FOOTER_SECTION_ITEMS_SUCCESS";
export const GET_FOOTER_SECTION_ITEMS_FAIL = "GET_FOOTER_SECTION_ITEMS_FAIL";

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_ONE_USER_SUCCESS = "GET_ONE_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const GET_MAIN_HOME_SERVICES_SUCCESS = "GET_MAIN_HOME_SERVICES_SUCCESS";
export const GET_NOT_MAIN_HOME_SERVICES_SUCCESS =
  "GET_NOT_MAIN_HOME_SERVICES_SUCCESS";

  export const GET_ADMIN_FILES_START = "GET_ADMIN_FILES_START";
export const GET_ADMIN_FILES_SUCCESS = "GET_ADMIN_FILES_SUCCESS";
export const GET_ONE_ADMIN_FILES_SUCCESS = "GET_ONE_ADMIN_FILES_SUCCESS";
export const GET_ADMIN_FILES_FAIL = "GET_ADMIN_FILES_FAIL";