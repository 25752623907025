import { updateObject } from '../../utils/updateObject';

import * as actionsTypes from '../actions/actionTypes';

const initialReducer = {
  services: [],
  servicesError: [],
  servicesLoading: false,
  serviceWidgets: [],
  serviceWidgetsError: [],
  serviceWidgetsLoading: false,
};

const getServicesStart = (state, action) => {
  return updateObject(state, {
    services: [],
    servicesError: [],
    servicesLoading: true,
  });
};

const getServicesSuccess = (state, action) => {
  return updateObject(state, {
    services: action.services,
    servicesError: [],
    servicesLoading: false,
  });
};

const getServicesFail = (state, action) => {
  return updateObject(state, {
    services: [],
    servicesError: action.error,
    servicesLoading: false,
  });
};

const getServiceWidgetsStart = (state, action) => {
  return updateObject(state, {
    serviceWidgets: [],
    serviceWidgetsError: [],
    serviceWidgetsLoading: true,
  });
};

const getServiceWidgetsSuccess = (state, action) => {
  return updateObject(state, {
    serviceWidgets: action.serviceWidgets,
    serviceWidgetsError: [],
    serviceWidgetsLoading: false,
  });
};

const getServiceWidgetsFail = (state, action) => {
  return updateObject(state, {
    serviceWidgets: [],
    serviceWidgetsError: action.error,
    serviceWidgetsLoading: false,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_SERVICES_START:
      return getServicesStart(state, action);
    case actionsTypes.GET_SERVICES_SUCCESS:
      return getServicesSuccess(state, action);
    case actionsTypes.GET_SERVICES_FAIL:
      return getServicesFail(state, action);
    case actionsTypes.GET_SERVICE_WIDGETS_START:
      return getServiceWidgetsStart(state, action);
    case actionsTypes.GET_SERVICE_WIDGETS_SUCCESS:
      return getServiceWidgetsSuccess(state, action);
    case actionsTypes.GET_SERVICE_WIDGETS_FAIL:
      return getServiceWidgetsFail(state, action);
    default:
      return state;
  }
};
export default reducer;
