import HomeAbout from '../aboutComponents/HomeAbout';
import TodayQuote from '../components/TodayQuote';
import Footer from '../footerComponents/Footer';
import Header from '../headerCompoents/Header';
import ScrollToTop from '../components/ScrollToTop';
import { HomeCarousel } from '../homeCarousel/HomeCarousel';
import HomePartners from '../partnersComponents/HomePartners';
import HomeServices from '../servicesComponent/HomeServices';
import HomeSpotlight from '../spotlight/HomeSpotlight';

export const Home = (props) => {
  return (
    <div className=" overflow-x-hidden scrollbar-hidden">
      <Header pageLink="/" />
      <HomeCarousel />
      <HomeAbout />
      <HomeSpotlight />
      <HomePartners />
      <HomeServices />
      <TodayQuote />
      <Footer />
      <ScrollToTop />
    </div>
  );
};
