import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from '../store/actions';

const AutoSignIn = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.token !== null);
  const tryAutoSignin = actionTypes.authCheckState;
  const autoSignin = useCallback(() => {
    dispatch(tryAutoSignin());
  }, [dispatch, tryAutoSignin]);
  if (!isAuthenticated) {
    autoSignin();
  }
  return null;
};

export default AutoSignIn;
