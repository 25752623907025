import { updateObject } from "../../utils/updateObject";

import * as adminActionTypes from "../actions/adminActionTypes";

const initialReducer = {
  adminPublications: [],
  adminPublicationsError: null,
  adminPublicationsLoading: false,
  publication: null,
  adminNewsletter: [],
  adminNewsletterError: null,
  adminNewsletterLoading: false,
  newsletter: null,
  adminEvents: [],
  adminEventsError: null,
  adminEventsLoading: false,
  event: null,
  adminMediaCoverage: [],
  adminMediaCoverageError: null,
  adminMediaCoverageLoading: false,
  mediaCoverage: null,
  adminGiftAndFreebies: [],
  adminGiftAndFreebiesError: null,
  adminGiftAndFreebiesLoading: false,
  giftAndFreebie: null,
  adminTodayAtOGenius: [],
  adminTodayAtOGeniusError: null,
  adminTodayAtOGeniusLoading: false,
  todayAtOGenius: null,
};

const getAdminPublicationsStart = (state, action) => {
  return updateObject(state, {
    adminPublications: [],
    adminPublicationsError: null,
    adminPublicationsLoading: true,
    publication: null,
  });
};

const getAdminPublicationsSuccess = (state, action) => {
  return updateObject(state, {
    adminPublications: action.adminPublications,
    adminPublicationsError: null,
    adminPublicationsLoading: false,
    publication: null,
  });
};

const getAdminPublicationsFail = (state, action) => {
  return updateObject(state, {
    adminPublications: [],
    adminPublicationsError: action.error,
    adminPublicationsLoading: false,
    publication: null,
  });
};

const getOneAdminPublicationsSuccess = (state, action) => {
  return updateObject(state, {
    adminPublications: [],
    adminPublicationsError: null,
    adminPublicationsLoading: false,
    publication: action.publication,
  });
};

const getAdminNewsletterStart = (state, action) => {
  return updateObject(state, {
    adminNewsletter: [],
    adminNewsletterError: null,
    adminNewsletterLoading: true,
    newsletter: null,
  });
};

const getAdminNewsletterSuccess = (state, action) => {
  return updateObject(state, {
    adminNewsletter: action.adminNewsletter,
    adminNewsletterError: null,
    adminNewsletterLoading: false,
    newsletter: null,
  });
};

const getAdminNewsletterFail = (state, action) => {
  return updateObject(state, {
    adminNewsletter: [],
    adminNewsletterError: action.error,
    adminNewsletterLoading: false,
    newsletter: null,
  });
};

const getOneAdminNewsletterSuccess = (state, action) => {
  return updateObject(state, {
    adminNewsletter: [],
    adminNewsletterError: null,
    adminNewsletterLoading: false,
    newsletter: action.newsletter,
  });
};

const getAdminEventsStart = (state, action) => {
  return updateObject(state, {
    adminEvents: [],
    adminEventsError: null,
    adminEventsLoading: true,
    event: null,
  });
};

const getAdminEventsSuccess = (state, action) => {
  return updateObject(state, {
    adminEvents: action.adminEvents,
    adminEventsError: null,
    adminEventsLoading: false,
    event: null,
  });
};

const getAdminEventsFail = (state, action) => {
  return updateObject(state, {
    adminEvents: [],
    adminEventsError: action.error,
    adminEventsLoading: false,
    event: null,
  });
};

const getOneAdminEventsSuccess = (state, action) => {
  return updateObject(state, {
    adminEvents: [],
    adminEventsError: null,
    adminEventsLoading: false,
    event: action.event,
  });
};

const getAdminMediaCoverageStart = (state, action) => {
  return updateObject(state, {
    adminMediaCoverage: [],
    adminMediaCoverageError: null,
    adminMediaCoverageLoading: true,
    mediaCoverage: null,
  });
};

const getAdminMediaCoverageSuccess = (state, action) => {
  return updateObject(state, {
    adminMediaCoverage: action.adminMediaCoverage,
    adminMediaCoverageError: null,
    adminMediaCoverageLoading: false,
    mediaCoverage: null,
  });
};

const getAdminMediaCoverageFail = (state, action) => {
  return updateObject(state, {
    adminMediaCoverage: [],
    adminMediaCoverageError: action.error,
    adminMediaCoverageLoading: false,
    mediaCoverage: null,
  });
};

const getOneAdminMediaCoverageSuccess = (state, action) => {
  return updateObject(state, {
    adminMediaCoverage: [],
    adminMediaCoverageError: [],
    adminMediaCoverageLoading: false,
    mediaCoverage: action.mediaCoverage,
  });
};

const getAdminGiftsAndFreebiesStart = (state, action) => {
  return updateObject(state, {
    adminGiftAndFreebies: [],
    adminGiftAndFreebiesError: null,
    adminGiftAndFreebiesLoading: true,
    giftAndFreebie: null,
  });
};

const getAdminGiftsAndFreebiesSuccess = (state, action) => {
  return updateObject(state, {
    adminGiftAndFreebies: action.adminGiftAndFreebies,
    adminGiftAndFreebiesError: null,
    adminGiftAndFreebiesLoading: false,
    giftAndFreebie: null,
  });
};

const getAdminGiftsAndFreebiesFail = (state, action) => {
  return updateObject(state, {
    adminGiftAndFreebies: [],
    adminGiftAndFreebiesError: action.error,
    adminGiftAndFreebiesLoading: false,
    giftAndFreebie: null,
  });
};

const getOneAdminGiftsAndFreebiesSuccess = (state, action) => {
  return updateObject(state, {
    adminGiftAndFreebies: [],
    adminGiftAndFreebiesError: null,
    adminGiftAndFreebiesLoading: false,
    giftAndFreebie: action.giftAndFreebie,
  });
};

const getAdminTodayAtOgeniusStart = (state, action) => {
  return updateObject(state, {
    adminTodayAtOGenius: [],
    adminTodayAtOGeniusError: null,
    adminTodayAtOGeniusLoading: true,
    todayAtOGenius: null,
  });
};

const getAdminTodayAtOgeniusSuccess = (state, action) => {
  return updateObject(state, {
    adminTodayAtOGenius: action.adminTodayAtOGenius,
    adminTodayAtOGeniusError: null,
    adminTodayAtOGeniusLoading: false,
    todayAtOGenius: null,
  });
};

const getAdminTodayAtOgeniusFail = (state, action) => {
  return updateObject(state, {
    adminTodayAtOGenius: [],
    adminTodayAtOGeniusError: action.error,
    adminTodayAtOGeniusLoading: false,
    todayAtOGenius: null,
  });
};

const getOneAdminTodayAtOgeniusSuccess = (state, action) => {
  return updateObject(state, {
    adminTodayAtOGenius: [],
    adminTodayAtOGeniusError: null,
    adminTodayAtOGeniusLoading: false,
    todayAtOGenius: action.todayAtOGenius,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case adminActionTypes.GET_ADMIN_PUBLICATIONS_START:
      return getAdminPublicationsStart(state, action);
    case adminActionTypes.GET_ADMIN_PUBLICATIONS_SUCCESS:
      return getAdminPublicationsSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_PUBLICATIONS_SUCCESS:
      return getOneAdminPublicationsSuccess(state, action);
    case adminActionTypes.GET_ADMIN_PUBLICATIONS_FAIL:
      return getAdminPublicationsFail(state, action);
    case adminActionTypes.GET_ADMIN_NEWSLETTER_START:
      return getAdminNewsletterStart(state, action);
    case adminActionTypes.GET_ADMIN_NEWSLETTER_SUCCESS:
      return getAdminNewsletterSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_NEWSLETTER_SUCCESS:
      return getOneAdminNewsletterSuccess(state, action);
    case adminActionTypes.GET_ADMIN_NEWSLETTER_FAIL:
      return getAdminNewsletterFail(state, action);
    case adminActionTypes.GET_ADMIN_EVENTS_START:
      return getAdminEventsStart(state, action);
    case adminActionTypes.GET_ADMIN_EVENTS_SUCCESS:
      return getAdminEventsSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_EVENTS_SUCCESS:
      return getOneAdminEventsSuccess(state, action);
    case adminActionTypes.GET_ADMIN_EVENTS_FAIL:
      return getAdminEventsFail(state, action);
    case adminActionTypes.GET_ADMIN_MEDIA_COVERAGE_START:
      return getAdminMediaCoverageStart(state, action);
    case adminActionTypes.GET_ADMIN_MEDIA_COVERAGE_SUCCESS:
      return getAdminMediaCoverageSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_MEDIA_COVERAGE_SUCCESS:
      return getOneAdminMediaCoverageSuccess(state, action);
    case adminActionTypes.GET_ADMIN_MEDIA_COVERAGE_FAIL:
      return getAdminMediaCoverageFail(state, action);
    case adminActionTypes.GET_ADMIN_GIFTS_AND_FREEBIES_START:
      return getAdminGiftsAndFreebiesStart(state, action);
    case adminActionTypes.GET_ADMIN_GIFTS_AND_FREEBIES_SUCCESS:
      return getAdminGiftsAndFreebiesSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_GIFTS_AND_FREEBIES_SUCCESS:
      return getOneAdminGiftsAndFreebiesSuccess(state, action);
    case adminActionTypes.GET_ADMIN_GIFTS_AND_FREEBIES_FAIL:
      return getAdminGiftsAndFreebiesFail(state, action);
    case adminActionTypes.GET_ADMIN_TODAY_AT_OGENIUS_START:
      return getAdminTodayAtOgeniusStart(state, action);
    case adminActionTypes.GET_ADMIN_TODAY_AT_OGENIUS_SUCCESS:
      return getAdminTodayAtOgeniusSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_TODAY_AT_OGENIUS_SUCCESS:
      return getOneAdminTodayAtOgeniusSuccess(state, action);
    case adminActionTypes.GET_ADMIN_TODAY_AT_OGENIUS_FAIL:
      return getAdminTodayAtOgeniusFail(state, action);
    default:
      return state;
  }
};
export default reducer;
