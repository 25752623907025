import "./servicescss.css";
import parse from "html-react-parser";

import dotenv from "dotenv";
dotenv.config();

const TestimonyCard = (props) => {
  return (
    <div className="it-testimony-card h-full flex space-x-5 w-full h-full">
      <div className="pr-4 border-r">
        <div className="text-base font-semibold text-main-color site-text-blue py-2">
          {props.client}
        </div>
        <div className="it-testimony-description max-h-3/5 overflow-scroll scrollbar-hidden">
          {props.testimony != undefined
            ? parse(eval(props.testimony))
            : parse("<p></p>")}
        </div>
      </div>
    </div>
  );
};
export default TestimonyCard;
