import { Button, TableLink } from "../components/Buttons";
import "./admin.css";
import {
  ActivateButton,
  ActivatingButton,
  ArchivingButton,
  InputText,
  InputTextArea,
  LinkComponent,
  MoreDropDown,
  Uploading,
} from "./AdminComponentsList";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions";
import * as adminActionTypes from "../store/actions/adminIndex";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import AsyncSelect from "react-select/async";
import contants, {
  successUpdateNotification,
  successDeleteNotification,
  confirmNotification,
} from "./contants";
import {ReactNotifications} from "react-notifications-component";
import { MyDataTable } from "./MyDataTable";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { useParams } from "react-router";
import { GenericDisplayMedia } from "./AdminGallerieComponentsList";

import dotenv from "dotenv";
dotenv.config();
const  ReactNotification=ReactNotifications


export const ExecutiveIntroPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminAbout.executifLeadership);
  const objectLoading = useSelector(
    (state) => state.adminAbout.adminExecutifLeadershipLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminExcutifLeadership(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(
          adminActionTypes.desactivateAdminExcutifLeadership(object.uuid)
        );
        successUpdateNotification(" Executive Leadership", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminExcutifLeadership(object.uuid));
        successUpdateNotification(" Executive Leadership", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminExcutifLeadership(object.uuid));
        successUpdateNotification(" Executive Leadership", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminExcutifLeadership(object.uuid));
        successUpdateNotification(" Executive Leadership", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminExcutifLeadership(object.uuid));
      successDeleteNotification(" Executive Leadership");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">
                  EXECUTIVE INTRODUCTION PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Subtitle :</span>
                    <span className="site-text-blue px-3">
                      {object?.subtitle}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Description:
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.description != undefined
                        ? parse(eval(object?.description))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/about/executive-intro"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/about/executive-intro/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const CareersPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminAbout.career);
  const objectLoading = useSelector(
    (state) => state.adminAbout.adminCareersLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminCareer(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminCareer(object.uuid));
        successUpdateNotification(" Career", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminCareer(object.uuid));
        successUpdateNotification(" Career", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminCareer(object.uuid));
        successUpdateNotification(" Career", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminCareer(object.uuid));
        successUpdateNotification(" Career", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminCareer(object.uuid));
      successDeleteNotification(" Career");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">CAREER PREVIEW</span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Call to Action Name :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.calltoactionname}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Call to Action :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.calltoaction}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Description:
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.description != undefined
                        ? parse(eval(object?.description))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between h-80 ">
                <GenericDisplayMedia media={object?.image} />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/about/careers"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/about/careers/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const StaffIntroPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminAbout.staff);
  const objectLoading = useSelector(
    (state) => state.adminAbout.adminStaffLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminStaff(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminStaff(object.uuid));
        successUpdateNotification(" Staff Intro", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminStaff(object.uuid));
        successUpdateNotification(" Staff Intro", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminStaff(object.uuid));
        successUpdateNotification(" Staff Intro", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminStaff(object.uuid));
        successUpdateNotification(" Staff Intro", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminStaff(object.uuid));
      successDeleteNotification(" Staff Intro");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">
                  STAFF INTRO PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Link :</span>
                    <span className="site-text-blue px-3">
                      {object?.link}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Description:
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.description != undefined
                        ? parse(eval(object?.description))
                        : parse("<p></p>")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/about/staff-intros"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/about/staff-intros/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const StaffAndTeamPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminAbout.staffMember);
  const objectLoading = useSelector(
    (state) => state.adminAbout.adminStaffMembersLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminStaffMembers(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminStaffMembers(object.uuid));
        successUpdateNotification(" Staff Member", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminStaffMembers(object.uuid));
        successUpdateNotification(" Staff Member", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminStaffMembers(object.uuid));
        successUpdateNotification(" Staff Member", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminStaffMembers(object.uuid));
        successUpdateNotification(" Staff Member", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminStaffMembers(object.uuid));
      successDeleteNotification(" Staff Member");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">
                  STAFF MEMBER PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Name :</span>
                    <span className="site-text-blue px-3">
                      {object?.name}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Email :</span>
                    <span className="site-text-blue px-3">
                      {object?.email}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Position :</span>
                    <span className="site-text-blue px-3">
                      {object?.position}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Executive :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.isExecutive}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Quote:</span>
                    <span className="site-text-blue px-3">
                      {
                        object?.quote != undefined ? parse(eval(object?.quote)) : parse("<p></p>"
                      )}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between h-80 ">
                <GenericDisplayMedia media={object?.profile} />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/about/staff-members"
                objectId={object != null ? object.uuid : ""}
              />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/about/staff-members/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const AwardsIntroPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminAbout.awardsIntro);
  const objectLoading = useSelector(
    (state) => state.adminAbout.adminAwardsIntroLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminAwardsIntro(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminAwardsIntro(object.uuid));
        successUpdateNotification(" Awards Intro", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminAwardsIntro(object.uuid));
        successUpdateNotification(" Awards Intro", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminAwardsIntro(object.uuid));
        successUpdateNotification(" Awards Intro", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminAwardsIntro(object.uuid));
        successUpdateNotification(" Awards Intro", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminAwardsIntro(object.uuid));
      successDeleteNotification(" Awards Intro");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">
                  AWARDS INTRO PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Summary:</span>
                    <span className="site-text-blue px-3">
                      {
                        object?.summary != undefined
                          ? parse(eval(object?.summary))
                          : parse("<p></p>"
                      )}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/about/awards-summary"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/about/awards-summary/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const AwardsPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminAbout.award);
  const objectLoading = useSelector(
    (state) => state.adminAbout.adminAwardsLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminAwards(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminAwards(object.uuid));
        successUpdateNotification(" Award", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminAwards(object.uuid));
        successUpdateNotification(" Award", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminAwards(object.uuid));
        successUpdateNotification(" Award", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminAwards(object.uuid));
        successUpdateNotification(" Award", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminAwards(object.uuid));
      successDeleteNotification(" Award");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold">
                <span className="admin-page-title text-xl">AWARD PREVIEW</span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Subtitle :</span>
                    <span className="site-text-blue px-3">
                      {object?.subtitle}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Main :</span>
                    <span className="site-text-blue px-3">
                      {object?.ismain}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between h-80 ">
                <GenericDisplayMedia media={object?.image} />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/about/awards"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/about/awards/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const EthicsAndCompliancePreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminAbout.ethicsAndCompliance);
  const objectLoading = useSelector(
    (state) => state.adminAbout.adminEthicsAndComplianceLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminEthicsAndCompliance(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(
          adminActionTypes.desactivateAdminEthicsAndCompliance(object.uuid)
        );
        successUpdateNotification(" Ethics/Compliance", contants.INACTIVE);
      } else {
        dispatch(
          adminActionTypes.activateAdminEthicsAndCompliance(object.uuid)
        );
        successUpdateNotification(" Ethics/Compliance", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminEthicsAndCompliance(object.uuid));
        successUpdateNotification(" Ethics/Compliance", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminEthicsAndCompliance(object.uuid));
        successUpdateNotification(" Ethics/Compliance", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminEthicsAndCompliance(object.uuid));
      successDeleteNotification(" Ethics/Compliance");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold pb-5">
                <span className="admin-page-title text-xl">
                  ETHICS/COMPLIANCE PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Type :</span>
                    <span className="site-text-blue px-3">
                      {object?.type}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Description:
                    </span>
                    <span className="site-text-blue px-3">
                      {
                        object?.description != undefined
                          ? parse(eval(object?.description))
                          : parse("<p></p>"
                      )}
                    </span>{" "}
                  </div>

                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between h-80 ">
                <GenericDisplayMedia media={object?.image} />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/about/ethics-and-compliance"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/about/ethics-and-compliance/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const GetInTouchPreview = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const object = useSelector((state) => state.adminAbout.getInTouch);
  const objectLoading = useSelector(
    (state) => state.adminAbout.adminGetInTouchLoading
  );

  useEffect(() => {
    dispatch(adminActionTypes.getOneAdminGetInTouch(params.objectId));
  }, [dispatch]);

  const activate = () => {
    if (window.confirm("Are you sure you want activate/desactivate?")) {
      if (object.status == contants.active) {
        dispatch(adminActionTypes.desactivateAdminGetInTouch(object.uuid));
        successUpdateNotification(" Get In Touch", contants.INACTIVE);
      } else {
        dispatch(adminActionTypes.activateAdminGetInTouch(object.uuid));
        successUpdateNotification(" Get In Touch", contants.ACTIVE);
      }
    } else {
    }
  };

  const archive = () => {
    if (window.confirm("Are you sure you want archive/set to visible?")) {
      if (object.access == contants.visible) {
        dispatch(adminActionTypes.archiveAdminGetInTouch(object.uuid));
        successUpdateNotification(" Get In Touch", contants.ARCHIVE);
      } else {
        dispatch(adminActionTypes.visibleAdminGetInTouch(object.uuid));
        successUpdateNotification(" Get In Touch", contants.VISIBLE);
      }
    } else {
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want delete?")) {
      dispatch(adminActionTypes.deleteAdminGetInTouch(object.uuid));
      successDeleteNotification(" Get In Touch");
    } else {
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {objectLoading && object ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form className="flex justify-between pt-6 px-12 w-full">
            <div className="flex flex-col pt-5 w-full">
              <div className="font-bold pb-5">
                <span className="admin-page-title text-xl">
                  GET IN TOUCH PREVIEW
                </span>
              </div>

              <div className="flex w-full space-x-10">
                <div className="w-3/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Title :</span>
                    <span className="site-text-blue px-3">
                      {object?.title}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Call to Action Name :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.calltoactionname}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Call to Action Link :
                    </span>
                    <span className="site-text-blue px-3">
                      {object?.calltoactionlink}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Description:
                    </span>
                    <span className="site-text-blue px-3">
                      {
                        object?.description != undefined
                          ? parse(eval(object?.description))
                          : parse("<p></p>"
                      )}
                    </span>{" "}
                  </div>

                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Language :</span>
                    <span className="site-text-blue px-3">
                      {object?.language}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Creator :</span>
                    <span className="site-text-blue px-3">
                      {object?.creator}
                    </span>{" "}
                  </div>
                </div>
                <div className="w-2/5">
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Created At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.createdAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">
                      Updated At :
                    </span>
                    <span className="site-text-blue px-3">
                      {moment(object?.updatedAt).format("DD-MM-YYYY")}
                    </span>{" "}
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Status :</span>
                    <span
                      className={
                        object?.status == contants.active
                          ? "bg-green-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-red-600 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.status}
                    </span>{" "}
                    <ActivatingButton
                      onClick={activate}
                      status={object?.status}
                    />
                  </div>
                  <div className="flex py-2">
                    <span className="site-text-blue font-bold">Access :</span>
                    <span
                      className={
                        object?.access == contants.visible
                          ? "bg-blue-600 px-4 text-white text-md rounded-sm ml-4"
                          : "bg-gray-500 px-4 text-white rounded-sm"
                      }
                    >
                      {object?.access}
                    </span>{" "}
                    <ArchivingButton
                      onClick={archive}
                      access={object?.access}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <TableLink
                title="Update"
                type="updateLink"
                link="/admin/about/get-in-touch"
                objectId={object != null ? object.uuid : ""}
              />
              <Button name="DELETE" type="delete" onClick={onDelete} />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/about/get-in-touch/objects/list"
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
