import { Button, SelectButton, TableLink } from "../components/Buttons";
import "./admin.css";
import {
  DeleteButton,
  EditButton,
  InputText,
  InputTextArea,
  LinkComponent,
  MoreDropDown,
  PreviewButton,
  Uploading,
} from "./AdminComponentsList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import AsyncSelect from "react-select/async";
import { successNotification, confirmNotification } from "./contants";
import {ReactNotifications} from "react-notifications-component";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { MyDataTable } from "./MyDataTable";
import * as adminActionTypes from "../store/actions/adminIndex";
import moment from "moment";
const ReactNotification=ReactNotifications
export const PartnersTable = () => {
  const dispatch = useDispatch();
  const partners = useSelector((state) => state.adminPartners.adminPartners);
  const partnersLoading = useSelector(
    (state) => state.adminPartners.adminPartnersLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAllAdminPartners());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {partnersLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">PARTNERS</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Name</th>

                      <th className="border border-blue-400 px-4 py-2">Link</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Laguage
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {partners.map((partner, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {partner.name}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {partner.link}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(partner.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {partner.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {partner.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {partner.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={partner.uuid}
                              link="/admin/partners/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Partner"
                  type="addLink"
                  link="/admin/partners"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const PartnersIntroTable = () => {
  const dispatch = useDispatch();
  const partnersIntro = useSelector(
    (state) => state.adminPartners.adminPartnersIntro
  );
  const partnersIntroLoading = useSelector(
    (state) => state.adminPartners.adminPartnersIntroLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminPartnersIntro());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {partnersIntroLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">
                    PARTNERS INTRO
                  </span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">
                        Title
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {partnersIntro.map((intro, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.title}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(intro.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {intro.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={intro.uuid}
                              link="/admin/partners-introduction/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Intro"
                  type="addLink"
                  link="/admin/partners-introduction"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const AffiliationsTable = () => {
  const dispatch = useDispatch();
  const affiliations = useSelector(
    (state) => state.adminPartners.adminAffiliations
  );
  const affiliationsLoading = useSelector(
    (state) => state.adminPartners.adminAffiliationsLoading
  );
  useEffect(() => {
    dispatch(adminActionTypes.getAdminAffiliations());
  }, [dispatch]);
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {affiliationsLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="pt-4 pr-16 w-full">
            <div className="flex justify-between pl-12 ">
              <div className="flex flex-col pt-5 space-y-4 w-full">
                <div className="font-bold flex flex-col">
                  <span className="admin-page-title text-xl">AFFILIATIONS</span>
                </div>
                <table className="table-auto border-collapse border-2 border-gray-300">
                  <thead>
                    <tr>
                      <th className="border border-blue-400 px-4 py-2">Name</th>
                      <th className="border border-blue-400 px-4 py-2">Link</th>
                      <th className="border border-blue-400 px-4 py-2">
                        Updated At
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Language
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Status
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        Creator
                      </th>
                      <th className="border border-blue-400 px-4 py-2">
                        ACTIONS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {affiliations.map((affiliation, i) => (
                      <tr key={i} className="text-lg font-semibold">
                        <td className="border border-blue-400 px-4 py-2">
                          {affiliation.name}
                        </td>

                        <td className="border border-blue-400 px-4 py-2">
                          {affiliation.link}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {moment(affiliation.updatedAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {affiliation.language}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {affiliation.status}
                        </td>
                        <td className="border border-blue-400 px-4 py-2">
                          {affiliation.creator}
                        </td>
                        <td className="border border-blue-400 px-4 py-2 justify-center">
                          <div className="flex justify-center space-x-1">
                            <PreviewButton
                              id={affiliation.uuid}
                              link="/admin/affiliations/edit"
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex flex-col space-y-2">
                <TableLink
                  title="Add New Affiliation"
                  type="addLink"
                  link="/admin/affiliations"
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
