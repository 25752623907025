import cogwheel from "../images/cogwheel.svg";
import { BsFillGearFill } from "react-icons/bs";
const Spinner = (props) => {
  return (
    <div className="z-50 flex justify-center items-center">
      <div
        className={`animate-spin  ${props.small ? "h-5 w-5 " : "h-32 w-32 "}  ${
          props.color
        }`}
      >
        <BsFillGearFill className="w-full h-full object-cover object-center text-gray-100" />
      </div>
    </div>
  );
};

export default Spinner;
