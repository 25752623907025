import { HashLink } from 'react-router-hash-link';
import dotenv from "dotenv";
dotenv.config();

const FooterSectionItem = (props) => {
  const scrollWidthOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };
  if (props.link.includes('http')) {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={props.link}
        className="footer-items-list"
      >
        {props.name}
      </a>
    );
  } else {
    return (
      <HashLink
        to={props.link}
        scroll={scrollWidthOffset}
        smooth
        className="footer-items-list"
      >
        {props.name}
      </HashLink>
    );
  }
};
export default FooterSectionItem;
