import { updateObject } from '../../utils/updateObject';

import * as actionsTypes from '../actions/actionTypes';

const initialReducer = {
  contactUs: null,
  contactUsError: null,
  contactUsLoading: false,
  quickLinks: [],
  quickLinksError: [],
  quickLinksLoading: false,
  faqs: [],
  faqsError: [],
  faqsLoading: false,
  message: null,
  messageError: null,
  messageLoading: false,
};

const getContactUsStart = (state, action) => {
  return updateObject(state, {
    contactUs: null,
    contactUsError: null,
    contactUsLoading: true,
  });
};

const getContactUsSuccess = (state, action) => {
  return updateObject(state, {
    contactUs: action.contactUs,
    contactUsError: null,
    contactUsLoading: false,
  });
};

const getContactUsFail = (state, action) => {
  return updateObject(state, {
    contactUs: null,
    contactUsError: action.error,
    contactUsLoading: false,
  });
};

const getQuickLinksStart = (state, action) => {
  return updateObject(state, {
    quickLinks: [],
    quickLinksError: [],
    quickLinksLoading: true,
  });
};

const getQuickLinksSuccess = (state, action) => {
  return updateObject(state, {
    quickLinks: action.quickLinks,
    quickLinksError: null,
    quickLinksLoading: false,
  });
};

const getQuickLinksFail = (state, action) => {
  return updateObject(state, {
    quickLinks: [],
    quickLinksError: action.error,
    quickLinksLoading: false,
  });
};

const getFaqsStart = (state, action) => {
  return updateObject(state, {
    faqs: [],
    faqsError: [],
    faqsLoading: true,
  });
};

const getFaqsSuccess = (state, action) => {
  return updateObject(state, {
    faqs: action.faqs,
    faqsError: null,
    faqsLoading: false,
  });
};

const getFaqsFail = (state, action) => {
  return updateObject(state, {
    faqs: [],
    faqsError: action.error,
    faqsLoading: false,
  });
};

const sendMessageStart = (state, action) => {
  return updateObject(state, {
    message: null,
    messageError: null,
    messageLoading: true,
  });
};

const sendMessageSuccess = (state, action) => {
  return updateObject(state, {
    message: action.message,
    messageError: null,
    messageLoading: false,
  });
};

const sendMessageFail = (state, action) => {
  return updateObject(state, {
    message: [],
    messageError: action.error,
    messageLoading: false,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_CONTACT_US_START:
      return getContactUsStart(state, action);
    case actionsTypes.GET_CONTACT_US_SUCCESS:
      return getContactUsSuccess(state, action);
    case actionsTypes.GET_CONTACT_US_FAIL:
      return getContactUsFail(state, action);
    case actionsTypes.GET_QUICK_LINKS_START:
      return getQuickLinksStart(state, action);
    case actionsTypes.GET_QUICK_LINKS_SUCCESS:
      return getQuickLinksSuccess(state, action);
    case actionsTypes.GET_QUICK_LINKS_FAIL:
      return getQuickLinksFail(state, action);
    case actionsTypes.GET_FAQS_START:
      return getFaqsStart(state, action);
    case actionsTypes.GET_FAQS_SUCCESS:
      return getFaqsSuccess(state, action);
    case actionsTypes.GET_FAQS_FAIL:
      return getFaqsFail(state, action);
    case actionsTypes.SEND_MESSAGE_START:
      return sendMessageStart(state, action);
    case actionsTypes.SEND_MESSAGE_SUCCESS:
      return sendMessageSuccess(state, action);
    case actionsTypes.SEND_MESSAGE_FAIL:
      return sendMessageFail(state, action);
    default:
      return state;
  }
};
export default reducer;
