import React from "react";
import parse from "html-react-parser";

const WidgetF = (props) => {
  return (
    <div
      id={props.widget.hashlink}
      className="training-service flex flex-col md:flex-row md:pt-10 md:pb-6 l:pt-20 lg:pb-12 md:h-60vh lg:h-70vh"
    >
      <div
        data-aos="slide-up"
        data-aos-duration="1500"
        className="w-full md:w-1/2 flex flex-col pr-10 pt-6 pb-10 pl-5"
      >
        <div className="flex justify-end training-title-info site-text-blue py-5">
          {props.widget.title}
        </div>
        <div className="training-text flex flex-col overflow-scroll scrollbar-hidden">
          {
            props.widget.body != undefined ? parse(eval(props.widget.body)) : parse("<p></p>"
          )}
        </div>
      </div>
      <div className="md:w-1/2">
        <img
          src={`${process.env.REACT_APP_BACKEND_URL}/site/${props.widget.image}`}
          alt="Training"
          className="object-cover object-center h-full w-full"
        />
      </div>
    </div>
  );
};

export default WidgetF;
