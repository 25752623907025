import { Button, TableLink } from "../components/Buttons";
import "./admin.css";
import {
  InputText,
  InputTextArea,
  InputTextAreaQuill,
  InsertingLanguagesTable,
  LinkComponent,
  MoreDropDown,
  SelectRadioButton,
  ShowOrHideLinks,
  Uploading,
} from "./AdminComponentsList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionTypes from "../store/actions/";
import * as adminActionTypes from "../store/actions/adminIndex";
import Spinner from "../components/Spinner";
import AsyncSelect from "react-select/async";
import contants, {
  successNotification,
  confirmNotification,
  successUpdateNotification,
  successCreationNotification,
} from "./contants";
import {ReactNotifications} from "react-notifications-component";
import { useParams } from "react-router";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import classnames from "classnames";
import richTextConstants from "./richtextconstants";
import dotenv from "dotenv";
dotenv.config();
const ReactNotification=ReactNotifications


export const Carousel = () => {
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const links = useSelector((state) => state.links.links);
  const platfomLinks = useSelector((state) => state.links.platfomLinks);
  const genericLinks = useSelector((state) => state.links.genericLinks);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const object = useSelector((state) => state.adminHome.homeCarousel);
  const params = useParams();
  const [header, setHeader] = useState(object != null ? object?.header : "");
  const [subtitle, setSubtitle] = useState(
    object != null ? eval(object?.subtitle) : ""
  );
  const [video, setVideo] = useState(null);
  const [coverimage, setCoverimage] = useState("");
  const linksLoading = useSelector((state) => state.links.linksLoading);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const [filesintext, setfilesintext] = useState([]);

  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(actionTypes.getAdminPlatfomLinks());
    dispatch(actionTypes.getAdminGenericLinks());
    dispatch(adminActionTypes.getOneAdminHomeCarousels(params.objectId));
  }, [dispatch]);

  const videoSelectoHandler = (event) => {
    setVideo(event.target.files[0]);
  };

  const coverimageSelectoHandler = (event) => {
    setCoverimage(event.target.files[0]);
  };

  //SHOWING SITE LINKS
  const [sitelinks, setsitelinks] = useState(false);
  const showOrHideSiteLinks = () => {
    setsitelinks(!sitelinks);
  };

  // SELECT BUTTO IMPLEMENTATION
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);

  // ON SUBMIT FUNCTION

  const onSubmit = (e) => {
    e.preventDefault();
    if (header === "" || subtitle === "" || selectedValue === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            header,
            subtitle: richtext.editorValue,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminHomeCarousels(obj));
          setHeader("");
          setSubtitle("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          const id = selectedValue.uuid;
          const subs = setObjects([
            ...objects,
            {
              header,
              subtitle: richtext.editorValue,
              creator: creatorName,
              language: lang,
              discovermorelink: id,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Home Carousel", languageName);
          setHeader("");
          setSubtitle("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setHeader(header);
          setSubtitle(subtitle);
          setdisabled(true);
        }
        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("video", video);
          fd.append("coverimage", coverimage);
          fd.append("objects", JSON.stringify(objects));
          dispatch(adminActionTypes.createAdminHomeCarousel(fd, token));
          setcounter(0);
          setObjects([]);
          setHeader("");
          setSubtitle("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };

  return (
    <div>
      <ReactNotification />
      {languageLoading && linksLoading ? (
        <div className="w-full h-80vh flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={onSubmit} className="flex justify-between pl-12">
          <div className="flex flex-col pt-5 pb-10">
            <div className="font-bold">
              <span
                className={
                  object != null ? "hidden" : "block admin-page-title text-xl"
                }
              >
                New Carousel (Language:{" "}
                {languages[counter] ? languages[counter].name : ""})
              </span>
              <span
                className={
                  object != null ? "block admin-page-title text-xl" : "hidden"
                }
              >
                Carousel (Language: {object?.language}) Editing
              </span>
            </div>
            <InputText
              title="Header"
              value={header}
              name="header"
              isClicked={clicked}
              onChange={(e) => setHeader(e.target.value)}
            />
            <InputTextArea
              title="Subtitle"
              value={subtitle}
              data={subtitle}
              isClicked={clicked}
              name="subtitle"
              onChange={(e) => {
                setSubtitle(e);
              }}
            />
            <Uploading
              updating={object != null ? contants.isTrue : contants.isFalse}
              title="Cover Image"
              text1="upload image"
              text2="View the image"
              name="coverimage"
              required
              onChange={coverimageSelectoHandler}
            />
            <Uploading
              updating={object != null ? contants.isTrue : contants.isFalse}
              title="Video"
              text1="upload video"
              text2="View the video"
              name="video"
              required
              accept={" .mp4, .mpeg"}
              onChange={videoSelectoHandler}
            />
            <div className="pt-5 flex flex-col">
              <ShowOrHideLinks
                title={"Select From Site Links"}
                name={"showLinks"}
                option1="True"
                option2="False"
                value={sitelinks}
                onChange={showOrHideSiteLinks}
              />
              <div
                className={sitelinks === true ? "pt-3 w-full block" : "hidden"}
              >
                <label className="input-title">Platfom Links</label>
                <div className="flex">
                  <AsyncSelect
                    placeholder="Select Platfom Link"
                    className={classnames("mb-2 w-90%", {
                      "mb-2 w-90% border-2 border-red-500":
                        selectedValue === "" && clicked === true,
                    })}
                    cacheOptions
                    defaultOptions={platfomLinks}
                    getOptionLabel={(e) => e.name + "  (" + e.language + ")"}
                    getOptionValue={(e) => e.uuid}
                    value={selectedValue}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    isSearchable
                  />
                </div>
                <label className="block text-red-500">
                  {selectedValue === "" && clicked === true
                    ? "Select Link"
                    : ""}
                </label>
              </div>
            </div>
            <div
              className={sitelinks !== true ? "pt-3 w-full block" : "hidden"}
            >
              <label className="input-title">Discover More Link</label>
              <div className="flex">
                <AsyncSelect
                  placeholder="Select Link"
                  className={classnames("mb-2 w-90%", {
                    "mb-2 w-90% border-2 border-red-500":
                      selectedValue === "" && clicked === true,
                  })}
                  cacheOptions
                  defaultOptions={genericLinks}
                  getOptionLabel={(e) => e.name + "  (" + e.language + ")"}
                  getOptionValue={(e) => e.uuid}
                  value={selectedValue}
                  onChange={handleChange}
                  onInputChange={handleInputChange}
                  isSearchable
                />
              </div>
              <label className="block text-red-500">
                {selectedValue === "" && clicked === true ? "Select Link" : ""}
              </label>
            </div>
          </div>
          <div className="flex flex-col space-y-20">
            <div className="flex flex-col space-y-2">
              <Button
                disabled={disabled}
                name={object === null ? contants.Save : contants.Update}
                type="square"
              />
              <Button
                name={contants.Publish}
                disabled={!disabled}
                type="square"
              />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/home-carousel/objects/list"
              />
            </div>
            <div>
              <InsertingLanguagesTable
                languages={languages}
                counter={counter}
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export const HomeAbout = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const languages = useSelector((state) => state.languages.languages);
  const links = useSelector((state) => state.links.links);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminHome.homeAbout);
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );

  const linksLoading = useSelector((state) => state.links.linksLoading);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminHomeAbout(params.objectId));
  }, [dispatch]);

  const [title, setTitle] = useState(object != null ? object?.title : "");
  const [subtitle, setSubtitle] = useState(
    object != null ? object?.subtitle : ""
  );
  const [description, setDescription] = useState(
    object != null ? eval(object?.description) : ""
  );
  const [image, setImage] = useState(null);

  const fileSelectorHandler = (event) => {
    setImage(event.target.files[0]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || subtitle === "" || description === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            subtitle,
            description: richtext.editorValue,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminHomeAbouts(obj));
          successUpdateNotification("Home About", contants.UPDATE);
          setTitle("");
          setDescription("");
          setSubtitle("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              title,
              subtitle,
              description: richtext.editorValue,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Home About", languageName);
          setTitle("");
          setDescription("");
          setSubtitle("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setTitle(title);
          setDescription(description);
          setSubtitle(subtitle);
          setdisabled(true);
        }
        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("image", image);
          fd.append("objects", JSON.stringify(objects));
          dispatch(adminActionTypes.createAdminHomeAbout(fd, token));
          setcounter(0);
          setObjects([]);
          setTitle("");
          setDescription("");
          setSubtitle("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      {languageLoading && linksLoading ? (
        <div className="w-full h-80vh flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={onSubmit} className="flex justify-between pl-12">
          <div className="flex flex-col pt-5 pb-10">
            <div className="font-bold">
              <span
                className={
                  object != null ? "hidden" : "block admin-page-title text-xl"
                }
              >
                New Home About (Language:{" "}
                {languages[counter] ? languages[counter].name : ""})
              </span>
              <span
                className={
                  object != null ? "block admin-page-title text-xl" : "hidden"
                }
              >
                Home About (Language: {object?.language}) Editing
              </span>
            </div>
            <div className="flex space-x-10 w-full">
              <div className="w-1/2">
                <InputText
                  title="Title"
                  value={title}
                  isClicked={clicked}
                  name="title"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="w-1/2">
                <InputText
                  title="Subtitle"
                  value={subtitle}
                  isClicked={clicked}
                  name="subtitle"
                  onChange={(e) => setSubtitle(e.target.value)}
                />
              </div>
            </div>
            <InputTextArea
              title="Description"
              data={description}
              isClicked={clicked}
              name="description"
              onChange={(e) => {
                setDescription(e);
              }}
            />
            <Uploading
              updating={object != null ? contants.isTrue : contants.isFalse}
              title="Image"
              text1="upload image"
              text2="View the image"
              name="image"
              required
              onChange={fileSelectorHandler}
            />
          </div>
          <div className="flex flex-col space-y-20">
            <div className="flex flex-col space-y-2">
              <Button
                disabled={disabled}
                name={object === null ? contants.Save : contants.Update}
                type="square"
              />
              <Button
                name={contants.Publish}
                disabled={!disabled}
                type="square"
              />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/home-about/objects/list"
              />
            </div>
            <div>
              <InsertingLanguagesTable
                languages={languages}
                counter={counter}
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export const ProductSpotlight = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const links = useSelector((state) => state.links.links);
  const [counter, setcounter] = useState(0);
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminHome.homeProduct);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );

  const linksLoading = useSelector((state) => state.links.linksLoading);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(actionTypes.getLinks());
    dispatch(adminActionTypes.getOneAdminHomeProducts(params.objectId));
  }, [dispatch]);

  const [title, setTitle] = useState(object != null ? object?.title : "");
  const [info, setInfo] = useState(object != null ? eval(object?.info) : "");
  const [video, setVideo] = useState(null);
  const [image, setImage] = useState("");

  const videoSelectoHandler = (event) => {
    setVideo(event.target.files[0]);
  };

  const imageSelectoHandler = (event) => {
    setImage(event.target.files[0]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || info === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            info: richtext.editorValue,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminHomeProducts(obj));
          successUpdateNotification("Home Product", contants.UPDATE);
          setTitle("");
          setInfo("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              title,
              info: richtext.editorValue,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Home Product", languageName);
          setTitle("");
          setInfo("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setTitle(title);
          setInfo(info);
          setdisabled(true);
        }
        if (counter == languages.length) {
          const fd = new FormData();
          fd.append("video", video);
          fd.append("image", image);
          fd.append("objects", JSON.stringify(objects));
          dispatch(adminActionTypes.createAdminHomeProduct(fd, token));
          setcounter(0);
          setObjects([]);
          setTitle("");
          setInfo("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      {languageLoading && linksLoading ? (
        <div className="w-full h-80vh flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={onSubmit} className="flex justify-between pl-12">
          <div className="flex flex-col pt-5">
            <div className="font-bold">
              <span
                className={
                  object != null ? "hidden" : "block admin-page-title text-xl"
                }
              >
                New Product Spotlight (Language:{" "}
                {languages[counter] ? languages[counter].name : ""})
              </span>
              <span
                className={
                  object != null ? "block admin-page-title text-xl" : "hidden"
                }
              >
                Product Spotlight (Language: {object?.language}) Editing
              </span>
            </div>
            <InputText
              title="Title"
              value={title}
              isClicked={clicked}
              name="title"
              onChange={(e) => setTitle(e.target.value)}
            />
            <div className="pt-5">
              <InputTextArea
                title="Info"
                data={info}
                isClicked={clicked}
                name="info"
                onChange={(e) => {
                  setInfo(e);
                }}
              />
            </div>
            <Uploading
              title="Image"
              text1="upload image"
              text2="View the image"
              name="image"
              required
              updating={object != null ? contants.isTrue : contants.isFalse}
              onChange={imageSelectoHandler}
            />
            <Uploading
              updating={object != null ? contants.isTrue : contants.isFalse}
              title="Video"
              text1="upload video"
              text2="View the video"
              name="video"
              accept={" .mp4, .mpeg"}
              required
              onChange={videoSelectoHandler}
            />
          </div>
          <div className="flex flex-col space-y-20">
            <div className="flex flex-col space-y-2">
              <Button
                disabled={disabled}
                name={object === null ? contants.Save : contants.Update}
                type="square"
              />
              <Button
                name={contants.Publish}
                disabled={!disabled}
                type="square"
              />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/home-product/objects/list"
              />
            </div>
            <div>
              <InsertingLanguagesTable
                languages={languages}
                counter={counter}
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export const Partner = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const partners = useSelector((state) => state.adminPartners.adminPartners);
  const partnersLoading = useSelector(
    (state) => state.adminPartners.adminPartnersLoading
  );
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(adminActionTypes.getAllActivePartners());
  }, [dispatch]);

  // SELECT BUTTO IMPLEMENTATION
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (selectedValue === "") {
      setclicked(true);
    } else {
      const id = selectedValue.uuid;
      dispatch(adminActionTypes.createHomePartner(id, token));
      successCreationNotification("Home Partner");
    }
  };

  return (
    <div>
      <ReactNotification />
      {partnersLoading ? (
        <div className="w-full h-80vh flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={onSubmit} className="flex justify-between pl-12 w-full">
          <div className="flex flex-col pt-5 w-80%">
            <div className="font-bold">
              <span className="admin-page-title text-xl"> Home Partner</span>
            </div>
            <div className="pt-10 w-full ">
              <div className="flex w-full space-x-5 items-center">
                <AsyncSelect
                  placeholder="Select Partner"
                  className={classnames("mb-2 w-70%", {
                    "mb-2 w-70% border-2 border-red-500":
                      selectedValue === "" && clicked === true,
                  })}
                  cacheOptions
                  defaultOptions={partners}
                  getOptionLabel={(e) => e.name + "  " + e.language}
                  getOptionValue={(e) => e.uuid}
                  value={selectedValue}
                  onChange={handleChange}
                  onInputChange={handleInputChange}
                  isSearchable
                />
                <TableLink
                  type="addLink"
                  title="Add New"
                  link="/admin/partners"
                />
              </div>
              <label className="block text-red-500">
                {selectedValue === "" && clicked === true
                  ? "Select Partner"
                  : ""}
              </label>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <Button name={contants.Publish} type="square" />
            <TableLink
              title="List"
              type="viewLink"
              link="/admin/home-partner/objects/list"
            />
          </div>
        </form>
      )}
    </div>
  );
};

export const Service = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const services = useSelector(
    (state) => state.adminService.adminServiceWidgets
  );
  const servicesLoading = useSelector(
    (state) => state.adminService.adminServiceWidgetsLoading
  );
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(adminActionTypes.getAllServicesActiveMainWidgets());
  }, [dispatch]);

  const [ismain, setismain] = useState("");
  const [summary, setsummary] = useState("");

  // SELECT BUTTO IMPLEMENTATION
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (summary === "" || ismain === "") {
      setclicked(true);
    } else {
      const id = selectedValue.uuid;
      const obj = {
        ismain,
        summary,
        id,
        creator: creatorName,
      };
      dispatch(adminActionTypes.createAdminHomeServices(obj, token));
      successCreationNotification("Home Service");
      setsummary("");
      setismain("");
      setclicked(false);
      setdisabled(false);
    }
  };

  return (
    <div>
      <ReactNotification />
      {servicesLoading ? (
        <div className="w-full h-80vh flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={onSubmit} className="flex justify-between pl-12 w-full">
          <div className="flex flex-col pt-5 w-80%">
            <div className="font-bold">
              <span className="admin-page-title text-xl"> Home Service</span>
            </div>
            <div className="pt-10 w-full space-y-4">
              <div>
                <div className="flex w-full space-x-5 items-center">
                  <AsyncSelect
                    placeholder="Select Service"
                    className={classnames("mb-2 w-70%", {
                      "mb-2 w-70% border-2 border-red-500":
                        selectedValue === "" && clicked === true,
                    })}
                    cacheOptions
                    defaultOptions={services}
                    getOptionLabel={(e) => e.title + "  " + e.language}
                    getOptionValue={(e) => e.uuid}
                    value={selectedValue}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                  />
                  <TableLink
                    type="addLink"
                    title="Add New"
                    link="/admin/service-widget"
                  />
                </div>
                <label className="block text-red-500">
                  {selectedValue === "" && clicked === true
                    ? "Select Service"
                    : ""}
                </label>{" "}
              </div>
              <div className="w-4/5 space-y-4">
                <InputText
                  title="Summary"
                  value={summary}
                  isClicked={clicked}
                  name="summary"
                  onChange={(e) => setsummary(e.target.value)}
                />
                <SelectRadioButton
                  title="IsMain"
                  option1="true"
                  option2="false"
                  name="ismain"
                  isClicked={clicked}
                  value={ismain}
                  onChange={(e) => setismain(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <Button disabled={disabled} name={contants.Save} type="square" />
            <Button
              name={contants.Publish}
              disabled={!disabled}
              type="square"
            />
            <TableLink
              title="List"
              type="viewLink"
              link="/admin/home-service/objects/list"
            />
          </div>
        </form>
      )}
    </div>
  );
};

export const HomeServiceIntro = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const [objects, setObjects] = useState([]);
  const object = useSelector((state) => state.adminHome.homeServiceIntro);
  const params = useParams();
  const [counter, setcounter] = useState(0);
  const languages = useSelector((state) => state.languages.languages);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
  }, [dispatch]);

  const [title, settitle] = useState(object != null ? object?.title : "");
  const [description, setdescription] = useState(
    object != null ? eval(object?.description) : ""
  );

  const onSubmit = (e) => {
    e.preventDefault();
    if (title === "" || description === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            title,
            description: richtext.editorValue,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminHomeServicesIntros(obj));
          settitle("");
          setdescription("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              title,
              description: richtext.editorValue,
              creator: creatorName,
              language: lang,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Home Service Intro", languageName);
          settitle("");
          setdescription("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          settitle(title);
          setdescription(description);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(
            adminActionTypes.createAdminHomeServiceIntro(objects, token)
          );
          setcounter(0);
          setObjects([]);
          settitle("");
          setdescription("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };

  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        {languageLoading ? (
          <div className="w-full h-80vh flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <form
            onSubmit={onSubmit}
            className="flex justify-between pl-12 w-full pr-5 pt-4"
          >
            <div className="flex flex-col pt-5 w-80%">
              <div className="font-bold">
                <span
                  className={
                    object != null ? "hidden" : "block admin-page-title text-xl"
                  }
                >
                  New Home Service Intro (Language:{" "}
                  {languages[counter] ? languages[counter].name : ""})
                </span>
                <span
                  className={
                    object != null ? "block admin-page-title text-xl" : "hidden"
                  }
                >
                  Home Service Intro (Language: {object?.language}) Editing
                </span>
              </div>
              <div className="pt-10 w-full space-y-4">
                <div className="w-4/5 space-y-4">
                  <InputText
                    title="Title"
                    value={title}
                    isClicked={clicked}
                    name="title"
                    onChange={(e) => settitle(e.target.value)}
                  />
                  <InputTextArea
                    title="Description"
                    data={description}
                    isClicked={clicked}
                    name="description"
                    onChange={(e) => {
                      setdescription(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-20">
              <div className="flex flex-col space-y-2">
                <Button
                  disabled={disabled}
                  name={object === null ? contants.Save : contants.Update}
                  type="square"
                />
                <Button
                  name={contants.Publish}
                  disabled={!disabled}
                  type="square"
                />
                <TableLink
                  title="List"
                  type="viewLink"
                  link="/admin/home-service-intro/objects/list"
                />
              </div>
              <div>
                <InsertingLanguagesTable
                  languages={languages}
                  counter={counter}
                />
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export const Quote = () => {
  const [disabled, setdisabled] = useState(false);
  const [clicked, setclicked] = useState(false);
  const dispatch = useDispatch();
  const languages = useSelector((state) => state.languages.languages);
  const [counter, setcounter] = useState(0);
  const languageLoading = useSelector(
    (state) => state.languages.languagesLoading
  );
  const object = useSelector((state) => state.adminHome.homeTodaysQuote);
  const params = useParams();
  const creatorName = useSelector((state) => state.auth.name);
  const token = useSelector((state) => state.auth.token);
  useEffect(() => {
    dispatch(actionTypes.getLanguages());
    dispatch(adminActionTypes.getOneAdminHomeTodayQuotes(params.objectId));
  }, [dispatch]);

  const [quote, setQuote] = useState(object != null ? eval(object?.quote) : "");
  const [owner, setOwner] = useState(object != null ? object?.owner : "");
  const [objects, setObjects] = useState([]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (owner === "" || quote === "") {
      setclicked(true);
    } else {
      const richtext = richTextConstants.separateTextAndFiles("");
      richtext.files.forEach((element) => {
        dispatch(adminActionTypes.uploadFilesInText(element));
      });
      if (object != null) {
        if (window.confirm("Are you sure you want update?")) {
          const obj = {
            owner,
            quote: richtext.editorValue,
            id: object.uuid,
          };
          dispatch(adminActionTypes.updateAdminHomeTodayQuotes(obj));
          successUpdateNotification("Home TodayQuote", contants.UPDATE);
          setOwner("");
          setQuote("");
          setclicked(false);
        } else {
        }
      } else {
        if (counter < languages.length) {
          const lang = languages[counter]
            ? languages[counter].abbreviation
            : "";
          const languageName = languages[counter]
            ? languages[counter].name
            : "";
          setObjects([
            ...objects,
            {
              quote: richtext.editorValue,
              owner,
              language: lang,
              creator: creatorName,
            },
          ]);
          setcounter(counter + 1);
          successNotification("Todays Quote", languageName);
          setOwner("");
          setQuote("");
          setclicked(false);
        }
        if (counter == languages.length - 1) {
          setOwner(owner);
          setQuote(quote);
          setdisabled(true);
        }
        if (counter == languages.length) {
          dispatch(adminActionTypes.createAdminTodaysQuote(objects, token));
          setcounter(0);
          setObjects([]);
          setOwner("");
          setQuote("");
          setclicked(false);
          setdisabled(false);
        }
      }
    }
  };
  return (
    <div>
      <ReactNotification />
      {languageLoading ? (
        <div className="w-full h-80vh flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={onSubmit} className="flex justify-between pl-12">
          <div className="flex flex-col pt-5">
            <div className="font-bold">
              <span
                className={
                  object != null ? "hidden" : "block admin-page-title text-xl"
                }
              >
                New Quote (Language:{" "}
                {languages[counter] ? languages[counter].name : ""})
              </span>
              <span
                className={
                  object != null ? "block admin-page-title text-xl" : "hidden"
                }
              >
                Quote (Language: {object?.language}) Editing
              </span>
            </div>
            <InputText
              title="Author"
              value={owner}
              isClicked={clicked}
              name="owner"
              onChange={(e) => setOwner(e.target.value)}
            />
            <div className="pt-10">
              <InputTextArea
                title="Quote"
                data={quote}
                isClicked={clicked}
                name="quote"
                onChange={(e) => {
                  setQuote(e);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col space-y-20">
            <div className="flex flex-col space-y-2">
              <Button
                disabled={disabled}
                name={object === null ? contants.Save : contants.Update}
                type="square"
              />
              <Button
                name={contants.Publish}
                disabled={!disabled}
                type="square"
              />
              <TableLink
                title="List"
                type="viewLink"
                link="/admin/home-quote/objects/list"
              />
            </div>
            <div>
              <InsertingLanguagesTable
                languages={languages}
                counter={counter}
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
