import { updateObject } from '../../utils/updateObject';

import * as actionsTypes from '../actions/actionTypes';

const initialReducer = {
  products: [],
  productsError: [],
  productsLoading: false,
  productWidgets: [],
  productWidgetsError: [],
  productWidgetsLoading: false,
};

const getProductstart = (state, action) => {
  return updateObject(state, {
    products: [],
    productsError: [],
    productsLoading: true,
  });
};

const getProductsuccess = (state, action) => {
  return updateObject(state, {
    products: action.products,
    productsError: [],
    productsLoading: false,
  });
};

const getProductsFail = (state, action) => {
  return updateObject(state, {
    products: [],
    productsError: action.error,
    productsLoading: false,
  });
};

const getProductWidgetstart = (state, action) => {
  return updateObject(state, {
    productWidgets: [],
    productWidgetsError: [],
    productWidgetsLoading: true,
  });
};

const getProductWidgetsuccess = (state, action) => {
  return updateObject(state, {
    productWidgets: action.productWidgets,
    productWidgetsError: [],
    productWidgetsLoading: false,
  });
};

const getProductWidgetsFail = (state, action) => {
  return updateObject(state, {
    productWidgets: [],
    productWidgetsError: action.error,
    productWidgetsLoading: false,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_PRODUCTS_START:
      return getProductstart(state, action);
    case actionsTypes.GET_PRODUCTS_SUCCESS:
      return getProductsuccess(state, action);
    case actionsTypes.GET_PRODUCTS_FAIL:
      return getProductsFail(state, action);
    case actionsTypes.GET_PRODUCT_WIDGETS_START:
      return getProductWidgetstart(state, action);
    case actionsTypes.GET_PRODUCT_WIDGETS_SUCCESS:
      return getProductWidgetsuccess(state, action);
    case actionsTypes.GET_PRODUCT_WIDGETS_FAIL:
      return getProductWidgetsFail(state, action);
    default:
      return state;
  }
};
export default reducer;
