import { SocialMediaLink } from '../components/SocialMediaLink';
import './footercss.css';
import { SummaryCard } from './SummaryCard';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect } from 'react';
import * as actionTypes from '../store/actions/';
import Spinner from '../components/Spinner';
import { useTranslation } from 'react-i18next';

import dotenv from "dotenv";
dotenv.config();

const FooterSummary = function () {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const homeFooterSections = useSelector(
    (state) => state.home.homeFooterSections
  );
  const homeFooterSectionsLoading = useSelector(
    (state) => state.home.homeFooterSectionsLoading
  );
  const homeFooterAddress = useSelector(
    (state) => state.home.homeFooterAddress
  );
  const homeFooterAddressLoading = useSelector(
    (state) => state.home.homeFooterAddressLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getHomeFooterSections(language));
    dispatch(actionTypes.getHomeFooterAddress(language));
  }, [dispatch, language]);
  return (
    <div className="home-footer p-6 md:px-20 md:pt-24">
      {homeFooterSectionsLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          <div className="hidden lg:grid grid-cols-6 gap-4 relative">
            {homeFooterSections.map((item, index) => {
              if (index > 3) {
                return null;
              } else {
                return (
                  <SummaryCard
                    key={index}
                    className=""
                    serviceTitle={item.name}
                    services={item.sectionItems}
                  />
                );
              }
            })}
            <div>
              {homeFooterSections.map((item, index) => {
                if (index <= 3 || index === homeFooterSections.length - 1) {
                  return null;
                } else {
                  return (
                    <SummaryCard
                      key={index}
                      className=""
                      serviceTitle={item.name}
                      services={item.sectionItems}
                    />
                  );
                }
              })}
            </div>

            {homeFooterSections.map((item, index) => {
              if (index === homeFooterSections.length - 1) {
                return (
                  <SummaryCard
                    key={index}
                    className=""
                    serviceTitle={item.name}
                    services={item.sectionItems}
                  />
                );
              } else {
                return null;
              }
            })}
          </div>
          <div className="lg:hidden relative">
            {homeFooterSections.map((item, index) => (
              <SummaryCard
                key={index}
                className=""
                serviceTitle={item.name}
                services={item.sectionItems}
              />
            ))}
          </div>
        </>
      )}
      <div className="flex justify-between pt-8 border-b-2">
        <div className="flex items-center">
          <span>{t('Reach Out')}</span>
        </div>
        <div className="flex text-4xl space-x-1 justify-around items-center pb-2">
          <SocialMediaLink
            name={'facebook'}
            link="https://www.facebook.com/OGeniusPriority/"
          />
          <SocialMediaLink
            name={'twitter'}
            link="https://twitter.com/ogeniuspriority"
          />
          <SocialMediaLink
            name={'linkedin'}
            link="https://www.linkedin.com/company/ogeniuspriority/"
          />
          <SocialMediaLink
            name={'youtube'}
            link="https://www.youtube.com/channel/UCoYoSCzzAkskRuONVgwmZLQ"
          />
          <SocialMediaLink name={'phone'} link="tel:+250788308461" />
        </div>
      </div>
      <div className="flex flex-col pt-9 pb-5 space-y-5 md:flex-row md:space-y-0 md:justify-between">
        {homeFooterAddressLoading ? (
          <div>
            <Spinner />
          </div>
        ) : (
          <>
            <div className="text-center">
              {homeFooterAddress?.location ??
                'Kigali-Rwanda'}
            </div>
            <div className="text-center">
              {homeFooterAddress?.contact?.split(',')?.map((contact, index) => (
                <Fragment key={index}>
                  {contact} <br className="inline md:hidden" />
                </Fragment>
              ))}
              {homeFooterAddress?.email}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default FooterSummary;
