import Footer from "../footerComponents/Footer";
import Header from "../headerCompoents/Header";
import {
  PartnersHeader,
  PartnersBody,
  Affiliation,
} from "../partnersComponents/PartnersComponentsList";
import ScrollToTop from '../components/ScrollToTop';

export const Partners = () => {
  return (
    <div>
      <Header pageLink="/partners"/>
      <PartnersHeader />
      <PartnersBody />
      <Affiliation />
      <Footer />
      <ScrollToTop />
    </div>
  );
};

