import Footer from '../footerComponents/Footer';
import Header from '../headerCompoents/Header';
import {
  BigTitle,
  GiftsAndFrebbies,
  PandaInSitu,
  PublicationHeaderLinks,
  PublicationPageHeader,
  StoryOne,
  Today,
} from '../publicationsComponents/PublicationsCompoentsList';
import ScrollToTop from '../components/ScrollToTop';

export const Publications = () => {
  return (
    <div>
      <Header pageLink="/publications" />
      <PublicationPageHeader />
      <PublicationHeaderLinks />
      <BigTitle />
      <PandaInSitu />
      <StoryOne />
      <GiftsAndFrebbies />
      <Today />
      <Footer />
      <ScrollToTop />
    </div>
  );
};
