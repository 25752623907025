import "./aboutcss.css";
import parse from "html-react-parser";

import dotenv from "dotenv";
dotenv.config();

const AboutText = (props) => {
  return (
    <div className="site-text-blue flex flex-wrap flex-col justify-around">
      <div>
        <h2 className="about-text-title text-center md:text-left">
          {props.title}
        </h2>
      </div>
      <div>
        <h2 className="about-text-sub-title py-6 text-center md:text-left">
          {props.smallTitle}
        </h2>
      </div>
      <div className="max-h-90 md:max-h-52 lg:max-h-96 overflow-auto space-y-4 about-text-body text-sm md:pr-10">
        {props?.description != undefined
          ? parse(eval(props?.description))
          : parse("<p></p>")}
      </div>
    </div>
  );
};
export default AboutText;
