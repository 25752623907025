import axios from "../../axios-base";
import * as adminActionTypes from "./adminActionTypes.js";
import * as notifications from "../../admin/contants";

export const getAdminContactUsStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_CONTACT_US_START,
  };
};

export const getAdminContactUsSuccess = (adminContactUs) => {
  return {
    type: adminActionTypes.GET_ADMIN_CONTACT_US_SUCCESS,
    adminContactUs: adminContactUs,
  };
};

export const getOneAdminContactUsSuccess = (contactUs) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_CONTACT_US_SUCCESS,
    contactUs: contactUs,
  };
};

export const getAdminContactUsFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_CONTACT_US_FAIL,
    error: error,
  };
};

export const getAdminContactUs = () => {
  return (dispatch) => {
    dispatch(getAdminContactUsStart());
    axios
      .get(`/site/contact-us`)
      .then((response) => {
        dispatch(getAdminContactUsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminContactUsFail(err.message));
      });
  };
};

export const getOneAdminContactUs = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminContactUsStart());
    axios
      .get(`/site/contact-us/contact/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminContactUsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminContactUsFail(err.message));
      });
  };
};

export const deleteAdminContactUs = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminContactUsStart());
    axios
      .delete(`/site/contact-us/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminContactUsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminContactUsFail(err.message));
      });
  };
};

export const activateAdminContactUs = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminContactUsStart());
    axios
      .put(`/site/contact-us/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminContactUsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminContactUsFail(err.message));
      });
  };
};

export const desactivateAdminContactUs = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminContactUsStart());
    axios
      .put(`/site/contact-us/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminContactUsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminContactUsFail(err.message));
      });
  };
};

export const updateAdminContactUs = (object) => {
  return (dispatch) => {
    dispatch(getAdminContactUsStart());
    axios
      .put(`/site/contact-us/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminContactUsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminContactUsFail(err.message));
      });
  };
};

export const archiveAdminContactUs = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminContactUsStart());
    axios
      .put(`/site/contact-us/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminContactUsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminContactUsFail(err.message));
      });
  };
};

export const visibleAdminContactUs = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminContactUsStart());
    axios
      .put(`/site/contact-us/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminContactUsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminContactUsFail(err.message));
      });
  };
};

export const getAdminQuickLinksStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_QUICK_LINKS_START,
  };
};

export const getAdminQuickLinksSuccess = (adminQuickLinks) => {
  return {
    type: adminActionTypes.GET_ADMIN_QUICK_LINKS_SUCCESS,
    adminQuickLinks: adminQuickLinks,
  };
};

export const getOneAdminQuickLinksSuccess = (quickLink) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_QUICK_LINKS_SUCCESS,
    quickLink: quickLink,
  };
};

export const getAdminQuickLinksFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_QUICK_LINKS_FAIL,
    error: error,
  };
};

export const getAdminQuickLinks = () => {
  return (dispatch) => {
    dispatch(getAdminQuickLinksStart());
    axios
      .get(`/site/quick-links`)
      .then((response) => {
        dispatch(getAdminQuickLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminQuickLinksFail(err.message));
      });
  };
};

export const getOneAdminQuickLinks = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminQuickLinksStart());
    axios
      .get(`/site/quick-links/quick-link/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminQuickLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminQuickLinksFail(err.message));
      });
  };
};

export const deleteAdminQuickLinks = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminQuickLinksStart());
    axios
      .delete(`/site/quick-links/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminQuickLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminQuickLinksFail(err.message));
      });
  };
};

export const activateAdminQuickLinks = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminQuickLinksStart());
    axios
      .put(`/site/quick-links/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminQuickLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminQuickLinksFail(err.message));
      });
  };
};

export const desactivateAdminQuickLinks = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminQuickLinksStart());
    axios
      .put(`/site/quick-links/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminQuickLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminQuickLinksFail(err.message));
      });
  };
};

export const updateAdminQuickLinks = (object) => {
  return (dispatch) => {
    dispatch(getAdminQuickLinksStart());
    axios
      .put(`/site/quick-links/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminQuickLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminQuickLinksFail(err.message));
      });
  };
};

export const archiveAdminQuickLinks = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminQuickLinksStart());
    axios
      .put(`/site/quick-links/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminQuickLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminQuickLinksFail(err.message));
      });
  };
};

export const visibleAdminQuickLinks = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminQuickLinksStart());
    axios
      .put(`/site/quick-links/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminQuickLinksSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminQuickLinksFail(err.message));
      });
  };
};

export const getAdminFaqsStart = () => {
  return {
    type: adminActionTypes.GET_ADMIN_FAQS_START,
  };
};

export const getAdminFaqsSuccess = (adminFaqs) => {
  return {
    type: adminActionTypes.GET_ADMIN_FAQS_SUCCESS,
    adminFaqs: adminFaqs,
  };
};

export const getOneAdminFaqsSuccess = (faq) => {
  return {
    type: adminActionTypes.GET_ONE_ADMIN_FAQS_SUCCESS,
    faq: faq,
  };
};

export const getAdminFaqsFail = (error) => {
  return {
    type: adminActionTypes.GET_ADMIN_FAQS_FAIL,
    error: error,
  };
};

export const getAdminFaqs = () => {
  return (dispatch) => {
    dispatch(getAdminFaqsStart());
    axios
      .get(`/site/faqs`)
      .then((response) => {
        dispatch(getAdminFaqsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFaqsFail(err.message));
      });
  };
};

export const getOneAdminFaqs = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFaqsStart());
    axios
      .get(`/site/faqs/faq/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFaqsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFaqsFail(err.message));
      });
  };
};

export const deleteAdminFaqs = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFaqsStart());
    axios
      .delete(`/site/faqs/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFaqsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFaqsFail(err.message));
      });
  };
};

export const activateAdminFaqs = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFaqsStart());
    axios
      .put(`/site/faqs/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFaqsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFaqsFail(err.message));
      });
  };
};

export const desactivateAdminFaqs = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFaqsStart());
    axios
      .put(`/site/faqs/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFaqsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFaqsFail(err.message));
      });
  };
};

export const updateAdminFaqs = (object) => {
  return (dispatch) => {
    dispatch(getAdminFaqsStart());
    axios
      .put(`/site/faqs/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminFaqsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFaqsFail(err.message));
      });
  };
};

export const archiveAdminFaqs = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFaqsStart());
    axios
      .put(`/site/faqs/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFaqsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFaqsFail(err.message));
      });
  };
};

export const visibleAdminFaqs = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminFaqsStart());
    axios
      .put(`/site/faqs/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminFaqsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminFaqsFail(err.message));
      });
  };
};

export const getAdminMessageStart = () => {
  return {
    type: adminActionTypes.ADMIN_MESSAGE_START,
  };
};

export const getAdminMessageSuccess = (adminMessage) => {
  return {
    type: adminActionTypes.ADMIN_MESSAGE_SUCCESS,
    adminMessage: adminMessage,
  };
};

export const getOneAdminMessageSuccess = (message) => {
  return {
    type: adminActionTypes.ONE_ADMIN_MESSAGE_SUCCESS,
    message: message,
  };
};

export const getAdminMessageFail = (error) => {
  return {
    type: adminActionTypes.ADMIN_MESSAGE_FAIL,
    error: error,
  };
};

export const getAdminMessage = () => {
  return (dispatch) => {
    dispatch(getAdminMessageStart());
    axios
      .get("/site/messages")
      .then((response) => {
        dispatch(getAdminMessageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMessageFail(err.message));
      });
  };
};

export const getAdminMessageByReply = (reply) => {
  if (reply === true) {
    return (dispatch) => {
      dispatch(getAdminMessageStart());
      axios
        .get("/site/messages/replied")
        .then((response) => {
          dispatch(getAdminMessageSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getAdminMessageFail(err.message));
        });
    };
  } else {
    return (dispatch) => {
      dispatch(getAdminMessageStart());
      axios
        .get("/site/messages/unreplied")
        .then((response) => {
          dispatch(getAdminMessageSuccess(response.data.results));
        })
        .catch((err) => {
          dispatch(getAdminMessageFail(err.message));
        });
    };
  }
};

export const getOneAdminMessage = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminMessageStart());
    axios
      .get(`/site/messages/message/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminMessageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMessageFail(err.message));
      });
  };
};

export const deleteAdminMessage = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminMessageStart());
    axios
      .delete(`/site/messages/delete/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminMessageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMessageFail(err.message));
      });
  };
};

export const activateAdminMessage = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminMessageStart());
    axios
      .put(`/site/messages/activate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminMessageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMessageFail(err.message));
      });
  };
};

export const desactivateAdminMessage = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminMessageStart());
    axios
      .put(`/site/messages/desactivate/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminMessageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMessageFail(err.message));
      });
  };
};

export const updateAdminMessage = (object) => {
  return (dispatch) => {
    dispatch(getAdminMessageStart());
    axios
      .put(`/site/messages/edit/${object.id}`, object)
      .then((response) => {
        dispatch(getOneAdminMessageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMessageFail(err.message));
      });
  };
};

export const replyMessage = (object, token) => {
  return (dispatch) => {
    dispatch(getAdminMessageStart());
    axios
      .put(`/site/messages/reply-message/${object.id}`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getOneAdminMessageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMessageFail(err.message));
      });
  };
};

export const archiveAdminMessage = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminMessageStart());
    axios
      .put(`/site/messages/archive/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminMessageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMessageFail(err.message));
      });
  };
};

export const visibleAdminMessage = (objectId) => {
  return (dispatch) => {
    dispatch(getAdminMessageStart());
    axios
      .put(`/site/messages/visible/${objectId}`)
      .then((response) => {
        dispatch(getOneAdminMessageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getAdminMessageFail(err.message));
      });
  };
};
