import { BiPlay, BiPause } from "react-icons/bi";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { useState } from "react";

export const Button = (props) => {
  let classes =
    "hover:-translate-y-0.5 transform transtion site-bg rounded-3xl text-white py-2 p-6";
  let type = props.type;
  if (type === "square") {
    classes =
      "hover:-translate-y-0.5 transform transtion site-bg text-sm text-white font-bold md:w-36 px-2 py-2 text-center rounded";
    if (props.disabled) {
      classes =
        "hover:-translate-y-0.5 transform transtion site-bg opacity-50 text-sm text-white font-bold md:w-36 px-2 py-2 text-center rounded";
    }
  } else if (type === "contact-us") {
    classes =
      "hover:-translate-y-0.5 transform transtion buttons contact-button ";
  } else if (type === "upload") {
    classes =
      "hover:-translate-y-0.5 transform transtion rounded-md bg-indigo-800 px-1 py-2 text-indigo-200 upload-button";
  } else if (type === "create") {
    classes =
      "hover:-translate-y-0.5 transform transtion rounded create-button";
  } else if (type === "remove") {
    classes =
      "hover:-translate-y-0.5 transform transtion rounded remove-button";
  } else if (type === "add") {
    classes = "hover:-translate-y-0.5 transform rounded transtion add-button";
  } else if (type === "table-link") {
    classes =
      "hover:-translate-y-0.5 transform rounded transtion bg-green-500 text-white px-4 py-1";
  } else if (type === "delete") {
    classes =
      "hover:-translate-y-0.5 transform transtion bg-red-500 text-sm text-white font-bold md:w-36 px-2 py-2 text-center rounded";
  }
  return (
    <button
      className={classes}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.name}
    </button>
  );
};

export const TableLink = (props) => {
  let link = props.link;
  if (props.objectId) {
    link = props.link + "/" + props.objectId;
  }
  let classes =
    "hover:-translate-y-0.5 transform rounded transtion text-center font-bold bg-green-500 text-white px-4 py-1";
  if (props.type === "addLink") {
    classes =
      "hover:-translate-y-0.5 transform rounded transtion text-center font-bold site-bg text-white px-4 py-1";
  } else if (props.type === "updateLink") {
    classes =
      "hover:-translate-y-0.5 transform rounded transtion text-center font-bold site-bg text-white px-4 py-1";
  }
  return (
    <Link to={link} className={classes}>
      <span>{props.title}</span>
    </Link>
  );
};

export const SelectButton = (props) => {
  let classes =
    "select-button block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500";
  if (props.advanced) {
    classes =
      "select-button-adv block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500";
  }

  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const handleInputChange = (value) => {
    setValue(value);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  return (
    <div>
      <AsyncSelect
        placeholder="Select Action"
        className="mb-2"
        cacheOptions
        defaultOptions={props.options}
        getOptionLabel={(e) => e.content}
        getOptionValue={(e) => e.uuid}
        value={selectedValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
      />
      <div className="hidden">
        <select className={classes}>
          <option value="select">SELECT {props.item}</option>
        </select>
      </div>
    </div>
  );
};

export const RightArrow = function () {
  return (
    <button className="font-semibold text-white rounded">
      <FaArrowRight />
    </button>
  );
};

export const PlayButton = function (props) {
  if (props.isPlaying) {
    return (
      <button onClick={props.clicked}>
        <div className="button-circle flex items-center justify-center">
          <BiPause className="playing-buttons" />
        </div>
      </button>
    );
  } else {
    return (
      <button onClick={props.clicked}>
        <div className="button-circle flex items-center justify-center">
          <BiPlay className="playing-buttons" />
        </div>
      </button>
    );
  }
};
