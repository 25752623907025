import "./publication.css";
import { FaArrowRight } from "react-icons/fa";
import image from "../images/motherboard.jpg";
import { PhoneImage } from "../servicesComponent/ServicesListComponents";
import { IoMdAddCircle } from "react-icons/io";
import { BsStarFill } from "react-icons/bs";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

import dotenv from "dotenv";
dotenv.config();

export const PublicationPageHeader = () => {
  const { t } = useTranslation();
  return (
    <div className="relative w-full h-64 flex mt-28">
      <img
        alt="about"
        src={image}
        className="absolute object-center object-cover w-full h-full"
      />
      <div className="w-full h-full z-10 opacity-60 ph-publications absolute"></div>
      <div className="w-full flex items-center text-4xl justify-center h-full z-20 text-white absolute ph-publications-title">
        <span>{t("Publications")}</span>
      </div>
    </div>
  );
};
export const PublicationHeaderLinks = () => {
  const dispatch = useDispatch();
  const publications = useSelector((state) => state.publications.publications);
  const publicationsLoading = useSelector(
    (state) => state.publications.publicationsLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getPublications(language));
  }, [dispatch, language]);
  const [active, setActive] = useState("newsletter");
  return (
    <div className="flex w-full overflow-scroll scrollbar-hidden xl:justify-center pt-10">
      {publicationsLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          {publications.map((link, index) => (
            <div key={index} className="w-48 flex-shrink-0">
              <HashLink
                className="flex flex-col justify-center cursor-pointer"
                smooth
                onClick={() => {
                  setActive(link.link);
                }}
                to={"#" + link.link}
              >
                <div className="w-full flex-shrink-0 relative h-px bg-publication-header-link-line">
                  <div
                    className={`absolute rounded-full ${
                      active === link.link
                        ? "-top-5 left-2/5 w-10 h-10 bg-publication-header-link-line"
                        : "-top-0.5 left-1/2 w-1.5 h-1.5 bg-publication-header-link-circle"
                    }`}
                  ></div>
                </div>
                <p className="w-full text-center flex-shrink-0 py-6 px-4 text-xs text-publication-header-link-line font-bold">
                  {link.name}
                </p>
              </HashLink>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export const BigTitle = () => {
  const dispatch = useDispatch();
  const newsletter = useSelector((state) => state.publications.newsletter);
  const newsletterLoading = useSelector(
    (state) => state.publications.newsletterLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getNewsletter(language));
  }, [dispatch, language]);
  return (
    <div id="newsletter" className="flex flex-col w-full pb-1 md:pt-10 md:pb-0">
      {newsletterLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col-reverse bgt-body md:flex-row md:h-screen-second">
          <div className="h-full md:w-1/2">
            <img
              src={`${process.env.REACT_APP_BACKEND_URL}/site/${newsletter?.image}`}
              className="w-full h-full object-center object-cover"
              alt=""
            />
          </div>
          <div className="text-center md:w-1/2 m-8 md:ml-20 md:my-auto">
            <div className="story-number pb-10">
              <span>STORY 0.1</span>
            </div>
            <div className="lbg-title pb-5">
              <span>{newsletter?.title}</span>
            </div>
            <div className="lsm-title pb-10">
              <span>{newsletter?.subtitle}</span>
            </div>
            <div className="pub-top-text">
              {newsletter?.description != undefined
                ? parse(eval(newsletter?.description))
                : parse("<p></p>")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const PandaInSitu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const events = useSelector((state) => state.publications.events);
  const eventsLoading = useSelector(
    (state) => state.publications.eventsLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getEvents(language));
  }, [dispatch, language]);
  return (
    <div
      id="events"
      className="pis-body min-h-simulations-mobile flex md:h-screen-second flex-col space-y-28 md:space-y-0"
    >
      <div className="h-screen-second md:h-full w-full">
        <div
          className="z-10 h-full w-full pis-part flex flex-col md:flex-row
        px-4 lg:px-10 py-10 lg:py-20 md:space-x-3"
        >
          <div className="text-center text-3xl text-white featured py-6 md:hidden">
            <span>{t("Featured")}</span>
          </div>
          {eventsLoading ? (
            <div className="w-full h-full flex justify-center align-center">
              <Spinner />
            </div>
          ) : (
            events.map((event, index) => {
              if (event.isleft === "true") {
                return (
                  <div
                    key={index}
                    className="h-full pis rounded-r-xl md:rounded-xl pl-7 pt-7 lg:pl-16 lg:pt-20 md:w-1/2 mr-4 md:mr-0"
                  >
                    <div className="pis-title flex flex-col">
                      <span className="text-6xl">{event.title}</span>
                      <span className="pis-sentence">{event.subtitle}</span>
                    </div>
                    <div className="pis-by">
                      <span>by {event.hostname}</span>
                    </div>
                    <div className="flex space-x-5 pt-4">
                      <div className="pis-add-button">
                        <IoMdAddCircle className="h-8 w-8" />
                      </div>
                      <div>
                        <PhoneImage
                          image={`${process.env.REACT_APP_BACKEND_URL}/site/${event.cardpicture}`}
                          page="publications"
                        />
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={index}
                    className="hidden md:flex flex-col justify-between sandra-holmes md:w-1/2 p-7 lg:pt-20"
                  >
                    <div className="sh-title text-sm">
                      <span>{event.subtitle}</span>
                    </div>
                    <div className="sh-big-title py-2 text-3xl">
                      <span>{event.title}</span>
                    </div>
                    <div className="sh-text pb-2">
                      {event?.description != undefined
                        ? parse(eval(event?.description))
                        : parse("<p></p>")}
                    </div>
                    <div className="flex flex-col sh-cost-info mr-5 py-2 pl-3">
                      <div>
                        <div className="flex flex-col text-sm space-y-1">
                          <div>
                            <span>${event.cardprice}</span>
                          </div>
                          <div>
                            <span>{event.cardsummary}</span>
                          </div>
                        </div>
                        <div className="flex py-2 space-x-1 items-center">
                          <BsStarFill color="#173F71" size="12" />
                          <BsStarFill color="#173F71" size="12" />
                          <BsStarFill color="#173F71" size="12" />
                          <BsStarFill color="#173F71" size="12" />
                          <BsStarFill color="#7F7F7F" size="12" />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between items-center text-white pt-12">
                      <div className="flex space-x-2">
                        <div className="relative w-12 h-12">
                          <img
                            src={`${process.env.REACT_APP_BACKEND_URL}/site/${event.hostprofile}`}
                            alt=""
                            className="sh-staff-photo object-cover object-center absolute w-full h-full"
                          />
                        </div>
                        <div className="flex flex-col justify-center space-y-1">
                          <span className="sh-staff-name text-xs">
                            By {event.hostname}
                          </span>
                          <span className="sh-staff-title text-xs">
                            {event.hostposition}
                          </span>
                        </div>
                      </div>
                      <Link
                        to={{
                          pathname: event.link.link,
                          state: {
                            title: event.title,
                            content: event.link.content,
                          },
                        }}
                      >
                        <ReadMore />
                      </Link>
                    </div>
                  </div>
                );
              }
            })
          )}
        </div>
      </div>
      <div className="md:hidden flex flex-col justify-between sandra-holmes p-7 pt-10">
        {eventsLoading ? (
          <div className="w-full h-full flex justify-center align-center">
            <Spinner />
          </div>
        ) : (
          events.map((event, index) => {
            if (event.isleft === "false") {
              return (
                <>
                  <div key={index} className="sh-title text-sm">
                    <span>{event.subtitle}</span>
                  </div>
                  <div className="sh-big-title py-8 text-3xl">
                    <span>{event.title}</span>
                  </div>
                  <div className="sh-text pb-6">
                    {event?.description != undefined
                      ? parse(eval(event?.description))
                      : parse("<p></p>")}
                  </div>
                  <div className="flex flex-col sh-cost-info mr-5 py-2 pl-3">
                    <div>
                      <div className="flex flex-col text-sm space-y-1">
                        <div>
                          <span>${event.price}</span>
                        </div>
                        <div>
                          <span>{event.cardsummary}</span>
                        </div>
                      </div>
                      <div className="flex py-2 items-center">
                        <BsStarFill color="#173F71" size="12" />
                        <BsStarFill color="#173F71" size="12" />
                        <BsStarFill color="#173F71" size="12" />
                        <BsStarFill color="#173F71" size="12" />
                        <BsStarFill color="#7F7F7F" size="12" />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between items-center text-white pt-12">
                    <div className="flex space-x-2">
                      <div className="relative w-12 h-12">
                        <img
                          src={`${process.env.REACT_APP_BACKEND_URL}/site/${event.hostprofile}`}
                          alt=""
                          className="sh-staff-photo object-cover object-center absolute w-full h-full"
                        />
                      </div>
                      <div className="flex flex-col justify-center space-y-1">
                        <span className="sh-staff-name text-xs">
                          By {event.hostname}
                        </span>
                        <span className="sh-staff-title text-xs">
                          {event.hostposition}
                        </span>
                      </div>
                    </div>
                    <div>
                      <ReadMore />
                    </div>
                  </div>
                </>
              );
            } else {
              return null;
            }
          })
        )}
      </div>
    </div>
  );
};

export const Star = (props) => {
  let classes = "star";
  if (props.rated === "un-rated") {
    classes = "star-un-rated";
  }
  return <div className={classes}></div>;
};

export const StoryOne = () => {
  const dispatch = useDispatch();
  const mediaCoverage = useSelector(
    (state) => state.publications.mediaCoverage
  );
  const mediaCoverageLoading = useSelector(
    (state) => state.publications.mediaCoverageLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getMediaCoverage(language));
  }, [dispatch, language]);
  return (
    <div id="mediacoverage" className="flex flex-col md:flex-row md:space-x-3">
      {mediaCoverageLoading ? (
        <div className="w-full h-full flex justify-center align-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="story-one-left pl-10 pr-5 py-20 md:w-1/2">
            <div className="sol-story-number text-center">
              <span>STORY 0.1</span>
            </div>
            <div className="sol-bg-title text-center py-10">
              <span>{mediaCoverage[0]?.title}</span>
            </div>
            <div className="sol-text text-xs">
              {mediaCoverage[0]?.description != undefined
                ? parse(eval(mediaCoverage[0]?.description))
                : parse("<p></p>")}
            </div>
          </div>
          <div className="flex flex-col md:w-1/2 md:space-y-3">
            <div className="story-one-right-top pt-10 pb-5 pl-7 pr-5 ">
              <div className="sort-story-number text-sm ">
                <span>STORY 0.1</span>
              </div>
              <div className="sort-title">
                <span>{mediaCoverage[1]?.title}</span>
              </div>
              <div>
                {mediaCoverage[1]?.description != undefined
                  ? parse(eval(mediaCoverage[1]?.description))
                  : parse("<p></p>")}
              </div>
              <Link
                to={{
                  pathname: mediaCoverage[1]?.link?.link,
                  state: {
                    title: mediaCoverage[1]?.title,
                    content: mediaCoverage[1]?.link?.content,
                  },
                }}
                className="pt-5"
              >
                <ReadMore className="text-gray-700" />
              </Link>
            </div>
            <div className="flex flex-col story-one-right">
              <div className="hidden story-one-right-top pt-10 pl-7 pr-5">
                <div className="sort-story-number text-sm ">
                  <span>STORY 0.1</span>
                </div>
                <div className="sort-title">
                  <span>{mediaCoverage[1]?.title}</span>
                </div>
                <div>
                  {mediaCoverage[1]?.description != undefined
                      ? parse(eval(mediaCoverage[1]?.description))
                      : parse("<p></p>")}
                </div>
                <Link
                  to={{
                    pathname: mediaCoverage[1]?.link?.link,
                    state: {
                      title: mediaCoverage[1]?.title,
                      content: mediaCoverage[1]?.link?.content,
                    },
                  }}
                  className="pt-5"
                >
                  <ReadMore className="text-gray-700" />
                </Link>
              </div>
              <div className="story-one-right-bottom pt-10 pl-7 pr-5">
                <div className="sorb-story-number">
                  <span>STORY 0.1</span>
                </div>
                <div className="sorb-title text-xl py-8">
                  <span>{mediaCoverage[2]?.title}</span>
                </div>
                <div className="sorb-text">
                  {mediaCoverage[2]?.description != undefined
                      ? parse(eval(mediaCoverage[2]?.description))
                      : parse("<p></p>")}
                </div>
                <Link
                  to={{
                    pathname: mediaCoverage[2]?.link?.link,
                    state: {
                      title: mediaCoverage[2]?.title,
                      content: mediaCoverage[2]?.link?.content,
                    },
                  }}
                  className="py-5"
                >
                  <ReadMore className="text-gray-700" />
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const ReadMore = () => {
  const { t } = useTranslation();
  return (
    <div className="flex font-bold justify-end space-x-1">
      <div className="text-xs">{t("Read More").toUpperCase()}</div>
      <div>
        <FaArrowRight />
      </div>
    </div>
  );
};

export const GiftsAndFrebbies = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const giftsAndFreebies = useSelector(
    (state) => state.publications.giftsAndFreebies
  );
  const giftsAndFreebiesLoading = useSelector(
    (state) => state.publications.giftsAndFreebiesLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getGiftsAndFreebies(language));
  }, [dispatch, language]);
  return (
    <div
      id="giftsandfrebbies"
      className="flex flex-col gifts-frebbies py-10 bg-yellow-200"
    >
      <div className="gifts-title text-center text-3xl py-5">
        <span>{t("Gifts and Frebbies")}</span>
      </div>
      {giftsAndFreebiesLoading ? (
        <div className="w-full h-full flex justify-content items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex md:pr-10 flex-col space-y-3 top-gifts md:flex-row md:space-y-0 md:space-x-5">
            {giftsAndFreebies.slice(0, 5).map((gift, index) => {
              if (index >= 3) {
                return null;
              } else {
                return <GiftCard key={index} gift={gift} />;
              }
            })}
          </div>
          <div className="md:pt-5 flex flex-col space-y-3 md:flex-row md:space-y-0 md:space-x-5 md:pl-10">
            {giftsAndFreebies.map((gift, index) => {
              if (index < 3) {
                return null;
              } else {
                return <GiftCard key={index} gift={gift} />;
              }
            })}
          </div>
        </>
      )}
    </div>
  );
};

export const GiftCard = (props) => {
  return (
    <div className="flex flex-col gift-card md:w-1/3">
      <div className="gift-card-title text-gray-100 pt-8 pb-12 pl-8 ">
        <span>{props.gift.title}</span>
      </div>
      <div className="gift-img-height relative">
        <img
          src={`${process.env.REACT_APP_BACKEND_URL}/site/${props.gift.image}`}
          alt="gift"
          className="absolute w-full h-full object-cover object-center"
        />
      </div>
    </div>
  );
};

export const Today = () => {
  const dispatch = useDispatch();
  const todayAtOgenius = useSelector(
    (state) => state.publications.todayAtOgenius
  );
  const todayAtOgeniusLoading = useSelector(
    (state) => state.publications.todayAtOgeniusLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getTodayAtOgenius(language));
  }, [dispatch, language]);
  return (
    <div id="todayatogenius" className="today flex flex-col py-10 md:px-10">
      {todayAtOgeniusLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="today-title text-left text-4xl md pb-5 px-10">
            <span>{todayAtOgenius?.title}</span>
          </div>
          {parse(eval(todayAtOgenius?.content) ?? "")}
        </>
      )}
    </div>
  );
};
