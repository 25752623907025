import Footer from '../footerComponents/Footer';
import Header from '../headerCompoents/Header';
import { ServicePageHeader } from '../servicesComponent/servicePageComponents';
import Widget from '../components/Widgets/Widget';
import ScrollToTop from '../components/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from '../store/actions/';
import Spinner from '../components/Spinner';
import { useEffect } from 'react';

export const Services = (props) => {
  const dispatch = useDispatch();
  const serviceWidgets = useSelector((state) => state.services.serviceWidgets);
  const serviceWidgetsLoading = useSelector(
    (state) => state.services.serviceWidgetsLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getServiceWidgets(language));
  }, [dispatch, language]);
  return (
    <div>
      <Header pageLink="/services" />
      <ServicePageHeader />
      {serviceWidgetsLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        serviceWidgets.map((service, index) => (
          <Widget key={index} widget={service} />
        ))
      )}
      <Footer />
      <ScrollToTop />
    </div>
  );
};
