import { updateObject } from "../../utils/updateObject";

import * as adminActionTypes from "../actions/adminActionTypes";

const initialReducer = {
  adminProducts: [],
  adminProductsError: null,
  adminProductsLoading: false,
  product: null,
  adminProductWidgets: [],
  adminProductWidgetsError: null,
  adminProductWidgetsLoading: false,
  productWidget: null,
};

const getAdminProductsStart = (state, action) => {
  return updateObject(state, {
    adminProducts: [],
    adminProductsError: null,
    adminProductsLoading: true,
    product: null,
  });
};

const getAdminProductsSuccess = (state, action) => {
  return updateObject(state, {
    adminProducts: action.adminProducts,
    adminProductsError: null,
    adminProductsLoading: false,
    product: null,
  });
};

const getAdminProductsFail = (state, action) => {
  return updateObject(state, {
    adminProducts: [],
    adminProductsError: action.error,
    adminProductsLoading: false,
    product: null,
  });
};

const getOneAdminProductsSuccess = (state, action) => {
  return updateObject(state, {
    adminProducts: [],
    adminProductsError: null,
    adminProductsLoading: false,
    product: action.product,
  });
};

const getAdminProductWidgetsStart = (state, action) => {
  return updateObject(state, {
    adminProductWidgets: [],
    adminProductWidgetsError: null,
    adminProductWidgetsLoading: true,
    productWidget: null,
  });
};

const getAdminProductWidgetsSuccess = (state, action) => {
  return updateObject(state, {
    adminProductWidgets: action.adminProductWidgets,
    adminProductWidgetsError: null,
    adminProductWidgetsLoading: false,
    productWidget: null,
  });
};

const getAdminProductWidgetsFail = (state, action) => {
  return updateObject(state, {
    adminProductWidgets: [],
    adminProductWidgetsError: action.error,
    adminProductWidgetsLoading: false,
    productWidget: null,
  });
};

const getOneAdminProductWidgetsSuccess = (state, action) => {
  return updateObject(state, {
    adminProductWidgetsError: null,
    adminProductWidgetsLoading: false,
    productWidget: action.productWidget,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case adminActionTypes.GET_ADMIN_PRODUCTS_START:
      return getAdminProductsStart(state, action);
    case adminActionTypes.GET_ADMIN_PRODUCTS_SUCCESS:
      return getAdminProductsSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_PRODUCTS_SUCCESS:
      return getOneAdminProductsSuccess(state, action);
    case adminActionTypes.GET_ADMIN_PRODUCTS_FAIL:
      return getAdminProductsFail(state, action);
    case adminActionTypes.GET_ADMIN_PRODUCT_WIDGETS_START:
      return getAdminProductWidgetsStart(state, action);
    case adminActionTypes.GET_ADMIN_PRODUCT_WIDGETS_SUCCESS:
      return getAdminProductWidgetsSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_PRODUCT_WIDGETS_SUCCESS:
      return getOneAdminProductWidgetsSuccess(state, action);
    case adminActionTypes.GET_ADMIN_PRODUCT_WIDGETS_FAIL:
      return getAdminProductWidgetsFail(state, action);
    default:
      return state;
  }
};
export default reducer;
