import axios from "../../axios-base";
import * as actionTypes from "./actionTypes.js";
import * as notifications from "../../admin/contants";

export const getPublicationsStart = () => {
  return {
    type: actionTypes.GET_PUBLICATIONS_START,
  };
};

export const getPublicationsSuccess = (publications) => {
  return {
    type: actionTypes.GET_PUBLICATIONS_SUCCESS,
    publications: publications,
  };
};

export const getPublicationsFail = (error) => {
  return {
    type: actionTypes.GET_PUBLICATIONS_FAIL,
    error: error,
  };
};

export const getPublications = (lang) => {
  return (dispatch) => {
    dispatch(getPublicationsStart());
    axios
      .get(`/site/publications/active/${lang}`)
      .then((response) => {
        dispatch(getPublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationsFail(err.message));
      });
  };
};

export const getNewsletterStart = () => {
  return {
    type: actionTypes.GET_NEWSLETTER_START,
  };
};

export const getNewsletterSuccess = (newsletter) => {
  return {
    type: actionTypes.GET_NEWSLETTER_SUCCESS,
    newsletter: newsletter,
  };
};

export const getNewsletterFail = (error) => {
  return {
    type: actionTypes.GET_NEWSLETTER_FAIL,
    error: error,
  };
};

export const getNewsletter = (lang) => {
  return (dispatch) => {
    dispatch(getNewsletterStart());
    axios
      .get(`/site/newsletters/active/${lang}`)
      .then((response) => {
        dispatch(getNewsletterSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getNewsletterFail(err.message));
      });
  };
};

export const getEventsStart = () => {
  return {
    type: actionTypes.GET_EVENTS_START,
  };
};

export const getEventsSuccess = (events) => {
  return {
    type: actionTypes.GET_EVENTS_SUCCESS,
    events: events,
  };
};

export const getEventsFail = (error) => {
  return {
    type: actionTypes.GET_EVENTS_FAIL,
    error: error,
  };
};

export const getEvents = (lang) => {
  return (dispatch) => {
    dispatch(getEventsStart());
    axios
      .get(`/site/events/active/${lang}`)
      .then((response) => {
        dispatch(getEventsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getEventsFail(err.message));
      });
  };
};

export const getMediaCoverageStart = () => {
  return {
    type: actionTypes.GET_MEDIA_COVERAGE_START,
  };
};

export const getMediaCoverageSuccess = (mediaCoverage) => {
  return {
    type: actionTypes.GET_MEDIA_COVERAGE_SUCCESS,
    mediaCoverage: mediaCoverage,
  };
};

export const getMediaCoverageFail = (error) => {
  return {
    type: actionTypes.GET_MEDIA_COVERAGE_FAIL,
    error: error,
  };
};

export const getMediaCoverage = (lang) => {
  return (dispatch) => {
    dispatch(getMediaCoverageStart());
    axios
      .get(`/site/media-coverages/active/${lang}`)
      .then((response) => {
        dispatch(getMediaCoverageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMediaCoverageFail(err.message));
      });
  };
};

export const getGiftsAndFreebiesStart = () => {
  return {
    type: actionTypes.GET_GIFTS_AND_FREEBIES_START,
  };
};

export const getGiftsAndFreebiesSuccess = (giftsAndFreebies) => {
  return {
    type: actionTypes.GET_GIFTS_AND_FREEBIES_SUCCESS,
    giftsAndFreebies: giftsAndFreebies,
  };
};

export const getGiftsAndFreebiesFail = (error) => {
  return {
    type: actionTypes.GET_GIFTS_AND_FREEBIES_FAIL,
    error: error,
  };
};

export const getGiftsAndFreebies = (lang) => {
  return (dispatch) => {
    dispatch(getGiftsAndFreebiesStart());
    axios
      .get(`/site/gift-freebies/active/${lang}`)
      .then((response) => {
        dispatch(getGiftsAndFreebiesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getGiftsAndFreebiesFail(err.message));
      });
  };
};

export const getTodayAtOgeniusStart = () => {
  return {
    type: actionTypes.GET_TODAY_AT_OGENIUS_START,
  };
};

export const getTodayAtOgeniusSuccess = (todayAtOgenius) => {
  return {
    type: actionTypes.GET_TODAY_AT_OGENIUS_SUCCESS,
    todayAtOgenius: todayAtOgenius,
  };
};

export const getTodayAtOgeniusFail = (error) => {
  return {
    type: actionTypes.GET_TODAY_AT_OGENIUS_FAIL,
    error: error,
  };
};

export const getTodayAtOgenius = (lang) => {
  return (dispatch) => {
    dispatch(getTodayAtOgeniusStart());
    axios
      .get(`/site/today-at-ogenius/active/${lang}`)
      .then((response) => {
        dispatch(getTodayAtOgeniusSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTodayAtOgeniusFail(err.message));
      });
  };
};

export const createNewsletter = (object, token) => {
  return (dispatch) => {
    dispatch(getNewsletterStart());
    axios
      .post(`/site/newsletters/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getNewsletterSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getNewsletterFail(err.message));
        notifications.errorNotification("Newsletter");
      });
    notifications.successCreationNotification("Newsletter");
  };
};

export const createEvent = (object, token) => {
  return (dispatch) => {
    dispatch(getEventsStart());
    axios
      .post(`/site/events/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getEventsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getEventsFail(err.message));
        notifications.errorNotification("Event");
      });
    notifications.successCreationNotification("Event");
  };
};

export const createMediaCoverage = (object, token) => {
  return (dispatch) => {
    dispatch(getMediaCoverageStart());
    axios
      .post(`/site/media-coverages/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getMediaCoverageSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getMediaCoverageFail(err.message));
        notifications.errorNotification("Media Coverage");
      });
    notifications.successCreationNotification("Media Coverage");
  };
};

export const createGiftAndFreebie = (object, token) => {
  return (dispatch) => {
    dispatch(getGiftsAndFreebiesStart());
    axios
      .post(`/site/gift-freebies/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getGiftsAndFreebiesSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getGiftsAndFreebiesFail(err.message));
        notifications.errorNotification("Gift/Freebie");
      });
    notifications.successCreationNotification("Gift/Freebie");
  };
};

export const createTodaysAtOGenius = (object, token) => {
  return (dispatch) => {
    dispatch(getTodayAtOgeniusStart());
    axios
      .post(`/site/today-at-ogenius/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getTodayAtOgeniusSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getTodayAtOgeniusFail(err.message));
        notifications.errorNotification("Today at OGenius");
      });
    notifications.successCreationNotification("Today at OGenius");
  };
};

export const createPublications = (object, token) => {
  return (dispatch) => {
    dispatch(getPublicationsStart());
    axios
      .post(`/site/publications/create`, object, {
        headers: { Authorization: token },
      })
      .then((response) => {
        dispatch(getPublicationsSuccess(response.data.results));
      })
      .catch((err) => {
        dispatch(getPublicationsFail(err.message));
        notifications.errorNotification("Publications");
      });
    notifications.successCreationNotification("Publications");
  };
};
