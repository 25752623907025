import { MoreDropDown } from "../admin/AdminComponentsList";
import {
  Carousel,
  HomeAbout,
  Partner,
  ProductSpotlight,
  Quote,
  Service,
} from "../admin/AdminHomeComponentsList";
import { AdminHeaderMenu, SideMenu } from "../admin/Menus";
import { Button } from "../components/Buttons";

export const AdminHome = () => {
  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        <div className="pt-4 pr-16 w-full">
          <Carousel />
        </div>
      </div>
    </div>
  );
};

export const AdminCarousel = () => {
  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/home-carousel" menuName="Home" />
        </div>
        <div className="pt-4 pr-16 w-full">
          <Carousel />
        </div>
      </div>
    </div>
  );
};

export const AdminHomeAbout = () => {
  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/home-about" menuName="Home" />
        </div>
        <div className="pt-4 pr-16 w-full">
          <HomeAbout />
        </div>
      </div>
    </div>
  );
};

export const AdminProductSpotlight = () => {
  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/home-product" menuName="Home" />
        </div>
        <div className="pt-4 pr-16 w-full">
          <ProductSpotlight />
        </div>
      </div>
    </div>
  );
};

export const AdminPartner = () => {
  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/home-partner" menuName="Home" />
        </div>
        <div className="pt-4 pr-16 w-full">
          <Partner />
        </div>
      </div>
    </div>
  );
};

export const AdminHomeService = () => {
  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/home-service" menuName="Home" />
        </div>
        <div className="pt-4 pr-16 w-full">
          <Service />
        </div>
      </div>
    </div>
  );
};

export const AdminQuote = () => {
  return (
    <div>
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu pageLink="/admin/home-quote" menuName="Home" />
        </div>
        <div className="pt-4 pr-16 w-full">
          <Quote />
        </div>
      </div>
    </div>
  );
};
