import { updateObject } from "../../utils/updateObject";

import * as adminActionTypes from "../actions/adminActionTypes";

const initialReducer = {
  adminContactUs: [],
  adminContactUsError: null,
  adminContactUsLoading: false,
  contactUs: null,
  adminQuickLinks: [],
  adminQuickLinksError: null,
  adminQuickLinksLoading: false,
  quickLink: null,
  adminFaqs: [],
  adminFaqsError: null,
  adminFaqsLoading: false,
  faq: null,
  adminMessage: [],
  adminMessageError: null,
  adminMessageLoading: false,
  message: null,
};

const getAdminContactUsStart = (state, action) => {
  return updateObject(state, {
    adminContactUs: [],
    adminContactUsError: null,
    adminContactUsLoading: true,
    contactUs: null,
  });
};

const getAdminContactUsSuccess = (state, action) => {
  return updateObject(state, {
    adminContactUs: action.adminContactUs,
    adminContactUsError: null,
    adminContactUsLoading: false,
    contactUs: null,
  });
};

const getAdminContactUsFail = (state, action) => {
  return updateObject(state, {
    adminContactUs: [],
    adminContactUsError: action.error,
    adminContactUsLoading: false,
    contactUs: null,
  });
};

const getOneAdminContactUsSuccess = (state, action) => {
  return updateObject(state, {
    adminContactUs: [],
    adminContactUsError: null,
    adminContactUsLoading: false,
    contactUs: action.contactUs,
  });
};

const getAdminQuickLinksStart = (state, action) => {
  return updateObject(state, {
    adminQuickLinks: [],
    adminQuickLinksError: null,
    adminQuickLinksLoading: true,
    quickLink: null,
  });
};

const getAdminQuickLinksSuccess = (state, action) => {
  return updateObject(state, {
    adminQuickLinks: action.adminQuickLinks,
    adminQuickLinksError: null,
    adminQuickLinksLoading: false,
    quickLink: null,
  });
};

const getAdminQuickLinksFail = (state, action) => {
  return updateObject(state, {
    adminQuickLinks: [],
    adminQuickLinksError: action.error,
    adminQuickLinksLoading: false,
    quickLink: null,
  });
};

const getOneAdminQuickLinksSuccess = (state, action) => {
  return updateObject(state, {
    adminQuickLinks: [],
    adminQuickLinksError: null,
    adminQuickLinksLoading: false,
    quickLink: action.quickLink,
  });
};

const getAdminFaqsStart = (state, action) => {
  return updateObject(state, {
    adminFaqs: [],
    adminFaqsError: [],
    faqsLoading: true,
    faq: null,
  });
};

const getAdminFaqsSuccess = (state, action) => {
  return updateObject(state, {
    adminFaqs: action.adminFaqs,
    adminFaqsError: null,
    adminFaqsLoading: false,
    faq: null,
  });
};

const getAdminFaqsFail = (state, action) => {
  return updateObject(state, {
    adminFaqs: [],
    adminFaqsError: action.error,
    adminFaqsLoading: false,
    faq: null,
  });
};

const getOneAdminFaqsSuccess = (state, action) => {
  return updateObject(state, {
    adminFaqs: [],
    adminFaqsError: null,
    adminFaqsLoading: false,
    faq: action.faq,
  });
};

const getAdminMessageStart = (state, action) => {
  return updateObject(state, {
    adminMessage: [],
    adminMessageError: null,
    adminMessageLoading: true,
    message: null,
  });
};

const getAdminMessageSuccess = (state, action) => {
  return updateObject(state, {
    adminMessage: action.adminMessage,
    adminMessageError: null,
    adminMessageLoading: false,
    message: null,
  });
};

const getAdminMessageFail = (state, action) => {
  return updateObject(state, {
    adminMessage: [],
    adminMessageError: action.error,
    adminMessageLoading: false,
    message: null,
  });
};

const getOneAdminMessageSuccess = (state, action) => {
  return updateObject(state, {
    adminMessage: [],
    adminMessageError: null,
    adminMessageLoading: false,
    message: action.message,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case adminActionTypes.GET_ADMIN_CONTACT_US_START:
      return getAdminContactUsStart(state, action);
    case adminActionTypes.GET_ADMIN_CONTACT_US_SUCCESS:
      return getAdminContactUsSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_CONTACT_US_SUCCESS:
      return getOneAdminContactUsSuccess(state, action);
    case adminActionTypes.GET_ADMIN_CONTACT_US_FAIL:
      return getAdminContactUsFail(state, action);
    case adminActionTypes.GET_ADMIN_QUICK_LINKS_START:
      return getAdminQuickLinksStart(state, action);
    case adminActionTypes.GET_ADMIN_QUICK_LINKS_SUCCESS:
      return getAdminQuickLinksSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_QUICK_LINKS_SUCCESS:
      return getOneAdminQuickLinksSuccess(state, action);
    case adminActionTypes.GET_ADMIN_QUICK_LINKS_FAIL:
      return getAdminQuickLinksFail(state, action);
    case adminActionTypes.GET_ADMIN_FAQS_START:
      return getAdminFaqsStart(state, action);
    case adminActionTypes.GET_ADMIN_FAQS_SUCCESS:
      return getAdminFaqsSuccess(state, action);
    case adminActionTypes.GET_ONE_ADMIN_FAQS_SUCCESS:
      return getOneAdminFaqsSuccess(state, action);
    case adminActionTypes.GET_ADMIN_FAQS_FAIL:
      return getAdminFaqsFail(state, action);
    case adminActionTypes.ADMIN_MESSAGE_START:
      return getAdminMessageStart(state, action);
    case adminActionTypes.ADMIN_MESSAGE_SUCCESS:
      return getAdminMessageSuccess(state, action);
    case adminActionTypes.ONE_ADMIN_MESSAGE_SUCCESS:
      return getOneAdminMessageSuccess(state, action);
    case adminActionTypes.ADMIN_MESSAGE_FAIL:
      return getAdminMessageFail(state, action);
    default:
      return state;
  }
};
export default reducer;
