import { Button, TableLink } from "../components/Buttons";
import "./admin.css";
import { Uploading } from "./AdminComponentsList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as adminActionTypes from "../store/actions/adminIndex";
import contants, { successUpdateFile } from "./contants";
import {ReactNotifications} from "react-notifications-component";
import { AdminHeaderMenu, SideMenu } from "./Menus";
import { useParams } from "react-router";
import image from "../images/career.jpg";
import { Link, useHistory } from "react-router-dom";

import dotenv from "dotenv";
dotenv.config();
const  ReactNotification=ReactNotifications


export const AdminGallery = (props) => {
  const dispatch = useDispatch();
  const files = useSelector((state) => state.adminFiles.files);
  const fileLoading = useSelector((state) => state.adminFiles.filesLoading);
  useEffect(() => {
    dispatch(adminActionTypes.getFiles());
  }, [dispatch]);
  const images = [image, image];
  return (
    <div>
      <ReactNotification />
      <AdminHeaderMenu />
      <div className="flex">
        <div>
          <SideMenu />
        </div>
        <form className="flex justify-between pt-6 px-12 w-full">
          <div className="flex flex-col pt-5 w-full pr-10">
            <div className="font-bold">
              <span className="admin-page-title text-xl">GALLERY</span>
            </div>
            {files.map((image, i) => (
              <div className="flex justify-between h-80 space-x-14 pr-10">
                <DisplayMedia image={image} />
                <DisplayMedia image={image} />
              </div>
            ))}
          </div>
          <div className="flex flex-col space-y-2"></div>
        </form>
      </div>
    </div>
  );
};

export const DisplayMedia = (props) => {
  const fileType = props?.image?.split(".").pop();
  let folder = contants.filesFolder;
  if (props.preview) {
    folder = "";
  }
  if (
    fileType?.toLowerCase() === contants.imageTypes[0] ||
    fileType?.toLowerCase() === contants.imageTypes[1] ||
    fileType?.toLowerCase() === contants.imageTypes[2] ||
    fileType?.toLowerCase() === contants.imageTypes[3]
  ) {
    return (
      <Link
        className="flex flex-col h-4/5 w-1/2 relative"
        to={"/admin/gallerie/view/" + props.image}
      >
        <div className="h-90% w-full pt-3">
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/site/${folder}${props.image}`}
            alt={props.image}
            className="h-full w-full object-cover object-center absolute border-2 border-blue-900"
          />
        </div>
      </Link>
    );
  } else {
    return (
      <Link
        className="flex flex-col h-4/5 w-1/2 relative"
        to={"/admin/gallerie/view/" + props.image}
      >
        <div className="h-90% w-full pt-3">
          <video
            src={`${process.env.REACT_APP_BACKEND_URL}/site/${folder}${props.image}`}
            controls
            className="h-full w-full object-cover object-center absolute border-2 border-blue-900"
          />
        </div>
      </Link>
    );
  }
};

export const ImageAndVideoPeview = (props) => {
  const dispatch = useDispatch();
  const [file, setfile] = useState(null);
  const params = useParams();
  const fileType = params.objectId.split(".").pop();
  const history = useHistory();

  const fileSelectionHandler = (event) => {
    setfile(event.target.files[0]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const filename = params.objectId;
    if (file) {
      const fd = new FormData();
      fd.append("file", file);
      fd.append("filename", filename);
      dispatch(adminActionTypes.updateFiles(fd, filename));
      successUpdateFile("FILE");
    }
  };

  if (
    fileType.toLowerCase() === contants.imageTypes[0] ||
    fileType.toLowerCase() === contants.imageTypes[1] ||
    fileType.toLowerCase() === contants.imageTypes[2] ||
    fileType.toLowerCase() === contants.imageTypes[3]
  ) {
    return (
      <div>
        <ReactNotification />
        <AdminHeaderMenu />
        <div className="flex">
          <div>
            <SideMenu />
          </div>
          <form
            onSubmit={onSubmit}
            className="flex justify-between pt-6 px-12 w-full"
          >
            <div className="flex flex-col pt-5 pr-3 w-full ">
              <div className="font-bold flex justify-between">
                <div>
                  <span className="admin-page-title text-xl">
                    IMAGE PREVIEW
                  </span>
                </div>
                <div className="justify-center -mt-4">
                  <Uploading
                    title="Change The File"
                    required
                    onChange={fileSelectionHandler}
                  />
                </div>
              </div>
              <div className="h-80% w-full pt-3 flex relative">
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}/site/${contants.filesFolder}/${params.objectId}`}
                  alt="file"
                  className="h-full w-full object-cover object-center absolute border-2 border-blue-900"
                />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <Button name={contants.Update} type="square" />
              <TableLink
                title="GALLERY"
                type="viewLink"
                link="/admin/gallerie"
              />
              <Button
                name="Back"
                type="square"
                onClick={() => history.goBack()}
              />
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <ReactNotification />
        <AdminHeaderMenu />
        <div className="flex">
          <div>
            <SideMenu />
          </div>
          <form
            onSubmit={onSubmit}
            className="flex justify-between pt-6 px-12 w-full"
          >
            <div className="flex flex-col pt-5 pr-3 w-full ">
              <div className="font-bold flex justify-between">
                <div>
                  <span className="admin-page-title text-xl">
                    PREVIEW VIDEO
                  </span>
                </div>
                <div className="justify-center -mt-4">
                  <Uploading
                    title="Change The File"
                    accept={" .mp4, .mpeg"}
                    required
                    onChange={fileSelectionHandler}
                  />
                </div>
              </div>
              <div className="h-80% w-full pt-3 flex relative">
                <video
                  src={`${process.env.REACT_APP_BACKEND_URL}/site/${contants.filesFolder}/${params.objectId}`}
                  controls
                  className="h-full w-full object-cover object-center absolute"
                />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <Button name={contants.Update} type="square" />
              <TableLink
                title="GALLERY"
                type="viewLink"
                link="/admin/gallerie"
              />
              <Button
                name="Back"
                type="square"
                onClick={() => history.goBack()}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export const GenericDisplayMedia = (props) => {
  const fileType = props?.media?.split(".").pop();
  const filename = props?.media?.split(contants.filesFolder)[1];
  let classesImage = "flex flex-col h-4/5 w-2/5 relative";
  let classesVideo = "flex flex-col relative w-1/2 h-full";
  if (props.widget) {
    classesImage = "flex flex-col h-4/5 w-full relative";
    classesVideo = "flex flex-col relative w-full h-full";
  }
  if (
    fileType?.toLowerCase() === contants.imageTypes[0] ||
    fileType?.toLowerCase() === contants.imageTypes[1] ||
    fileType?.toLowerCase() === contants.imageTypes[2] ||
    fileType?.toLowerCase() === contants.imageTypes[3]
  ) {
    return (
      <Link className={classesImage} to={"/admin/gallerie/view/" + filename}>
        <span className="site-text-blue font-bold text-left">Image :</span>
        <div className="h-90% w-full pt-3">
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/site/${props?.media}`}
            alt="media"
            className="h-full w-full object-cover object-center absolute border-2 border-blue-900"
          />
        </div>
      </Link>
    );
  } else if (
    fileType?.toLowerCase() === contants.videoTypes[0] ||
    fileType?.toLowerCase() === contants.videoTypes[1]
  ) {
    return (
      <Link className={classesVideo} to={"/admin/gallerie/view/" + filename}>
        <div className="pt-3 h-90% ">
          <span className="site-text-blue font-bold text-left">Video :</span>
          <video
            controls
            className="w-full h-80% object-cover object-center absolute"
            src={`${process.env.REACT_APP_BACKEND_URL}/site/${props?.media}`}
          ></video>
        </div>
      </Link>
    );
  } else {
    return <Link className="hidden"></Link>;
  }
};
