import { useEffect, useState, useRef, useMemo } from "react";
import "./carousel.css";
import { PlayButton } from "../components/Buttons";
import { SiInstagram, SiTwitter, SiFacebook } from "react-icons/si";
import { RiEarthLine } from "react-icons/ri";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { videoAutoPlay } from "./automatevideo";

import dotenv from "dotenv";
dotenv.config();

export const HomeCarousel = () => {
  const [video, setVideo] = useState();
  const targetRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const handleClick = () => {
    try {
      if (isPlaying) {
        targetRef.current.pause();
        setIsPlaying(false);
      } else {
        targetRef.current.play();
        setIsPlaying(true);
      }
    } catch (error) {
      
    }
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const homeCarousel = useSelector((state) => state.home.homeCarousel);
  const homeCarouselLoading = useSelector(
    (state) => state.home.homeCarouselLoading
  );
  const homeCommunicationLinks = useSelector(
    (state) => state.home.homeCommunicationLinks
  );
  const language = useSelector((state) => state.home.language);

  // PAUSING A VIDEO WHEN NOT VISIBLE

  const callBackFunction = (entries) => {
    const [entry] = entries;
    if (!entry.isIntersecting) {
      targetRef?.current?.pause();
      setIsPlaying(false);
    }
  };

  const options = useMemo(() => {
    return {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
  }, []);

  const refreshing = () => {
    const observer = new IntersectionObserver(callBackFunction, options);
    const currentTarget = targetRef.current;
    if (currentTarget) observer.observe(currentTarget);
    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  };

  document.addEventListener("scroll", () => {
    refreshing();
  });

  // END OF PAUSING A VIDEO WHEN NOT VISIBLE

  useEffect(() => {
    dispatch(actionTypes.getHomeCarousel(language));
    dispatch(actionTypes.getHomeCommunicationLinks(language));
  }, [dispatch, language]);

  return (
    <div className="home-coursel h-screen-second w-full relative mt-28">
      {homeCarouselLoading ? (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <video
            id="carouselVideoId"
            className="w-full h-full object-cover object-center absolute top-0 left-0"
            src={`${process.env.REACT_APP_BACKEND_URL}/site/${homeCarousel?.video}`}
            ref={targetRef}
          ></video>
          <div className="absolute z-0 top-0 w-full h-full flex bg-blue-900 opacity-30"></div>
          <div
            className=" absolute z-10 top-0  w-full h-full flex"
            id="carouselId"
          >
            <div className="absolute z-20 top-0 w-full h-full flex">
              <div className="flex flex-col items-center lg:items-start lg:justify-between w-1/5">
                <div className="flex flex-col justify-around lg:h-5/6 h-full items-center w-full lg:w-20">
                  {homeCommunicationLinks.map((link, index) => (
                    <Link
                      key={index}
                      to={{
                        pathname: link.link,
                        state: {
                          title: link.name,
                          content: link.content,
                        },
                      }}
                      className="home-carousel-left-links transform -rotate-90 text-xs"
                    >
                      {link.name}
                    </Link>
                  ))}
                </div>
                <div className="hidden lg:flex justify-between text-center md:space-x-4 p-6">
                  <a
                    href={"https://www.instagram.com/ogeniuspriorityltd/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SiInstagram color="white" size={16} />
                  </a>
                  <a
                    href={"https://twitter.com/ogeniuspriority"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SiTwitter color="white" size={16} />
                  </a>
                  <a
                    href={"https://www.facebook.com/OGeniusPriority/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SiFacebook color="white" size={16} />
                  </a>
                  <a
                    href={"https://www.pinterest.com/OGeniusPriorityLtd/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <RiEarthLine color="white" size={16} />
                  </a>
                </div>
              </div>
              <div className="flex flex-col justify-between items-center w-4/5 md:pr-0">
                <div className="flex flex-1 flex-col justify-center items-center pr-10 lg:pr-30%">
                  <div className="self-center">
                    <PlayButton clicked={handleClick} isPlaying={isPlaying} />
                  </div>
                  <span className="home-carousel-title text-xl md:text-4xl py-4 text-center font-bold">
                    {homeCarousel?.header}
                  </span>
                  <div className="flex home-carousel-text text-base text-center lg:px-48">
                    {
                      homeCarousel?.subtitle != undefined
                        ? parse(eval(homeCarousel?.subtitle))
                        : parse("<p></p>"
                    )}
                  </div>
                  <div className="flex justify-between w-3/5 sm-2/5 md-1/5 text-center py-10 lg:hidden">
                    <a
                      href={"https://www.instagram.com/ogeniuspriorityltd/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SiInstagram color="white" size={16} />
                    </a>
                    <a
                      href={"https://twitter.com/ogeniuspriority"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SiTwitter color="white" size={16} />
                    </a>
                    <a
                      href={"https://www.facebook.com/OGeniusPriority/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SiFacebook color="white" size={16} />
                    </a>
                    <a
                      href={"https://www.pinterest.com/OGeniusPriorityLtd/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <RiEarthLine color="white" size={16} />
                    </a>
                  </div>
                </div>
                <Link
                  to={homeCarousel?.discovermorelink?.link ?? "/publications"}
                  to={{
                    pathname:
                      homeCarousel?.discovermorelink?.link ?? "/publications",
                    state: {
                      title: homeCarousel?.discovermorelink?.name ?? "",
                      content:
                        homeCarousel?.discovermorelink?.content ?? "<p></p>",
                    },
                  }}
                  className="flex justify-end items-center w-full px-2 pb-6"
                >
                  <div>
                    <span className="text-white font-bold text-sm leading-relaxed">
                      {t("DISCOVER MORE...")}
                    </span>
                  </div>
                  <BsArrowRightShort size={24} color="white" />
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
