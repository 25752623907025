import { updateObject } from "../../utils/updateObject";
import * as actionsTypes from "../actions/actionTypes";

const initialReducer = {
  links: [],
  linksError: null,
  linksLoading: false,
  link: null,
  genericLinks: [],
  platfomLinks: [],
};

const getLinksStart = (state, action) => {
  return updateObject(state, {
    links: [],
    linksError: null,
    linksLoading: true,
    link: null,
  });
};

const getLinksSuccess = (state, action) => {
  return updateObject(state, {
    links: action.links,
    linksError: null,
    linksLoading: false,
    link: null,
  });
};

const getAdminPlatfomLinksSuccess = (state, action) => {
  return updateObject(state, {
    linksError: null,
    linksLoading: false,
    link: null,
    platfomLinks: action.platfomLinks,
  });
};

const getAdminGenericLinksSuccess = (state, action) => {
  return updateObject(state, {
    linksError: null,
    linksLoading: false,
    link: null,
    genericLinks: action.genericLinks,
  });
};

const getLinksFail = (state, action) => {
  return updateObject(state, {
    links: [],
    linksError: action.error,
    linksLoading: false,
    link: null,
  });
};

const getOneLinksSuccess = (state, action) => {
  return updateObject(state, {
    links: [],
    linksError: null,
    linksLoading: false,
    link: action.link,
  });
};

const reducer = (state = initialReducer, action) => {
  switch (action.type) {
    case actionsTypes.GET_LINKS_START:
      return getLinksStart(state, action);
    case actionsTypes.GET_LINKS_SUCCESS:
      return getLinksSuccess(state, action);
    case actionsTypes.GET_ADMIN_GENERIC_LINKS_SUCCESS:
      return getAdminGenericLinksSuccess(state, action);
    case actionsTypes.GET_ADMIN_PLATFOM_LINKS_SUCCESS:
      return getAdminPlatfomLinksSuccess(state, action);
    case actionsTypes.GET_ONE_LINKS_SUCCESS:
      return getOneLinksSuccess(state, action);
    case actionsTypes.GET_LINKS_FAIL:
      return getLinksFail(state, action);
    default:
      return state;
  }
};

export default reducer;
