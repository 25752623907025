import React from "react";
import { useTranslation } from "react-i18next";
import { FaAppStoreIos } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { Button } from "../Buttons";
import parse from "html-react-parser";

const WidgetH = (props) => {
  const { t } = useTranslation();
  return (
    <div
      id={props.widget.hashlink}
      className="flex flex-col-reverse video-games h-full justify-between space-y-6 py-6"
    >
      <div className="flex flex-col-reverse lg:flex-row">
        <div className="w-full h-35vh md:h-50vh">
          <img
            className="h-full w-full object-cover object-center"
            alt="Games"
            src={`${process.env.REACT_APP_BACKEND_URL}/site/${props.widget.image}`}
          />
        </div>
        <div className="w-full  flex flex-col justify-between pl-16 pr-6">
          <div className="product-name text-xl pb-5">
            <span>{props.widget.title}</span>
          </div>
          <div className="video-games-text lg:max-h-30vh overflow-scroll scrollbar-hidden">
            {
              props.widget.body != undefined ? parse(eval(props.widget.body)) : parse("<p></p>"
            )}
          </div>
          {props.widget.description === "app" ? (
            <div className="flex items-center space-x-12 py-5 lg:items-baseline">
              <div className="game-available text-lg">
                {t("Available in Store")}
              </div>
              <div className="flex items-center space-x-6">
                <a
                  href={props.widget.appstore}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaAppStoreIos />
                </a>
                <a
                  href={props.widget.playstore}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IoLogoGooglePlaystore />
                </a>
              </div>
            </div>
          ) : (
            <a
              href={props.widget.externallink}
              target="_blank"
              rel="noopener noreferrer"
              className="py-5 lg:p-0"
            >
              <Button name={t("Visit Platform")} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default WidgetH;
