import "./aboutcss.css";
import motherboard from "../images/motherboard.jpg";
import { Button } from "../components/Buttons";
import { SiInstagram, SiTwitter, SiFacebook } from "react-icons/si";
import { RiEarthLine } from "react-icons/ri";
import ServiceCard from "../servicesComponent/ServiceCard";
import opanda from "../images/development.jpg";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as actionTypes from "../store/actions/";
import Spinner from "../components/Spinner";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { Carousel } from "@trendyol-js/react-carousel";
import contants from "../admin/contants";
import { AiOutlineLinkedin } from "react-icons/ai";

import dotenv from "dotenv";
dotenv.config();

export const AboutPageHeader = () => {
  const { t } = useTranslation();
  return (
    <div className="about-page-header about-hd-title w-full relative mt-28">
      <img
        alt="about"
        src={motherboard}
        className="absolute object-center object-cover w-full h-full"
      />
      <div className="about-page-title z-10 absolute w-full h-full opacity-60"></div>
      <div className="get-to-know-us justify-center items-center flex absolute z-20 w-full h-full text-center text-5xl">
        <span>{t("Get to Know Us")}</span>
      </div>
    </div>
  );
};

export const ExecutifLeadership = () => {
  const dispatch = useDispatch();
  const excutifLeadership = useSelector(
    (state) => state.about.excutifLeadership
  );
  const excutifLeadershipLoading = useSelector(
    (state) => state.about.excutifLeadershipLoading
  );
  const excutifLeaders = useSelector((state) => state.about.excutifLeaders);
  const excutifLeadersLoading = useSelector(
    (state) => state.about.excutifLeadersLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getExcutifLeadership(language));
    dispatch(actionTypes.getExcutifLeaders());
  }, [dispatch, language]);
  return (
    <div id="excutif-leadership" className="executif-leadership pt-16">
      {excutifLeadershipLoading ? (
        <div className="w-full h-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="founded-on text-sm text-center">
            {excutifLeadership?.subtitle}
          </div>
          <div className="executif-body text-center text-sm">
            <div className="executif-title text-4xl text-center py-6 px-5">
              <span>{excutifLeadership?.title}</span>
            </div>
            <div className="executif-text text-center text-base pb-7 px-5 lg:px-52">
              {excutifLeadership?.description != undefined
                ? parse(eval(excutifLeadership?.description))
                : parse("<p></p>")}
            </div>
            <div className="flex flex-col space-y-5 md:flex-row md:space-y-0 md:space-x-10 overflow-x-scroll scrollbar-hidden xl:justify-center items-center executif-leaders px-5 md:px-20">
              {excutifLeadersLoading ? (
                <div className="w-full h-full flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                excutifLeaders.map((leader, index) => (
                  <ExecutifLeaderCard
                    key={index}
                    number={index}
                    leader={leader}
                    className="leader"
                  />
                ))
              )}
            </div>
            <div>
              <Careers />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const ExecutifLeaderCard = (props) => {
  const post = props.post;
  let classes =
    "flex-shrink-0 executif-leader-card md:w-80 pt-7 px-6 pb-16 flex flex-col justify-center items-center";
  if (props.number === 0) {
    classes =
      "flex-shrink-0 executif-leader-card md:w-80 pt-7 px-6 pb-16 flex flex-col justify-center items-center";
  }
  if (props.number === 1) {
    classes =
      "m-3 md:m-0 md:h-96 flex-shrink-0 executif-leader-card md:w-80 pt-7 px-6 pb-16 md:py-4 flex flex-col justify-center md:justify-around items-center";
  }
  if (props.number === 2) {
    classes =
      "m-3 md:m-0 flex-shrink-0 executif-leader-card md:w-80 pt-7 px-6 pb-16 flex flex-col justify-center items-center";
  }
  let imageClasses = `object-fill ${
    props.number === 1 ? "w-20 h-20" : "w-16 h-16"
  } rounded-full`;
  if (post === "MD") {
    classes =
      "executif-leader-md pt-10 px-6 w-96  pb-20 flex flex-col justify-center items-center";
    imageClasses = "object-fill executif-photo-md";
  }
  return (
    <div className={classes}>
      <div>
        <img
          src={`${process.env.REACT_APP_BACKEND_URL}/site/${props.leader.profile}`}
          alt="image2"
          className={imageClasses}
        />
      </div>
      <div className="executif-name">
        <span>{props.leader.name}</span>
      </div>
      <div className="executif-leader-text">
        {props.leader.quote != undefined
          ? parse(eval(props.leader.quote))
          : parse("<p></p>")}
      </div>
    </div>
  );
};

export const Careers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const career = useSelector((state) => state.about.career);
  const careerLoading = useSelector((state) => state.about.careerLoading);
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getCareer(language));
  }, [dispatch, language]);
  return (
    <div
      id="careers"
      className="flex flex-col careers pt-5 md:flex-row md:pl-16 md:h-screen-second"
    >
      {careerLoading ? (
        <div className="w-full -h-full flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="w-full flex flex-col justify-between careers-body px-5 md:pr-10">
            <div className="careers-title text-4xl text-center md:text-left py-5">
              <span>{career?.title}</span>
            </div>
            <div className="careers-text text-center md:text-left text-base max-h-50vh overflow-scroll scrollbar-hidden">
              {career?.description != undefined
                ? parse(eval(career?.description))
                : parse("<p></p>")}
            </div>
            <div className="careers-bottom-text text-center md:text-left text-base pt-5">
              <p></p>
            </div>
            <Link to="/contact-us" className="py-5 md:text-left">
              <Button name={t("Contact us")} />
            </Link>
          </div>
          <div className="w-full h-full">
            <img
              className="w-full h-full object-cover object-center"
              alt="career"
              src={`${process.env.REACT_APP_BACKEND_URL}/site/${career?.image}`}
            />
          </div>
        </>
      )}
    </div>
  );
};

export const StaffAndTeam = () => {
  const dispatch = useDispatch();
  const staff = useSelector((state) => state.about.staff);
  const staffLoading = useSelector((state) => state.about.staffLoading);
  const staffMembers = useSelector((state) => state.about.staffMembers);

  let staffMembersOne = [];
  let staffMembersTwo = [];
  let i;
  for (i = 0; i < staffMembers?.length; i++) {
    if (i < staffMembers?.length / 2) {
      staffMembersOne.push(staffMembers[i]);
    } else {
      staffMembersTwo.push(staffMembers[i]);
    }
  }

  const staffMembersLoading = useSelector(
    (state) => state.about.staffMembersLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getStaff(language));
    dispatch(actionTypes.getStaffMembers());
  }, [dispatch, language]);
  const sliderSettings = {
    centerMode: true,
    lazyLoad: true,
    infinite: true,
    swipeToSlide: true,
    focusOnSelect: true,
    speed: 500,
    slidesToShow: 3,
    initialSlide: 1,
    arrows: false,
    rows: 2,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          initialSlide: 0,
          centerMode: false,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 2,
          initialSlide: 0,
          centerMode: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          initialSlide: 0,
          centerMode: false,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 2,
          initialSlide: 0,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <div
      id="team-staff"
      className="flex flex-col md:flex-row staff-team pb-10 pt-10"
    >
      <div
        data-aos="slide-right"
        data-aos-duration="2000"
        className="flex flex-col flex-wrap md:w-2/5 staff-team-body pl-5 pr-5 m-auto lg:ml-16"
      >
        {staffLoading ? (
          <div className="w-full h-full flwx justify-center itemss-center">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="staff-team-title text-4xl py-10">
              <span>{staff?.title}</span>
            </div>
            <div className="staff-team-text text-sm ">
              {staff?.description != undefined
                ? parse(eval(staff?.description))
                : parse("<p></p>")}
            </div>
            <div className="staff-team-bottom-text py-7">
              <p></p>
            </div>
          </>
        )}
      </div>
      <div
        data-aos="slide-right"
        data-aos-duration="1000"
        className="md:w-3/5 items-center space-y-1 overflow-x-scroll scrollbar-hidden px-5"
      >
        {staffMembersLoading ? (
          <div className="w-full h-full flex justify-center itemss-center">
            <Spinner />
          </div>
        ) : (
          <div className="flex flex-col space-y-3 md:space-y-5 lg:space-y-7">
            <Carousel
              useArrowKeys
              responsive={true}
              show={3.1}
              slide={2}
              transition={0.5}
              swiping={true}
              useArrowKeys={true}
            >
              {staffMembersOne?.map((member, index) => (
                <StaffCard member={member} />
              ))}
            </Carousel>
            <Carousel
              useArrowKeys={true}
              responsive={true}
              show={3.1}
              slide={2}
              transition={0.5}
              swiping={true}
            >
              {staffMembersTwo?.map((member, index) => (
                <StaffCard member={member} />
              ))}
            </Carousel>
          </div>
        )}
      </div>
    </div>
  );
};

export const StaffCard = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const hovering = () => {
    if (isHovered) {
      setIsHovered(false);
    } else {
      setIsHovered(true);
    }
  };

  if (isHovered) {
    return (
      <div
        className="relative staff-card-img w-28 h-28 md:w-36 md:h-36 lg:w-44 lg:h-44 xl:w-56 xl:h-56"
        onMouseLeave={hovering}
      >
        <img
          alt="image3"
          src={`${process.env.REACT_APP_BACKEND_URL}/site/${props.member.profile}`}
          className="h-full w-full object-center object-cover st-card-img"
        />
        <div className="absolute bottom-0 py-4 inset-x-0 bg-main-color flex justify-around staff-social-media">
          {props.member?.socialMedia?.map((media) => (
            <a href={media?.link} target="_blank" rel="noopener noreferrer">
              <SiInstagram
                color="white"
                size={16}
                className={
                  media?.name === contants.instagram ? "block" : "hidden"
                }
              />
              <SiTwitter
                color="white"
                size={16}
                className={
                  media?.name === contants.twitter ? "block" : "hidden"
                }
              />
              <SiFacebook
                color="white"
                size={16}
                className={
                  media?.name === contants.facebook ? "block" : "hidden"
                }
              />
              <AiOutlineLinkedin
                color="white"
                size={20}
                className={
                  media?.name === contants.linkedin ? "block" : "hidden"
                }
              />
            </a>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="staff-card w-28 h-28 md:w-32 md:h-32 lg:w-44 lg:h-44 xl:w-56 xl:h-56 py-3 px-2 flex flex-col justify-between"
        onMouseEnter={hovering}
      >
        <div className="staff-name text-sm md:text-lg text-center">
          <span>{props.member.name}</span>
        </div>
        <div className="staff-position text-sm  md:text-lg">
          <span>{props.member.position}</span>
        </div>
        <div className="flex justify-around staff-social-media">
          {props.member?.socialMedia?.map((media) => (
            <a href={media?.link} target="_blank" rel="noopener noreferrer">
              <SiInstagram
                color="white"
                size={16}
                className={
                  media?.name === contants.instagram ? "block" : "hidden"
                }
              />
              <SiTwitter
                color="white"
                size={16}
                className={
                  media?.name === contants.twitter ? "block" : "hidden"
                }
              />
              <SiFacebook
                color="white"
                size={16}
                className={
                  media?.name === contants.facebook ? "block" : "hidden"
                }
              />
              <AiOutlineLinkedin
                color="white"
                size={20}
                className={
                  media?.name === contants.linkedin ? "block" : "hidden"
                }
              />
            </a>
          ))}
        </div>
      </div>
    );
  }
};

export const AwardsAndRecognition = () => {
  const dispatch = useDispatch();
  const awardsIntro = useSelector((state) => state.about.awardsIntro);
  const awardsIntroLoading = useSelector(
    (state) => state.about.awardsIntroLoading
  );
  const awards = useSelector((state) => state.about.awards);
  const awardsLoading = useSelector((state) => state.about.awardsLoading);
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getAwardsIntro(language));
    dispatch(actionTypes.getAwards(language));
  }, [dispatch, language]);
  const service = {
    image: opanda,
    description: "Programming and Design",
    name: "Simulations",
  };
  return (
    <div
      id="awards"
      className="awards-recognition flex flex-col pt-10 overflow-hidden scrollbar-hidden"
    >
      {awardsIntroLoading ? (
        <div className="w-full h-full flwx justify-center itemss-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div
            data-aos="zoom-out-up"
            data-aos-duration="1000"
            className="awards-recognition-title text-center text-3xl"
          >
            <span>{awardsIntro?.title}</span>
          </div>
          <div
            data-aos="zoom-out-up"
            data-aos-duration="1000"
            className="awards-recognition-text text-base text-center px-10 pb-10 lg:px-52"
          >
            {awardsIntro?.summary != undefined
              ? parse(eval(awardsIntro?.summary))
              : parse("<p></p>")}
          </div>
        </>
      )}
      {awardsLoading ? (
        <div className="w-full h-full flwx justify-center itemss-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div
            data-aos="zoom-out-up"
            data-aos-duration="1500"
            className="flex flex-col md:flex-row md:space-x-6 justify-between"
          >
            {awards.map((award, index) => {
              if (award.ismain === "true") {
                return (
                  <div className="md:w-1/2">
                    <ServiceCard service={service} />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          <div
            data-aos="zoom-out-up"
            data-aos-duration="2000"
            className="flex flex-col recognition-image2 md:flex-row md:space-x-6 md:px-12 justify-center"
          >
            {awards.map((award, index) => {
              if (award.ismain === "false") {
                return (
                  <div className="">
                    <ServiceCard service={service} sm />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        </>
      )}
    </div>
  );
};

export const EthicsCompliance = () => {
  const dispatch = useDispatch();
  const ethicsAndCompliance = useSelector(
    (state) => state.about.ethicsAndCompliance
  );
  const ethicsAndComplianceLoading = useSelector(
    (state) => state.about.ethicsAndComplianceLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getEthicsAndCompliance(language));
  }, [dispatch, language]);
  return (
    <div
      id="ethics"
      className="ethics-compliance flex flex-col md:flex-row md:space-x-10 md:pl-14 md:py-28"
    >
      {ethicsAndComplianceLoading ? (
        <div className="w-full h-full flwx justify-center itemss-center">
          <Spinner />
        </div>
      ) : (
        <>
          {ethicsAndCompliance.map((ethicAndCompliance, index) => {
            if (ethicAndCompliance.type === "ethics") {
              return (
                <div
                  key={index}
                  className="flex flex-col justify-between md:w-1/2"
                >
                  <div className="ethics-compliance-title px-10 md:px-0">
                    <span>{ethicAndCompliance.title}</span>
                  </div>
                  <div className="ethics-compliance-text px-10 md:px-0">
                    {ethicAndCompliance.description != undefined
                      ? parse(eval(ethicAndCompliance.description))
                      : parse("<p></p>")}
                  </div>
                  <div className="ethics-compliance-bottom-text p-10 md:p-0">
                    <span></span>
                  </div>
                  <div className="h-30vh lg:h-40vh relative">
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}/site/${ethicAndCompliance.image}`}
                      alt="Compliance"
                      className="absolute w-full h-full object-cover object-center"
                    />
                  </div>
                </div>
              );
            } else {
              return (
                <div key={index} className="flex flex-col h-full md:w-1/2">
                  <div className="h-40vh relative mt-5 md:mt-0">
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}/site/${ethicAndCompliance.image}`}
                      alt="Compliance"
                      className="absolute w-full h-full object-cover object-center"
                    />
                  </div>
                  <div className="compliance-body py-10 pl-10 pr-5 md:h-30vh lg:h-40vh">
                    <div className="compliance-title">
                      <span>{ethicAndCompliance.title}</span>
                    </div>
                    <div className="compliance-text">
                      {ethicAndCompliance.description != undefined
                        ? parse(eval(ethicAndCompliance.description))
                        : parse("<p></p>")}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </>
      )}
    </div>
  );
};

export const WorkWithYou = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const getInTouch = useSelector((state) => state.about.getInTouch);
  const getInTouchLoading = useSelector(
    (state) => state.about.getInTouchLoading
  );
  const language = useSelector((state) => state.home.language);
  useEffect(() => {
    dispatch(actionTypes.getGetInTouch(language));
  }, [dispatch, language]);
  return (
    <div
      id="work-with-you"
      className="relative w-full min-h-mail-card sm:min-h-0 h-96 flex"
    >
      {getInTouchLoading ? (
        <div className="w-full h-full flwx justify-center itemss-center">
          <Spinner />
        </div>
      ) : (
        <>
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}/site/${getInTouch?.image}`}
            className="absolute w-full h-full object-center object-cover blur-md"
            alt="image4"
          />
          <div className="work-with-you h-full w-full z-10 absolute opacity-60"></div>
          <div
            className="work-with-you-body h-full text-center w-full z-20 absolute text-white 
      space-y-3 pl-7 p-5 md:px-56 lg:px-80 xl:px-96"
          >
            <div className="work-with-you-title text-xl py-5">
              <span>{getInTouch?.title}</span>
            </div>
            <div className="work-with-you-text pb-5 md:pr-30 md:pl-30">
              {getInTouch?.description != undefined
                ? parse(eval(getInTouch?.description))
                : parse("<p></p>")}
            </div>
            <Link to="contact-us">
              <Button name={t("Contact Us")} type="contact-us" />
            </Link>
          </div>
        </>
      )}
    </div>
  );
};
